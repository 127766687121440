import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import DressSearchResultsDesktop from "../dressSearchResultsDesktop/DressSearchResultsDesktop";
import DressSearchResultsMobile from "../dressSearchResultsMobile/DressSearchResultsMobile";

const DressSearchResults = () => {
  const { height, width } = useWindowDimensions();

  return (
    <>
      {width <= 1024 ? <DressSearchResultsMobile/> : <DressSearchResultsDesktop/>}
    </>
  );
};

export default DressSearchResults;
