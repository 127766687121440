import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styles from './DetailsRent.module.css';
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from '../navBarMobile/NavBarMobile';
import Footer from "../footer/Footer";
import FooterMobile from '../footerMobile/FooterMobile';
import { TextDescription, Title, TextLinking } from "../shared/text/Text";
import Breadcrumb from "../navigation/breadcrumb/Breadcrumb";
import CardProduct from "../reservationPayment/Product";
import moment from 'moment';
import SuccessMessage from '../successMessage/SucessMessage';
import Button from '../shared/button';
import axios from '../../api/axios.config';
import { useState } from 'react';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import { modal } from '../modalManagment';
import ModalConfirmationAppointment from '../modal/ModalConfirmationAppointment';
import ModalAddressComplete from '../modal/ModalAddressComplete';
import ModalWaiting from '../modal/ModalWaiting';

const DetailsRent = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { width } = useWindowDimensions();
    const [dressModel, setDressModel] = useState({});
    const [status, setStatus] = useState('')
    const [appointment, setAppointment] = useState(false)
    const { id } = useParams();
    const [rentData, setRentData] = useState({});
    const [isDisplay, setIsDisplay] = useState(true);
    const toggleDisplay = () => {
      setIsDisplay(!isDisplay);
    };
    const [orderCode, setOrderCode] = useState('')
    const [dressDefault, setDressDefault] = useState(null)

    const onGetOrder = async () => {
      await axios.get(`/api/orders/${location.state?.id_order}`)
      .then(resp => {
        if(resp.status === 200){
          setDressDefault(resp.data.data.dress_id)
          setOrderCode(resp.data.data.code)
        }
      })
      .catch(err => console.log(err))
    }


    const onGetDress = async () => {
      await axios.get(`/api/dress-models/${id}`)
      .then(resp => {
        if(resp.status === 200){
          let model = resp.data.data;
          const data = {
            ...model,
            dress: model.dress.find(d => d.id === dressDefault) ? model.dress.filter(d => d.id === dressDefault) : []
          }
          setDressModel(data)
        }
      })
      .catch(err => console.log(err))
    }

    const getRent = async () => {
      await axios.get(`/api/rent/${location.state?.id}`)
      .then(resp => {
        if(resp.data.data){
          const appmt = resp.data.data
          setRentData(appmt)

          setStatus(`Rentado para ${moment(appmt.end_date).format('DD - MMM YYYY')}`)

          if(appmt.status === 'cita_agendada'){
            setStatus(`Cita agendada: ${moment(appmt.appointment.date).format('DD - MMM YYYY hh:mmA')}`)
            setAppointment(appmt.appointment)
          }

          if(appmt.status === 'ajustes_listos'){
            setStatus(`Tus ajustes estan listos el: ${moment(appmt?.appointment?.ajustes_at).format('DD - MMM YYYY')}`)
          }
        }
      })
      .catch(error => console.log(error))
    }

    const [cancelAppoin, setCancelAppoint] = useState(false)

    const deleteAppointment = async () => {
      modal.close()
      modal.open(<ModalWaiting label="ELIMINANDO" description="Espere un momento..." />, () => {}, 'BackdropContainerCenter')
      await axios.delete(`/api/appointment/${appointment?.id}`)
      .then(resp => {
        if(resp.status === 200){
          modal.close()
          setCancelAppoint(true)
          setAppointment(null)
          getRent()
          onGetDress()
        }
      })
      .catch(e => {console.log(e);  modal.close()})
    }

    const onModalConfirmCancelAppointment = () => {
      modal.open(<ModalConfirmationAppointment onClick={deleteAppointment} date={moment(appointment.date)} hour={moment(appointment.date).format('hh:mm a')} cancel={true} />, () => {}, 'BackdropContainerCenter')
    }

    useEffect(() => {
      (async () => {
        await onGetOrder();
        getRent();
      })()
    },[location.state])

    useEffect(() => {
      if(dressDefault)
        onGetDress();
    },[dressDefault])

    const verifiedCityAbailable = () => {
      if(rentData?.order?.user?.address && rentData?.order?.user?.address?.length === 0){
        return true;
      }else if((rentData?.order?.dress?.store === 'GDL' && rentData?.order?.user?.address[0]?.city?.state_id === 14) || (rentData?.order?.dress?.store === 'LEON' && rentData?.order?.user?.address[0]?.city?.state_id === 12)){
        return true
      }else return false
    }

    return (
        <>
        {width <= 1024 ?
          <div>
            <section>
              <NavBarMobile isSelectedProfile/>
            </section>
            <section className={styles.container}>
              <Title>DETALLES DE RENTA</Title>
    
              <Breadcrumb items={[{name: 'Perfil', action: () => navigate('/perfil') }, {name: dressModel.name, action: () => {}}]} />
            </section>
            <SuccessMessage display={true} label={cancelAppoin ? 'Tu cita ha sido cancelada correctamente' : status} />
            <section className={styles.container}>
              <div className={styles.containerCart}>
                <CardProduct data={{...dressModel, type: 'RENTA'}} disabled={true} key={Math.random()} calendarRange={rentData?.start_date ? [rentData.start_date, rentData.end_date] : ['', '']} sizeValue={dressModel.dress ? dressModel.dress[0].size_id : ''} colorName={dressModel.dress ? dressModel.dress[0].color_id : ''} />
              </div>
            
                <div style={{marginBottom: 60}}>
                    <TextDescription>Código para entrega: {orderCode}</TextDescription>
                    <TextLinking style={{color: 'rgba(116, 128, 126, 1)'}} onClick={() => navigate('/terminos-condiciones', {state: { optionB: null, type: 'RENTA', isDetails: true }})}>Leer Términos y Condiciones de Renta</TextLinking>
                </div>

                {appointment && appointment.date &&
                  <div>
                    <button
                      type="button"
                      className={styles.collapsible}
                      onClick={toggleDisplay}
                    >
                      <span>CITA AGENDADA</span>
                      {isDisplay ? (
                        <Icon
                          icon="akar-icons:minus"
                          color="#d6a191"
                          width="14"
                          height="14"
                        />
                      ) : (
                        <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
                      )}
                    </button>
                    <div
                      className={`${styles.container} ${
                        isDisplay ? styles.display : styles.hidden
                      }`}
                    >
                      <div>
                        <TextDescription>Cita de prueba</TextDescription>

                        <TextDescription>Fecha y hora:</TextDescription>
                        <TextDescription>{moment(appointment.date).format('DD - MMM YYYY, hh:mm a')}</TextDescription>

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 16}}>
                          <TextDescription>
                            Ubicación:
                          </TextDescription>

                          <TextLinking onClick={() => window.open('https://goo.gl/maps/ZwuTQfzeWxDEKFbeA', '_blank')} style={{color: '#97A6A3', textDecorationLine: 'underline'}}>ver mapa</TextLinking>
                        </div>

                        <TextDescription>{appointment?.store?.ubication ? appointment?.store?.ubication : 'Sin dirección'}</TextDescription>
                      </div>

                      <Button label="CANCELAR CITA" onClick={onModalConfirmCancelAppointment} styleText={{color: '#DF585F'}} style={{borderColor: '#DF585F'}} outline={true} />
                      <TextDescription>*Para realizar cambios en la hora o fecha de tu cita, deberás cancelar y volver a agendar.</TextDescription>
                    </div>
                  </div>
                }

                {(!appointment || (moment(rentData.appointment.ajustes_at).isSameOrBefore(moment()) && rentData.first_date === false)) &&
                  <Button disabled={!verifiedCityAbailable()} onClick={() => (rentData?.order?.user?.address && rentData?.order?.user?.address?.length === 0) ? modal.open(<ModalAddressComplete onClick={() => {navigate("/perfil", { state: { settingActive: true } }); modal.close();}} />, () => {}, 'BackdropContainerCenter'):navigate(`/agendar-cita/${location.state?.id}`, { state: {id, rent: rentData} })} label="Agendar prueba"></Button>
                }
                {!verifiedCityAbailable() &&
                  <div style={{display: 'flex', alignItems: 'center', marginTop: 12}}>
                    <Icon icon="bi:exclamation-circle" style={{marginRight: 9}} />
                    <TextDescription>Las citas de prueba no están disponibles en tu ciudad.</TextDescription>
                  </div>
                }
            </section>
          </div>
          :
          <div>
            <section>
                <NavBarDesktop />
            </section>
            <section className={styles.container}>
              <Title>DETALLES DE RENTA</Title>
    
              <Breadcrumb items={[{name: 'Perfil', action: () => navigate('/perfil') }, {name: dressModel.name, action: () => {}}]} />
            </section>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{width: 648}}>
                <SuccessMessage display={true} label={cancelAppoin ? 'Tu cita ha sido cancelada correctamente' : status} />
                <section className={styles.container}>
                  <div className={styles.containerCart}>
                    <CardProduct data={{...dressModel, type: 'RENTA'}} disabled={true} key={Math.random()} calendarRange={rentData?.start_date ? [rentData.start_date, rentData.end_date] : ['', '']} sizeValue={dressModel.dress ? dressModel.dress[0].size_id : ''} colorName={dressModel.dress ? dressModel.dress[0].color.name : ''} />
                  </div>
                
                    <div style={{marginBottom: 60}}>
                        <TextDescription>Código para entrega: {orderCode}</TextDescription>
                        <TextLinking style={{color: 'rgba(116, 128, 126, 1)'}} onClick={() => navigate('/terminos-condiciones', {state: { optionB: null, type: 'RENTA', isDetails: true }})}>Leer Términos y Condiciones de Renta</TextLinking>
                    </div>

                    {appointment && appointment.date &&
                      <div>
                        <button
                          type="button"
                          className={styles.collapsible}
                          onClick={toggleDisplay}
                        >
                          <span>CITA AGENDADA</span>
                          {isDisplay ? (
                            <Icon
                              icon="akar-icons:minus"
                              color="#d6a191"
                              width="14"
                              height="14"
                            />
                          ) : (
                            <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
                          )}
                        </button>
                        <div
                          className={`${styles.container} ${
                            isDisplay ? styles.display : styles.hidden
                          }`}
                        >
                          <div>
                            <TextDescription>Cita de prueba</TextDescription>

                            <TextDescription>Fecha y hora:</TextDescription>
                            <TextDescription>{moment(appointment.date).format('DD - MMM YYYY, hh:mm a')}</TextDescription>

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 16}}>
                              <TextDescription>
                                Ubicación:
                              </TextDescription>

                              <TextLinking onClick={() => window.open('https://goo.gl/maps/ZwuTQfzeWxDEKFbeA', '_blank')} style={{color: '#97A6A3', textDecorationLine: 'underline'}}>ver mapa</TextLinking>
                            </div>

                            <TextDescription>{appointment?.store?.ubication ? appointment?.store?.ubication : 'Sin dirección'}</TextDescription>
                          </div>

                          <Button label="CANCELAR CITA" onClick={onModalConfirmCancelAppointment} styleText={{color: '#DF585F'}} style={{borderColor: '#DF585F'}} outline={true} />
                          <TextDescription>*Para realizar cambios en la hora o fecha de tu cita, deberás cancelar y volver a agendar.</TextDescription>
                        </div>
                      </div>
                    }

                {(!appointment || (moment(rentData.appointment.ajustes_at).isSameOrBefore(moment()) && rentData.first_date === false)) &&
                  <Button disabled={!verifiedCityAbailable()} onClick={() => (rentData?.order?.user?.address && rentData?.order?.user?.address?.length === 0) ? modal.open(<ModalAddressComplete onClick={() => {navigate("/perfil", { state: { settingActive: true } }); modal.close();}} />, () => {}, 'BackdropContainerCenter') : navigate(`/agendar-cita/${location.state?.id}`, { state: {id, rent: rentData} })} label="Agendar prueba"></Button>
                }
                {!verifiedCityAbailable() &&
                  <div style={{display: 'flex', alignItems: 'center', marginTop: 12}}>
                    <Icon icon="bi:exclamation-circle" style={{marginRight: 9}} />
                    <TextDescription>Las citas de prueba no están disponibles en tu ciudad.</TextDescription>
                  </div>
                }
                </section>
              </div>
            </div>
          </div>
          }
          {width <= 1024 ? <FooterMobile /> : <Footer /> }
        </>
      );
}

export default DetailsRent;