import React, { useCallback, useEffect, useRef, useState } from 'react';
import style from './rentalProcess.module.css';
import Cards1 from '../../assets/cards-home/cards-1.svg';
import Cards2 from '../../assets/cards-home/cards-2.svg';
import Cards3 from '../../assets/cards-home/cards-3.svg';
import Cards4 from '../../assets/cards-home/cards-4.svg';
import Cards5 from '../../assets/cards-home/cards-5.svg';
import Graduaciones from '../../assets/cards-home/graduaciones.jpg'
import OcasionEspecial from '../../assets/cards-home/ocasion-especial.jpg'
import Espalda from '../../assets/cards-home/escotes-y-espalda.jpg'
import Flores from '../../assets/cards-home/vestidos-floreados.jpg'
import CutOuts from '../../assets/cards-home/cut-outs.jpg'
import FAQ from '../../assets/cards-home/preguntas-frecuentes.jpg'
import Historia from '../../assets/cards-home/nuestra-historia.jpg'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onSetFilterValues } from '../../store/slices/filters/filtersSlice';
import useWindowDimensions  from '../../hooks/useWindowDimensions';
import ArrowNext from '../../assets/icons/ArrowNext'
import ArrowPrevious from '../../assets/icons/ArrowPrevious'


const Cards = ({ collaborations }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const parent = useRef(null);
    const { width } = useWindowDimensions()

    const [leftScrollWidth, setLeftScrollWidth] = useState(0);
    const [scrollWidth, setScrollWidth] = useState(0)
    const [clientWidth, setClientWidth] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [data, setData] = useState([
        // {image: Cards4, label: 'Vestidos con detalle', link: () => goToDressSearch(13, 'type_event')},
        {image: Graduaciones, label: 'Vestidos de graduación', link: () => goToDressSearch(2, 'type_event')},
        {image: OcasionEspecial, label: 'Una ocasión especial', link: () => goToDressSearch(16, 'colors')},
        {image: Espalda, label: 'Vestidos con espalda y escote', link: () => goToDressSearch(11, 'type_event')},
        {image: Flores, label: 'Vestidos florales', link: () => goToDressSearch(14, 'type_event')},
        {image: CutOuts, label: 'Vestidos Cut-Out', link: () => goToDressSearch(15, 'type_event')},
        {image: FAQ, label: 'Preguntas frecuentes', link: () => navigate('/faq')},
        {image: Historia, label: 'Nuestra historia', link: () => navigate('/faq')}
    ])

    const [positions, setPositions] = useState([])

    const goToDressSearch = (filter, nameFilter) =>{
        let query = '?'
        const filtersSend = [];
        let setFilters = {}

        if(nameFilter === 'colors'){
          query += query.includes('?') ? `&colors=${filter}` : `colors=${filter}`
          filtersSend.push({id: 'valueColor', value: 'Blanco', key: '16'})
          setFilters = { ...setFilters, valueColor: filter+''}
        }
        if(nameFilter === 'type_event' && filter === 11){
            query += query.includes('?') ? `&type_event_ids=${filter}` : `type_event_ids=${filter}`
            filtersSend.push({ id: 'valueTypeEvent', value: 'Espalda', key: '11' })
            setFilters = { ...setFilters, valueTypeEvent: [filter]}
        }
        if(nameFilter === 'type_event' && filter === 13){
            query += query.includes('?') ? `&type_event_ids=${filter}` : `type_event_ids=${filter}`
            filtersSend.push({ id: 'valueTypeEvent', value: 'Textura', key: '13' })
            setFilters = { ...setFilters, valueTypeEvent: [filter]}
        }
        if(nameFilter === 'type_event' && filter === 2){
            query += query.includes('?') ? `&type_event_ids=${filter}` : `type_event_ids=${filter}`
            filtersSend.push({ id: 'valueTypeEvent', value: 'Graduación', key: '2' })
            setFilters = { ...setFilters, valueTypeEvent: [filter]}
        }
        if(nameFilter === 'type_event' && filter === 14){
            query += query.includes('?') ? `&type_event_ids=${filter}` : `type_event_ids=${filter}`
            filtersSend.push({ id: 'valueTypeEvent', value: 'Flores', key: '14' })
            setFilters = { ...setFilters, valueTypeEvent: [filter]}
        }
        if(nameFilter === 'type_event' && filter === 15){
            query += query.includes('?') ? `&type_event_ids=${filter}` : `type_event_ids=${filter}`
            filtersSend.push({ id: 'valueTypeEvent', value: 'Cut-Out', key: '15' })
            setFilters = { ...setFilters, valueTypeEvent: [filter]}
        }
    
        dispatch(onSetFilterValues(setFilters))
    
        navigate({pathname: `/resultados-vestidos`, search: query}, { state: { itemView: filtersSend } });
    }

    const moveToright = () => {
        if (parent.current.scrollLeft < parent.current.scrollWidth) {
            if (currentIndex < (data.length - 1)) parent.current.scrollLeft = positions[currentIndex + 1];
            else parent.current.scrollLeft += 400
            setCurrentIndex(currentIndex + 1)
        }
    }

    const moveToLeft = () => {
        if (currentIndex > 1) parent.current.scrollLeft = positions[currentIndex - 1];
        else parent.current.scrollLeft -= 400
        setCurrentIndex(currentIndex - 1)
    }

    const handleScroll = useCallback(() => {
        setClientWidth(parent.current.clientWidth);
        setLeftScrollWidth(parent.current.scrollLeft)
        setScrollWidth(parent.current.scrollWidth)
    }, [])
    
    useEffect(() => {
        const div = parent.current
        div.addEventListener("scroll", handleScroll)
    }, [handleScroll])

    useEffect(() => {
        let auxArray = [...data]
        if (collaborations.length > 0) {
            collaborations.forEach((collaboration) => {
                auxArray.unshift({ image: collaboration.image, label: collaboration.name, link: () => navigate({ pathname: `/resultados-vestidos`, search: `?collaboration=${collaboration.id}` }) })
            })
        }
        setData(auxArray)
    }, [collaborations])

    useEffect(() => {
        setTimeout(() => {
            const array = []
            for (let i = 0; i < data.length; i++) {
                let position = document.getElementById(`card-${i}`).offsetLeft - 40
                array.push(position > 0 ? position : 0)
            }
            setPositions(array)
        }, 1000);
    }, [data])

    return (
        <div style={{position: 'relative'}}>
            <div className={style.containerInline} ref={parent} id="horizontal-scroller">
                {data.map((row, index) => (
                    <div className={style.cards} id={`card-${index}`} onClick={() => row.link()}>
                        <div style={{position: 'absolute', zIndex: 9, maxWidth: 327, left: 24 }}><h2>{row.label}</h2></div>
                        <img src={row.image} alt="" style={{objectFit: 'cover', height: '100%', width: '100%', borderRadius: 24}} />
                        <div style={{position: 'absolute', zIndex: 9, bottom: 24, left: 24}} onClick={() => row.link()}><p>VER MÁS</p></div>
                    </div>
                ))}

                {((leftScrollWidth + clientWidth) < scrollWidth || leftScrollWidth === 0) &&
                    <div onClick={moveToright} style={{ cursor: 'pointer', position: 'absolute', height: width < 774 ? 511 : 617, width: 60, right: 0, background: 'linear-gradient(to right, transparent 30%, #FFFFFF 70%)', zIndex: 9, display: 'flex', alignItems: 'center', justifyContent: 'right', paddingRight: '10px' }}>
                        <ArrowNext />
                    </div>
                }
                {(leftScrollWidth > 0) &&
                    <div onClick={moveToLeft} style={{ cursor: 'pointer', position: 'absolute', height: width < 774 ? 511 : 617, width: 60, left: 0, background: 'linear-gradient(to left, transparent 30%, #FFFFFF 70%)', zIndex: 9, display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: '10px'}}>
                        <ArrowPrevious />
                    </div>
                }
            </div>
        </div>
    )
}

export default Cards;