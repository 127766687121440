import React from "react";
import styles from "./navBarMobile.module.css";
import  UserIcon from '../../assets/icons/UserIcon';
import {HeartIcon} from '../../assets/icons/HeartIcon';
import BolsaIcon from '../../assets/icons/BolsaIcon';
import { useDispatch, useSelector } from "react-redux";
import sorellaMobile from "../../assets/icons/sorellaMobile.svg";
import burgerMenu from "../../assets/icons/burgerMenu.svg";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { modal } from "../modalManagment";
import ModalSidebar from "../modal/ModalSidebar";

const NavBarMobile = ({isSelectedProfile, isSelectedFavorite, isSelectedBag, onlyClose = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [onSidebar, setOnSidebar] = useState(false)
  const { cart } = useSelector((store) => store.shopCart)
  const { user } = useSelector(store => store.login)

  const closeMenu = () =>{
    onSidebar ? modal.close(() => setOnSidebar(!onSidebar)) : 
    modal.open(<ModalSidebar setOnSidebar={setOnSidebar} navigate={navigate} />,
    () => setOnSidebar(!onSidebar))
  }

  if (onlyClose) {
    return (
      <>
        <div className={styles.navContainer}>
          <div className={styles.menuSorellaArea}>
            <section className={styles.sorella} style={{ marginLeft: '45px' }} onClick={() => navigate('/principal')}>
              <img alt="sorella" src={sorellaMobile}></img>
            </section>
          </div>
          <div className={styles.containerButtonNav}>
          <button className={styles.menu} 
              onClick={() => window.close()}
              >
              <Icon icon="clarity:times-line" fontSize={30} color="rgba(95, 72, 64, 1)" />
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.navContainer}>
        <div className={styles.menuSorellaArea}>
          <button className={styles.menu} 
            onClick={() => closeMenu()}
            >
            {onSidebar ?
              <Icon icon="clarity:times-line" fontSize={30} color="rgba(95, 72, 64, 1)" />
            :
              <Icon icon="icon-park-outline:hamburger-button" fontSize={30} color="rgba(95, 72, 64, 1)" />
            }
          </button>
          <section className={styles.sorella} onClick={() => navigate('/principal')}>
            <img alt="sorella" src={sorellaMobile}></img>
          </section>
        </div>

        <div className={styles.containerButtonNav}>
          {window.localStorage.getItem("sorellaToken") ? (
            <div className={styles.containerIconNav}>
              <div className={styles.containIcon} onClick={()=>navigate("/perfil")}>
                <UserIcon colorFill={isSelectedProfile ? "#5F4840" : "none"} />
              </div>
              {user?.role !== 'administrator' &&
              <>
                <div className={styles.containIcon} onClick={()=>navigate("/favoritos")}>
                  <HeartIcon isSelected={isSelectedFavorite ? isSelectedFavorite : false}/>
                </div>
                <div className={styles.containIcon} onClick={() => navigate('/carrito')}>
                  <BolsaIcon isSelected={isSelectedBag ? isSelectedBag : false}/>
                  {(cart && cart.length > 0) &&
                    <div className={styles.containCountCart}>
                      <span className={styles.numberCountCart}>{cart.length}</span>
                    </div>
                  }
                </div>
              </>
              }
            </div>
          ) : (
            <>
              <button
                className={styles.buttonNavLogin}
                onClick={() => navigate('/inicio-sesion')}
              >
                LOG IN
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NavBarMobile;
