import React from 'react';
import styles from './ItemReservationActive.module.css';
import { useNavigate } from 'react-router-dom';
import alertImg from "../../assets/svg/provisional/alertImg.svg";
import { Icon } from '@iconify/react';

const ItemReservationActive = (props) => {
    const navigate = useNavigate();
    return(
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '90%', cursor: 'pointer'}} onClick={() => navigate(props.link, { state: {...props.state} })}>
          <div className={styles.reservesDescriptionArea} style={{display: 'flex'}}>
            <img alt="" src={props?.dress?.dress_models?.images[0]?.url} style={{width:35}}></img>
            <section className={styles.reservationArea}>
              <span className={styles.modelSpan}>{props.dress?.dress_models?.name}</span>
              <section className={styles.dateReservationArea}>
                  <img alt="" src={alertImg}></img>
                  <span className={styles.textReservationDate}>Reservado hasta: {props.reservation.end_date}</span>
              </section>
            </section>
          </div>
          <Icon icon="akar-icons:chevron-right" fontSize={11} />
        </div>
    )
}

export default ItemReservationActive;