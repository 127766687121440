import React from "react"
import styles from "./shopCart.module.css"
import NavBarDesktop from "../navBarDesktop/NavBarDesktop"
import NavBarMobile from '../navBarMobile/NavBarMobile'
import Footer from "../footer/Footer"
import FooterMobile from '../footerMobile/FooterMobile'
import { useDispatch, useSelector } from "react-redux"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { TextDescription, Title } from "../shared/text/Text"
import Breadcrumb from "../navigation/breadcrumb/Breadcrumb"
import CardProduct from "./CardProduct"
import { modal } from '../modalManagment'
import ModalTCMobile from "../modal/ModalTCMobile"
import ModalSelectType from '../modal/ModalSelectType'
import { useLocation, useNavigate } from "react-router-dom"
import Button from "../shared/button"
import { useState } from "react"
import EmptyImg from '../../assets/svg/empty-reserva.svg'
import EmptyImgRenta from '../../assets/svg/empty-renta.svg'
import { useEffect } from "react"
import Checkbox from "../shared/checkbox"
import { Icon } from "@iconify/react"
import Select from '../shared/select/Select'
import { AllSizes } from "../../constants"
import SuccessMessage from "../successMessage/SucessMessage"
import ModalOptionB from "../modal/ModalOptionB"
import { format_currency } from "../../utils"
import api from "../../api/axios.config"
import { removeProduct } from "../../store/slices/shopCart"
import ModalDeleteCartItem from "../modal/ModalDeleteCartItem"
import { getCartBack } from "../../store/thunks/shopCart"
import MexicoIcon from '../../assets/icons/mexico.svg'

const ShopCart = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { width } = useWindowDimensions()
  const [selectItem, setSelectItem] = useState('RENTA')
  const [types, setTypes] = useState([])
  const [optionB, setOptionB] = useState([])
  const [timesCart, setTimesCart] = useState([])
  const [firstLoad, setFirstLoad] = useState(false)
  
  const { valuesFilter: { valueSizes } } = useSelector((store) => store.filters)
  const { cart } = useSelector((store) => store.shopCart)

  useEffect(() => {
    if(cart && cart.length && !firstLoad) {
      setTypes([...new Set(cart.map(c => c.type).sort())])
      initTimeDress()
      setFirstLoad(true)
    }
  },[cart])

  useEffect(() => {
    if(location.state?.type){
      setSelectItem(location.state?.type)
    }
  },[location.state])

  const modalTermAndCond = (type = '') => {
    modal.close();
    modal.open(<ModalTCMobile type={type ? type : types[0]} optionB={optionB} selectSize={valueSizes[0]} isMultiple={type !== '' ? false : types.length > 1} navigate={navigate} onClick={() => {modal.close(); navigate('/pago', { state: { types: type === '' ? ['AMBOS'] : type === 'RENTA' ? [type, 'VENTA'] : [type], optionB } })}} />, () => {}, 'BackdropContainerCenter')
  }

  const modalPreventTypePayment = () => {
    modal.open(<ModalSelectType type={types[0]} optionB={optionB} selectSize={valueSizes[0]} onClick={modalTermAndCond} />, () => {}, 'BackdropContainerCenter')
  }

  const modalPopupOptionB = () => {
    modal.open(<ModalOptionB onClick={() => modal.close()} />, () => {}, 'BackdropContainerCenter')
  }

  const initTimeDress = async () => {
    const { data, status } = await api.get(`/api/cart`)
    if (status === 200) {
      const cart_back = data.data
      const time_cart = cart_back.filter(v => cart.find(c => c.id === v.id || c.id === v.sale_dress_id || c.id === v.shoe_id || c.id === v.complement_id))
      setTimesCart(time_cart)
    }
  }

  const getValidateTimeDress = async () => {
    const { data, status } = await api.get(`/api/cart`)
    if(status === 200 && data.data) {
      const cart_back = data.data;
      // Busca todos los artículos que estén en el carrito en local pero no en el carrito en backend
      const time_cart = cart_back.filter(v => !cart.some(c => c.id === v.id))
      // Eliminar productos que ya no estén en el backend
      if((time_cart.length > 0 && cart.some(c => time_cart.some(v => c.id === v.id))) || (cart_back.length === 0 && cart.length > 0)){
        dispatch(removeProduct({ ids: cart_back.length === 0 ? cart.map(c => (c.id)) : time_cart.map(v => (v.id)) }))
        modal.open(<ModalDeleteCartItem onClick={() => modal.close()} />, () => {}, 'BackdropContainerCenter')
      }
      // Crear arrays para compararlos, con lo mínimo necesario
      const timesCartToCompare = timesCart.map((c) => ({ 
        id: c.id, 
        limit_time: c.limit_time 
      }))
      const backCartToCompare = cart_back
      .filter(v => cart.find(c => c.id === v.id))
      .map((c) => ({ 
        id: c.id, 
        limit_time: c.limit_time 
      }))
      
      if(JSON.stringify(timesCartToCompare) !== JSON.stringify(backCartToCompare)){
        setTimesCart(cart_back.filter(v => cart.find(c => c.id === v.id)))
      }
    }
  }

  useEffect(() => {
    dispatch(getCartBack())
    const intervalId = setInterval(() => {
      getValidateTimeDress()
    }, 30000)
    return () => clearInterval(intervalId)
  },[])

  const convertPrice = (total, discount) => {
    let price = Number(total);
    if(discount > 0){
        price = price * (Number(discount) / 100)
        return Math.abs(price - Number(total));
    }
    return price;
  }

  return (
    <>
    {width <= 1024 ?
      <div>
        <section>
          <NavBarMobile isSelectedBag={true}/>
        </section>
        <section className={styles.container}>
          <Title>Bolsa de compras</Title>

          <Breadcrumb items={[{name: 'Selección', action: () => {} }, {name: 'Bolsa', action: () => {}}]} />

          <SectionType selectItem={selectItem} setSelectItem={setSelectItem} lengthReservation={cart ? cart.filter(c => c.type === 'APARTADO').length : 0} lengthRent={cart ? cart.filter(c => c.type === 'RENTA').length : 0} />
        </section>
        <SuccessMessage label="Las prendas permanecerán en la bolsa por el límite de tiempo indicado." icon="mdi:clock-time-four-outline" display={(cart && cart.length > 0)} />
        <section className={styles.container}>
          <div className={styles.containerCart}>
            {(cart && cart.length > 0) &&
              cart.map(row => {
                if(row.type === selectItem)
                  return <div key={Math.random()}>
                      <CardProduct 
                        data={row}
                        key={Math.random()} 
                        cartTime={true} 
                        calendarRange={row.date} 
                        sizeValue={row.type === 'VENTA' ? row.size : row.size[0]} 
                        colorValue={row.color}
                        type={row.product_type === 'dresses' && row.type === 'VENTA' ? 'sale_dresses' : row.product_type}
                      />
                      {selectItem === 'RENTA' &&
                        <div style={{marginTop: 63, marginBottom: 60}}>
                          <div className={styles.inline} style={{justifyContent: 'space-between', paddingBottom: 20}}>
                            <TextDescription style={{color: '#353535', fontWeight: '600'}}>OPCIÓN B</TextDescription>
                            <Icon icon="akar-icons:question" onClick={() => modalPopupOptionB()} />
                          </div>
                          <Checkbox label="Agregar vestido diferente de opción B" onCheck={() => (optionB.length > 0 && optionB.find(op => op.id === row.id)) ? setOptionB([...optionB.filter(op => op.id !== row.id)]) : setOptionB([...optionB, {id: row.id}])} checked={(optionB && optionB.find(op => op.id === row.id))} />
          
                          {(optionB && optionB.find(op => op.id === row.id)) &&
                            <div className={styles.contentSelectSize}>
                              <Select defaultValue={optionB.find(op => op.id === row.id).talla} onChange={(e) => setOptionB([...optionB.filter(op => op.id !== row.id), { id: optionB.find(op => op.id === row.id).id, talla: e.target.value }])}>
                                <option value="">Seleccionar talla</option>
                                {AllSizes.map(row => (
                                  <option value={row}>{row}</option>
                                ))}
                              </Select>
          
                              <TextDescription style={{marginTop: 12}}>*Te enviaremos a tu correo un catálogo para elegir tu vestido de opción B.</TextDescription>
                            </div>
                          }
                        </div>
                      }
                  </div>
                else return null
              })
            }
            <div style={{textAlign: 'center'}}>
              {((selectItem === 'RENTA' && cart) && (cart.length <= 0 || !cart.find(c => c.type === 'RENTA'))) && 
                <>
                  <img src={EmptyImgRenta} alt="empty" style={{marginBottom: 20}} />
                  <TextDescription styles={styles.textEmpty}>NO TIENES NINGÚN<br/> VESTIDO PARA RENTAR</TextDescription>
                </>
              }
              {((selectItem === 'APARTADO' && cart) && (cart.length <= 0 || !cart.find(c => c.type === 'APARTADO'))) &&
                <>
                  <TextDescription styles={styles.textEmpty}>NO TIENES NINGÚN<br/> VESTIDO APARTADO</TextDescription>
                  <img src={EmptyImg} alt="empty" style={{marginTop: 20}} />
                </>
              }
            </div>
          </div>
          {(cart && cart.length > 0) &&
          <>
            <div>
              <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 16}}>
                <TextDescription>TOTAL</TextDescription>
                <div>
                  <TextDescription style={{color: '#353535', fontWeight: '700'}}>{format_currency(cart.reduce((prev, c) =>  prev + convertPrice(Number(c.price * c.quantity), c.discount), 0))}</TextDescription>
                  <TextDescription style={{color: '#353535', fontWeight: '700'}}>+ IVA incluido</TextDescription>
                </div>
              </div>
            </div>
            <Button label="CONTINUAR CON PAGO" stylestext={styles.textButton} style={{marginBottom: 16}} onClick={() => types.length > 1 ? modalPreventTypePayment() : modalTermAndCond(types.length > 0 ? types[0] : '')} />
          </>
          }
          <Button outline={true} label="REGRESAR A BÚSQUEDA" onClick={() => navigate(`/resultados-vestidos`)} />
        </section>
      </div>
      :
      <div>
        <section>
            <NavBarDesktop />
        </section>
        <section className={styles.container}>
          <Title>Bolsa de compras</Title>

          <Breadcrumb items={[{name: 'Selección', action: () => {} }, {name: 'Bolsa', action: () => {}}]} />

          <SectionType selectItem={selectItem} setSelectItem={setSelectItem} lengthReservation={cart ? cart.filter(c => c.type === 'APARTADO').length : 0} lengthRent={cart ? cart.filter(c => c.type === 'RENTA').length : 0} lengthSale={cart ? cart.filter(c => c.type === 'VENTA').length : 0}/>
          {/* <SuccessMessage label="Los artículos permanecerán en la bolsa de compras únicamente por 00:14:59." display={(cart && cart.length > 0)} /> */}
        </section>
        <SuccessMessage label="Las prendas permanecerán en la bolsa por el límite de tiempo indicado." icon="mdi:clock-time-four-outline" display={(cart && cart.length > 0)} />
        <section className={styles.container}>
          <div className={`${styles.containerCart} ${styles.inline}`} style={{justifyContent: 'space-between'}}>
            {(cart && cart.length > 0) &&
              cart.map(row => {
                if(row.type === selectItem)
                  return <div key={Math.random()} className={styles.containDesktop}>
                      <CardProduct 
                        data={row} 
                        time_limit={timesCart.find(v => v.dress_id === row.id) ? timesCart.find(v => v.dress_id === row.id).limit_time : null} 
                        key={Math.random()} 
                        cartTime={true} 
                        calendarRange={row.date} 
                        sizeValue={row.type === 'VENTA' ? row.size : row.size[0]} 
                        colorValue={row.color}
                        type={row.product_type === 'dresses' && row.type === 'VENTA' ? 'sale_dresses' : row.product_type}
                      />
                      {selectItem === 'RENTA' &&
                        <div style={{marginTop: 63, marginBottom: 60}}>
                          <div className={styles.inline} style={{justifyContent: 'space-between', paddingBottom: 20}}>
                            <TextDescription style={{color: '#353535', fontWeight: '600'}}>OPCIÓN B</TextDescription>
                            <Icon icon="akar-icons:question" style={{cursor: 'pointer'}} onClick={() => modalPopupOptionB()} />
                          </div>
                          <Checkbox label="Agregar vestido diferente de opción B" onCheck={() => (optionB.length > 0 && optionB.find(op => op.id === row.id)) ? setOptionB([...optionB.filter(op => op.id !== row.id)]) : setOptionB([...optionB, {id: row.id}])} checked={(optionB && optionB.find(op => op.id === row.id))} />
          
                          {(optionB && optionB.find(op => op.id === row.id)) &&
                            <div className={styles.contentSelectSize}>
                              <Select defaultValue={optionB.find(op => op.id === row.id).talla} onChange={(e) => setOptionB([...optionB.filter(op => op.id !== row.id), { id: optionB.find(op => op.id === row.id).id, talla: e.target.value }])}>
                                <option value="">Seleccionar talla</option>
                                {AllSizes.map(row => (
                                  <option value={row}>{row}</option>
                                ))}
                              </Select>
          
                              <TextDescription style={{marginTop: 12}}>*Te enviaremos a tu correo un catálogo para elegir tu vestido de opción B.</TextDescription>
                            </div>
                          }
                        </div>
                      }
                  </div>
                else return null
              })
            }
            {((selectItem === 'RENTA' && cart) && (cart.length <= 0 || !cart.find(c => c.type === 'RENTA'))) && 
              <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20, width: '100%'}}>
                <div>
                  <img src={EmptyImgRenta} alt="empty" style={{marginBottom: 20, width: 337}} />
                  <TextDescription styles={styles.textEmpty}>NO TIENES NINGÚN<br/> VESTIDO PARA RENTAR</TextDescription>
                </div>
              </div>
            }
            {((selectItem === 'APARTADO' && cart) && (cart.length <= 0 || !cart.find(c => c.type === 'APARTADO'))) &&
              <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20, width: '100%'}}>
                <div>
                  <TextDescription styles={styles.textEmpty}>NO TIENES NINGÚN<br/> VESTIDO APARTADO</TextDescription>
                  <img src={EmptyImg} alt="empty" style={{marginTop: 20, width: 337}} />
                </div>
              </div>
            }
          </div>
          <div style={{height: 38, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span className={styles.textMexico} style={{marginRight: 12}}>ENVÍOS A TODA LA REPUBLICA MEXICANA </span> <img src={MexicoIcon} alt="" />
          </div>
          <div className={styles.linesDecorationDesktop}>
            <div>
              <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 64}}>
                <TextDescription style={{marginRight: 26}}>TOTAL</TextDescription>
                <div>
                  <TextDescription style={{color: '#353535', fontWeight: '700'}}>{format_currency(cart.reduce((prev, c) =>  prev + (c.type === 'APARTADO' ? convertPrice((Number(c.price) - (Number(c.price) * 0.5)), c.discount) : convertPrice(Number(c.price * c.quantity), c.discount)), 0))}</TextDescription>
                  <TextDescription style={{color: '#353535', fontWeight: '700'}}>* IVA incluido</TextDescription>
                </div>
              </div>
            </div>
              <Button outline={true} style={{width: 233}} label="Regresar a búsqueda" onClick={() => navigate(`/resultados-vestidos`)} />
              {(cart && cart.length > 0) &&
                // <Button label="CONTINUAR CON PAGO" style={{width: 233, marginLeft: 16}} stylestext={styles.textButton} onClick={() => types.length > 1 ? modalPreventTypePayment() : modalTermAndCond(types.length > 0 ? types[0] : '')} />
                <Button label="CONTINUAR CON PAGO" style={{width: 233, marginLeft: 16}} stylestext={styles.textButton} onClick={() => modalTermAndCond(types.length > 0 ? types[0] : '')} />
              }
          </div>
        </section>
      </div>
      }
      {width <= 1024 ? <FooterMobile /> : <Footer /> }
    </>
  );
};

const SectionType = ({ selectItem, setSelectItem, lengthRent, lengthReservation, lengthSale }) => (
  <div className={styles.inlineContainer}>
    <div className={styles.widthDektop}>
      <div className={styles.inline}>
        <span className={`${styles.textNormal} ${selectItem === 'RENTA' ? styles.active : ''}`} onClick={() => setSelectItem('RENTA')}>RENTA</span>
        {(lengthRent > 0 && selectItem !== 'RENTA') && <div className={styles.numerationDecorative}>{lengthRent}</div>}
      </div>
      {selectItem === 'RENTA' ? <div className={styles.decorationLine} /> : null}
    </div>

    <div className={styles.widthDektop}>
      <div className={styles.inline}>
        <span className={`${styles.textNormal} ${selectItem === 'APARTADO' ? styles.active : ''}`} onClick={() => setSelectItem('APARTADO')}>APARTADO</span>
        {(lengthReservation > 0 && selectItem !== 'APARTADO') && <div className={styles.numerationDecorative}>{lengthReservation}</div>}
      </div>
      {selectItem === 'APARTADO' ? <div className={styles.decorationLine} /> : null}
    </div>

    { process.env.REACT_APP_SHOW_SALE_SECTION === 'true' &&
      <div className={styles.widthDektop}>
        <div className={styles.inline}>
          <span className={`${styles.textNormal} ${selectItem === 'VENTA' ? styles.active : ''}`} onClick={() => setSelectItem('VENTA')}>VENTA</span>
          {(lengthSale > 0 && selectItem !== 'VENTA') && <div className={styles.numerationDecorative}>{lengthSale}</div>}
        </div>
        {selectItem === 'VENTA' ? <div className={styles.decorationLine} /> : null}
      </div>
    }
  </div>
)

export default ShopCart;
