import React, { useState } from "react";
import styles from "./rentalProcess.module.css";
import circleSorella from "../../assets/svg/circleSorella.svg";
import EventDate from "../eventDate/EventDate";
import EventType from "../eventType/EventType";
import SizeConfirmation from "../sizeConfirmation/SizeConfirmation";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from '../navBarMobile/NavBarMobile';
import Home1 from "../../assets/hero/home-1.jpg";
import Home2 from "../../assets/hero/home-2.jpg";
import Home3 from "../../assets/hero/home-3.jpg";

import lineDesktop from "../../assets/svg/line-desktop.svg";
import FindNewStyle from "../findNewStyle/FindNewStyle";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import FooterMobile from '../footerMobile/FooterMobile';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsLogged } from "../../store/slices/navBar/navBarSlice";
import { Link } from "react-router-dom";
import lineDividerRentalProcess from "../../assets/svg/lineDividerRentalProcess.svg"
import useWindowDimensions from "../../hooks/useWindowDimensions";
import axios from '../../api/axios.config';
import moment from "moment";
import { onSetFilterValues } from "../../store/slices/filters/filtersSlice";
import ModalWelcome from "../modal/ModalWelcome";
import { modal } from '../modalManagment';
import { TextLinking } from "../shared/text/Text";
import Cards from "./Cards";
import { setURLBeforeLeave } from "../../store/slices/navigation/navigationSlice";
import SaleSection from "./SaleSection";

const RentalProcess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSearchBtnDisable } = useSelector((store)=>store.rentalProcess)
  const { width } = useWindowDimensions();
  const [sizes, setSizes] = useState([]);
  const [typeEvents, setTypeEvents] = useState([]);
  const [collaborations, setCollaborations] = useState([])
  const [valuesFilters, setValuesFilters] = useState({
    valueTypeEvent: [], 
    valueCalendar: ['',''],
    valueSizes: [],
  })

  const getTypeEventCatalog = async () => {
    const resp = await axios.get(`/api/type-events`);
    if(resp.status === 200){
      setTypeEvents(resp.data.data)
    }
  }
  const getSizesCatalog = async () => {
    const resp = await axios.get(`/api/sizes`);
    if(resp.status === 200){
      setSizes(resp.data.data)
    }
  }

  const getCollaborations = async () => {
    const resp = await axios.get(`/api/collaborations`);
    if(resp.status === 200){
      setCollaborations(resp.data.data)
    }
  }

  const modalWelcome = (dress_id) => {
    modal.open(<ModalWelcome navigate={navigate} />, () => {}, 'BackdropContainerCenter')
  }

  useEffect(()=>{
    if(localStorage.getItem('init-register') === 'true'){
      modalWelcome();
      localStorage.setItem('init-register', null)
    }
  },[]);

  useEffect(() => {
    dispatch(onSetFilterValues({
      valueColor: '',
      valueTypeEvent: [], 
      valueCalendar: ['',''],
      valueBrand: [],
      valueLength: [],
      valueSizes: [],
      valueNecklines: [],
      valuePrices: ['','']
    }))
    getSizesCatalog()
    getTypeEventCatalog()
    getCollaborations()
  },[])

  const [allSteps, setAllSteps] = useState([
    { stepN: "1.", stepDetails: "Encuentra tu vestido" },
    // { stepN: "2.", stepDetails: "Pruébate el vestido" },
    { stepN: "2.", stepDetails: "Ajustamos si es necesario" },
    { stepN: "3.", stepDetails: "Realiza el pago" },
    { stepN: "4.", stepDetails: "Recibe y usa el vestido" },
    { stepN: "5.", stepDetails: "Regresa el vestido" },
  ]);

  useEffect(() => {
    dispatch(setIsLogged());
  }, []);

  const getNameItems = () => {
    const items = [];

    if(valuesFilters.valueTypeEvent.length){
      items.push({id: 'valueTypeEvent', value: typeEvents.find(vt => vt.id === valuesFilters.valueTypeEvent[0]) ? typeEvents.find(vt => vt.id === valuesFilters.valueTypeEvent[0]).description : 'Error', key: valuesFilters.valueTypeEvent[0]})
    }

    if(valuesFilters.valueCalendar.length && valuesFilters.valueCalendar[0] !== ''){
      items.push({id: 'valueCalendar', value: `${moment(valuesFilters.valueCalendar[0]).format('DD-MMM')}-${moment(valuesFilters.valueCalendar[1]).format('DD-MMM')}`})
    }

    if(valuesFilters.valueSizes.length){
      items.push({id: 'valueSizes', value: sizes.find(zs => zs.id === valuesFilters.valueSizes[0]) ? 'Talla '+sizes.find(zs => zs.id === valuesFilters.valueSizes[0]).description : 'Error', key: valuesFilters.valueSizes[0]})
    }

    return items
  }

  const goToDressSearch = () =>{
    let query = '?'
    if(valuesFilters.valueTypeEvent.length){
      query += query.includes('?') ? `&type_event_ids=${valuesFilters.valueTypeEvent[0]}` : `type_event_ids=${valuesFilters.valueTypeEvent[0]}`
    }
    if(valuesFilters.valueCalendar.length && valuesFilters.valueCalendar[0] !== ''){
      query += query.includes('?') ? `&date_start=${moment(valuesFilters.valueCalendar[0]).format('YYYY/MM/DD')}&date_end=${moment(valuesFilters.valueCalendar[1]).format('YYYY/MM/DD')}` : `date_start=${moment(valuesFilters.valueCalendar[0]).format('YYYY/MM/DD')}&date_end=${moment(valuesFilters.valueCalendar[1]).format('YYYY/MM/DD')}`
    }
    if(valuesFilters.valueSizes.length){
      query += query.includes('?') ? `&talla_ids=${valuesFilters.valueSizes[0]}` : `talla_ids=${valuesFilters.valueSizes[0]}`
    }

    dispatch(onSetFilterValues(valuesFilters))

    navigate({pathname: `/resultados-vestidos`, search: query}, { state: { itemView: getNameItems() } });
  }

  const onClickExplore = (navegate) => {
    dispatch(onSetFilterValues({
      valueColor: '',
      valueTypeEvent: [], 
      valueCalendar: ['',''],
      valueBrand: [],
      valueLength: [],
      valueSizes: [],
      valueNecklines: [],
      valuePrices: ['','']
    }))
    navigate(navegate)
  }

  return (
    <>
      <div className={styles.container}>
        <section className={styles.nav}>
          {width <= 1024 ? (
            <NavBarMobile />
          ): (
            <NavBarDesktop />
          )}
        </section>
        {width < 744 ? (
          <>
            <section style={{height: '100vh'}}>
              <div className={styles.containerImgBannerMainMobile} onClick={() => navigate('/resultados-vestidos')}>
                <section className={styles.section1} style={{position: 'relative'}}>
                  <div style={{height: '100vh', background: '#F7EFED', minWidth: '100%'}} className={styles.wrapperImage}>
                    <img
                        alt=""
                        src={Home1}
                        className={styles.figGirlLayDown}
                      ></img>
                      <img
                        alt=""
                        src={Home2}
                        className={styles.figGirlLayDown}
                      ></img>
                      <img
                        alt=""
                        src={Home3}
                        className={styles.figGirlLayDown}
                      ></img>
                  </div>
                </section>
                <span className={styles.titleHome}>Celebra al máximo <br/>con el look perfecto</span>
                <div className={styles.containerRentNowMobile}>
                  <div>
                    <div className={styles.lineDecorationMobile} />
                    <div className={styles.pointDecorationMobile} />
                  </div>
                  <button onClick={() => navigate('/resultados-vestidos')} className={styles.rentNowButtonMobile}>RENTA AHORA</button>
                </div>
              </div>
            </section>
            <section className={styles.sectionHowItWorks}>
              <div className={styles.howDoesItWork}>¿Cómo funciona?</div>
              <section className={styles.allSteps}>
                {allSteps.map((step, index) => (
                  <React.Fragment key={index}>
                    <div key={index} className={styles.step}>
                      <div className={styles.containerStep}>
                        <span>{step.stepN}</span>
                        <span>{step.stepDetails}</span>
                      </div>
                    </div>
                    {((index % 2) === 0) ? (
                      <div className={styles.lineRight}></div>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))}
              </section>
              <button onClick={() => {
                setURLBeforeLeave(null)
                navigate('/resultados-vestidos')
              }} className={`${styles.startSearch} ${styles.findYourDressWrapper}`}>Encuentra tu vestido</button>
            </section>
            <section className={styles.sectionFiltersMobile} id="form-rent">
              <EventType valueTypeEvent={valuesFilters.valueTypeEvent} typeEvents={typeEvents} valuesFilters={valuesFilters} setValuesFilters={setValuesFilters} />
              <EventDate valueCalendar={valuesFilters.valueCalendar} setValueCalendar={(values) => setValuesFilters({ ...valuesFilters, valueCalendar: values })} />
              <SizeConfirmation valueSizes={valuesFilters.valueSizes} sizes={sizes} valuesFilters={valuesFilters} setValuesFilters={setValuesFilters} />
              <section>
                <div className={styles.startSearchContainer}>
                  <button className={styles.startSearch} onClick={() => goToDressSearch()} disabled={isSearchBtnDisable}>
                    Empieza tu búsqueda
                  </button>
                </div>
                <span style={{textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: 24}}>
                  <TextLinking onClick={() => onClickExplore("/resultados-vestidos")} className={styles.justExploring}>
                    Solo estoy explorando
                  </TextLinking>
                </span>
              </section>
            </section>
            <section>
            <Cards collaborations={collaborations}/>
          </section>
          { process.env.REACT_APP_SHOW_SALE_SECTION === 'true' &&
            <section>
              <SaleSection />
            </section>
          }
          <section style={{height: '100vh', width: '100%'}}>
            <FindNewStyle />
          </section>
          <FooterMobile />
          </>
        ): (
          <div>
            <section className={styles.section1} style={{position: 'relative'}} onClick={() => navigate('/resultados-vestidos')}>
              <div style={{height: '100vh', background: '#F7EFED', minWidth: '100%'}} className={styles.wrapperImage}>
                <img
                    alt=""
                    src={Home1}
                    className={styles.figGirlLayDown}
                  ></img>
                  <img
                    alt=""
                    src={Home2}
                    className={styles.figGirlLayDown}
                  ></img>
                  <img
                    alt=""
                    src={Home3}
                    className={styles.figGirlLayDown}
                  ></img>
                  <img
                    alt=""
                    src={Home1}
                    className={styles.figGirlLayDown}
                  ></img>
              </div>
              <div>
                  <span className={styles.titleHome}>Celebra al máximo <br/>con el look perfecto</span>
                  <img
                    src={circleSorella}
                    alt="circleSorella"
                    className={styles.imgCircleSorella}
                  ></img>
                  <div className={styles.containerRentNow}>
                    <img src={lineDesktop} style={{width: '80%', marginRight: 13}} alt="" />
                    <button onClick={() => navigate('/resultados-vestidos')} className={styles.rentNowButton}>RENTA AHORA</button>
                  </div>
              </div>
            </section>
            <section className={styles.sectionHowItWorks}>
            <div className={styles.howDoesItWork}>¿Cómo funciona?</div>
            <section className={styles.allSteps}>
              {allSteps.map((step, index) => (
                <React.Fragment key={index}>
                  <div key={index} className={styles.step}>
                    <div className={styles.containerStep}>
                      <span>{step.stepN}</span>
                      <span>{step.stepDetails}</span>
                    </div>
                  </div>
                  {allSteps.length !== index + 1 ? (
                    <div className={styles.lineRight}></div>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            </section>
            <section className={styles.findYourDressWrapper}>
              <button onClick={() => navigate('/resultados-vestidos')} className="primaryButton">Encuentra tu vestido</button>
            </section>
          </section>
          <section className={`${styles.section2}`} id="form-rent">
            <div className={styles.faqsContainer}>
              {/* <EventType />
              <EventDate />
              <img src={lineDividerRentalProcess} alt=""></img>
              <SizeConfirmation /> */}
              <EventType valueTypeEvent={valuesFilters.valueTypeEvent} typeEvents={typeEvents} valuesFilters={valuesFilters} setValuesFilters={setValuesFilters} />
              <img src={lineDividerRentalProcess} alt=""></img>
              <EventDate valueCalendar={valuesFilters.valueCalendar} setValueCalendar={(values) => setValuesFilters({ ...valuesFilters, valueCalendar: values })} />
              <img src={lineDividerRentalProcess} alt=""></img>
              <SizeConfirmation valueSizes={valuesFilters.valueSizes} sizes={sizes} valuesFilters={valuesFilters} setValuesFilters={setValuesFilters} />
            </div>
            <section>
              <div className={styles.startSearchContainer}>
                <button className={styles.startSearch} onClick={() => goToDressSearch()} disabled={isSearchBtnDisable}>
                  Empieza tu búsqueda
                </button>
              </div>
              <span style={{textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: 24, color: '#949494'}}>
                <TextLinking onClick={() => onClickExplore("/resultados-vestidos")} styles={styles.justExploring}>
                  Solo estoy explorando
                </TextLinking>
              </span>
            </section>
          </section>
          <section>
            <Cards collaborations={collaborations}/>
          </section>
          { process.env.REACT_APP_SHOW_SALE_SECTION === 'true' &&
            <section>
              <SaleSection />
            </section>
          }
          <section>
            <FindNewStyle />
          </section>
          <Footer />
          </div>
        )}

        
      </div>
    </>
  );
};

export default RentalProcess;
