import React from "react";
import styles from "./helpWithMeasures.module.css";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import FooterMobile from "../footerMobile/FooterMobile";
import { useNavigate } from "react-router-dom";
import measures from "../../assets/icons/measures.svg";
import breadCrumbArrow from "../../assets/icons/breadCrumbArrow.svg"
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import Button from "../shared/button";
import Footer from "../footer/Footer";

const HelpWithMeasures = (props) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  return (
    <>
      <div>
        <section className={styles.navMobile}>
          {width <= 1024 ? <NavBarMobile /> : <NavBarDesktop />}
        </section>
        <span className={styles.titleGuide}>
            GUÍA DE MEDIDAS
        </span>
        <section className={styles.breadCrumbContainer}>
          <span className={styles.breadCrumbText}>Configuración</span>
          <img className={styles.imgBreadCrumb} alt="" src={breadCrumbArrow}></img>
          <span className={styles.breadCrumbText}>Guía de Medidas</span>
        </section>
        <section className={styles.containerMeasuresImg}>
          <div className={styles.flexCenter}>
            <img src={measures} alt="" style={{height: '100%'}} />
            <div className={styles.tableContainer}>
              <table className={styles.Sorella}>
                <thead>
                  <tr>
                    <th>TALLA</th>
                    <th>XXS</th>
                    <th>XS</th>
                    <th>S</th>
                    <th>M</th>
                    <th>L</th>
                    <th>XL</th>
                    <th>XXL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>BUSTO</td>
                    <td>81</td>
                    <td>85</td>
                    <td>88</td>
                    <td>92</td>
                    <td>97</td>
                    <td>100</td>
                    <td>104</td>
                  </tr>
                  <tr>
                    <td>CINTURA</td>
                    <td>62</td>
                    <td>65</td>
                    <td>69</td>
                    <td>73</td>
                    <td>77</td>
                    <td>81</td>
                    <td>85</td>
                  </tr>
                  <tr>
                    <td>CADERA</td>
                    <td>85</td>
                    <td>90</td>
                    <td>93</td>
                    <td>98</td>
                    <td>101</td>
                    <td>105</td>
                    <td>109</td>
                  </tr>
                </tbody>
              </table>
              <p>*Medidas en centímetros.</p>
              <p>*Recuerda que las tallas y medidas pueden tener una ligera variación entre cada estilo y marca de vestido.</p>
            </div>
          </div>
          <section className={styles.backToProfileArea}>
            <Button styles={styles.backButton} outline label="REGRESAR A PERFIL" onClick={() => navigate(localStorage.getItem('sorellaToken') ? "/perfil" : '/inicio-sesion')} textstyles={styles.backToProfileButton} />
          </section>
        </section>
      </div>
      {width <= 1024 ? <FooterMobile /> : <Footer />}
    </>
  );
};

export default HelpWithMeasures;
