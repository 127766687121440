import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import stylesLocal from "./faqProductType.module.css";
import { AllTypes } from "../../../constants";
import CustomCheck from "../../shared/customCheck/CustomCheck";
import { Icon } from "@iconify/react";
import { createKey } from "../../../utils";
import { useDispatch } from "react-redux";
import { onSetFilterValues } from "../../../store/slices/filters/filtersSlice";

const FaqProductType = (props) => {
  const [isDisplay, setIsDisplay] = useState(!!props.display);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };
    const dispath = useDispatch();
    const handlerChecked = (id) => {
      let types = [];
      console.log("types:", props.valueProductTypes);
      if (props.valueProductTypes?.includes(id)) {
        types = [...props.valueProductTypes.filter((v) => v !== id)];
      } else if (
        props.valueProductTypes !== undefined &&
        props.valueProductTypes !== null
      ) {
        types = [...props.valueProductTypes, id];
      } else {
        types = [id];
      }
      dispath(onSetFilterValues({ valueProductTypes: types }));
    };

    return (
      <>
        <button
          type="button"
          className={styles.collapsible}
          onClick={toggleDisplay}
        >
          <span>TIPO</span>
          {isDisplay ? (
            <Icon
              icon="akar-icons:minus"
              color="#d6a191"
              width="14"
              height="14"
            />
          ) : (
            <Icon
              icon="akar-icons:plus"
              color="#D6A191"
              width="14"
              height="14"
            />
          )}
        </button>
        <div
          className={`${styles.container} ${
            isDisplay ? styles.display : styles.hidden
          }`}
        >
          <div>
            <section className={stylesLocal.buttonSizeContainer}>
              {AllTypes.map((row) => (
                <div
                  key={createKey()}
                  className={stylesLocal.checkboxInline}
                >
                  <CustomCheck
                    content={row.display}
                    onChangeCallBack={() =>
                      handlerChecked(row.value)
                    }
                    isChecked={
                      props.valueProductTypes &&
                      props.valueProductTypes.find(
                        (nl) => nl === row.value
                      )
                      ? true
                      : false
                    }
                  />
                </div>
              ))}
            </section>
          </div>
        </div>
      </>
    );
};

export default FaqProductType;
