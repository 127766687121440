import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        previousURL:'',
        loading: false
    },
    reducers: {
        setURLBeforeLeave: (state, action) => {
            state.previousURL = action.payload;
        },
        clearState: (state)=>{
            state.previousURL = '';
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    }
});

export const { setURLBeforeLeave, clearState, setLoading } = navigationSlice.actions;