import React from "react";
import {EVENTO_PLAYA, EVENTO_PLAYA_ID, EVENTO_NOCHE, EVENTO_NOCHE_ID, BODA, BODA_ID, GRADUACION, GRADUACION_ID, FIESTA, FIESTA_ID, EVENTO_DIA, EVENTO_DIA_ID} from "../../../src/constants"
import styles from "./eventType.module.css";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { setIsCalendarEnable } from "../../store/slices/rentalProcess/rentalProcessSlice";
import { onSetFilterValues } from '../../store/slices/filters/filtersSlice';

const EventType = (props) => {
  const dispatch = useDispatch();
  const { 
    valuesFilter: { valueTypeEvent }
   } = useSelector((store)=>store.filters);

  const selectType = (type) => {
    props.setValuesFilters({ 
      ...props.valuesFilters,
      'valueTypeEvent': [type]
    });

    dispatch(onSetFilterValues({
      ...props.valuesFilters,
      'valueTypeEvent': [type]
    }))
    
    dispatch(setIsCalendarEnable());
  };

  return (
    <>
      <section className={[styles.subTitleContainer]}>
        <div className={[styles.collapsableHeader]}>
          <span className={[styles.subTitle]}>¿Cuál es la ocasión?</span>
        </div>
        <div className={[styles.eventArea]}>
          <section className={[styles.eventContainer]}>
            <button
              className={`${styles.container} ${valueTypeEvent.includes(BODA_ID)  ? styles.selected : '' }`}
              onClick={() => selectType(BODA_ID)}
            >
              <Icon
                icon="uil:glass-martini"
                className={styles.eventIcons}
                width="18"
                height="20"
              /> 
              Boda
            </button>
            <button
              className={`${styles.container} ${valueTypeEvent.includes(EVENTO_PLAYA_ID) ? styles.selected : '' }`}
              onClick={() => selectType(EVENTO_PLAYA_ID)}
            >
              <Icon
                icon="fa-solid:water"
                className={styles.eventIcons}
                width="18"
                height="20"
              ></Icon>
              Evento playa
            </button>
            <button
              className={`${styles.container} ${valueTypeEvent.includes(EVENTO_NOCHE_ID) ? styles.selected : '' }`}
              onClick={() => selectType(EVENTO_NOCHE_ID)}
            >
              <Icon
                icon="ci:moon"
                className={styles.eventIcons}
                width="18"
                height="20"
              ></Icon>
              Evento Noche
            </button>
          </section>
          <section
            className={styles.eventContainer}>
            <button 
              className={`${styles.container} ${valueTypeEvent.includes(GRADUACION_ID) ? styles.selected : '' }`}
              onClick={() => selectType(GRADUACION_ID)}
              >
              <Icon
                icon="uil:graduation-cap"
                className={styles.eventIcons}
                width="18"
                height="20"
              ></Icon>
              Graduación
            </button>
            <button
              className={`${styles.container} ${valueTypeEvent.includes(FIESTA_ID) ? styles.selected : '' }`}
              onClick={() => selectType(FIESTA_ID)}
            >
              <Icon
                icon="uil:glass-martini-alt"
                className={styles.eventIcons}
                width="18"
                height="20"
              ></Icon>
              Fiesta
            </button>
            <button
              className={`${styles.container} ${valueTypeEvent.includes(EVENTO_DIA_ID) ? styles.selected : '' }`}
              onClick={() => selectType(EVENTO_DIA_ID)}
            >
              <Icon
                icon="akar-icons:sun"
                className={styles.eventIcons}
                width="18"
                height="20"
              ></Icon>
              Evento día
            </button>
          </section>
        </div>
      </section>
    </>
  );
};

export default EventType;
