import React from "react";
import styles from "./Modal.module.css";
import Button from '../shared/button';
import { Icon } from "@iconify/react";
import { modal } from '../modalManagment';
import axios from '../../api/axios.config';
import { useEffect } from "react";
import { useState } from "react";
import FaqStatus from "../faq/faqStatus/FaqStatus";
import FaqAge from "../faq/faqAge/FaqAge";
import FaqCity from "../faq/faqCity/FaqCity";

const ModalFilterItemsAdmin = ({ valuesFilter, setValuesFilter }) => {
  const [cities, setCities] = useState([])

  const [valueStatus, setValueStatus] = useState('')
  const [valueAge, setValueAge] = useState('')
  const [valueCity, setValueCity] = useState('')

  useEffect(() => {
    setValueStatus(valuesFilter.status)
    setValueAge(valuesFilter.age)
    setValueCity(valuesFilter.city)
  },[valuesFilter])

  const handlerApplyFilter = () => {
    setValuesFilter({
      age: valueAge,
      status: valueStatus,
      city: valueCity
    })
    modal.close()
  }

  const getCities = async () => {
    const resp = await axios.get(`/api/city-clients`);
    if(resp.status === 200){
      setCities(resp.data)
    }
  }

  useEffect(() => {
    getCities()
  },[])

  return (
    <div className={styles.containerFiltersPadding}>
        <div className={styles.inlineContainer}>
          <span className={styles.titleFilters}>FILTROS DE BÚSQUEDA</span>
          <Icon icon="eva:close-outline" fontSize={25} style={{paddingLeft: 40, marginRight: 20}} onClick={() => modal.close()} />
        </div>
        <FaqStatus status={['Activo', 'Inactivo', 'Ausente', 'Nueva']} valueStatus={valueStatus} setValueStatus={setValueStatus} />
        <FaqAge ages={[{name:'18 años o menos', id: '18'}, {id: '19-25', name:'19-25 años'}, {id: '25-30', name:'26-30 años'}, {id: '36-40',name: '35-40 años'}, {id: '41',name: '40 años o más'}]} valueAge={valueAge} setValueAge={setValueAge} />
        <FaqCity cities={cities} valueCity={valueCity} setValueCity={setValueCity} />
        <div className={styles.inlineContainer} style={{marginTop: 26, padding: '0px 12px', justifyContent: 'space-between'}}>
          <Button outline label="Borrar" style={{width: '48%'}} onClick={() => {
              setValuesFilter({
                age: '',
                status: '',
                city: ''
              })
              modal.close()
            }} />
          <Button label="Aplicar" style={{width: '48%'}} onClick={() => handlerApplyFilter()} />
        </div>
    </div>
  );
};

export default ModalFilterItemsAdmin;