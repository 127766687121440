import React from 'react';
import styles from './Breadcrumb.module.css';
import Item from '../item';

const Breadcrumb = (props) => {
    return(
        <div className={styles.container}>
            {props.items &&
                <>
                    {props.items.map((item, i) => (
                        <Item key={Math.random()} last={props.items.length === (i+1)} onClick={item.action} label={item.name} />
                    ))}
                </>
            }
        </div>
    )
}

export default Breadcrumb;