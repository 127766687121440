import React, { useEffect, useState } from "react";
import stylesLocal from "./sizes.module.css";
import ToogleCustomDisplay from '../shared/toggleCustomDisplay/ToggleDisplay';
import ColorPicker from "../shared/colorPicker/ColorPicker"
import { createKey, getUniqueValues } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { setAllSizesAvailables, onSetFilterValues, setColorValue, clearColorFilter, setSizeValue } from "../../store/slices/filters/filtersSlice";

const Colors = (props) => {
  const { allColorsAvailables, allColorsAvailablesNames, valuesFilter: { valueColor } } = useSelector((store) => store.filters);
  const { dressModelDetail } = useSelector((store) => store.dressDetails)
  const dispatch = useDispatch();

  const updateStorageFilters = (colorCode) => {
    const filterByColor = dressModelDetail[props.type]?.find((d) => d.color.code === colorCode)
    const sizes = dressModelDetail[props.type]?.filter((d) => d.color?.code === colorCode).map((d) => d.size?.size ?? d.size?.description).reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
    if (filterByColor) {
        props.setValuesFilters({ valueColor: filterByColor?.color_id, valueSize: sizes.length === 1 ? sizes[0] : '' })
        dispatch(setColorValue(filterByColor?.color_id))
        if (sizes.length === 1) dispatch(setSizeValue(sizes[0]))
    }
  }

  const updateSizesForColorsAvailable = (colors = []) => {
    const colorsAux = colors.map((c) => c?.code)
    const filterByColor = dressModelDetail[props.type]?.filter(d => colorsAux.includes(d.color.code));

    const allSizes = getUniqueValues(filterByColor?.map(d => d.size.description));
    if (allSizes) dispatch(setAllSizesAvailables(allSizes))
  }

  useEffect(() => {
    if(allColorsAvailables && dressModelDetail){
      updateSizesForColorsAvailable(allColorsAvailablesNames);
      if(allColorsAvailables?.length === 1 && dressModelDetail[props.type]){
        updateStorageFilters(allColorsAvailablesNames[0]?.code)
      }
    }
  },[dressModelDetail])

  return (
    <ToogleCustomDisplay 
      show={true} 
      label="COLORES" 
      checked={props.valuesFilters.valueColor && props.valuesFilters.valueColor !== ''}
      style={stylesLocal.buttonSizeContainer}
      value={ allColorsAvailablesNames?.find((c) => c?.code === props.valuesFilters.valueColor || parseInt(c?.id) === parseInt(props.valuesFilters.valueColor))?.name ?? null}
    >
      <div className={stylesLocal.colorsContainer}>
        { allColorsAvailablesNames.map((color) => (
          <React.Fragment key={createKey()}>
            <ColorPicker isPressed={props.valuesFilters.valueColor === (dressModelDetail && dressModelDetail[props.type]?.find((d) => d.color?.id === color?.id) && dressModelDetail[props.type]?.find((d) => d?.color?.id === color?.id)?.color_id)} colorCode={color?.code} colorPickerCallBack={() => updateStorageFilters(color?.code)}/>
          </React.Fragment>
        ))}
      </div>
    </ToogleCustomDisplay>
  );
};

export default Colors;
