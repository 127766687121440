import React from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";
import { Icon } from "@iconify/react";

const ModalAddressComplete = ({onClick}) => {
    return (
        <div className={styles.containerModal}>
            <div className={styles.inlineContainer}>
                <Title styles={styles.titleFilters} style={{marginTop: 26, marginBottom: 26}}>AGENDAR PRUEBA</Title>
                <Icon icon="eva:close-outline" fontSize={25} style={{paddingLeft: 40, marginRight: 20, marginTop: 26, marginBottom: 26}} onClick={() => modal.close()} />
            </div>
            <TextDescription styles={styles.textDescription} style={{fontSize: 14}}>Para comprobar disponibilidad en tu ciudad debes completar los datos de dirección de tu perfil.</TextDescription>
            <div className={styles.containButtons} style={{marginTop: 26}}>
                <Button label="CANCELAR" outline styles={styles.buttonMiddle} onClick={() => modal.close()} />
                <Button label="IR A PERFIL" styles={`${styles.buttonMiddle}`} onClick={onClick} />
            </div>
        </div>
    )
}

export default ModalAddressComplete;