import React, { useEffect, useRef } from "react";
import styles from "./profileScreenMobile.module.css";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import FooterMobile from "../footerMobile/FooterMobile";
import FaqActiveReserves from "../faq/faqaAtiveReserves/FaqActiveReserves";
import FaqActiveRents from "../faq/faqActiveRents/FaqActiveRents";
import FaqRentalHistory from "../faq/faqRentalHistory/FaqRentalHistory";
import FaqProfileInfo from "../faq/faqProfileInfo/FaqProfileInfo";
import FaqMySize from "../faq/faqMySize/FaqMySize";
import BannerProfile from '../../assets/landing/profile-section-banner.jpg';
import pencil from "../../assets/icons/pencil.svg";
import flagSave from "../../assets/icons/flagSave.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { postSaveMySize } from "../../store/thunks/profileThunk";
import SuccessMessage from "../successMessage/SucessMessage";
import Button from "../shared/button";
import { modal } from "../modalManagment";
import ModalConfirmActionAvatar from "../modal/ModalConfirmActionAvatar";
import api from "../../api/axios.config";
import { getUserDetails } from "../../store/thunks/authThunk";
import FaqSaleHistory from "../faq/faqSaleHistory/FaqSalelHistory";
import Card from "../card/Card";

const Selected = {
  PEDIDOS: "PEDIDOS",
  CONFIGURACION: "CONFIGURACION",
  TARJETAS: "TARJETAS"
};

const ProfileScreenMobile = (props) => {
  const dispatch = useDispatch();
  const [currentSelected, setCurrentSelected] = useState(Selected.PEDIDOS);
  const [editMode, setEditMode] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const refButton = useRef(null);

  const { user } = useSelector((store) => store.login);

  useEffect(() => {
    if(props.isActiveSetting){
      setCurrentSelected(Selected.CONFIGURACION)
    }
  },[props.isActiveSetting])

  const formConfig = useForm();
  const onSubmitFormConfig = (data) => {
    const mapData = {
      nombres: data.userName,
      apellidos: data.lastName,
      fecha_nacimiento: data.birthday,
      phone: data.phone,
      correo_electronico: data.email,
      password: data.password,
      calle_num_ext: data.address,
      interior: data.insideNumber,
      estado: data.estado,
      postal_code: data.postalCode,
      city_id: data.city,
      colonia: data.colonia,
      "cintura": data.waist,
      "cadera": data.hip,
      "busto": data.bustSize,
      "altura": data.altura,
      "talla": data.dressSize
    };
    
    dispatch(postSaveMySize(mapData, user.id));
    dispatch(getUserDetails());
    setEditMode(false)
    setIsSaved(true)
  };

  const confirmAction = () => {
    modal.open(<ModalConfirmActionAvatar
      onClickUpload={() => {
        refButton.current.click()
      }} 
    onClickRemove={() => removeAvatar()} />, () => {}, 'BackdropContainerCenter')
  }

  const onUploadFile = (e) => {
    modal.close()
    const formdata = new FormData()
    formdata.append('avatar', e.target.files[0])
    api.post('/api/users/avatar', formdata, { headers: { "Content-Type": "multipart/form-data" } })
    .then(res => {
      if(res.status === 200){
        setIsSaved(true);
        dispatch(getUserDetails());
      }
    })
  }

  const removeAvatar = () => {
    modal.close()
    api.delete('/api/users/avatar')
    .then(res => {
      if(res.status === 200){
        setIsSaved(true);
        dispatch(getUserDetails());
      }
    })
  }

  return (
    <>
      <div>
        <section className={styles.navMobile}>
          <NavBarMobile isSelectedProfile={true} />
        </section>
        <section className={styles.infoProfileArea}>
          <img alt="" src={BannerProfile} style={{maxHeight: 136, width: '100%', objectFit: 'cover'}}></img>
          <div className={styles.profileImage} onClick={confirmAction}>
            {user?.avatar ?
              <img src={user?.avatar_image} alt="" style={{height: '100%', width: '100%', objectFit: 'cover', borderRadius: 50}} />
            :
              <p style={{color: '#FFF'}}>{user?.name ? user?.name.slice(0, -4) : ''}</p>
            }
            <input ref={refButton} type="file" style={{display: 'none'}} accept="jpg, png, jpeg" onChange={onUploadFile} />
          </div>
          <span
            className={styles.userNameArea}
          >{user.name ? `${user.name} ${user.lastname}` : ''}</span>
          <span className={styles.userLocationArea}>
            {user?.address ? user.address[0]?.city?.name : "Ubicación desconocida"}
          </span>
        </section>
        <section className={styles.profileButtonsArea}>
          <div>
            <button
              className={`${styles.ordersButton} ${currentSelected === Selected.PEDIDOS ? styles.colorSelected : ''}`}
              onClick={() => setCurrentSelected(Selected.PEDIDOS)}
            >
              PEDIDOS
            </button>
            <div
              className={
                currentSelected === Selected.PEDIDOS ? styles.selected : ""
              }
            ></div>
          </div>
          <div>
            <button
              className={`${styles.ordersButton} ${currentSelected === Selected.CONFIGURACION ? styles.colorSelected : ''}`}
              onClick={() => setCurrentSelected(Selected.CONFIGURACION)}
            >
              CONFIGURACION
            </button>
            <div
              className={
                currentSelected === Selected.CONFIGURACION
                  ? styles.selected
                  : ""
              }
            ></div>
          </div>
          <div>
            <button
              className={styles.ordersButton}
              onClick={() => setCurrentSelected(Selected.TARJETAS)}
            >
              TARJETAS
            </button>
            <div
              className={
                currentSelected === Selected.TARJETAS
                  ? styles.selected
                  : ""
              }
            ></div>
          </div>
        </section>
        {currentSelected === Selected.PEDIDOS ? (
          <section>
            <FaqActiveReserves {...props} />
            <FaqActiveRents {...props} />
            <FaqRentalHistory {...props} />
            <FaqSaleHistory {...props} />
          </section>
        ) : currentSelected === Selected.CONFIGURACION ? (
          <>
            <div className={styles.profile}>
              <form
                onSubmit={formConfig.handleSubmit(onSubmitFormConfig)}
                className={styles.areaEditButton}
              >
                <section style={{marginTop: 20}}>
                  <SuccessMessage display={isSaved} label="Tu perfil se actualizó correctamente"/>
                </section>
                <section>
                  {Object.keys(user).length > 0 ? (
                    <>
                      <FaqProfileInfo formConfig={formConfig} dataUser={user} disabled={!editMode} />
                      <FaqMySize formConfig={formConfig} dataUser={user} disabled={!editMode} />
                    </>
                  ) : (
                    <></>
                  )}
                </section>
                {editMode &&
                  <section style={{marginTop: 40, marginBottom: 20, padding: '0px 16px'}}>
                    <Button stylestext={styles.buttonPaddingLeft} type="submit" label="GUARDAR CAMBIOS">
                      <img src={flagSave} alt="" />
                    </Button>
                  </section>
                }
              </form>
              {!editMode &&
                <section style={{marginTop: 40, marginBottom: 20, padding: '0px 16px'}}>
                  <Button outline stylestext={styles.buttonPaddingLeft} onClick={() => setEditMode(true)} label="EDITAR PERFIL">
                    <img src={pencil} alt="" />
                  </Button>
                </section>
              }
            </div>
          </>
        ) : (
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: 200}}>
            <section style={{ display: 'flex', flexDirection: 'column', marginTop: '36px', width: 664, alignItems: 'center', gap: '12px' }}>
              { props.details?.cards?.map((card) => <Card cardObject={card}/>) }
            </section>
          </div>
        )}
      </div>
      <FooterMobile />
    </>
  );
};

export default ProfileScreenMobile;
