import React from "react";
import styles from './Select.module.css';

const LabelSelect = (props) => (
    <h2 {...props} className={`${styles.label} ${props.styles}`}>{props.children}</h2>
)

const Select = (props) => {
    return (
        <select {...props} className={`${styles.select} ${props.styles}`}>
            {props.children}
        </select>
    )
}

export { LabelSelect };
export default Select;