import React from "react";
import styles from "./findNewStyle.module.css"
import Section_2_1 from '../../assets/landing/sorella_primavera9.jpg';
import Section_2_2 from '../../assets/landing/sorella_primavera1.jpg';
import fillBlackPointLeftThenLine from "../../assets/svg/fillBlackPointLeftThenLine.svg"
import { useNavigate } from "react-router-dom";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { TextDescription, Title } from '../shared/text/Text';
import Button from '../shared/button';
import { scrollToGoSection } from '../../utils';

const FindNewStyle=()=>{
    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    return(
        <>
        {width <= 1024 ?
            <section style={{position: 'relative', height: '100%'}} onClick={() => navigate('/resultados-vestidos?type_event_ids=6')}>
                <div style={{position: 'absolute', top: 70, right: 0, height: 0, width: '40%', borderBottom:'1px solid #FFF'}}>
                    <div style={{position: 'absolute', height: 12, width: 12, borderRadius: 50, background: '#FFF', top: -5.6}} />
                </div>
                <img alt="" src={Section_2_2} style={{height: '100%', width: '100%', objectFit: 'cover'}}></img>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    height: '90%',
                    width: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    padding: '0px 32px'
                }}>
                    <Title style={{color: '#FAF6F5', fontWeight: 600, fontSize: 31, fontFamily: 'Poppins', minWidth: '185px' }}>Estilos Primavera - Verano </Title>
                    <TextDescription style={{marginTop: 24, color: '#FAF6F5'}}>
                        Encuentra las últimas tendencias para perderle el miedo a destacar.
                    </TextDescription>
                    <Button label="RENTA AHORA" onClick={() => navigate('/resultados-vestidos?type_event_ids=6')} style={{background: '#FAF6F5', marginTop: 38}} styleText={{color: '#A57C70'}} />
                </div>
            </section>
        :
            <section className={styles.container}>
                <div className={styles.leftArea}>
                    <div className={styles.subLeftArea}>
                        <div className={styles.textArea}>
                            <div className={styles.textAndLine}>
                                <span className={styles.title}>Estilos </span>
                                <img alt="line" src={fillBlackPointLeftThenLine} className={styles.fillBlackPointLeftThenLine}></img>
                            </div>
                            <span className={styles.title}>Primavera - Verano</span>
                            <span className={styles.aboveTitle}>Encuentra las últimas tendencias para perderle el miedo a destacar.</span>
                            <button onClick={() => navigate('/resultados-vestidos?type_event_ids=6')} className="primaryButton" style={{'width':'228px'}}>Descubre nuevos estilos</button>
                        </div>
                        <img alt="" src={Section_2_2} style={{height: 418, width: 280, objectFit: 'cover', cursor: 'pointer'}} onClick={() => navigate('/resultados-vestidos?type_event_ids=6')}></img>
                    </div>
                </div>
                <img alt="" src={Section_2_1} className={styles.rightArea} onClick={() => navigate('/resultados-vestidos?type_event_ids=6')}></img>
            </section>
        }
        </>
    )
}


export default FindNewStyle;