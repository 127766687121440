import React, { Component } from 'react';
import { EventEmitter } from 'events';
import './Modal.css';

class ModalManagement extends EventEmitter {
  constructor() {
    super();
    this.dom = null;
    this.className = null;

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open(dom, callback, className) {
    if (typeof callback === 'function') callback();
    this.dom = dom;
    this.className = className;
    this.emitChange();
  }

  close(callback) {
    if (typeof callback === 'function') callback();
    this.dom = null;
    this.className = null;
    this.emitChange();
  }

  emitChange() {
    this.emit('modal', {dom: this.dom, className: this.className});
  }
}

export const modal = new ModalManagement();

export default class ModalComponent extends Component {
  state = {
    dom: null,
    className: null
  };

  componentDidMount() {
    modal.addListener('modal', this.handleModal);
  }

  componentWillUnmount() {
    modal.removeListener('modal', this.handleModal);
  }

  handleModal = ({dom, className}) => {
    this.setState({ dom, className });
  };

  hendleClickClose = (e) => {
    if (e.target !== e.currentTarget) return;
    modal.close();
  };

  render() {
    const { dom, className } = this.state;
    return (
        <>
        {!!dom &&
          <div className={`BackdropContainer ${className ? className : ''}`}>
            <div className={`ScrollContainer ${className ? 'ScrollContainerFullHeight' : ''}`}>
              {dom}
            </div>
          </div>
        }
    </>
    );
  }
}