import React from "react";
import styles from "./dressNotFound.module.css";
import dressNotFound from "../../assets/icons/dressNotFound.svg";
import { useNavigate } from "react-router-dom";

const DressNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className={styles.allDressSearchContainer}>
        <img src={dressNotFound} alt="" className={styles.imgEmpty} />
        <button className={styles.modifySearchButton} onClick={() => navigate('/principal')}>
          MODIFICAR BÚSQUEDA
        </button>
      </section>
    </>
  );
};

export default DressNotFound;
