import React, { useState } from "react";
import styles from "./detailsAndFilters.module.css";
import FaqCalendarDressDetails from "../faq/faqCalendarDressDetails/FaqCalendarDressDetails";
import FaqCustomSizes from "../faq/faqCustomSize/FaqSize";
import FaqPriceDetails from "../faq/faqPriceDetails/FaqPriceDetails";
import MeasurementGuide from "../faq/faqMeasurementGuide/FaqMeasurementGuide";
import FaqDressCare from "../faq/faqDressCare/FaqDressCare";
import FaqShare from "../faq/faqShare/FaqShare";
import { useDispatch, useSelector } from "react-redux";
import FavoriteHeart from "../shared/favoritHeart/FavoriteHeart";
import { useEffect } from "react";
import { getUniqueValues } from "../../utils";
import { setAllColorsAvailables } from "../../store/slices/filters/filtersSlice";
import { useNavigate } from "react-router-dom";
import { setShopCart } from "../../store/slices/shopCart";
import Button from '../shared/button';
import api from "../../api/axios.config";
import { TextDescription } from "../shared/text/Text";
import moment from "moment/moment";
import { Icon } from "@iconify/react";
import HashtagDetails from "../shared/hashtag/hashtag-details";
import { setAllSizesAvailables } from "../../store/slices/filters/filtersSlice";

const DetailsAndFilters = (props) => {
  const navigate = useNavigate()
  const { dressModelDetail } = useSelector((store) => store.dressDetails);
  const [dressDefault, setDressDefault] = useState({});
  const dispatch = useDispatch();
  const { sorellaToken } = useSelector((store) => store.login);
  const [valuesFilters, setValuesFilters] = useState({
    valueCalendar: ['',''],
    valueSizes: [''],
    valueColor: ''
  })
  const [status, setStatus] = useState(null)

  const [pricesRent, setPricesRent] = useState(1)

  useEffect(() => {
    if (dressModelDetail) {
      dispatch(setAllColorsAvailables(
        getUniqueValues(dressModelDetail.dress.map((d) => d.color.code))
      ));
    }
  }, [dressModelDetail]);

  useEffect(() => {
    if(dressModelDetail && valuesFilters.valueSizes[0] !== ''){
      setDressDefault(...dressModelDetail.dress.filter(d => d.size_id === valuesFilters.valueSizes[0]))
    }else if(dressModelDetail){
      setDressDefault(dressModelDetail.dress[0]);
    }
  },[dressModelDetail, valuesFilters.valueSizes])

  const reservarHandlerClick = async (type) => {
    if((valuesFilters.valueCalendar && valuesFilters.valueCalendar[0] !== '')){
      window.heap.track('Added to cart', {
        "Item ID": dressDefault?.id
      })

      if(sorellaToken){
        const resp = await api.post('/api/cart', { dress_id: dressDefault?.id, fecha_limite: moment(valuesFilters.valueCalendar[1]).format('YYYY-MM-DD HH:mm:ss'), fecha_inicio: moment(valuesFilters.valueCalendar[0]).format('YYYY-MM-DD HH:mm:ss'), type, days: pricesRent === 2 ? 8 : 4 })
        if(resp.status === 200){
          dispatch(
            setShopCart({
              ...dressModelDetail, 
              id: dressDefault?.id, 
              cart_id: resp.data?.product?.id,
              dress: [dressDefault],
              final_price: pricesRent === 2 ? Number(dressDefault?.final_price) + 800 : dressDefault?.final_price, 
              price: pricesRent === 2 ? Number(dressModelDetail.price) + 800 : dressModelDetail.price,
              discount: dressDefault?.discount ?? 0,
              date: [valuesFilters.valueCalendar[0], valuesFilters.valueCalendar[1]], 
              size: valuesFilters.valueSizes, 
              color: valuesFilters.valueColor, 
              type
            }))
          navigate(`/carrito`, { state: { type } })
          window.scrollTo(0, 0)
        }else{
          alert('El vestido no está disponible')
        }
      }else{
        localStorage.setItem('memorie-dress', JSON.stringify({
          id: dressDefault?.id,
          ...dressModelDetail, 
          dress: [dressDefault],
          pricesRent,
          final_price: pricesRent === 2 ? Number(dressDefault?.final_price) + 800 : dressDefault?.final_price, 
          price: pricesRent === 2 ? Number(dressModelDetail.price) + 800 : dressModelDetail.price,
          discount: dressDefault?.discount ?? 0,
          date: [valuesFilters.valueCalendar[0], valuesFilters.valueCalendar[1]], 
          size: valuesFilters.valueSizes, 
          color: valuesFilters.valueColor, 
          type
        }));
        navigate('/registro');
      }
    }
  }

  const { allColorsAvailables } = useSelector((store)=>store.filters);

  const updateStorageFilters = () =>{
    if(dressDefault){
      setValuesFilters({ ...valuesFilters, valueColor: dressDefault.color_id })
    }
  }

  const updateSizesForColorsAvailable = (colorCode = []) => {
    const filterByColor = dressModelDetail.dress.filter(d=> colorCode.includes(d.color.code));

    const allSizes = getUniqueValues(filterByColor.map(d => d.size.description));
    dispatch(setAllSizesAvailables(allSizes))
  }

  useEffect(() => {
    if(allColorsAvailables && dressDefault && dressModelDetail){
      updateSizesForColorsAvailable(allColorsAvailables);
      if(allColorsAvailables.length > 0 && dressDefault){
        updateStorageFilters(allColorsAvailables[0])
      }
    }
  },[dressDefault, allColorsAvailables, dressModelDetail])

  const validateStore = (dress) => {
    const stores = [...new Set(dressModelDetail.dress?.map(d => d.store))];
    if(stores.includes('GDL') && stores.includes('LEON')){
      return 'Ambos';
    }

    return stores[0];
  }

  return (
    <>
        <div className={styles.containerDesktop}>
          <span className={styles.titleFilters}>{dressModelDetail.name}</span>
          {(dressModelDetail?.dress && dressDefault?.store) && <span style={{textAlign: 'center', fontSize: 12}} className={styles.dressDescription}><Icon icon="ph:map-pin-light" /> {validateStore(dressModelDetail.dress) === 'Ambos' ? 'Disponible en Guadalajara, Jal. y León, Gto.' : validateStore(dressModelDetail.dress) === 'GDL' ? 'En Guadalajara, Jalisco.' : 'León, Gto.'}</span>}
          <div className={styles.addFavoritesArea}>
            <span className={styles.addFavoritesSpan}>AGREGAR A FAVORITOS</span>
            <FavoriteHeart id={dressModelDetail?.dress ? dressDefault?.id : ''} classNameHeartImg={styles.heartImg} isBrownColor={true} />
          </div>
          <div className={styles.descriptionContainer}>
            <span className={styles.dressDescription}>
              {dressModelDetail.description}
            </span>
          </div>
          <div>
            <HashtagDetails discount={dressModelDetail ? dressDefault?.discount : null} new={dressModelDetail ? dressDefault?.now_tag : null} bestseller={dressModelDetail ? dressDefault?.bestseller_tag : null} />
          </div>
        </div>
      <div className={styles.containerOptions}>
        <div className={styles.containerColorPrimary}>
          <FaqPriceDetails pricesRent={pricesRent} setPricesRent={setPricesRent} discount={dressModelDetail ? dressDefault?.discount : null} />
          <div style={{paddingTop: 32, marginBottom: 26, paddingLeft: 24, paddingRight: 24}}>
            <span className={styles.textDescription}>
            Procura reservar con un mínimo de 4 días antes de tu evento para ajustes o envíos.
            </span>
          </div>
          <FaqCustomSizes details={true} showOnlyAvailables={true} valuesFilters={valuesFilters} containerStyle={styles.containerCenter} setValuesFilters={setValuesFilters} />
          <FaqCalendarDressDetails details={true} pricesRent={pricesRent} valuesFilters={valuesFilters} setValuesFilters={setValuesFilters} idDress={dressDefault ? dressDefault?.id : ''} enableSinceFirstLoad={true} />
          {/* <FaqCustomColor details={true} containerStyle={styles.containerCenter} valuesFilters={valuesFilters} setValuesFilters={setValuesFilters} /> */}
          { dressDefault.notes !== "" && dressDefault.notes !== "<p></p>" &&
            <div className={styles.notesSection}>
              <p className={styles.sectionTitle}>Notas importantes</p>
              <span className={styles.textDescription} dangerouslySetInnerHTML={{__html: dressDefault.notes}}></span>
            </div>
          }
          <div className={styles.rentButtonsArea}>
            <Button label="Rentar ahora"  style={{marginTop: 34}} disabled={((valuesFilters.valueCalendar[0] === '')|| (status === false)|| (valuesFilters.valueCalendar[0] === ''|| valuesFilters.valueCalendar[1] === ''|| valuesFilters.valueSizes[0] === ''|| valuesFilters.valueColor === ''))} onClick={() => reservarHandlerClick('RENTA')} />
            <Button outline={true} style={{marginTop: 32}} disabled={((valuesFilters.valueCalendar[0] === '')|| (status === false)|| (valuesFilters.valueCalendar[0] === ''|| valuesFilters.valueCalendar[1] === ''|| valuesFilters.valueSizes[0] === ''|| valuesFilters.valueColor === ''))} label="APARTAR VESTIDO" onClick={() => reservarHandlerClick('APARTADO')} />
            {status === false && <TextDescription style={{color: '#BF4052'}}>* El vestido no se encuentra disponible en la fecha seleccionada, pruebe otra fecha.</TextDescription>}
          </div>
        </div>
        <MeasurementGuide />
        <FaqDressCare />
        <FaqShare />
      </div>
    </>
  );
};

export default DetailsAndFilters;