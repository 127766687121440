import React from "react";
import Button from "../shared/button";
import Checkbox from "../shared/checkbox";
import { Title, TextDescription, TextLinking } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";
import { useState } from "react";

const ModalTCMobile = ({onClick, navigate, type, isMultiple, optionB}) => {

    const [isCheckTc, setIsCheckTc] = useState(false);

    return (
        <div className={styles.containerModal}>
            <Title styles={styles.textModalTitle}>{isMultiple ? 'RENTA Y APARTADO VESTIDO' : (type +' VESTIDO')}</Title>
            <TextDescription styles={styles.textDescription}>{isMultiple ? 'Debes aceptar haber leído los Términos y Condiciones para continuar.' : `Debes aceptar haber leído los Términos y Condiciones de ${type ? type.charAt(0).toUpperCase()+type.slice(1).toLowerCase() : ''} de este vestido para continuar.`}</TextDescription>

            {isMultiple ?
                <>
                    <TextLinking styles={styles.textCenter} onClick={() => window.open(`${process.env.PUBLIC_URL}/terminos-condiciones`) }>Leer Términos y Condiciones de Renta</TextLinking>
                    <TextLinking styles={styles.textCenter} onClick={() => window.open(`${process.env.PUBLIC_URL}/terminos-condiciones`) }>Leer Términos y Condiciones de Apartado</TextLinking>
                </>
            :
                <TextLinking styles={styles.textCenter} onClick={() => window.open(`${process.env.PUBLIC_URL}/terminos-condiciones`) }>Leer Términos y Condiciones de {type ? type.charAt(0).toUpperCase()+type.slice(1).toLowerCase() : ''}</TextLinking>
            }

            {isMultiple ? 
            <>
                <div className={styles.containCheck}>
                    <Checkbox checked={isCheckTc} onCheck={setIsCheckTc} label="He leído y Acepto los Términos y Condiciones de Renta" />
                </div>
                <div className={styles.containCheck}>
                    <Checkbox checked={isCheckTc} onCheck={setIsCheckTc} label="He leído y Acepto los Términos y Condiciones de Apartado" />
                </div>
            </>
            :
                <div className={styles.containCheck}>
                    <Checkbox checked={isCheckTc} onCheck={setIsCheckTc} label="He leído y Acepto los Términos y Condiciones" />
                </div>
            }
            <div className={styles.containButtons}>
                <Button label="Cancelar" outline styles={styles.buttonMiddle} onClick={() => modal.close()} />
                <Button label="Continuar" styles={styles.buttonMiddle} disabled={false} onClick={() => isCheckTc ? onClick() : {}} />
            </div>
        </div>
    )
}

export default ModalTCMobile;