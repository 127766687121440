import moment from "moment";
import api from "../../api/axios.config"
import { setLogin, setUserDetails } from "../slices/login/loginSlice"
import { setShopCart } from "../slices/shopCart";

export const postAuthRegister = (user) => {
  return async (dispatch, getState) => {
    const result = await api.post("/api/auth/register", user);
    dispatch(postAuthLogin({ email: user.correo_electronico, password: user.password }))
  }
}

export const postAuthLogin = (user) => {
  return async (dispatch, getState) => {
    if(user){
      const result = await api.post("/api/auth/login", user);
      dispatch(setLogin({token: result.data.access_token, user: result.data.user}));
      window.heap.identify(user.email);
      window.localStorage.setItem('sorellaToken', JSON.stringify(result.data.access_token));//save in local storage
      window.localStorage.setItem('user-sorella', JSON.stringify(result.data.user));

      const state = JSON.parse(localStorage.getItem('memorie-dress'));
      if(state){
        const { status } = await api.post('/api/cart', { dress_id: state.id, type: state.type, days: state.pricesRent === 2 ? 8 : 4, fecha_limite: moment(state.date[1]).format('YYYY-MM-DD HH:mm:ss'), fecha_inicio: moment(state.date[0]).format('YYYY-MM-DD HH:mm:ss') })
        if(status === 200){
          localStorage.setItem('memorie-dress', null)
          dispatch(setShopCart(state));
          window.location.href = '/carrito';
        }else{
          window.location.href = '/resultados-vestidos';
        }
      }else{
        window.location.href = '/principal';
      }
    }
  }
}

export const getUserDetails = (id) => {
  return async (dispatch) => {
    const response = await api.get(`/api/user`);
    dispatch(setUserDetails({user: response.data.data}));
  }
}
