import React, { useRef } from "react";
import styles from "./dressSearchResultsDesktop.module.css";
import { Icon } from "@iconify/react";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import Footer from "../footer/Footer";
import CardDress from "../cardDress/CardDress";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SearchFilters from "../searchFiltersSale/SearchFilters";
import LoaderIcon from '../../assets/svg/loader-icon.svg';
import {
  onSetFilterValues
} from "../../store/slices/filters/filtersSlice";
import { useState } from "react";
import { createKey, setParamsToUrl, urlSearchParams } from "../../utils";
import leftArrow from "../../assets/icons/chevron-left.svg";
import rightArrow from "../../assets/icons/chevron-right.svg";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PaginationComponent from "../shared/paginationNumeric/PaginationNumeric";
import DressNotFound from "../dressNotFound/DressNotFound";
import { getAllFavorites } from "../../store/thunks/favoritesThunk";
import api from "../../api/axios.config";
import { setURLBeforeLeave } from "../../store/slices/navigation/navigationSlice";

const DressSearchResultsDesktop = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [dataDress, setDataDress] = useState([])
  const { previousURL } = useSelector((store) => store.navigation)
  //const { allDresses } = useSelector((store) => store.dressSearchResults);
  const [allDresses, setAllDresses] = useState([])
  const refPage = useRef(null)
  const [page, setPage] = useState('1')
  const [perPage, setPerPage] = useState('20')
  const [itemView, setItemView] = useState([])
  const section2 = useRef(null)
  const [heightSection, setHeightSection] = useState('')
  const [fadeOut, setFadeOut] = useState(false)
  const [loading, setLoading] = useState(false)
  const [prevFilters, setPrevFilters] = useState([])
  const [scrollUp, setScrollUp] = useState(false)
  const [collaboration, setCollaboration] = useState('Productos en venta')
  const {
    valuesFilter: {
      valueColor,
      valueSizes,
      valuePrices,
      valueHashtags,
      valueProductTypes
    }
   } = useSelector((store) => store.filters)

  useEffect(() => {
    window.localStorage.setItem('first-init', true)
  }, [])

  const{ valuesFilter } = useSelector((store) => store.filters)

  const getMapQueryDress = () => {
    const items = [];

    if(valueColor?.length > 0){
      const vlc = []
      for(let i = 0; i < valueColor.length; i++)
        vlc.push(valueColor[i])
      items.push({ name: 'colors', params: vlc.join(',') })
    }

    if(valueHashtags?.length > 0){
      const hst = []
      for(let i = 0; i < valueHashtags.length; i++)
        hst.push(valueHashtags[i])
      items.push({ name: 'hashtags_ids', params: hst.join(',') })
    }

    if(valueSizes?.length > 0){
      const vsz = []
      for(let i = 0; i < valueSizes.length; i++)
        vsz.push(valueSizes[i])
      items.push({ name: 'sizes', params: vsz.join(',') })
    }

    if(valuePrices?.length > 0 && valuePrices[0] !== ''){
      items.push({ name: 'min_precio', params: valuePrices[0] })
      items.push({ name: 'max_precio', params: valuePrices[1] })
    }

    if(valueProductTypes?.length > 0){
      const vlpt = []
      for(let i = 0; i < valueProductTypes.length; i++) vlpt.push(valueProductTypes[i])
      items.push({ name: 'type_product_ids', params: vlpt.join(',') })
    }

    if(items.length > 0 && JSON.stringify(prevFilters) !== JSON.stringify(items)){
      setPrevFilters(items);
      setPage(1);
    }

    return items
  }

  const getUrlParamsDress = () => {
    const queryParams = urlSearchParams(location.search)
    // let colors = []
    let search = null
    let colors = []
    let hashtags = []
    let sizes = []
    let prices = []
    let typeProducts = []
    const items = []

    if (queryParams.object?.search && queryParams.object?.search !== '') {
      search = queryParams.object?.search
    }
    if (queryParams.object?.colors?.split(',').length > 0) {
      items.push({ name: 'colors', params: queryParams.object?.colors })
      colors = queryParams.object?.colors?.split(',').map((type) => parseInt(type))
    }
    if (queryParams.object?.hashtags_ids?.split(',').length > 0) {
      items.push({ name: 'hashtags_ids', params: queryParams.object?.hashtags_ids })
      hashtags = queryParams.object?.hashtags_ids?.split(',')
    }
    if (queryParams.object?.talla_ids?.split(',').length > 0) {
      items.push({ name: 'sizes', params: queryParams.object?.talla_ids })
      sizes = queryParams.object?.talla_ids?.split(',').map((size) => parseInt(size))
    }
    if (queryParams.object?.min_precio && queryParams.object?.max_precio) {
      items.push({ name: 'min_precio', params: queryParams.object?.min_precio })
      items.push({ name: 'max_precio', params: queryParams.object?.max_precio })
      prices = [
        queryParams.object?.min_precio,
        queryParams.object?.max_precio
      ]
    }
    if (queryParams.object?.type_product_ids?.split(',').length > 0) {
      items.push({ name: 'type_product_ids', params: queryParams.object?.type_product_ids })
      typeProducts = queryParams.object?.type_product_ids?.split(',')
    }

    if((items.length > 0 || search) && JSON.stringify(prevFilters) !== JSON.stringify(items)){
      setPrevFilters(items);
      // setPage(1);
    }

    return {
      search,
      items,
      colors,
      hashtags,
      sizes,
      prices,
      typeProducts
    }
  }

  useEffect(() => {
    const mapQuery = getMapQueryDress()
    if (location.search.includes('search') && location.search !== '') {
      dispatch(onSetFilterValues({
        valueColor: [],
        valueSizes: [],
        valuePrices: ['', ''],
        valueHashtags: [],
        valueProductTypes: []
      }))
    } else if (location.search.includes('collaboration') && location.search !== '') {
      dispatch(onSetFilterValues({
        valueColor: [],
        valueSizes: [],
        valuePrices: ['', ''],
        valueHashtags: [],
        valueProductTypes: []
      }))
    } else if (mapQuery.length > 0 && (!location.search.includes('search') || location.search === '')) {
      // setPage(1);
      setParamsToUrl(navigate, "/en-venta", mapQuery);
    } else if (mapQuery.length === 0 && (!location.search.includes('search') || location.search === '')) {
      navigate('/en-venta')
    }
  },[
    valueColor,
    valueSizes,
    valuePrices,
    valueHashtags,
    valueProductTypes
  ])

  const fetchData = async () => {
    if(window.localStorage.getItem('first-init') === null){
      setFadeOut(true);
    }

    const search = urlSearchParams(window.location.href)
    let query = ''
    if(search && search.object){
      Object.keys(search.object).map(item => {
        query += `&${item}=${search.object[item]}`
        return null
      })
    }

    setTimeout(() => {
      setLoading(true);
    }, 500);
    const result = await api.get(`/api/on-sale?page=${page}&per_page=${perPage}${query}`);
    if(result.status === 200){
      setAllDresses(result.data)
      if (result.data?.meta?.collaboration) {
        setCollaboration(result.data?.meta?.collaboration)
      }
      if(result.data.data && typeof result.data.data === 'object'){
        if(scrollUp === `${query}` || scrollUp === false){
          refPage.current.scrollIntoView({ behavior: 'smooth' })
          setScrollUp(`${query}`)
        }
        setDataDress(Object.values(result.data.data))
      }
    }
    
    setTimeout(() => {
      setLoading(false);
      window.localStorage.removeItem('first-init')
      setFadeOut(false);
    }, 2000)
  };

  useEffect(() => {
    if (previousURL !== window.location.href) {
      setURLBeforeLeave(window.location.href)
    }
    if (previousURL !== null) fetchData()
  }, [page, perPage, window.location.href])

  useEffect(() => {
    if(dataDress.length) {
      setTimeout(() => {
        setHeightSection((Number(section2.current?.clientHeight) + 120))
      }, 3500)
    }
  },[allDresses, page, perPage, dataDress])

  useEffect(() => {
    const urlQuery = getUrlParamsDress()
    if (urlQuery.items.length > 0) {
      setPage(1)
      dispatch(onSetFilterValues({
        valueColor: urlQuery.colors,
        valueSizes: urlQuery.sizes,
        valuePrices: urlQuery.prices,
        valueHashtags: urlQuery.hashtags,
        valueProductTypes: urlQuery.typeProducts
      }))
    }
    dispatch(getAllFavorites())
  },[])

  return (
    <>
      <div ref={refPage} className={styles.container}>
        <section className={styles.nav}>
          <NavBarDesktop />
        </section>
        <section className={styles.rowsSections}>
          <section className={styles.section1} style={{ maxHeight: heightSection ? (heightSection)+'px': '100%'}}>
            <SearchFilters
              setItemView={setItemView}
              navigate={navigate}
              setFilter={onSetFilterValues}
              allDresses={dataDress}
            />
          </section>
          <section className={styles.section2} ref={section2}>
            <section className={styles.topSection}>
              <div className={styles.topLeftArea}>
                <span className={styles.titleStyles}>{ collaboration }</span>
                <div className={styles.inlineWrap}>
                  {(itemView) && itemView.map(item => 
                  <div className={styles.filtersArea} key={createKey()}>
                    <section className={styles.filterItem}>
                      <p>{item.value}</p>
                      <button
                        className={styles.close}
                        onClick={() => {
                          const values = valuesFilter[item.id];
                          dispatch(onSetFilterValues({
                            [item.id]: (item.id === 'valuePrices') ? ['', ''] : [...values.filter(v => v !== item.key)]
                          }))
                          setItemView([...itemView.filter(v => v.id !== item.id)])
                        }}
                      >
                        <Icon
                          icon="eva:close-outline"
                          color={'#434A48'}
                          width="10px"
                          height="10px"
                        />
                      </button>
                    </section>
                  </div>
                  )}
                </div>
              </div>
              <div className={styles.topRightArea}>
                <section className={styles.modelsForEachPage}>
                    <span className={styles.titlePaginationModels}>
                      Productos por página:
                    </span>
                    <select className={styles.qtyModels} defaultValue={perPage} onChange={(e) => {setPerPage(e.target.value); setPage(1)}}>
                      <option value="8">8</option>
                      <option value="12">12</option>
                      <option value="16">16</option>
                      <option value="20">20</option>
                  </select>
                </section>
                <section className={styles.paginationWrapper}>
                  <span className={styles.spanPage}>Página:</span>
                  <div className={styles.paginationContainer}>
                    <img src={leftArrow} alt="" className={styles.arrows} style={{cursor: 'pointer'}} onClick={() => page > 1 ? setPage((+page) - 1) : {}}></img>
                    <div className={styles.pages}>
                      <span>{allDresses?.current_page}</span>
                      <span>&nbsp;/&nbsp;</span>
                      <span>{allDresses?.last_page}</span>
                    </div>
                    <img src={rightArrow} alt="" className={styles.arrows} style={{cursor: 'pointer'}} onClick={() => page < (+allDresses?.last_page) ? setPage((+page) + 1) : {}}></img>
                  </div>
                </section>
              </div>
            </section>

            <section className={styles.allDressSearchContainer}>
              {loading ? 
                <div style={{width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div>
                    <img src={LoaderIcon} alt="animation" className={styles.rotateCenter} />
                  </div>
                </div>
              :
              <>
                {dataDress && dataDress.length === 0 ?
                    <DressNotFound />
                  :
                  dataDress.map((row, i) => (
                    <div key={i} className={fadeOut ? styles.itemFadeOut : styles.itemFadeIn}>
                      <CardDress
                        dressModel={row}
                        dress={row.type === 'dresses' ? row.sale_dresses[0] : row.type === 'shoes' ? row.shoes[0] : row.complements[0]}
                        url={row.images ? row.images[0]?.url : ''}
                        id={row ? row.id : null}
                        id_shop={row.id}
                      />
                    </div>
                  ))
                }
              </>
              }
            </section>
            {dataDress && dataDress.length > 0 &&
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <PaginationComponent page={page} setPage={setPage} total={allDresses?.total} last_page={allDresses?.last_page} per_page={perPage} data={dataDress} />
              </div>
            }
          </section>
        </section>

      </div>
      <Footer />
    </>
  );
};

export default DressSearchResultsDesktop;
