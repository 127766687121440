import React from "react";
import styles from "./Modal.module.css";
import { modal } from '../modalManagment';
import { InputSearch } from '../shared/input/Input';
import { TextDescription } from '../shared/text/Text';
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from '../../store/slices/login/loginSlice';
import ModalConfirmLogOut from "../modal/ModalConfirmLogOut";

const ModalSidebar = ({ navigate, setOnSidebar }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(store => store.login)
    
    const modalConfirmLogOut = () => {
        modal.open(<ModalConfirmLogOut onClick={()=>logOut()} />, () => {}, 'BackdropContainerCenter')
    }

    const logOut = () =>{
        modal.close(() => {
            setOnSidebar(false);
            dispatch(setLogout());
            navigate('/principal')
        })
    }

    return (
        <div className={styles.containerColor}>
            <InputSearch placeholder="BUSCAR" onKeyUp={(e) => {if(e.key === 'Enter') {setOnSidebar(false);navigate(`/resultados-vestidos?search=${e.target.value}`);modal.close();} }} />

            {user?.role === 'administrator' ?
                <>
                    <div className={styles.itemSidebar} onClick={() => {
                        modal.close(() => {
                            setOnSidebar(false);
                            navigate('/citas-agendadas')
                        })
                    }}>
                        <TextDescription styles={styles.itemsLabel}>CITAS AGENDADAS</TextDescription>
                    </div>
                    <div className={styles.itemSidebar} onClick={() => {
                        modal.close(() => {
                            setOnSidebar(false);
                            navigate('/clientes')
                        })
                    }}>
                        <TextDescription styles={styles.itemsLabel}>CLIENTES</TextDescription>
                    </div>
                    <div className={styles.itemSidebar} onClick={() => {
                        modal.close(() => {
                            setOnSidebar(false);
                            navigate('/config-terminos-condiciones')
                        })
                    }}>
                        <TextDescription styles={styles.itemsLabel}>CONFIGURACIÓN DE TÉRMINOS Y CONDICIONES</TextDescription>
                    </div>
                </>
            :
                <>
                    {/* <div className={styles.itemSidebar}>
                        <TextDescription styles={styles.itemsLabel} onClick={()=> 
                            modal.close( () => {
                                setOnSidebar(false);
                                navigate('/resultados-vestidos?hashtags_ids=Nuevo')
                            })
                        }>LO MÁS NUEVO</TextDescription>
                    </div> */}
                    <div className={styles.itemSidebar}>
                        <TextDescription styles={styles.itemsLabel} onClick={()=> 
                            modal.close( () => {
                                setOnSidebar(false);
                                navigate('/resultados-vestidos?hashtags_ids=Descuento')
                            })
                        }>DESCUENTOS</TextDescription>
                    </div>
                    <div className={styles.itemSidebar}>
                        <TextDescription styles={styles.itemsLabel} onClick={()=> 
                            modal.close( () => {
                                setOnSidebar(false);
                                navigate('/resultados-vestidos?hashtags_ids=BestSeller')
                            })
                        }>BESTSELLERS</TextDescription>
                    </div>
                    {/* <div className={styles.itemSidebar}>
                        <TextDescription styles={styles.itemsLabel} onClick={()=>{
                             modal.close( () => {
                                setOnSidebar(false);
                                navigate("/resultados-vestidos")
                            })
                        }}>RENTA</TextDescription>
                    </div> */}
                    {/* <div className={styles.itemSidebar}>
                        <TextDescription styles={styles.itemsLabel}>DESCUENTOS</TextDescription>
                    </div> */}
                    <div className={styles.itemSidebar} onClick={() => {
                       modal.close( () => {
                            setOnSidebar(false);
                            navigate("/contacto")
                        })
                    }}>
                        <TextDescription styles={styles.itemsLabel}>CONTACTO</TextDescription>
                    </div>
                </>
            }
            {window.localStorage.getItem('sorellaToken') &&
                <div className={styles.itemSidebar} onClick={() => {
                    setOnSidebar(false);
                    modalConfirmLogOut()
                }}>
                    <TextDescription styles={styles.itemsLabelRed}>CERRAR SESIÓN</TextDescription>
                </div>
            }
            
        </div>
    );
};

export default ModalSidebar;