import React from 'react';
import styles from './InputCoupon.module.css';

const InputCoupon = (props) => (
    <div className={styles.containtCoupon}>
        <input type="text" className={`${styles.input}`} onChange={props.onChange} value={props.value} placeholder="Código de promoción" />
        <button className={styles.button} onClick={props.onClick}>APLICAR</button>
    </div>
)

export default InputCoupon;