import React from "react";
import styles from "./searchFilters.module.css";
import { useState } from "react";
import FaqProductType from "../faq/faqProductType/FaqProductType";
import FaqPrice from "../faq/faqPrice/FaqPrice";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../api/axios.config";
import FaqHashtags from "../faq/faqHashtags/FaqHashtags";
import { AllTypes, AllHashTagsSale } from "../../constants";
import FaqSaleSize from "../faq/faqSaleSize/FaqSaleSize";

const SearchFilters = (props) => {
  const dispatch = useDispatch()
  const [colors, setColors] = useState([]);
  const [dressSizes, setDressSizes] = useState([])
  const [shoesSizes, setShoesSizes] = useState([])
  const [complementsSizes, setComplementsSizes] = useState([])
  const [sizes, setSizes] = useState([]);

  const [valueSizes, setValueSizes] = useState([]);
  const [valuePrices, setValuePrices] = useState(['','']);
  const [valueHashtags, setValueHashtags] = useState([]);
  const [valueProductTypes, setValueProductTypes] = useState([])

  const { valuesFilter } = useSelector((store) => store.filters)

  useEffect(() => {
    setValueSizes(valuesFilter.valueSizes)
    setValuePrices(valuesFilter.valuePrices)
    setValueHashtags(valuesFilter.valueHashtags)
    setValueProductTypes(valuesFilter.valueProductTypes)
    
  },[valuesFilter])

  const handlerApplyFilter = () => {
    dispatch(props.setFilter({
      valueSizes,
      valuePrices,
      valueHashtags,
      valueProductTypes
    }))
  }

  const getParamsUrl = () => {
    const url = window.location.href;

    let query = {};
    let pairs = (url[0] === '?' ? url.substr(1) : url).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return {
        valueColor: query['colors'] ? query['colors'].split(',') : [],  
        valueSizes: query['sizes'] ? query['sizes'].split(',') : [], 
        valueHashtags: query['hashtags_ids'] ? query['hashtags_ids'].split(',') : [], 
        valueProductTypes: query['type_product_ids'] ? query['type_product_ids'].split(',') : [],
        valuePrices: query['max_precio'] ? [moneyFormat(query['min_precio']), moneyFormat(query['max_precio'])] : ['','']
      }
  }

  const moneyFormat = (value) => {
    const options = { style: 'currency', currency: 'MXN', maximumFractionDigits: 0, minimumFractionDigits: 0 }
    const format = new Intl.NumberFormat('es-MX', options)
    return format.format(value)
  }

  const getNameItems = ({ valueSizes, valuePrices, valueHashtags, valueProductTypes }) => {
    const items = [];

    if(sizes?.length && valueSizes.length){
      for(let i = 0; i < valueSizes.length; i++)
        items.push({id: 'valueSizes', value: sizes.find(zs => zs.id === Number(valueSizes[i])) ? 'Talla '+sizes.find(zs => zs.id === Number(valueSizes[i])).description : '', key: Number(valueSizes[i])})
    }

    if(valuePrices?.length && valuePrices[0] !== ''){
      items.push({id: 'valuePrices', value: `${valuePrices[0]}-${valuePrices[1]}`})
    }

    if(valueHashtags?.length){
      for(let i = 0; i < valueHashtags.length; i++)
        items.push({id: 'valueHashtags', value: valueHashtags[i], key: valueHashtags[i]})
    }

    if(valueProductTypes?.length){
      for(let i = 0; i < valueProductTypes.length; i++) {
        const value = AllTypes.find((type) => type.value === valueProductTypes[i])
        items.push({id: 'valueProductTypes', value: value?.display ?? valueProductTypes[i], key: valueProductTypes[i]})
      }
    }

    return items
  }

  const getSizesCatalog = async () => {
    const resp = await axios.get(`/api/sale-sizes`);
    if(resp.status === 200) {
      setDressSizes(resp.data.dress_sizes)
      setShoesSizes(resp.data.shoes_sizes)
      setComplementsSizes(resp.data.complements_sizes)
    }
  }

  useEffect(() => {
    getSizesCatalog()
  },[])

  useEffect(() => {
    props.setItemView(getNameItems(getParamsUrl()))
  }, [
    valueSizes,
    valuePrices,
    sizes,
    colors,
    valueHashtags,
    valueProductTypes,
    window.location.href
  ])

  return (
    <div className={styles.scrollContaint}>
      <span className={styles.titleFilters}>FILTROS DE BÚSQUEDA</span>
      <FaqHashtags valueHashtags={valueHashtags} setValueHashtags={setValueHashtags} apply={() => handlerApplyFilter()} AllHashTags={AllHashTagsSale} />
      <FaqProductType valueProductTypes={valueProductTypes} setValueProductTypes={setValueProductTypes} apply={() => handlerApplyFilter()} display={true} />
      <FaqSaleSize allDresses={props?.allDresses ?? []} valueSizesArray={valueSizes} setValueSizes={setValueSizes} showOnlyAvailables={false} display={true} dressSizes={dressSizes} shoesSizes={shoesSizes} complementsSizes={complementsSizes} filterProducts={valueProductTypes} navigate={props.navigate} apply={() => handlerApplyFilter()} />
      <FaqPrice valuePrices={valuePrices} setValuePrices={setValuePrices} navigate={props.navigate} apply={() => handlerApplyFilter()} />
    </div>
  );
};

export default SearchFilters;
