import React from "react";
import styles from "./About.module.css";
import { Title, TextDescription } from '../shared/text/Text';
import Button from '../shared/button';
import Footer from "../footer/Footer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FooterMobile from "../footerMobile/FooterMobile";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import AboutImage from '../../assets/about/about-1.svg';
import AboutImage2 from '../../assets/about/about-2.svg';
import { useNavigate } from "react-router-dom";

const AboutPage = (props) => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    return (
        <>
        <div>
            <section className={styles.navMobile}>
                {width <= 1024 ? <NavBarMobile /> : <NavBarDesktop />}
            </section>
            {width <= 1024 ? 
            <>
                <div className={styles.rowSm}>
                    <div className={styles.colSm}>
                        <Title>Sobre nosotros</Title>
                        <TextDescription>E-Sorella nace después de tener tiendas en físico en Guadalajara y León empezamos a recibir pedidos en toda la republica. Decidimos hacer una plataforma y buscamos mejorar la experiencia para que sea de lo más sencillo el servicio para nuestros clientes.</TextDescription>
                    </div>
                    <div className={styles.colSm}>
                        <img src={AboutImage} alt="" />
                    </div>
                </div>
                <div className={styles.rowSm}>
                    <div className={styles.colSm}>
                        <Title>Misión</Title>
                        <TextDescription>Nuestra misión es ofrecer a las mujeres productos de la mas alta calidad para todas las ocasiones y eventos que les permita sentirse especiales por dentro y por fuera.</TextDescription>
                    </div>
                    <div className={styles.colSm}>
                        <img src={AboutImage2} alt="" />
                    </div>
                </div>
                <div className={styles.colMaxSm}>
                    <Title>Sé especial con E-Sorella</Title>
                    <TextDescription>Prueba nuestro catálogo hoy, descubre el vestido ideal para tu siguiente evento.</TextDescription>

                    <Button style={{maxWidth: 274}} onClick={() => navigate('/resultados-vestidos')}>DESCUBRE NUEVOS ESTILOS</Button>
                </div>
            </>
            : 
            <>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Title>Sobre nosotros</Title>
                        <TextDescription>E-Sorella nace después de tener tiendas en físico en Guadalajara y León empezamos a recibir pedidos en toda la republica. Decidimos hacer una plataforma y buscamos mejorar la experiencia para que sea de lo más sencillo el servicio para nuestros clientes.</TextDescription>
                    </div>
                    <div className={styles.col}>
                        <img src={AboutImage} alt="" />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <img src={AboutImage2} alt="" />
                    </div>
                    <div className={styles.col}>
                        <Title>Misión</Title>
                        <TextDescription>Nuestra misión es ofrecer a las mujeres productos de la mas alta calidad para todas las ocasiones y eventos que les permita sentirse especiales por dentro y por fuera.</TextDescription>
                    </div>
                </div>
                <div className={styles.colMax}>
                    <Title>Sé especial con E-Sorella</Title>
                    <TextDescription>Prueba nuestro catálogo hoy, descubre el vestido ideal para tu siguiente evento.</TextDescription>

                    <Button style={{maxWidth: 274}} onClick={() => navigate('/resultados-vestidos')}>DESCUBRE NUEVOS ESTILOS</Button>
                </div>
            </>
            }
        </div>
        {width <= 1024 ? <FooterMobile /> : <Footer />}
        </>
    );
};

export default AboutPage;
