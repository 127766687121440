import React from 'react';
import styles from './hashtag.module.css';
import Star from '../../../assets/icons/star.png';
import Discount from '../../../assets/icons/tag.png';
import Trophy from '../../../assets/icons/trophy.png';

//discount new bestseller
const Hashtag = (props) => {
    return (
        <div className={styles.containerHashtags} style={{bottom: props.discount > 0 ? 80 : 70}}>
            {props.new &&
                <div className={styles.containTexts} style={{background: (props.new) ? 'rgba(255, 255, 255, 0.75)' : 'transparent'}}>
                    <img src={Star} alt="icon star" />
                    <p>NUEVO</p>
                </div>
            }
            {props.discount > 0 &&
                <div className={styles.containTexts} style={{background: (props.discount > 0 ) ? 'rgba(255, 255, 255, 0.75)' : 'transparent'}}>
                    <img src={Discount} alt="icon discount" />
                    <p>DESCUENTO</p>
                </div>
            }
            {(props.bestseller && (!props.new || !props.discount)) &&
                <div className={styles.containTexts} style={{background: (props.bestseller) ? 'rgba(255, 255, 255, 0.75)' : 'transparent'}}>
                    <img src={Trophy} alt="icon bestseller" />
                    <p>BESTSELLER</p>
                </div>
            }
        </div>
    )
}

export default Hashtag;