import { createSlice } from '@reduxjs/toolkit';

export const termsAndConditionsSlice = createSlice({
    name: 'termsAndConditions',
    initialState: {
        dataTermsConditions: null,
    },
    reducers: {
        setDataTermsConditions:(state, action)=>{
            state.dataTermsConditions = action.payload;
        },
    }

});

export const { setDataTermsConditions } = termsAndConditionsSlice.actions;