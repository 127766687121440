export const EVENTO_PLAYA = 'Evento en playa';
export const EVENTO_PLAYA_ID = 5;
export const EVENTO_NOCHE = 'Evento noche';
export const EVENTO_NOCHE_ID = 8;
export const BODA = 'Boda';
export const BODA_ID = 3;
export const GRADUACION = 'Graduación';
export const GRADUACION_ID = 2;
export const FIESTA = 'Fiesta';
export const FIESTA_ID = 4;
export const EVENTO_DIA = 'Evento día';
export const EVENTO_DIA_ID = 1;
export const AllEventTypeOptions = [EVENTO_PLAYA, EVENTO_NOCHE, BODA, GRADUACION, FIESTA, EVENTO_DIA];


export const MINI = 'Mini';
export const DEBAJO_RODILLA = "Debajo rodilla";
export const ENCIMA_RODILLA = "Encima rodilla";
export const MIDI = "Midi";
export const A_LA_RODILLA = "A la rodilla";
export const MAXI = "Maxi";
export const AllLargeOptions = [MINI, DEBAJO_RODILLA, ENCIMA_RODILLA, MIDI, A_LA_RODILLA, MAXI];



export const ASIMETRICO = "Asimétrico";
export const RECTO = "Recto";
export const CORAZON = "Corazón";
export const HALTER = "Halter";
export const RENDONDO = "Redondo";
export const A_LOS_HOMBROS = "A los hombros";
export const TIRANTES = "Tirantes";
export const CUELLO_V = "Cuello V";
export const AllNeckLineOptions = [ASIMETRICO, RECTO, CORAZON, HALTER, RENDONDO, A_LOS_HOMBROS, TIRANTES, CUELLO_V];

export const VALERIA_LARR = "Valeria Larr";
export const DIAZ_GARZA = "Diaz Garza";
export const BCBG = "BCBG";
export const LA_FEMME = "La Femme";
export const GAUNA = "Gauna";
export const FASHION_BNKR = "Fashion BNKR";
export const AllBrands = [VALERIA_LARR, DIAZ_GARZA, BCBG, LA_FEMME, GAUNA, FASHION_BNKR];


export const XS = "XS";
export const S = "S";
export const M = "M";
export const L = "L";
export const XL = "Xl";
export const XXL = "XXl";
export const MX2 = "MX 2";
export const MX3 = "MX 3";
export const MX4 = "MX 4";
export const MX5 = "MX 5";
export const MX6 = "MX 6";
export const MX7 = "MX 7";
export const UT = "Unitalla";
export const A = "A";
export const B = "B";
export const C = "C";
export const D = "D";
export const AllSizes = [XS, S, M, L, XL, XXL];
export const AllDressSizes = [XS, S, M, L, XL, XXL];
export const AllShoesSizes = [MX2, MX3, MX4, MX5, MX6, MX7];
export const AllComplementsSizes = [UT, A, B, C, D];


export const AllHashTags = ["Descuento", "BestSeller"] // Hashtags for search filter

export const AllHashTagsSale = ["Descuento"]

export const AllTypes = [
    {
        display: "BoobTapes",
        value: "complements"
    },
    {
        display: "Vestidos",
        value: "dresses"
    },
    {
        display: "Tacones",
        value: "shoes"
    }
]