import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getUserDetails } from "../../store/thunks/authThunk";
import FavoritesMobile from "../favoritesMobile/FavoritesMobile";
import FavoritesDesktop from "../favoritesDesktop/FavoritesDesktop";

const Favorites = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch()
  const { user } = useSelector((store) => store.login);

  const onInit = () => {
    try {
      dispatch(getUserDetails())
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    onInit()
  },[])

  return (
    <>
      {width <= 1024 ? <FavoritesMobile details={user} /> : <FavoritesDesktop details={user} />}
    </>
  );
};

export default Favorites;