import React from 'react';
import { ChrevronRight } from '../../../assets/icons/ChrevronRight';
import styles from './Item.module.css';

const Item = (props) => (
    <div className={styles.container}>
        <span className={styles.labelItem} onClick={props.onClick}>{props.label}</span>
        {!props.last && <ChrevronRight />}
    </div>
)

export default Item;