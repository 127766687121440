import React from "react";
import styles from "./ForgotMobile.module.css";
import { useForm } from "react-hook-form";
import api from "../../api/axios.config";
import { useState } from "react";
import Button from "../shared/button";
import { useNavigate } from "react-router-dom";

const ForgotMobile = (props) => {
  const navigate = useNavigate()
  const [status, setStatus] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const resp = await api.post(`/api/recovery-password`, { email: data.email })
      if(resp.status === 200){
        setStatus(true);
      }
      } catch (error) {
        console.log(error)
      }
  };

  return (
    <>
      <div className={styles.cardContainerMobile}>
        <span className={styles.titleCard}>Restablecer contraseña</span>

        {status ? 
          <>
            <p className={styles.textSubtitle}>
            ¡Listo!<br/><br/>
            Te hemos enviado un correo con un link para realizar el cambio de contraseña. No olvides revisar tu bandeja de spam. <br/><br/>
            Si sigues con dificultades, no dudes en ponerte en contacto.
            </p>
            <Button outline label="CONTÁCTANOS" onClick={() => navigate('/faq')} />
          </>      
        :
        <>
          <p className={styles.textSubtitle}>Ingresa el correo relacionado con la cuenta que quieres recuperar. Te mandaremos un link para restablecer tu contraseña.</p>
          <form
            className={styles.registerContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="marginBottomType1">
              <span className="spanTopInputs">Ingresa tu correo electrónico</span>
              <input
                autoComplete="off"
                type="email"
                placeholder="Correo"
                className={`${errors?.email ? 'inputError' : ''} inputTypeText`}
                id="inputEmail"
                {...register("email", {
                  required: { value: true, message: "Correo electrónico no valido" },
                  pattern: {
                    value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Correo electrónico no válido",
                  },
                })}
              />
              {errors.email && (
                <p className={styles.errorMessage}>{errors.email.message}</p>
              )}
            </div>
            <button type="submit" className="primaryButton">
              ENVIAR
            </button>
          </form>
        </>
        }
      </div>
    </>
  );
};

export default ForgotMobile;
