import React from "react";
import styles from "./favoriteHeart.module.css";
import heartFavoritesSvg from "../../../assets/svg/heartFavorites.svg";
import heartIsFavoritesSvg from "../../../assets/svg/heartIsFavorites.svg";
import heartIsFavoritesAndIsBrownSvg from "../../../assets/svg/heartIsFavoritesAndIsBrownSvg.svg"
import heartFavoritesAndIsBrownSvg from "../../../assets/svg/heartFavoritesAndIsBrown.svg"
import { useDispatch, useSelector } from "react-redux";
import { postFavoritesAddFavorite, postFavoritesDeleteFavorite } from "../../../store/thunks/favoritesThunk";

const FavoriteHeart = (props) => {
  const dispatch = useDispatch();
  const { allFavorite } = useSelector((store) => store.dressDetails);
  
  const clickFavorite = (isAdd) => {
    if(isAdd){
      window.heap.track('Added to favorite', {
        "Item ID": props.id
      });
      dispatch(postFavoritesAddFavorite(props.id));
    }else{
      dispatch(postFavoritesDeleteFavorite(props.id));
    }
  }

  return (
    <>
      <button className={`${styles.addFavoritesButton} ${props.className}`}>
        {(allFavorite && allFavorite.find((itemFav) => String(itemFav.id) === String(props.id))) ? (
          (props.isBrownColor) ?
            <img src={heartIsFavoritesAndIsBrownSvg} alt="Favorito" style={{height: 18, width: 18}} className={`${styles.heartImg} ${props.classNameHeartImg}`} onClick={()=>props.customFavHeartBehavior ? props.customFavHeartBehavior() : clickFavorite(false)}/>            
            :
            <img src={heartIsFavoritesSvg} alt="Favorito" style={{height: 18, width: 18}} className={`${styles.heartImg} ${props.classNameHeartImg}`} onClick={()=>props.customFavHeartBehavior ? props.customFavHeartBehavior() : clickFavorite(false)}/>
        ) : (
          (props.isBrownColor) ?
          <img src={heartFavoritesAndIsBrownSvg} alt="Favorito" style={{height: 18, width: 18}} className={`${styles.heartImg} ${props.classNameHeartImg}`} onClick={()=>clickFavorite(true)}/>
          :
          <img src={heartFavoritesSvg} alt="Favorito" style={{height: 18, width: 18}} className={`${styles.heartImg} ${props.classNameHeartImg}`} onClick={()=>clickFavorite(true)}/>
        )}
      </button>
    </>
  );
};

export default FavoriteHeart;
