import styles from './mainImageDress.module.css'
import InnerImageZoom from 'react-inner-image-zoom';
import ReactPlayer from 'react-player'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

const MainImageDress = ({ dressModelDetail, mainImg, setMainImg, handledImages, checkAvailityImg }) => {
    // const arrows = () => (
    //     <div className={styles.arrows}>
    //         <Icon icon="material-symbols:chevron-left-rounded" fontSize={20} style={{cursor: 'pointer'}} 
    //             onClick={() => {
    //             if( mainImg && mainImg.id > 0 && dressModelDetail?.images.length > 1){
    //                 setMainImg({ img: dressModelDetail?.images.find((m,k) => k === (mainImg.id - 1)).url, id: (mainImg.id - 1) })
    //             }
    //         }} />
    //         <Icon icon="material-symbols:chevron-right-rounded" fontSize={20} style={{cursor: 'pointer'}} 
    //             onClick={() => {
    //             if((mainImg && mainImg.id >= 0 && mainImg.id < (dressModelDetail?.images.length - 1) && dressModelDetail?.images.length > 1) || !mainImg.img){
    //                 setMainImg({ img: dressModelDetail?.images.find((m,k) => k === (mainImg.id ? (mainImg.id + 1) : 1)).url, id: (mainImg.id ? (mainImg.id + 1) : 1) })
    //             }
    //         }} />
    //     </div>
    // )

    return mainImg.type === 'image' || Object.keys(mainImg).length === 0 ? (
        <div className={styles.mainImageDressContainer}>
            <InnerImageZoom src={mainImg.img ? mainImg.img : handledImages(0)} zoomSrc={mainImg.img ? mainImg.img : handledImages(0)} imgAttributes={{ style: { objectFit: 'cover', height: '80vh' } }} hideHint={true} zoomPreload={true} zoomType='hover' className={styles.image} />
            {/* <img src={mainImg.img ? mainImg.img : handledImages(0)} id={`model-details-000`} onError={() => checkAvailityImg(`model-details-000`)} alt="" className={styles.image} /> */}
        </div>
    ): (
        <div className={styles.mainImageDressContainer}>
            <ReactPlayer url={mainImg.img} controls={false} width="100%" height="100%" loop={true} playing={true}/>
        </div>
    )
}

export default MainImageDress
