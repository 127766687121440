import React from "react";
import styles from "./navBarDesktop.module.css";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import esorella from "../../assets/icons/esorella.svg";
// import sorella from "../../assets/svg/sorella.svg";
import heartsvg from "../../assets/icons/heart.svg";
import { useNavigate, Link } from "react-router-dom"
import bolsaSvg from "../../assets/icons/BolsaIcon.svg";

const NavBarDesktop = ({isSelectedBag}) => {
  const navigate = useNavigate();
  const { cart } = useSelector((store) => store.shopCart)
  const { user } = useSelector(store => store.login)

  return (
    <>
      <div className={styles.navContainer}>
        {user?.role !== 'administrator' ?
          <div className={styles.newsRentArea} style={{width: '33%'}}>
            {/* <Link className={styles.news} to={'/resultados-vestidos?hashtags_ids=Nuevo'}>LO NUEVO</Link> */}
            <Link className={styles.news} to={'/resultados-vestidos?hashtags_ids=Descuento'}>DESCUENTOS</Link>
            <Link className={styles.news} to={'/resultados-vestidos?hashtags_ids=BestSeller'}>BESTSELLERS</Link>
            {/* <Link className={styles.news} to={'/resultados-vestidos'}>RENTA</Link> */}
          </div>
          :
          <div className={styles.newsRentArea} style={{width: '33%'}}>
            <button className={styles.news} onClick={()=>navigate('/citas-agendadas')}>CITAS</button>
            <button className={styles.news} onClick={()=> navigate('/clientes')}>CLIENTES</button>
            <button className={styles.news} onClick={()=> navigate('/pedidos')}>PEDIDOS</button>
            <button className={styles.news} onClick={()=> navigate('/inventario')}>INVENTARIO</button>
            <button className={styles.news} onClick={()=> navigate('/config-terminos-condiciones')}>CONFIGURACIÓN</button>
          </div>
        }
        <div style={{width: '33%', display: 'flex', justifyContent: 'center'}}>
          <section className={styles.sorella} style={{cursor: 'pointer'}} onClick={() => navigate('/principal')}>
            <img
              alt="sorella"
              src={esorella}
            >
            </img>
          </section>
        </div>
        <div className={styles.containerButtonNav} style={{width: '33%'}}>
          <div className="search-box">
            <span className="search-btn">
              <Icon icon="bytesize:search" style={{"color": "#5f4840"}} width="20" height="18"/>
            </span>
            <input type="text" className="search-input" placeholder="BUSCAR" onKeyUp={(e) => e.key === 'Enter' ? navigate(`/resultados-vestidos?search=${e.target.value}`) : {}} />
          </div>
          {window.localStorage.getItem('sorellaToken') ? (
            <>
              <button onClick={() => navigate('/perfil')} className={styles.buttonNavCount}>MI CUENTA</button>
              
              {user?.role !== 'administrator' &&
                <>
                  <button className={styles.buttonNav} onClick={() => navigate('/favoritos')}>
                    <img alt="heart" style={{height: 16, width: 16}} src={heartsvg}></img>
                  </button>
                  
                  <div className={styles.containIcon} style={{cursor: 'pointer'}} onClick={() => navigate('/carrito')}>
                    <img alt="bolsa" style={{height: 16, width: 16}} src={bolsaSvg}></img>
                    {(cart && cart.length > 0) &&
                      <div className={styles.containCountCart}>
                        <span className={styles.numberCountCart}>{cart.length}</span>
                      </div>
                    }
                  </div>
                </>
              }
            </>
          ) : (
            <>
              <button className={styles.buttonNavLogin} onClick={()=> navigate('/inicio-sesion')}>LOG IN</button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NavBarDesktop;