import localStyles from "./price-input.module.css";

const PriceInput = ({ placeholder, value, onChange, invalid }) => {
    return (
        <div className={`${localStyles.inputGroup} ${ invalid ? localStyles.invalid : ''}`}>
            <input 
                type="text" 
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    const value = e.target.value.replace('$', '').replace(/,/g, '')
                    if (!isNaN(value)) {
                        const options = { style: 'currency', currency: 'MXN', maximumFractionDigits: 0, minimumFractionDigits: 0 }
                        const format = new Intl.NumberFormat('es-MX', options)
                        onChange(format.format(value).replace('$', ''))
                    } else if (value === '') {
                        onChange(value)
                    } else {
                        console.log('Value:', value)
                    }
                }}
            />
        </div>
    )
}

export default PriceInput