import { createSlice } from '@reduxjs/toolkit';

export const dressDetailsSlice = createSlice({
    name: 'dressDetails',
    initialState: {
        dressModelDetail: '',
        allFavorite: [],
        paginationFavorite: []
    },
    reducers: {
        setDressModelDetail: (state, action)=>{
            state.dressModelDetail = action.payload;
        },
        setAllFavorite:  (state, action)=>{
            state.allFavorite = action.payload;
        },
        setAllPaginationFavorite: (state, action) => {
            state.paginationFavorite = action.payload
        },
        removeFavoriteDress: (state, action) =>{
            state.allFavorite = state.allFavorite.filter(itemFav=>itemFav.id !== action.payload)
        }
    }
});

export const {setDressModelDetail, setAllFavorite, removeFavoriteDress, setAllPaginationFavorite} = dressDetailsSlice.actions;