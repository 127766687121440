import { createSlice } from '@reduxjs/toolkit';

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        valuesFilter: {
            valueColor: [],
            valueTypeEvent: [], 
            valueCalendar: ['',''],
            valueBrand: [],
            valueLength: [],
            valueSizes: [],
            valueNecklines: [],
            valuePrices: ['0','0'],
            valueHashtags: [],
            valueProductTypes: []
        },
        valuesFilterFavourites: {
            valueColor: [],
            valueTypeEvent: [], 
            valueCalendar: ['',''],
            valueBrand: [],
            valueLength: [],
            valueSizes: [],
            valueNecklines: [],
            valuePrices: ['',''],
            valueHashtags: [],
            valueProductTypes: []
        },
        colorValue: '',
        sizeValue: '',
        itemsValue: 1,
        allSizesAvailables: [],
        allColorsAvailables: [],
        allColorsAvailablesNames: []
    },
    reducers: {
        onSetFilterValues: (state, action) => {
            state.valuesFilter = {
                ...state.valuesFilter,
                ...action.payload
            }
        },
        onSetFilterFavouritesValues: (state, action) => {
            state.valuesFilterFavourites = {
                ...state.valuesFilterFavourites,
                ...action.payload
            }
        },
        setSingleOccasion: (state, action) => {
            state.occasionValue = [action.payload];
        },
        setMultiOccasion: (state, action) => {
            state.occasionValue = [...state.occasionValue, action.payload];
        },
        removeOccasion: (state, action) => {
            state.occasionValue = state.occasionValue.filter(oc => oc !== action.payload)
        },
        clearOccasionFilter: (state, action) => {
            state.occasionValue = [];
        },
        setCalendarRange: (state, action) => {
            state.calendarRange = [...action.payload.range];
        },
        clearCalendarFilter: (state, action) => {
            state.calendarRange = [];
        },
        setSizeValue: (state, action) => {
            state.sizeValue = action.payload;
        },
        setAllSizesAvailables: (state, action) => {
            state.allSizesAvailables = [...action.payload];
        },
        clearSizeFilter: (state, action) => {
            state.sizeValue = "";
        },
        setAllColorsAvailables: (state, action) => {
            state.allColorsAvailables = [...action.payload];
        },
        setAllColorsAvailablesNames:  (state, action) => {
            state.allColorsAvailablesNames = [...action.payload];
        },
        setColorValue: (state, action) => {
            state.colorValue = action.payload;
        },
        clearColorFilter: (state, action) => {
            state.colorValue = "";
        },
        clearLargeFilter: (state, action) => {
            state.largeValue = [];
        },
        setMultiLarge: (state, action) => {
            state.largeValue = [...state.largeValue, action.payload];
        },
        removeLarge: (state, action) => {
            state.largeValue = state.largeValue.filter(oc => oc !== action.payload)
        },
        clearNeckLineFilter: (state, action) => {
            state.neckLineValue = [];
        },
        setMultiNeckLine: (state, action) => {
            state.neckLineValue = [...state.neckLineValue, action.payload];
        },
        removeNeckLine: (state, action) => {
            state.neckLineValue = state.neckLineValue.filter(nl => nl !== action.payload)
        },
        clearBrandFilter: (state, action) => {
            state.brandValue = [];
        },
        setMultiBrand: (state, action) => {
            state.brandValue = [...state.brandValue, action.payload];
        },
        removeBrand: (state, action) => {
            state.brandValue = state.brandValue.filter(b => b !== action.payload)
        },
        setProductTypeValue: (state, action) => {
            state.valueProductTypes = action.payload;
        },
        clearProductTypeFilter: (state, action) => {
            state.valueProductTypes = [];
        },
        setItemsValue: (state, action) => {
            state.itemsValue = action.payload
        }
    }
});

export const { 
    setSingleOccasion, setMultiOccasion, removeOccasion, setCalendarRange, 
    clearCalendarFilter, setSizeValue, clearSizeFilter, setAllSizesAvailables,
    setColorValue, clearColorFilter, setAllColorsAvailables, setAllColorsAvailablesNames,
    clearLargeFilter, setMultiLarge, removeLarge,
    clearNeckLineFilter,  setMultiNeckLine, removeNeckLine,
    clearBrandFilter, setMultiBrand, removeBrand, 
    onSetFilterValues, onSetFilterFavouritesValues,
    setProductTypeValue, clearProductTypeFilter,
    setItemsValue
} = filtersSlice.actions;