import React, { useState } from "react";
import localStyles from "./faqMeasurementGuide.module.css";
import ToggleDisplay from '../../shared/toggleDisplay/ToggleDisplay';
import {TextDescription} from '../../shared/text/Text';

const FaqMeasurementGuide = () => {
  return (
    <>
      <ToggleDisplay label="GUÍA DE MEDIDAS">
        <div className={localStyles.measurementArea}>
          <table className={localStyles.sorellaTable}>
            <thead>
              <tr>
                <th>TALLA</th>
                <th>XXS</th>
                <th>XS</th>
                <th>S</th>
                <th>M</th>
                <th>L</th>
                <th>XL</th>
                <th>XXL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BUSTO</td>
                <td>81</td>
                <td>85</td>
                <td>88</td>
                <td>92</td>
                <td>97</td>
                <td>100</td>
                <td>104</td>
              </tr>
              <tr>
                <td>CINTURA</td>
                <td>62</td>
                <td>65</td>
                <td>69</td>
                <td>73</td>
                <td>77</td>
                <td>81</td>
                <td>85</td>
              </tr>
              <tr>
                <td>CADERA</td>
                <td>85</td>
                <td>90</td>
                <td>93</td>
                <td>98</td>
                <td>101</td>
                <td>105</td>
                <td>109</td>
              </tr>
            </tbody>
          </table>
          
        </div>
        <div>
            <TextDescription style={{marginTop: 12, textAlign: 'center'}}>Recuerda que las medidas pueden variar entre marca y modelo.</TextDescription>
          </div>
      </ToggleDisplay>
    </>
  );
};

export default FaqMeasurementGuide;
