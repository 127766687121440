import React from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";

const ModalSettingOk = ({onClick}) => {
    return (
        <div className={styles.containerModal}>
            <Title styles={styles.textModalTitle}>AJUSTES LISTOS</Title>
            <TextDescription styles={styles.textDescription}>Al continuar, se le enviará un correo a la usuaria notificando la finalización de los ajustes y permitiendo agendar una cita para la prueba final del vestido.</TextDescription>
            <TextDescription styles={styles.textDescription}>¿Deseas continuar?</TextDescription>
            <div className={styles.containButtons}>
                <Button label="Cancelar" outline styles={styles.buttonMiddle} onClick={() => modal.close()} />
                <Button label="Continuar" styles={`${styles.buttonMiddle}`} onClick={onClick} />
            </div>
        </div>
    )
}

export default ModalSettingOk;