import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import CustomCheck from "../../shared/customCheck/CustomCheck";
import { AllBrands } from "../../../constants";
import { createKey, setParamsToUrl } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { onSetFilterValues, removeBrand, setMultiBrand } from "../../../store/slices/filters/filtersSlice";

const FaqBrand = (props) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const dispatch = useDispatch();
  const { brandValue } = useSelector((store) => store.filters);

  const toggleSelectFilter = (brand) => {
    const alreadyExist = brandValue.find((br) => br === brand);
    if (alreadyExist) {
      dispatch(removeBrand(brand));
    } else {
      dispatch(setMultiBrand(brand));
    }
  };

  // const onChangeFilter = (id) => {
  //   setParamsToUrl(props.navigate, window.location.href, {name: 'marca_ids', params: id})
  // }

  const handlerChecked = (id) => {
    let valueBrand = []
    if(props.valueBrand.includes(id)){
      valueBrand = [...props.valueBrand.filter(v => v !== id)]
    }else{
      valueBrand = [...props.valueBrand, id]
    }
    dispatch(onSetFilterValues({ valueBrand }))
  }

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>MARCA</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <section className={styles.checkContainer}>
        {props.brands ?
            props.brands.map(row => (
              <div key={createKey()} className={styles.checkboxInline}>
                <CustomCheck
                  content={row.description}
                  onChangeCallBack={() => handlerChecked(row.id)}
                  isChecked={
                    props.valueBrand && props.valueBrand.find((nl) => nl === row.id)
                      ? true
                      : false
                  }
                />
              </div>
            ))
          :
          AllBrands.map((brand, index) => (
            <React.Fragment key={createKey()}>
              <CustomCheck
                content={brand}
                onChangeCallBack={() => toggleSelectFilter(brand)}
                isChecked={
                  brandValue && brandValue.find((lar) => lar === brand)
                    ? true
                    : false
                }
              />
            </React.Fragment>
          ))}
        </section>
      </div>
    </>
  );
};

export default FaqBrand;
