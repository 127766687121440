import React from 'react';
import styles from './Text.module.css';

const Title = (props) => (
    <h1 {...props} className={`${styles.title} ${props.styles}`}>{props.children}</h1>
)

const TextDescription = (props) => (
    <p {...props} className={`${styles.description} ${props.styles}`}>{props.children}</p>
) 

const TextLinking = (props) => (
    <span {...props} className={`${styles.linking} ${props.styles}`}>{props.children}</span>
)

const TextSpanSmall = (props) => (
    <span {...props} className={`${styles.styleSpanSmall} ${props.styles}`}>{props.children}</span>
)

export { Title, TextDescription, TextLinking, TextSpanSmall }