import localStyles from "./priceDetails.module.css";
import RemoveIcon from '../../assets/icons/RemoveIcon'
import AddIcon from '../../assets/icons/AddIcon'
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setItemsValue } from "../../store/slices/filters/filtersSlice";

const NumberItems = ({ limit, setValuesFilters, defaultValue = 1, showTitle = true }) => {
    const [itemsCount, setItemsCount] = useState(defaultValue)
    const dispatch = useDispatch()

    const removeItem = () => {
        setItemsCount(itemsCount > 1 ? itemsCount - 1 : itemsCount)
        setValuesFilters({ valueItems: itemsCount > 1 ? itemsCount - 1 : itemsCount })
        dispatch(setItemsValue(itemsCount > 1 ? itemsCount - 1 : itemsCount))
    }

    const addItem = () => {
        setItemsCount(itemsCount < limit ? itemsCount + 1 : itemsCount)
        setValuesFilters({ valueItems: itemsCount < limit ? itemsCount + 1 : itemsCount })
        dispatch(setItemsValue(itemsCount < limit ? itemsCount + 1 : itemsCount))
    }

    const onChange = (e) => {
        if (parseInt(e.target.value) > limit) {
            setItemsCount(limit)
            setValuesFilters({ valueItems: limit })
            dispatch(setItemsValue(limit))
        }
        else if (parseInt(e.target.value) === 0) {
            setItemsCount(1)
            setValuesFilters({ valueItems: 1 })
            dispatch(setItemsValue(1))
        }
        else {
            setItemsCount(parseInt(e.target.value))
            setValuesFilters({ valueItems: parseInt(e.target.value) })
            dispatch(setItemsValue(parseInt(e.target.value)))
        } 
    }

    return (
        <div className={`${localStyles.containerPriceElement}`}>
            { showTitle && <span className={localStyles.spanTitle}>CANTIDAD</span> }
            <div className={`${localStyles.numberContainer}`}>
                <div className={`${localStyles.numberButton}`} onClick={removeItem}><RemoveIcon /> </div>
                <input className={`${localStyles.numberInput}`} value={itemsCount} type="number" max={limit} onChange={onChange}/>
                <div className={`${localStyles.numberButton}`} onClick={addItem}><AddIcon /> </div>
            </div>
        </div>
    )
}

export default NumberItems