
import React from "react";
import {Equis} from "../../assets/icons/Equis";
import { Title, TextDescription, TextLinking } from "../shared/text/Text";
import styles from './shopCart.module.css';
import { useDispatch, useSelector } from "react-redux";
import { removeProduct, updateQuantity } from "../../store/slices/shopCart";
import moment from "moment";
import { modal } from "../modalManagment";
import ModalConfirmRemoveCart from "../modal/ModalConfirmRemoveCart";
import { format_currency } from "../../utils";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { useEffect } from "react";
import api from "../../api/axios.config";
import { useNavigate } from "react-router-dom";
import NumberItems from "../detailsAndFiltersSale/NumberItems";

const CardProduct = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [time, setTime] = useState(null)
    const { dressModelDetail } = useSelector(store => store.dressDetails);

    const getImage = (data) => {
        let srcImage = ''
        if(data.images)
            srcImage = `${data?.images[0]?.url}`;
        return srcImage;
    };

    const getNameColor = (data, color, type) => {
        let value = {}
        if(data[type]?.length > 0 && color) {
            value = data[type]?.find(v => parseInt(v.color.id) === parseInt(color))
        }
        
        return value?.color ? value.color : {name: ''}
    }

    const getNameSize = (size, type) => {
        // console.log('Type:', type)
        if (type === 'VENTA') return size
        if(dressModelDetail.dress){
            const filterSize = dressModelDetail.dress.filter(d => d.size.id === size);

            if(filterSize.length > 0 && size){
                return filterSize[0].size.description
            }

            return size;
        }

        return null
    }

    const getFormatDate = (date) => {
        let date_format = ''
        if(date && date.length === 2 && date[0] !== null) date_format = `${moment(date[0]).format('DD-MMM')} - ${moment(date[1]).format('DD-MMM')}`
        return date_format
    }

    const handlerClick = (id) => {
        modal.open(
            <ModalConfirmRemoveCart onClick={async () => {
                await api.post('/api/remove-cart', { ids: id })
                modal.close();
                dispatch(removeProduct({ids:[id]}))
            }} />, () => {}, 'BackdropContainerCenter'
        )
    }

    const getTimeCountDown = () => {
        return setInterval(() => {
            const time_limit = props.data?.limit_time
            const countDownDate = new Date(moment.unix(time_limit)).getTime();
            const now = new Date().getTime();
            const timeleft = countDownDate - now;

            const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

            if (minutes + seconds > 0) {
                setTime(`${minutes < 10 ? '0'+minutes : minutes}:${seconds < 10 ? '0'+seconds : seconds}`)
            } else {
                setTime('00:00')
            }
        }, 1000)
    }

    useEffect(() => {
        const intervalId = getTimeCountDown()

        return () => {
            clearInterval(intervalId)
        }
    },[])

    const convertPrice = () => {
        const quantity = Number(props.data?.quantity ?? 1)
        let price = Number(props.data?.price);
        if(props.data?.product?.discount > 0) {
            price = price * (Number(props.data?.product?.discount) / 100)
            return Math.abs(price - Number(props.data?.price));
        }
        return price * quantity;
    }

    const updateCartProductQuantity = async ({ valueItems }) => {
        console.log('Quantity:', valueItems)
        dispatch(updateQuantity({ cart_id: props.data?.cart_id, quantity: valueItems }))
        const response = await api.put('/api/cart', { id: props.data?.cart_id, quantity: valueItems })
        if (response.status === 200) {
            dispatch(updateQuantity({ cart_id: response.data?.product?.id, quantity: response.data?.product?.quantity }))
        }
    }
 
    return (
        <div className={styles.containerCard}>
            <div className={styles.containerCardProduct}>
                <div className={styles.containImg}>
                    {/* eslint-disable  jsx-a11y/alt-text */}
                    <img src={getImage(props.data)} className={styles.imgStyle} />
                </div>
                <div className={styles.containTexs}>
                    <Title styles={styles.titleCard}>{props.data?.type}</Title>
                    <TextDescription>{props.data?.name} ({props.data?.color_name ?? getNameColor(props.data, props.colorValue, props.type).name})</TextDescription>
                    <TextDescription>Talla: {getNameSize(props.sizeValue, props.data.type)}</TextDescription>
                    <TextDescription>{getFormatDate(props.calendarRange)}</TextDescription>
                    { props.data?.quantity && props.type === 'complements' && props.readonly && (
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                            <TextDescription>Cantidad: <b>{props.data?.quantity}</b> x {format_currency(props.data?.price)}</TextDescription>
                        </div>
                    )}
                    {props.data?.product?.discount > 0 && <TextDescription><s>{format_currency(props.data?.price)}</s> <span style={{color: '#A02C47'}}>({props.data?.product?.discount}%)</span></TextDescription>}
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <TextDescription style={{color: props.data?.product?.discount > 0  ? '#A02C47' : '#353535'}}>{(props.data?.type === 'RENTA' && !props.isReserve) ? format_currency(convertPrice()) : props.data?.type === 'APARTADO' ? format_currency(Number(convertPrice()) - (Number(convertPrice()) * 0.5)) : format_currency(convertPrice())} {((props.data?.type === 'RENTA' && !props.isReserve) || props.data?.type === 'VENTA') ? '' : '(50% Renta)'}</TextDescription>
                        { props.data?.type !== 'VENTA' && time && 
                            <div style={{display: 'flex', alignItems: 'center', marginLeft: 20}}>
                                <Icon icon={"mdi:clock-time-four-outline"} color="#BF9081" />
                                <TextDescription style={{color: '#BF9081', marginLeft: 2}}>{time}</TextDescription>
                            </div>
                        }
                    </div>
                    { props.data?.quantity && props.type === 'complements' && !props.readonly && (
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', marginTop: '20px'}}>
                            <TextDescription>Cantidad:</TextDescription>
                            <NumberItems
                                defaultValue={props.data?.quantity}
                                limit={props.data?.product?.stock ?? 1}
                                setValuesFilters={updateCartProductQuantity}
                                showTitle={false}
                            />
                        </div>
                    )}

                    {props.conditionReserve &&
                        <div style={{marginTop: 60}}>
                            <TextLinking style={{color: '#5F6867'}}>Leer Términos y Condiciones de Apartado</TextLinking>
                        </div>
                    }
                </div>
            </div>
            {!props.disabled &&
                <div onClick={() => handlerClick(props.data?.cart_id ?? props.data?.id)} style={{ cursor: 'pointer' }}>
                    <Equis />
                </div>
            }
        </div>
    )
}

export default CardProduct;