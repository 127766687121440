import React, { useState } from "react";
import stylesLocal from "./faqShare.module.css";
import facebookIconsvg from "../../../assets/svg/facebookIcon.svg";
import WhatsAppIconsvg from "../../../assets/svg/WhatsAppIcon.svg";
import TelegramIconsvg from "../../../assets/svg/TelegramIcon.svg";
import InstagramIconsvg from "../../../assets/svg/InstagramIcon.svg";
import ToggleDisplay from '../../shared/toggleDisplay/ToggleDisplay';

const FaqShare = () => {
  
  return (
    <ToggleDisplay label="COMPARTIR">
        <section className={stylesLocal.buttonShareContainer}>
          <button className={stylesLocal.buttonShare} onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank')}>
            <img src={facebookIconsvg} alt="" />
          </button>
          <button className={stylesLocal.buttonShare} onClick={() => window.open(`whatsapp://send?text=${window.location.href}`, '_blank')}>
            <img src={WhatsAppIconsvg} alt="" />
          </button>
          <button className={stylesLocal.buttonShare} onClick={() => window.open(`https://t.me/share/url?url=${window.location.href}`, '_blank')}>
            <img src={TelegramIconsvg} alt="" />
          </button>
          <button className={stylesLocal.buttonShare} onClick={() => window.open(`https://www.instagram.com/?url=${window.location.href}`, '_blank')}>
            <img src={InstagramIconsvg} alt="" />
          </button>
        </section>
    </ToggleDisplay>
  );
};

export default FaqShare;