import React, { useState } from "react";
import styles from "./detailsAndFilters.module.css";
import MeasurementGuide from "../faq/faqMeasurementGuide/FaqMeasurementGuide";
import FaqDressCare from "../faq/faqDressCare/FaqDressCare";
import FaqShare from "../faq/faqShare/FaqShare";
import { useDispatch, useSelector } from "react-redux";
import FavoriteHeart from "../shared/favoritHeart/FavoriteHeart";
import { useEffect } from "react";
import { getUniqueValues } from "../../utils";
import { useNavigate } from "react-router-dom";
import { setShopCart } from "../../store/slices/shopCart";
import Button from "../shared/button";
import api from "../../api/axios.config";
import moment from "moment/moment";
import { Icon } from "@iconify/react";
import HashtagDetails from "../shared/hashtag/hashtag-details";
import { setAllSizesAvailables, setSizeValue, setColorValue, setAllColorsAvailables, setAllColorsAvailablesNames, clearColorFilter, clearSizeFilter } from "../../store/slices/filters/filtersSlice";
import PriceDetails from "./PriceDetails";
import Sizes from "./Sizes";
import Colors from "./Colors";
import NumberItems from "./NumberItems";

const DetailsAndFiltersSale = (props) => {
  const navigate = useNavigate();
  const { dressModelDetail: model } = useSelector(
    (store) => store.dressDetails
  );
  const { allColorsAvailables } = useSelector((store) => store.filters)
  const [productDefault, setProductDefault] = useState({});
  const dispatch = useDispatch();
  const { sorellaToken } = useSelector((store) => store.login);
  const [valuesFilters, setValuesFilters] = useState({
    valueSize: "",
    valueColor: "",
    valueItems: 1
  });
  const [type, setType] = useState(null)

  const [pricesRent, setPricesRent] = useState(1);

  const setValueFilter = (value) => {
    setValuesFilters({
      ...valuesFilters,
      ...value
    })
  }

  // Setting allColors and allSizes for the default color if is only one in create component
  useEffect(() => {
    if (model) {
      const type = model.type === 'dresses' ? 'sale_dresses' : model.type
      setType(type)
      let valueColor = valuesFilters.valueColor
      let valueSize = valuesFilters.valueSize
      let colors = model[type]?.map((d) => d.color.id)
      let colorsName = model[type]?.map((d) => ({ id: d.color.id, code: d.color.code, name: d.color.name }))
      colorsName = [...new Map(colorsName.map((color) => [color.id, color])).values()]
      colors = getUniqueValues(colors)
      dispatch(setAllColorsAvailables(colors))
      dispatch(setAllColorsAvailablesNames(colorsName))
      if (colors.length === 1) {
        valueColor = colors[0]
        dispatch(setColorValue(valueColor))
        const sizesOfDefaultColor = model[type]?.filter((d) => d.color.id === valueColor)
        if (sizesOfDefaultColor.length === 1) {
          valueSize = sizesOfDefaultColor[0]?.size?.size ?? sizesOfDefaultColor[0]?.size?.description
          dispatch(setSizeValue(valueSize))
        } else {
          dispatch(clearSizeFilter())
        }
      } else {
        dispatch(clearColorFilter())
      }
      setValuesFilters({
        ...valuesFilters,
        valueSize,
        valueColor
      })
    }
  }, [model]);

  // Setting productDefault, stock limit and default items number after select color and size
  useEffect(() => {
    if (model) {
      console.log('Color:', valuesFilters.valueColor)
      console.log('Talla:', valuesFilters.valueSize)
      let items = model[type]
      if (items.length > 1 && valuesFilters.valueColor !== "") items = items.filter((d) => d.color.code === valuesFilters.valueColor || d.color.id === valuesFilters.valueColor)
      if (items.length > 1 && valuesFilters.valueSize !== "") items = items.filter((d) => d.size.size === valuesFilters.valueSize || d.size.description === valuesFilters.valueSize)
      if (items.length > 0) setProductDefault(items[0])
      else setProductDefault(model[type][0])
    }
  }, [valuesFilters]);

  const reservarHandlerClick = async (type) => {
    const productType = model.type
    if (sorellaToken) {
      const resp = await api.post("/api/cart", {
        dress_id: productDefault?.id,
        type,
        product_type: productType,
        quantity: valuesFilters.valueItems ?? 1
      });
      if (resp.status === 200) {
        dispatch(
          setShopCart({
            ...model,
            id: productDefault?.id,
            cart_id: resp.data?.product?.id,
            product: productDefault,
            final_price: productDefault?.final_price,
            price: model.price,
            discount: productDefault?.discount ?? 0,
            size: valuesFilters.valueSize,
            color: valuesFilters.valueColor,
            type,
            product_type: productType,
            quantity: valuesFilters.valueItems
          })
        );
        navigate(`/carrito`, { state: { type } });
        window.scrollTo(0, 0);
      } else {
        alert("El vestido no está disponible");
      }
    } else {
      localStorage.setItem(
        "memorie-dress",
        JSON.stringify({
          ...model,
          id: productDefault?.id,
          product: productDefault,
          pricesRent,
          final_price: productDefault?.final_price,
          price: model.price,
          discount: productDefault?.discount ?? 0,
          size: valuesFilters.valueSize,
          color: valuesFilters.valueColor,
          type,
          product_type: productType,
          quantity: valuesFilters.valueItems
        })
      );
      navigate("/registro");
    }
  }

  const validateStore = (dress) => {
    const stores = [...new Set(model[type]?.map((d) => d.store))];
    if (stores.includes("GDL") && stores.includes("LEON")) {
      return "Ambos";
    }

    return stores[0];
  };

  return (
    <>
      <div className={styles.containerDesktop}>
        <span className={styles.titleFilters}>{model.name}</span>
        {model?.dress && productDefault?.store && (
          <span
            style={{ textAlign: "center", fontSize: 12 }}
            className={styles.dressDescription}
          >
            <Icon icon="ph:map-pin-light" />{" "}
            {validateStore(model.dress) === "Ambos"
              ? "Disponible en Guadalajara, Jal. y León, Gto."
              : validateStore(model.dress) === "GDL"
              ? "En Guadalajara, Jalisco."
              : "León, Gto."}
          </span>
        )}
        <div className={styles.addFavoritesArea}>
          <span className={styles.addFavoritesSpan}>AGREGAR A FAVORITOS</span>
          <FavoriteHeart
            id={model?.dress ? productDefault?.id : ""}
            classNameHeartImg={styles.heartImg}
            isBrownColor={true}
          />
        </div>
        <div className={styles.descriptionContainer}>
          <span className={styles.dressDescription}>{model.description}</span>
        </div>
        <div>
          {(productDefault?.discount ||
            productDefault?.now_tag ||
            productDefault?.bestseller_tag) && (
            <HashtagDetails
              discount={model ? productDefault?.discount : null}
              new={model ? productDefault?.now_tag : null}
              bestseller={model ? productDefault?.bestseller_tag : null}
            />
          )}
        </div>
      </div>
      <div className={styles.containerOptions}>
        <div className={styles.containerColorPrimary}>
          <PriceDetails
            pricesRent={pricesRent}
            setPricesRent={setPricesRent}
            discount={model ? productDefault?.discount : null}
          />
          { allColorsAvailables && allColorsAvailables.length > 0 &&
            <Colors 
              type={model.type === 'dresses' ? 'sale_dresses' : model.type}
              containerStyle={styles.containerCenter} 
              valuesFilters={valuesFilters} 
              setValuesFilters={setValueFilter} 
            />
          }
          <Sizes
            type={model.type === 'dresses' ? 'sale_dresses' : model.type}
            details={false}
            showOnlyAvailables={false}
            valuesFilters={valuesFilters}
            containerStyle={styles.containerCenter}
            setValuesFilters={setValueFilter}
          />
          { model.type === 'complements' &&
            <NumberItems 
              limit={productDefault?.stock ?? 1}
              setValuesFilters={setValueFilter}
            />
          }
          { valuesFilters.valueSize !== "" && valuesFilters.valueColor !== "" && productDefault.notes !== "" && productDefault.notes !== "<p></p>" &&
            <div className={styles.notesSection}>
              <p className={styles.sectionTitle}>Notas importantes</p>
              <span className={styles.textDescription} dangerouslySetInnerHTML={{__html: productDefault.notes}}></span>
            </div>
          }
          <div className={styles.rentButtonsArea}>
            <Button
              label="Comprar ahora"
              disabled={ valuesFilters.valueSize === "" || valuesFilters.valueColor === "" || valuesFilters.valueItems === "" || valuesFilters.valueItems === 0}
              onClick={() => reservarHandlerClick("VENTA")}
            />
          </div>
        </div>
        { model.type === "dresses" &&
          <>
            <MeasurementGuide />
            <FaqDressCare />
          </>
        }
        <FaqShare />
      </div>
    </>
  );
};

export default DetailsAndFiltersSale;
