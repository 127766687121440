import React from "react";
import styles from "./Modal.module.css";
import { Icon } from "@iconify/react";
import { modal } from '../modalManagment';
import FaqProfileInfo from "../faq/faqProfileInfo/FaqProfileInfo";
import FaqMySize from "../faq/faqMySize/FaqMySize";

const ModalInfoProfile = (props) => {
  return (
    <div className={styles.containerFiltersPadding} style={{maxWidth: 774, maxHeight: '90vh', overflowY: 'scroll'}}>
        <div className={styles.inlineContainer}>
          <span className={styles.titleFilters}>Perfil de clienta</span>
          <Icon icon="eva:close-outline" fontSize={25} style={{paddingLeft: 40, marginRight: 20}} onClick={() => modal.close()} />
        </div>
        <FaqProfileInfo formConfig={props.formConfig} dataUser={props.row} disabled={true} show={true} />
        <FaqMySize formConfig={props.formConfig} dataUser={props.row} disabled={true} show={true} />
    </div>
  );
};

export default ModalInfoProfile;