import React, { useEffect } from "react";
import styles from "./profileScreenDesktop.module.css";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import FooterDesktop from "../footer/Footer";
import FaqActiveReserves from "../faq/faqaAtiveReserves/FaqActiveReserves";
import FaqActiveRents from "../faq/faqActiveRents/FaqActiveRents";
import FaqRentalHistory from "../faq/faqRentalHistory/FaqRentalHistory";
import FaqProfileInfo from "../faq/faqProfileInfo/FaqProfileInfo";
import FaqMySize from "../faq/faqMySize/FaqMySize";
import BannerProfile from '../../assets/landing/profile-section-banner.jpg';
import pencil from "../../assets/icons/pencil.svg";
import flagSave from "../../assets/icons/flagSave.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { postSaveMySize } from "../../store/thunks/profileThunk"
import { setLogout } from "../../store/slices/login/loginSlice";
import ModalConfirmLogOut from "../modal/ModalConfirmLogOut";
import { modal } from '../modalManagment';
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../shared/button";
import SuccessMessage from "../successMessage/SucessMessage";
import { useRef } from "react";
import ModalConfirmActionAvatar from "../modal/ModalConfirmActionAvatar";
import api from "../../api/axios.config";
import { getUserDetails } from "../../store/thunks/authThunk";
import { removeAllProduct } from "../../store/slices/shopCart";
import FaqSaleHistory from "../faq/faqSaleHistory/FaqSalelHistory";
import Card from "../card/Card";
import ModalConfirmDeleteCard from "../modal/ModalConfirmDeleteCard";

const Selected = {
  PEDIDOS: "PEDIDOS",
  CONFIGURACION: "CONFIGURACION",
  TARJETAS: "TARJETAS"
};

const ProfileScreenDesktop = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [currentSelected, setCurrentSelected] = useState(Selected.PEDIDOS);
  const [edit, setEdit] = useState(false);
  const [ok, setOk] = useState(false);
  const refTop = useRef(null)
  const refButton = useRef(null);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if(props.isActiveSetting || (location.state && location.state?.isActiveSetting)){
      setCurrentSelected(Selected.CONFIGURACION)
    }
  },[props.isActiveSetting, location])

  const { user } = useSelector((store) => store.login);

  const formConfig = useForm();
  const onSubmitFormConfig = (data) => {
    const mapData = {
      nombres: data.userName,
      apellidos: data.lastName,
      fecha_nacimiento: data.birthday,
      phone: data.phone,
      correo_electronico: data.email,
      password: data.password,
      calle_num_ext: data.address,
      interior: data.insideNumber,
      estado: data.estado,
      postal_code: data.postalCode,
      city_id: data.city,
      colonia: data.colonia,
      "cintura": data.waist,
      "cadera": data.hip,
      "busto": data.bustSize,
      "altura": data.altura,
      "talla": data.dressSize
    }

    dispatch(postSaveMySize(mapData, user.id));
    dispatch(getUserDetails());
    setEdit(false);
    setOk(true);
    setDisplay(false);
  }

  const modalConfirmLogOut = () => {
    refTop.current.scrollIntoView({ behavior: 'smooth' })
    modal.open(<ModalConfirmLogOut onClick={()=>logOut()} />, () => {}, 'BackdropContainerCenter')
  }

  const logOut = () =>{
    modal.close(() => {
      dispatch(removeAllProduct())
      dispatch(setLogout());
      window.localStorage.clear()
      navigate('/principal')
    })
  }

  const confirmAction = () => {
    modal.open(<ModalConfirmActionAvatar 
      onClickUpload={() => {
        refButton.current.click()
      }} 
    onClickRemove={() => removeAvatar()} />, () => {}, 'BackdropContainerCenter')
  }

  const onUploadFile = (e) => {
    modal.close()
    const formdata = new FormData()
    formdata.append('avatar', e.target.files[0])
    api.post('/api/users/avatar', formdata, { headers: { "Content-Type": "multipart/form-data" } })
    .then(res => {
      if(res.status === 200){
        setOk(true);
        dispatch(getUserDetails());
      }
    })
    e.target.value = null;
  }

  const removeAvatar = () => {
    modal.close()
    api.delete('/api/users/avatar')
    .then(res => {
      if(res.status === 200){
        setOk(true);
        dispatch(getUserDetails());
      }
    })
  }

  const deleteCard = (card) => {
    modal.open(
      <ModalConfirmDeleteCard
        onClick={async () => {
          await api.delete(`/api/users/cards/${card.id}`)
          console.log('Click')
          modal.close()
        }} 
      />, 
      () => {}, 
      'BackdropContainerCenter'
    )
  }

  return (
    <>
      <div ref={refTop}>
        <section className={styles.navMobile}>
          <NavBarDesktop isSelectedProfile={true} />
        </section>
        <section className={styles.infoProfileArea}>
          <img alt="" src={BannerProfile} className={styles.containImgBanner}></img>
          <div className={styles.profileImage} onClick={confirmAction}>
            {user?.avatar ?
              <img src={user?.avatar_image} alt="" style={{height: '100%', width: '100%', objectFit: 'cover', borderRadius: 50}} />
            :
              <p style={{color: '#FFF', fontSize: '28px'}}>{ `${ user?.name?.length > 0 ? user?.name?.split(' ')?.map((n) => n.charAt(0))[0] : '' }${ user?.lastname?.length > 0 ? user?.lastname?.split(' ').map((n) => n.charAt(0))[0] : ''}` }</p>
            }
            <input ref={refButton} type="file" style={{display: 'none'}} accept="jpg, png, jpeg" onChange={onUploadFile} />
          </div>
          <span
            className={styles.userNameArea}
          >{user.name ? `${user.name} ${user.lastname}` : ''}</span>
          <span className={styles.userLocationArea}>
            {user?.address ? user.address[0]?.city?.name : "Ubicación desconocida"}
          </span>
          <div>
            <Button label="CERRAR SESIÓN" type="button" outline style={{ borderColor: '#DF585F', paddingLeft: 24, paddingRight: 24 }} styleText={{color: '#DF585F'}} onClick={modalConfirmLogOut} />
          </div>
        </section>
        <section className={styles.profileButtonsArea}>
          <div>
            <button
              className={styles.ordersButton}
              onClick={() => setCurrentSelected(Selected.PEDIDOS)}
            >
              PEDIDOS
            </button>
            <div
              className={
                currentSelected === Selected.PEDIDOS ? styles.selected : ""
              }
            ></div>
          </div>
          <div>
            <button
              className={styles.ordersButton}
              onClick={() => setCurrentSelected(Selected.CONFIGURACION)}
            >
              CONFIGURACION
            </button>
            <div
              className={
                currentSelected === Selected.CONFIGURACION
                  ? styles.selected
                  : ""
              }
            ></div>
          </div>
          <div>
            <button
              className={styles.ordersButton}
              onClick={() => setCurrentSelected(Selected.TARJETAS)}
            >
              TARJETAS
            </button>
            <div
              className={
                currentSelected === Selected.TARJETAS
                  ? styles.selected
                  : ""
              }
            ></div>
          </div>
        </section>
        {currentSelected === Selected.PEDIDOS ? (
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: 200}}>
            <section style={{width: 664}}>
              <FaqActiveReserves {...props} />
              <FaqActiveRents {...props} />
              <FaqRentalHistory {...props} />
              <FaqSaleHistory {...props} />
            </section>
          </div>
        ) : currentSelected === Selected.CONFIGURACION ? (
          <>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: 200}}>
              <section style={{width: 664}}>
              <form onSubmit={formConfig.handleSubmit(onSubmitFormConfig)} className={styles.areaEditButton}>
                <div className={styles.profile}>
                    {!edit &&
                      <section style={{marginTop: 40, marginBottom: 20}}>
                        <Button outline stylestext={styles.buttonPaddingLeft} type="button" onClick={() => {
                          setEdit(true)
                          setDisplay(true);
                        }} label="EDITAR PERFIL">
                          <img src={pencil} alt="" />
                        </Button>
                      </section>
                    }
                    {edit &&
                      <section style={{marginTop: 40, marginBottom: 20}}>
                        <Button stylestext={styles.buttonPaddingLeft} type="submit" label="GUARDAR CAMBIOS">
                          <img src={flagSave} alt="" />
                        </Button>
                      </section>
                    }
                    <section style={{ marginTop: 20, display: ok ? 'block' : 'none' }}>
                      <SuccessMessage display={ok} label="Tu perfil se actualizó correctamente"/>
                    </section>
                    
                    <section>
                      <FaqProfileInfo formConfig={formConfig} display={display} dataUser={user} disabled={!edit} />
                      <FaqMySize formConfig={formConfig} display={display} dataUser={user} disabled={!edit} />
                    </section>
                  </div>
                </form>
              </section>
            </div>
          </>
        ) : (
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: 200}}>
            <section style={{ display: 'flex', flexDirection: 'column', marginTop: '36px', width: 664, alignItems: 'center', gap: '12px' }}>
              { props.details?.cards?.map((card) => <Card cardObject={card} onClick={() => deleteCard(card)}/>) }
            </section>
          </div>
        )}
      </div>
      <FooterDesktop />
    </>
  );
};

export default ProfileScreenDesktop;
