import React from 'react';
import styles from './hashtag.module.css';
import Star from '../../../assets/icons/star.png';
import Discount from '../../../assets/icons/tag.png';
import Trophy from '../../../assets/icons/trophy.png';

//discount new bestseller
const HashtagDetails = (props) => {
    return (
        <div className={styles.containerHashtagsDetails}>
            {props.new &&
                <div className={styles.containTextsDetails}>
                    <img src={Star} alt="icon star" />
                    <p>NUEVO</p>
                </div>
            }
            {props.discount > 0 &&
                <div className={styles.containTextsDetails}>
                    <img src={Discount} alt="icon discount" />
                    <p>DESCUENTO</p>
                </div>
            }
            {(props.bestseller && (!props.new || !props.discount)) &&
                <div className={styles.containTextsDetails}>
                    <img src={Trophy} alt="icon bestseller" />
                    <p>BESTSELLER</p>
                </div>
            }
        </div>
    )
}

export default HashtagDetails;