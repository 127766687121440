import React, { useState } from "react";
import styles from "./colorPicker.module.css";

const ColorPicker = (props) => {
  const [isPushed, setIsPushed] = useState(props.isPressed);
  const clearWhenPushedAndCallBack = () => {
    setIsPushed(!isPushed);
    props.colorPickerCallBack();
  }

  return (
    <>
      <div className={styles.wrapperPressColorPicker }>
        <button onClick={()=>clearWhenPushedAndCallBack()} style={{"backgroundColor":props.colorCode || "#FFF"}} className={isPushed ? styles.pressColorPicker : styles.colorPicker}></button>
      </div>
    </>
  );
};


export default ColorPicker;