import React, { useEffect, useState } from "react"
import styles from "./Modal.module.css"
import { Icon } from "@iconify/react"
import { modal } from "../modalManagment"
import Button from "../shared/button"
import Input, { LabelInput } from "../shared/input/Input"
import { useDispatch, useSelector } from "react-redux"
import { getClients } from "../../store/thunks/clientsThunk"
import { AllSizes } from "../../constants"
import api from "../../api/axios.config"
import { Editor, EditorState, convertToRaw} from 'draft-js'
import { convertToHTML } from 'draft-convert'
import { getMonths } from "../../utils"
import 'draft-js/dist/Draft.css'
import "react-calendar/dist/Calendar.css"
import { Calendar } from "react-calendar"
import moment from "moment"
import ErrorMessage from "../errorMessage/ErrorMessage"

const ModalNewOrder = ({ dress, formConfig, createOrder, error, setError, errorMessage }) => {
    const dispatch = useDispatch()
    const { loading } = useSelector((store) => store.navigation)
    const [states, setStates] = useState([])
    const [state, setState] = useState('')
    const [cities, setCities] = useState([])
    const [city, setCity] = useState('')
    const [num, setNum] = useState(null)
    const [interior, setInterior] = useState(null)
    const [colonia, setColonia] = useState(null)
    const [postalCode, setPostalCode] = useState(null)
    const [editor, setEditor] = useState(EditorState.createEmpty())
    const [dateChange, setDateChange] = useState(new Date())
    const [date, setDate] = useState(new Date())
    const { data: clients } = useSelector((state) => state.clients)
    const [disabledDates, setDisabledDates] = useState([])
    const [days, setDays] = useState(3)

    const onChangeValues = ({ target: {name, value}}) => {
        formConfig.setValue(name, value)
    }

    const watchLogistic = formConfig.watch('send_method')
    const calendarDays = formConfig.watch('days')

    const onChangeEditor = (e) => {
        setEditor(e)
    }

    const newRange = () => {
        if (date[1]) {
            const initialDate = date[0];
            const finalDate = addDays(new Date(initialDate), days);
            if(disabledDates.find(d => (moment(initialDate).isSame(moment(d)) || moment(finalDate).isSame(moment(d))))){
                setDate(initialDate)
                formConfig.setValue('start_date', initialDate)
            }
            setDate([initialDate, finalDate])
        }
    }
    
    useEffect(() => {
        newRange()
    },[days])

    const addDays = (theDate, days) => {
        return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000)
    }

    const toCalendarType = (weekStartDay) => weekStartDay === 0 ? "US" : "ISO 8601"; //set from Sunday to Saturday

    const formatMonthY = (locale, value) => {
        let resultMonth = getMonths(locale, value)
        return `${resultMonth} ${value.getFullYear()}`
    }

    const selectDate = (e) => {
        const initialDate = new Date(e);
        const finalDate = new Date(addDays(e, days))
    
        if (disabledDates.find(d => (moment(initialDate).isSame(moment(d)) || moment(finalDate).isSame(moment(d))))) {
          setDate(initialDate)
          return
        }
        setDate([initialDate, finalDate])
    }

    const getStates = async () => {
        await api.get('/api/states')
        .then(resp => {
            if(resp.status === 200){
                setStates(resp.data.data);
            }
        })
        .catch(error => console.log(error))
    }
    
    const getCities = async () => {
        await api.get(`/api/cities/${state}`)
        .then(resp => {
            if(resp.status === 200){
                setCities(resp.data.data);
            }
        })
        .catch(error => console.log(error))
    }

    const onGetAbilityDressDate = async () => {
        await api.post(`/api/dress-availability`, { year: +moment(dateChange ? dateChange : date).format('YYYY'), month: +moment(dateChange ? dateChange : date).format('MM'), dress_id: dress.id, days: calendarDays ? calendarDays : 4 })
        .then(resp => {
          if(resp.status === 200){
            setDisabledDates(resp.data.disable_dates)
          }
        })
        .catch(e => {
          console.log(e)
        })
    } 

    const disabledTitle = (date) => {
        return disabledDates.find(d => moment(date).isSame(moment(d)) ? true : false)
    }

    const fillAddress = (user_id) => {
        console.debug('User ID:', user_id)
        const user = clients.find((client) => client.id === parseInt(user_id))
        if (user) {
            const address = user.address.find((add) => add.default === 1) ?? null
            if (address) {
                formConfig.setValue('address_num_ext', address.address_num_ext)
                setNum(address.address_num_ext)
                if (address.num_int) {
                    formConfig.setValue('num_int', address.num_int)
                    setInterior(address.num_int)
                }
                formConfig.setValue('colonia', address.colonia)
                setColonia(address.colonia)
                formConfig.setValue('postal_code', address.postal_code)
                setPostalCode(address.postal_code)
                formConfig.setValue('city_id', address.city_id)
                setCity(address.city_id)
                setState(address.city.state_id)
            }
        }
    }

    useEffect(() => {
        if (date.length > 0) {
            formConfig.setValue('start_date', moment(date[0]).format('YYYY-MM-DD'))
        }
    }, [date])

    useEffect(() => {
        onGetAbilityDressDate()
      },[dateChange])

    useEffect(() => {
        setDays(calendarDays)
    }, [calendarDays])

    useEffect(() => {
        if (state !== '') {
            getCities(state)
        }
    }, [state])

    useEffect(() => {
        if (editor) {
            formConfig.setValue('notes', convertToHTML(editor.getCurrentContent()))
        }
    }, [editor])

    useEffect(() => {
        formConfig.setValue('dress_id', dress.id)
        getStates()
        dispatch(getClients())
        onGetAbilityDressDate()
    }, [])

    return (
        <div
            className={styles.containerFiltersPadding}
            style={{ maxWidth: 500, maxHeight: "80vh", marginTop: "40px" }}
        >
            <div className={styles.inlineContainer}>
                <span className={styles.titleFilters}>Nuevo pedido de { dress.dress_models?.name }</span>
                <Icon
                    icon="eva:close-outline"
                    fontSize={25}
                    style={{ paddingLeft: 40, marginRight: 20, cursor: "pointer" }}
                    onClick={() => {
                        document.getElementById('root').style.overflowY = ''
                        modal.close()
                    }}
                />
            </div>
            <form
                className="form-order" 
                onSubmit={formConfig.handleSubmit(createOrder)}
            >
                <div className={styles.modalContainter}>
                    <section className={styles.inputSections}>
                        <LabelInput label="Clienta*"></LabelInput>
                        <select
                            name="user_id"
                            id="user_id"
                            className={styles.itemInputSelect}
                            {...formConfig.register("user_id")}
                            onChange={(e) => {
                                formConfig.setValue("user_id", e.target.value)
                                if (formConfig.getValues("send_method") === 'delivery') fillAddress(formConfig.getValues("user_id"))
                            }}
                            required
                        >
                            <option value="">Clienta</option>
                            { clients?.map((client) => (
                                <option
                                    key={client.id}
                                    value={client.id}
                                >
                                    {client.fullname} - {client.email}
                                </option>
                            ))}
                        </select>
                    </section>

                    <section className={styles.rowContainer}>
                        <section className={styles.inputSections}>
                            <LabelInput label="Días de renta*"></LabelInput>
                            <select
                                name="days"
                                id="days"
                                className={styles.itemInputSelect}
                                {...formConfig.register("days")}
                                onChange={(e) => {
                                    formConfig.setValue("days", e.target.value);
                                }}
                                required
                            >
                                <option value="3" selected>4</option>
                                <option value="7">8</option>
                            </select>
                        </section>
                        <section className={styles.inputSections}>
                            <LabelInput label="Logística de pedido*"></LabelInput>
                            <select
                                name="send_method"
                                id="send_method"
                                className={styles.itemInputSelect}
                                {...formConfig.register("send_method")}
                                onChange={(e) => {
                                    formConfig.setValue("send_method", e.target.value)
                                    if (e.target.value === 'delivery') fillAddress(formConfig.getValues("user_id"))
                                }}
                                required
                            >
                                <option value="pick_up" selected>Recoger en tienda</option>
                                <option value="delivery">Envío a domicilio</option>
                            </select>
                        </section>
                    </section>

                    <section className={styles.rowContainer}>
                        <section className={styles.inputSections}>
                            <LabelInput label="Fecha de inicio*"></LabelInput>
                            <section className={styles.calendarSection}>
                                <Calendar 
                                    onClickDay={selectDate}
                                    value={date}
                                    calendarType={toCalendarType(0)}
                                    minDate={new Date()}
                                    maxDetail="month"
                                    nextLabel=""
                                    prevLabel=""
                                    formatMonthYear={formatMonthY}
                                    onActiveStartDateChange={({activeStartDate}) => setDateChange(activeStartDate)}
                                    formatShortWeekday={(locale, value) =>
                                    ["D", "L", "M", "M", "J", "V", "S"][value.getDay()]
                                    }
                                    tileDisabled={({ activeStartDate, date, view }) => (date < (new Date()).getDate() - 1) || date.getDay() === 0 || disabledTitle(date) || moment(date).isAfter(moment().add(90, 'days'))}
                                />
                            </section>
                        </section>
                    </section>
                    
                    { watchLogistic === 'delivery' && 
                        <>
                            <section className={styles.rowContainer}>
                                <section className={styles.inputSections}>
                                        <LabelInput label="Calle y número*"></LabelInput>
                                        <Input
                                            placeholder="Calle y número"
                                            styles={styles.itemInput}
                                            type="text"
                                            name="address_num_ext"
                                            {...formConfig.register("address_num_ext")}
                                            onChange={onChangeValues}
                                            value={num}
                                            required
                                        ></Input>
                                </section>
                                <section className={styles.inputSections}>
                                        <LabelInput label="Número interior (Opcional)"></LabelInput>
                                        <Input
                                            placeholder="Número interior"
                                            styles={styles.itemInput}
                                            type="text"
                                            name="num_int"
                                            {...formConfig.register("num_int")}
                                            onChange={onChangeValues}
                                            value={interior}
                                        ></Input>
                                </section>
                            </section>
                            <section className={styles.rowContainer}>
                                <section className={styles.inputSections}>
                                        <LabelInput label="Colonia"></LabelInput>
                                        <Input
                                            placeholder="Colonia"
                                            styles={styles.itemInput}
                                            type="text"
                                            name="colonia"
                                            {...formConfig.register("colonia")}
                                            onChange={onChangeValues}
                                            value={colonia}
                                        ></Input>
                                </section>
                                <section className={styles.inputSections}>
                                        <LabelInput label="Código Postal"></LabelInput>
                                        <Input
                                            placeholder="Código Postal"
                                            styles={styles.itemInput}
                                            type="number"
                                            name="postal_code"
                                            {...formConfig.register("postal_code")}
                                            onChange={onChangeValues}
                                            value={postalCode}
                                        ></Input>
                                </section>
                            </section>
                            <section className={styles.rowContainer}>
                                <section className={styles.inputSections}>
                                        <LabelInput label="Estado*"></LabelInput>
                                        <select
                                            id="state"
                                            className={styles.itemInputSelect}
                                            onChange={(e) => {
                                                setState(e.target.value)
                                            }}
                                            value={state}
                                        >
                                            <option value="">Estado</option>
                                            { states?.map((state) => (
                                                <option
                                                    key={state.id}
                                                    value={state.id}
                                                >
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>
                                </section>
                                <section className={styles.inputSections}>
                                        <LabelInput label="Ciudad*"></LabelInput>
                                        <select
                                            name="city_id"
                                            id="city_id"
                                            disabled={cities.length === 0}
                                            className={styles.itemInputSelect}
                                            {...formConfig.register("city_id")}
                                            onChange={(e) => {
                                                formConfig.setValue("city_id", e.target.value)
                                            }}
                                            value={city}
                                            required
                                        >
                                            <option value="">Ciudad</option>
                                            { cities?.map((city) => (
                                                <option
                                                    key={city.id}
                                                    value={city.id}
                                                >
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                                </section>
                            </section>
                            <section className={styles.columnContainer}>
                                <LabelInput label="Tipo de envío*" />
                                <section className={styles.rowContainerLeft}>
                                        <section className={styles.rowContainerLeft}>
                                            <input
                                                styles={styles.itemInput}
                                                type="radio"
                                                name="send_type"
                                                value="normal"
                                                {...formConfig.register("send_type")}
                                                onChange={onChangeValues}
                                                required
                                            /> Normal
                                        </section>
                                        <section className={styles.rowContainerLeft}>
                                            <input
                                                styles={styles.itemInput}
                                                type="radio"
                                                name="send_type"
                                                value="express"
                                                {...formConfig.register("send_type")}
                                                onChange={onChangeValues}
                                                required
                                            /> Express
                                        </section>
                                </section>
                            </section>
                        </>
                    }
                    <section className={styles.rowContainer}>
                        <section className={styles.inputSections}>
                            <LabelInput label="Vendedora"></LabelInput>
                            <Input
                                placeholder="Vendedora"
                                styles={styles.itemInput}
                                type="text"
                                name="seller"
                                {...formConfig.register("seller")}
                                onChange={onChangeValues}
                            ></Input>
                        </section>
                        <section className={styles.inputSections}>
                            <LabelInput label="Opción B"></LabelInput>
                            <select
                                name="second_dress"
                                id="second_dress"
                                className={styles.itemInputSelect}
                                {...formConfig.register("second_dress")}
                                onChange={(e) => {
                                    formConfig.setValue("second_dress", e.target.value)
                                }}
                            >
                                { AllSizes?.map((size, index) => (
                                    <option
                                        key={index}
                                        value={size}
                                    >
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </section>
                    </section>
                    <section className={styles.inputSections}>
                        <LabelInput label="Notas"></LabelInput>
                        <Editor editorState={editor} onChange={onChangeEditor} required />
                    </section>
                </div>
                <div className={styles.spaceBetween} style={{ marginTop: '24px'}}>
                    <ErrorMessage display={error} setError={setError} textDescription={errorMessage} />
                    <Button label="Atrás" outline onClick={() => {
                        document.getElementById('root').style.overflowY = ''
                        modal.close()
                        formConfig.reset({
                            user_id: null,
                            days: 3,
                            seller: '',
                            send_method: 'pick_up',
                            start_date: null,
                            second_dress: null,
                            address_num_ext: null,
                            colonia: null,
                            postal_code: null,
                            city_id: null
                        })
                    }}/>
                    <Button label="Crear pedido" type="submit" disabled={loading}>
                        { loading && <div className={styles.loaderSpinner}></div> }
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ModalNewOrder
