import React from "react";
import styles from "./forgotLanding.module.css";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import circleSorellaMobile from "../../assets/svg/group-dark.svg";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import FooterMobile from "../footerMobile/FooterMobile";
import ForgotMobile from "../forgotMobile/ForgotMobile";
import Footer from '../footer/Footer';
import { useParams } from "react-router-dom";
import ForgotPasswordMobile from "../forgotPasswordMobile/ForgotPasswordMobile";
import BannerDesktop from "../../assets/landing/login-banner.jpg";

const ForgotLanding = () => {
  const { id } = useParams()
  const { width } = useWindowDimensions();

  return (
    <>
    {width <= 1024 ?
      <div className={styles.containerResultsMobile}>
        <section className={styles.navMobile}>
          <NavBarMobile />
        </section>

        <section>
          <div className={styles.containerMobileImgMain}>
            <img
              src={BannerDesktop}
              style={{ width: '100%' }}
              alt=""
            ></img>
            {!id ? <ForgotMobile /> : <ForgotPasswordMobile id={id} />}
          </div>
          <div className={styles.containerImgCircleMobile}>
            <img
              src={circleSorellaMobile}
              alt="circleSorella"
              className={styles.imgCircleMobile}
            ></img>
          </div>
        </section>
        <FooterMobile />
      </div>
    :
    <>
      <div className={styles.container}>
        <section className={styles.nav}>
          <NavBarDesktop />
        </section>

        <section className={styles.leftArea}>
          <div className={styles.containerLeftImg}>
            <img
              src={BannerDesktop}
              style={{width: 756, height: 'calc(100vh - 73px)', objectFit: 'cover' }}
              alt=""
            ></img>
          </div>
        </section>

        <section className={styles.rightArea}>
        {!id ? <ForgotMobile /> : <ForgotPasswordMobile id={id} />}
        </section>
      </div>
      <Footer />
    </>
    }
    </>
  );
};

export default ForgotLanding;
