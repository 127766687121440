import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import CustomCheck from "../../shared/customCheck/CustomCheck";
import { AllNeckLineOptions } from "../../../constants";
import { createKey, setParamsToUrl } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  onSetFilterValues,
  removeNeckLine,
  setMultiNeckLine,
} from "../../../store/slices/filters/filtersSlice";

const FaqNeckLine = (props) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const dispatch = useDispatch();
  const { neckLineValue } = useSelector((store) => store.filters);

  const toggleSelectFilter = (neckLine) => {
    const alreadyExist = neckLineValue.find((lar) => lar === neckLine);
    if (alreadyExist) {
      dispatch(removeNeckLine(neckLine));
    } else {
      dispatch(setMultiNeckLine(neckLine));
    }
  };

  // const onChangeFilter = (id) => {
  //   setParamsToUrl(props.navigate, window.location.href, {name: 'escote_ids', params: id})
  // }

  const handlerChecked = (id) => {
    let valueNecklines = []
    if(props.valueNecklines.includes(id)){
      valueNecklines = [...props.valueNecklines.filter(v => v !== id)]
    }else{
      valueNecklines = [...props.valueNecklines, id]
    }
    dispatch(onSetFilterValues({ valueNecklines }))
  }

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>TIPO DE ESCOTE</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <section className={styles.checkContainer}>
          {props.necklines ? 
            props.necklines.map((neckLine, index) => (
              <div key={createKey()} className={styles.checkboxInline}>
                <CustomCheck
                  content={neckLine.description}
                  onChangeCallBack={() => handlerChecked(neckLine.id)}
                  isChecked={
                    props.valueNecklines && props.valueNecklines.find((nl) => nl === neckLine.id)
                      ? true
                      : false
                  }
                />
              </div>
            ))
          : 
          AllNeckLineOptions.map((neckLine, index) => (
            <div key={createKey()} className={styles.checkboxInline}>
              <CustomCheck
                content={neckLine}
                onChangeCallBack={() => toggleSelectFilter(neckLine)}
                isChecked={
                  neckLineValue && neckLineValue.find((nl) => nl === neckLine)
                    ? true
                    : false
                }
              />
            </div>
          ))}
        </section>
      </div>
    </>
  );
};

export default FaqNeckLine;
