import React from "react";
import styles from "./Modal.module.css";
import { Icon } from "@iconify/react";
import { modal } from '../modalManagment';
import Button from "../shared/button";

const ModalDeleteOrder = (props) => {
  return (
    <div className={styles.containerFiltersPadding} style={{maxWidth: 500, maxHeight: '80vh', marginTop: '100px'}}>
        <div className={styles.inlineContainer}>
          <span className={styles.titleFilters}>Cancelar pedido</span>
          <Icon icon="eva:close-outline" fontSize={25} style={{paddingLeft: 40, marginRight: 20, cursor: 'pointer'}} onClick={() => modal.close()} />
        </div>
        <div className={styles.textContainer}>
            <p>¿Estás seguro de cancelar este pedido? Esta acción no es reversible</p>
            <div className={styles.spaceBetween}>
                <Button label="Atrás" outline onClick={() => modal.close()}/>
                <Button label="Cancelar pedido" onClick={() => props.delete()}/>
            </div>
        </div>
    </div>
  );
};

export default ModalDeleteOrder;