import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import stylesLocal from "./faqLengths.module.css";
import { Icon } from "@iconify/react";
import CustomCheck from "../../shared/customCheck/CustomCheck";
import { createKey } from "../../../utils";
import { onSetFilterValues } from "../../../store/slices/filters/filtersSlice";
import { useDispatch } from "react-redux";

const FaqLenghts = (props) => {
  const dispatch = useDispatch()
  const [isDisplay, setIsDisplay] = useState(props.display ? props.display : false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  // const onChangeFilter = (id) => {
  //   setParamsToUrl(props.navigate, window.location.href, {name: 'largo_ids', params: id})
  // }

  const handlerChecked = (id) => {
    let valueLength = []
    if(props.valueLength.includes(id)){
      valueLength = [...props.valueLength.filter(v => v !== id)]
    }else{
      valueLength = [...props.valueLength, id]
    }
    dispatch(onSetFilterValues({ valueLength }))
  }

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>LARGO</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <section className={stylesLocal.buttonSizeContainer}>
          {props.lengths &&
            props.lengths.map(row => {
              if(row.description.toUpperCase().includes('CORTO')
              || row.description.toUpperCase().includes('MIDI')
              || row.description.toUpperCase().includes('LARGO')
              )
               return <div key={createKey()} className={styles.checkboxInline}>
                  <CustomCheck
                    content={row.description}
                    onChangeCallBack={() => handlerChecked(row.id)}
                    isChecked={
                      props.valueLength && props.valueLength.find((nl) => nl === row.id)
                        ? true
                        : false
                    }
                  />
                </div>
              else return null
            })
          }
        </section>
      </div>
    </>
  );
};

export default FaqLenghts;
