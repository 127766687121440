import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import stylesLocal from "./faqTypeEvent.module.css";
import { Icon } from "@iconify/react";
import { AllEventTypeOptions } from "../../../constants";
import CustomCheck from "../../shared/customCheck/CustomCheck";
import { createKey } from "../../../utils";
import { useDispatch } from "react-redux";
import { onSetFilterValues } from "../../../store/slices/filters/filtersSlice";

const FaqTypeEvent = (props) => {
  const dispath = useDispatch()
  const [isDisplay, setIsDisplay] = useState(props.display ?? false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const handlerChecked = (id) => {
    let typeEvent = []
    if(props.valueTypeEvent.includes(id)){
      typeEvent = [...props.valueTypeEvent.filter(v => v !== id)]
    }else{
      typeEvent = [...props.valueTypeEvent, id]
    }
    dispath(onSetFilterValues({ valueTypeEvent: typeEvent }))
  }

  // const onChangeFilter = (id) => {
  //   setParamsToUrl(props.navigate, window.location.href, {name: 'type_event_ids', params: id})
  // }

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>TIPO DE EVENTO</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <section className={stylesLocal.buttonSizeContainer}>
          {props.typeEvents ?
            props.typeEvents.map(row => (
              <div key={createKey()} className={styles.checkboxInline}>
                <CustomCheck
                  content={row.description}
                  onChangeCallBack={() => handlerChecked(row.id)}
                  isChecked={
                    props.valueTypeEvent && props.valueTypeEvent.find((nl) => nl === row.id)
                      ? true
                      : false
                  }
                />
              </div>
            ))
          :
            AllEventTypeOptions.map(row => (
              <div key={createKey()} className={styles.checkboxInline}>
                <CustomCheck
                  content={row.description}
                  onChangeCallBack={() => {}}
                  isChecked={false
                    // neckLineValue && neckLineValue.find((nl) => nl === row.id)
                    //   ? true
                    //   : false
                  }
                />
              </div>
            ))
          }
        </section>
      </div>
    </>
  );
};

export default FaqTypeEvent;
