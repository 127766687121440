import React from "react";
import styles from "./footerMobile.module.css";
import instagramLogo from "../../assets/icons/instagramLogo.svg";
import facebookLogo from "../../assets/icons/facebookLogo.svg";
import sorellaFooterMobile from "../../assets/icons/FooterSorella.svg";
import { useNavigate } from "react-router-dom";
import { scrollToGoSection } from "../../utils";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <section className={styles.container}>
        <section className={styles.socialNetworksContainer}>
          <section>
            <div className={styles.titleSocialNetworks}>NUESTRAS REDES</div>
            <div className={styles.titleSocialNetworks}>
              <a href="https://www.facebook.com/etudsorella" className={styles.socialNetworksButton}>
                <img alt="" src={facebookLogo}></img>
              </a>
              <a href="https://instagram.com/eesorella?igshid=YmMyMTA2M2Y=" className={styles.socialNetworksButton}>
                <img alt="" src={instagramLogo}></img>
              </a>
            </div>
          </section>
        </section>
      <section className={styles.containerGrid}>
        
        <section className={styles.process}>
          <div className={styles.titleFooter}>PROCESO</div>
          <span onClick={() => scrollToGoSection("form-rent")} className={styles.footerContent} >Encuentra vestido</span>
          <span className={styles.footerContent} onClick={() => navigate("/ayuda-medidas")}>Guía de tallas</span>
          <span className={styles.footerContent} onClick={() => navigate("/faq/ajustes")}>Ajustes al vestido</span>
          <span className={styles.footerContent} onClick={() => navigate("/faq/pagos")}>Pagos</span>
          <span className={styles.footerContent} onClick={() => navigate("/faq/regresar_vestido")}>Regresar el vestido</span>
        </section>
        
        <section className={styles.help}>
          <div className={styles.titleFooter}>AYUDA</div>
          <span className={styles.footerContent} onClick={() => navigate("/contacto")}>Contacto y sucursales</span>
          <span className={styles.footerContent} onClick={() => navigate("/faq")}>FAQ</span>
          {/* <span className={styles.footerContent} onClick={() => navigate("/faq/recolección")}>Recolección</span> */}
          <span className={styles.footerContent} onClick={() => navigate("/terminos-condiciones")}>Términos y Condiciones</span>
          <span className={styles.footerContent} onClick={() => navigate("/sobre-nosotros")}>Sobre nosotros</span>
        </section>

      </section>
      <div className={styles.containerSorella} style={{cursor: 'pointer'}} onClick={() => navigate('/principal')}>
        <img alt="sorella" src={sorellaFooterMobile}></img>
      </div>
    </section>
  );
};

export default Footer;