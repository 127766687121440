import React, { useState } from "react";
import localStyles from "./faqDressCare.module.css";
import ToggleDisplay from "../../shared/toggleDisplay/ToggleDisplay";

const FaqDressCare = () => {
 
  return (
    <ToggleDisplay label="CUIDADOS">
      <div className={localStyles.careDescriptionArea}>
          <span className={localStyles.careSpan}>Nosotras nos encargamos de la limpieza, pero no olvides ser cuidadosa con el vestido. Evita jalar la tela y las manchas de grasa. </span>
      </div>
    </ToggleDisplay>
  );
};

export default FaqDressCare;