import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NewestDressesDesktop from "../newestDressesDesktop/NewestDressesDesktop";
import NewestDressesMobile from "../newestDressesMobile/NewestDressesMobile";

const NewestDresses = () => {
  const { height, width } = useWindowDimensions();

  return (
    <>
      {width <= 1024 ? <NewestDressesMobile/> : <NewestDressesDesktop/>}
    </>
  );
};

export default NewestDresses;