import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styles from './DetailsSale.module.css';
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from '../navBarMobile/NavBarMobile';
import Footer from "../footer/Footer";
import FooterMobile from '../footerMobile/FooterMobile';
import { TextDescription, Title, TextLinking } from "../shared/text/Text";
import Breadcrumb from "../navigation/breadcrumb/Breadcrumb";
import CardProduct from "../reservationPayment/Product";
import moment from 'moment';
import SuccessMessage from '../successMessage/SucessMessage';
import Button from '../shared/button';
import axios from '../../api/axios.config';
import { useState } from 'react';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import { modal } from '../modalManagment';
import ModalConfirmationAppointment from '../modal/ModalConfirmationAppointment';
import ModalAddressComplete from '../modal/ModalAddressComplete';
import ModalWaiting from '../modal/ModalWaiting';
import DetailsSaleProduct from './DetailsSaleProduct';

const DetailsSale = () => {
    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    const [products, setProducts] = useState([])
    const { id } = useParams()
    const [isDisplay, setIsDisplay] = useState(true)
    const toggleDisplay = () => {
      setIsDisplay(!isDisplay)
    };
    const [orderCode, setOrderCode] = useState('')

    const getSale = async () => {
      await axios.get(`/api/sales/${id}`)
      .then(resp => {
        if(resp.status === 200) {
          console.log('Data:', resp.data)
          const data = resp.data
          const productsAux = []
          data.products.forEach((product) => {
            if (product.sale_dress_id) productsAux.push({ ...product.sale_dress, type: 'dresses', quantity: product.quantity })
            else if (product.shoe_id) productsAux.push({ ...product.shoes, type: 'shoes', quantity: product.quantity })
            else if (product.complement_id) productsAux.push({ ...product.complement, type: 'complements', quantity: product.quantity })
          })
          setProducts(productsAux)
          setOrderCode(resp.data.code)
          // setDressModel(resp.data.)
          // setDressDefault(resp.data.data.dress_id)
          // setOrderCode(resp.data.data.code)
        }
      })
      .catch(err => console.log(err))
    }

    useEffect(() => {
      getSale()
    }, [])

    return (
        <>
          <div>
            <section>
            {width <= 1024 ? <NavBarMobile isSelectedProfile/> : <NavBarDesktop /> }
            </section>
            <section className={styles.container}>
              <Title>DETALLES DE VENTA</Title>
              <Breadcrumb items={[
                {name: 'Perfil', action: () => navigate('/perfil') }, 
                {name: 'Venta', action: () => navigate('/perfil') }, 
                {name: `Orden ${ id.padStart(5, '0')}`, action: () => {}}
              ]} />
            </section>
            <div className={styles.containerContent}>
              { products && products.map((product) => (
                <DetailsSaleProduct product={product}/>
              ))}
            </div>
            <section className={styles.containerCode}>
              <TextDescription>Código para entrega: <b>{orderCode}</b></TextDescription>
            </section>
          </div>
          {width <= 1024 ? <FooterMobile /> : <Footer /> }
        </>
      );
}

export default DetailsSale;