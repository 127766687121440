import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import stylesLocal from "./faqSize.module.css";
import { Icon } from "@iconify/react";
import { AllSizes } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { setAllColorsAvailables, onSetFilterValues, setSizeValue } from "../../../store/slices/filters/filtersSlice";
import { createKey, getUniqueValues } from "../../../utils";

const FaqSize = (props) => {
  const dispatch = useDispatch();
  const { sizeValue, allSizesAvailables, valuesFilter: { valueSizes } } = useSelector((store) => store.filters);
  const { dressModelDetail } = useSelector(store=>store.dressDetails);

  const [isDisplay, setIsDisplay] = useState(!!props.display);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const selectSize = (size) => {
    const filterBySize = dressModelDetail.dress.filter(d=>d.size.description.toLowerCase() === size.toLowerCase());

    if(filterBySize.length > 0 && size){
      if(!valueSizes.includes(filterBySize[0].size.id))
        dispatch(onSetFilterValues({ 'valueSizes': [filterBySize[0].size.id] }))
      else
        dispatch(onSetFilterValues({ 'valueSizes': valueSizes.filter(v => v !== filterBySize[0].size.id) }))
    }

    if(props.showOnlyAvailables){
      const filterBySize = dressModelDetail.dress.filter(d=>d.size.description.toLowerCase() === size.toLowerCase());
      const allColorCodes = getUniqueValues(filterBySize.map(d=>d.color.code));
      dispatch(setAllColorsAvailables(allColorCodes))
    }

    dispatch(setSizeValue(size))
  };

  const checkIfDisabled=(size)=>{
    if(allSizesAvailables?.length > 0){
      return allSizesAvailables?.find((siz)=> siz?.toLowerCase() === size?.toLowerCase()) ? false : true;
    }
    else{
      return dressModelDetail?.dress?.find((dr)=> dr.size?.description.toLowerCase() === size?.toLowerCase()) ? false : true;
    }
  }

  const handlerChecked = (id) => {
    let valueSizes = []
    if(props.valueSizes?.includes(id)){
      valueSizes = [...props.valueSizes?.filter(v => v !== id)]
    }else{
      valueSizes = [...props.valueSizes, id]
    }
    dispatch(onSetFilterValues({ valueSizes }))
  }

  // const onChangeFilter = (id) => {
  //   setParamsToUrl(props.navigate, window.location.href, {name: 'talla_ids', params: id})
  // }

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>TALLA</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        {props.sizes ?
          <section className={stylesLocal.buttonSizeContainer}>
            {
              props.sizes.map((size) => (
                <button
                  key={createKey()}
                  onClick={() => handlerChecked(size.id)}
                  className={`${stylesLocal.buttonSize} ${
                    props.valueSizes.includes(size.id) ? stylesLocal.selected : ""
                  }`
                  }
                >
                  {size.description}
                </button>
              ))
            }
          </section>
        :
        <section className={`${stylesLocal.buttonSizeContainer}  ${props.containerStyle ? props.containerStyle : ''}`}>
          {props.showOnlyAvailables
            ? AllSizes.map((size) => {

              if(!checkIfDisabled(size))
                return (
                  <button
                    key={createKey()}
                    onClick={() => selectSize(size)}
                    className={`${stylesLocal.buttonSize} ${
                      sizeValue === size ? stylesLocal.selected : ""
                    }`
                    }
                    disabled={checkIfDisabled(size)}
                  >
                    {size}
                  </button>
                  )
                else return null
              })
            : AllSizes.map((size) => (
                <button
                  key={createKey()}
                  onClick={() => selectSize(size)}
                  className={`${stylesLocal.buttonSize} ${
                    sizeValue === size ? stylesLocal.selected : ""
                  }`}
                >
                  {size}
                </button>
              ))}
        </section>
        }
      </div>
    </>
  );
};

export default FaqSize;
