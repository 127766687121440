import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styles from './DetailsReserve.module.css';
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from '../navBarMobile/NavBarMobile';
import Footer from "../footer/Footer";
import FooterMobile from '../footerMobile/FooterMobile';
import { TextDescription, Title, TextLinking } from "../shared/text/Text";
import Breadcrumb from "../navigation/breadcrumb/Breadcrumb";
import CardProduct from "../reservationPayment/Product";
import moment from 'moment';
import SuccessMessage from '../successMessage/SucessMessage';
import Button from '../shared/button';
import axios from '../../api/axios.config';
import { useState } from 'react';
import { useEffect } from 'react';

const DetailsReserve = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { width } = useWindowDimensions();
    const [dressModel, setDressModel] = useState({});
    const [dress, setDress] = useState({});
    const { id } = useParams();

    const onGetDress = async () => {
        await axios.get(`/api/dress-models/${id}`)
        .then(resp => {
          if(resp.status === 200){
            setDressModel({
              ...resp.data.data,
              type: 'APARTADO'
            })
          }
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
      if (Object.keys(dressModel).length > 0) {
        setDress(dressModel?.dress.find((dress) => dress.id === location.state?.dress_id));
      }
    }, [dressModel]);

    useEffect(() => {
      onGetDress();
    },[])

    return (
        <>
        {width <= 1024 ?
          <div>
            <section>
              <NavBarMobile isSelectedProfile/>
            </section>
            <section className={styles.container}>
              <Title>DETALLES DE APARTADO</Title>
    
              <Breadcrumb items={[{name: 'Perfil', action: () => navigate('/perfil') }, {name: dressModel.name, action: () => {}}]} />
            </section>
            <SuccessMessage display={true} label={`Apartado hasta: ${moment(location.state?.reservation.end_date).format('DD - MMM YYYY')}`} />
            <section className={styles.container}>
              <div className={styles.containerCart}>
                <CardProduct data={dressModel} disabled key={Math.random()} calendarRange={[location?.state?.reservation?.start_date, location.state?.reservation?.end_date]} sizeValue={dressModel.dress ? dressModel.dress[0].size.description : ''} colorName={dressModel.dress ? dressModel.dress[0].color.name : ''} />
              </div>
              <div style={{marginBottom: 60}}>
                  <TextLinking style={{color: '#5F6867'}}>Leer Términos y Condiciones de Apartado</TextLinking>
              </div>
              <Button onClick={() => 
                navigate('/pago', { 
                  state: { 
                    cart: [
                      { 
                        id: location.state?.dress?.id,
                        cart_id: 0,
                        name: dressModel.name,
                        price: (Number(dressModel.price) / 2),
                        type: 'RENTA',
                        product: dress,
                        images: dressModel.images,
                        final_price: (Number(dressModel.price) / 2),
                        discount: dressModel.dress[0]?.discount, 
                        size: dress?.size?.description,
                        color: dress?.color?.id,
                        color_name: dress?.color?.name,
                        product_type: 'dresses',
                        date: [location.state?.reservation?.start_date, location.state?.reservation?.end_date], 
                        quantity: 1
                      }
                    ], 
                    types: ['RENTA'], 
                    isReserve: true 
                  } 
                })} 
                label="Rentar ahora"
              />
            </section>
          </div>
          :
          <div>
            <section>
                <NavBarDesktop />
            </section>
            <section className={styles.container}>
              <Title>DETALLES DE APARTADO</Title>
    
              <Breadcrumb items={[{name: 'Perfil', action: () => navigate('/perfil') }, {name: dressModel.name, action: () => {}}]} />
            </section>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{width: 648}}>
                <SuccessMessage display={true} label={`Apartado hasta: ${moment(location.state?.reservation.end_date).format('DD - MMM YYYY')}`} />
                <section className={styles.container}>
                  <div className={styles.containerCart} style={{borderTop: '1px solid #D1D1D1', borderBottom: '1px solid #D1D1D1', paddingTop: 23}}>
                    <CardProduct conditionReserve data={dressModel} disabled key={Math.random()} calendarRange={[location?.state?.reservation?.start_date, location.state?.reservation?.end_date]} sizeValue={dressModel.dress ? dressModel.dress[0].size.description : ''} colorName={dressModel.dress ? dressModel.dress[0].color.name : ''}/>
                  </div>
                  <Button onClick={() => 
                    navigate('/pago', { 
                      state: { 
                        cart: [
                          { 
                            id: location.state?.dress?.id,
                            cart_id: 0,
                            name: dressModel.name,
                            price: (Number(dressModel.price) / 2),
                            type: 'RENTA',
                            product: dress,
                            images: dressModel.images,
                            final_price: (Number(dressModel.price) / 2),
                            discount: dressModel.dress[0]?.discount, 
                            size: dress?.size?.description,
                            color: dress?.color?.id,
                            color_name: dress?.color?.name,
                            product_type: 'dresses',
                            date: [location.state?.reservation?.start_date, location.state?.reservation?.end_date], 
                            quantity: 1
                          }
                        ], 
                        types: ['RENTA'], 
                        isReserve: true 
                      } 
                    })} 
                    label="Rentar ahora"
                  />
                </section>
              </div>
            </div>
          </div>
          }
          {width <= 1024 ? <FooterMobile /> : <Footer /> }
        </>
      );
}

export default DetailsReserve;