import api from "../../api/axios.config";
import { shopCartArray, initShopCart } from "../slices/shopCart";

export const setCartBack = (cart) => {
  return async (dispatch) => {
    const response = await api.post(`/api/cart`, cart);
    if(response.status === 200)
        dispatch(shopCartArray({cart: cart}));
  }
}

export const getCartBack = () => {
  return async (dispatch) => {
    const response = await api.get(`/api/cart`);
    if (response.status === 200) {
      const cart = response.data?.data ?? []
      dispatch(initShopCart(cart))
    }
  }
}
