import React from "react";
import styles from "./appointmentSchedules.module.css";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import FooterMobile from "../footerMobile/FooterMobile";
import NavbarDesktop from '../navBarDesktop/NavBarDesktop';
import Footer from '../footer/Footer';
import { useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Title } from "../shared/text/Text";
import api from '../../api/axios.config';
import { useEffect } from "react";
import moment from "moment";
import ToggleDisplay from '../shared/toggleDisplay/ToggleDisplay'
import ItemRentActive from "../itemRentActive/ItemRentActive";
import { InputSearch } from "../shared/input/Input";

const Selected = {
  PRIMERA_CITA: "PRIMERA CITA",
  PRUEBA_AJUSTES: "PRUEBA AJUSTES",
};

const AppointmentSchedule = (props) => {
  const [currentSelected, setCurrentSelected] = useState(Selected.PRIMERA_CITA);
  const { width } = useWindowDimensions()
  const [rows, setRows] = useState([])
  const [search, setSearch] = useState('')
  const [appointments, setAppointments] = useState({
    month: [],
    day: [],
    week: [],
    settings: []
  });

  const [appointments2, setAppointments2] = useState({
    month: [],
    day: [],
    week: [],
  });

  const onSearch = (value = '') => {
    setSearch(value)

    if(value !== ''){
      calcAppointments(rows.filter(a => a.rent?.order?.dress?.dress_models?.name.includes(value.toUpperCase())))
      calcAppointments2(rows.filter(a => a.rent?.order?.dress?.dress_models?.name.includes(value.toUpperCase())))
    }else{
      calcAppointments(rows)
      calcAppointments2(rows)
    }
  }

  const calcAppointments = (appointment = []) => {
    const settings = appointment.filter(a => a.ajustes_pendientes && a.rent.status !== 'ajustes_listos' && a.rent.first_date)

    const day = appointment.filter(a => moment(a.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'), 'day') && !a.ajustes_pendientes && a.rent.first_date)
    const week = appointment.filter(a => moment(a.date, 'YYYY-MM-DD').diff(moment(), 'day') <= 14 && !moment(a.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'), 'day') && !a.ajustes_pendientes && a.rent.first_date)
    const month = appointment.filter(a => moment(a.date, 'YYYY-MM-DD').diff(moment(), 'day') > 14 && moment(a.date, 'YYYY-MM-DD').diff(moment(), 'day') <= 31 && !moment(a.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'), 'day') && !a.ajustes_pendientes && a.rent.first_date)

    setAppointments({
      settings,
      month,
      week,
      day
    })
  }

  const calcAppointments2 = (appointment = []) => {
    const day = appointment.filter(a => moment(a.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'), 'day') && !a.rent.first_date)
    const week = appointment.filter(a => moment(a.date, 'YYYY-MM-DD').diff(moment(), 'day') <= 14 && !moment(a.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'), 'day') && !a.rent.first_date)
    const month = appointment.filter(a => moment(a.date, 'YYYY-MM-DD').diff(moment(), 'day') > 14 && moment(a.date, 'YYYY-MM-DD').diff(moment(), 'day') <= 31 && !moment(a.date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'), 'day') && !a.rent.first_date)

    setAppointments2({
      month,
      week,
      day
    })
  }

  const getAppointments = async () => {
    await api.get(`/api/appointment`)
    .then(resp => {
      if(resp.status === 200){
        setRows(resp.data.data)
        calcAppointments(resp.data.data)
        calcAppointments2(resp.data.data)
      }
    })
    .catch(e => {
      console.log(e)
    })
  }

  useEffect(() => {
    getAppointments()
  },[])

  return (
    <>
      <div>
        <section>
          {width <= 1024 ? <NavBarMobile /> : <NavbarDesktop />}
        </section>
        <section className={styles.container}>
          <Title style={{marginBottom: 28}}>Citas agendadas</Title>
          <section className={styles.profileButtonsArea}>
            <div>
              <button
                className={`${styles.ordersButton} ${currentSelected === Selected.PRIMERA_CITA ? styles.colorSelected : ''}`}
                onClick={() => setCurrentSelected(Selected.PRIMERA_CITA)}
              >
                PRIMERA CITA
              </button>
              <div
                className={
                  currentSelected === Selected.PRIMERA_CITA ? styles.selected : ""
                }
              ></div>
            </div>
            <div>
              <button
                className={`${styles.ordersButton} ${currentSelected === Selected.PRUEBA_AJUSTES ? styles.colorSelected : ''}`}
                onClick={() => setCurrentSelected(Selected.PRUEBA_AJUSTES)}
              >
                PRUEBA AJUSTES
              </button>
              <div
                className={
                  currentSelected === Selected.PRUEBA_AJUSTES
                    ? styles.selected
                    : ""
                }
              ></div>
            </div>
          </section>
          <div style={{marginTop: 20}}>
            <InputSearch placeholder="Buscar..." onChange={e => onSearch(e.target.value)} value={search} />
          </div>
        </section>
        {currentSelected === Selected.PRIMERA_CITA ? (
          <section className={styles.container} style={{padding: 0}}>
              <ToggleDisplay label="AJUSTES PENDIENTES" show={appointments.settings.length > 0 ? true : false}>
                {appointments.settings.map((a, i) => (
                  <div key={Math.random()}>
                    <ItemRentActive rent={a.rent} dress={a.rent?.order?.dress} date={a.ajustes_at} prefCita={'Ajustes pendientes'} format="DD/MM/YYYY" link={'/detalle-cita/'+a.id} state={{id: a?.rent?.id, appointment: a, date: a.ajustes_at, modelid: a.rent?.order?.dress?.dress_model_id, pref: 'AJUSTES'}} />
                    {i > 1 && <div style={{borderTop: '1px solid #D1D1D1'}} />}
                  </div>
                ))}
              </ToggleDisplay>

              <ToggleDisplay label="CITAS DE HOY" show={appointments.day.length > 0 ? true : false}>
                {appointments.day.map((a, i) => (
                  <div key={Math.random()}>
                    <ItemRentActive rent={a.rent} dress={a.rent?.order?.dress} date={a.date} link={'/detalle-cita/'+a.id} state={{id: a?.rent?.id, modelid: a.rent?.order?.dress?.dress_model_id, pref: 'CITA'}} />
                    {i > 1 && <div style={{borderTop: '1px solid #D1D1D1'}} />}
                  </div>
                ))}
              </ToggleDisplay>
              <ToggleDisplay label="CITAS DE 2 SEMANAS" show={appointments.week.length > 0 ? true : false}>
                {appointments.week.map((a, i) => (
                  <div key={Math.random()}>
                    <ItemRentActive rent={a.rent} dress={a.rent?.order?.dress} date={a.date} link={'/detalle-cita/'+a.id} state={{id: a?.rent?.id, modelid: a.rent?.order?.dress?.dress_model_id, pref: 'CITA'}} />
                    {i > 1 && <div style={{borderTop: '1px solid #D1D1D1'}} />}
                  </div>
                ))}
              </ToggleDisplay>
              <ToggleDisplay label="CITAS DE 4 SEMANAS" show={appointments.month.length > 0 ? true : false}>
                {appointments.month.map((a, i) => (
                   <div key={Math.random()}>
                    <ItemRentActive rent={a.rent} dress={a.rent?.order?.dress} date={a.date} link={'/detalle-cita/'+a.id} state={{id: a?.rent?.id, modelid: a.rent?.order?.dress?.dress_model_id, pref: 'CITA'}} />
                    {i > 1 && <div style={{borderTop: '1px solid #D1D1D1'}} />}
                  </div>
                ))}
              </ToggleDisplay>
          </section>
        ) : (
          <section className={styles.container} style={{padding: 0}}>
              <ToggleDisplay label="CITAS DE HOY" show={appointments2.day.length > 0 ? true : false}>
                {appointments2.day.map((a, i) => (
                  <div key={Math.random()}>
                    <ItemRentActive rent={a.rent} dress={a.rent?.order?.dress} date={a.date} link={'/detalle-cita/'+a.id} state={{id: a?.rent?.id, modelid: a.rent?.order?.dress?.dress_model_id, pref: 'DETALLE'}} />
                    {i > 1 && <div style={{borderTop: '1px solid #D1D1D1'}} />}
                  </div>
                ))}
              </ToggleDisplay>
              <ToggleDisplay label="CITAS DE 2 SEMANAS" show={appointments2.week.length > 0 ? true : false}>
                {appointments2.week.map((a, i) => (
                  <div key={Math.random()}>
                    <ItemRentActive rent={a.rent} dress={a.rent?.order?.dress} date={a.date} link={'/detalle-cita/'+a.id} state={{id: a?.rent?.id, modelid: a.rent?.order?.dress?.dress_model_id, pref: 'DETALLE'}} />
                    {i > 1 && <div style={{borderTop: '1px solid #D1D1D1'}} />}
                  </div>
                ))}
              </ToggleDisplay>
              <ToggleDisplay label="CITAS DE 4 SEMANAS" show={appointments2.month.length > 0 ? true : false}>
                {appointments2.month.map((a, i) => (
                   <div key={Math.random()}>
                    <ItemRentActive rent={a.rent} dress={a.rent?.order?.dress} date={a.date} link={'/detalle-cita/'+a.id} state={{id: a?.rent?.id, modelid: a.rent?.order?.dress?.dress_model_id, pref: 'DETALLE'}} />
                    {i > 1 && <div style={{borderTop: '1px solid #D1D1D1'}} />}
                  </div>
                ))}
              </ToggleDisplay>
          </section>
        )}
      </div>
      {width <= 1024 ? <FooterMobile /> : <Footer />}
    </>
  );
};

export default AppointmentSchedule;
