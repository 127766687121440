import React from "react";
import styles from "./sizeConfirmation.module.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setIsSearchBtnEnable } from "../../store/slices/rentalProcess/rentalProcessSlice";
import { onSetFilterValues } from "../../store/slices/filters/filtersSlice";

const SizeConfirmation = (props) => {
  const dispatch = useDispatch();
  const { isSizeDisable } = useSelector((store) => store.rentalProcess);
  const { valuesFilter: { valueSizes } } = useSelector((store) => store.filters);

  const selectSize = (size) => {
    props.setValuesFilters({
      ...props.valuesFilters,
      'valueSizes': [size]
    })
    dispatch(onSetFilterValues({
      ...props.valuesFilters,
      'valueSizes': [size]
    }))

    dispatch(setIsSearchBtnEnable());
  }

  return (
    <section style={{position: 'relative'}}>
      {isSizeDisable && <div style={{background: 'rgba(255, 255, 255, 0.4)', height: '100%', width: '100%', position: 'absolute'}} />}
      <div className={styles.subTitleContainer}>
        <div className={styles.collapsableHeader}>
          <span className={styles.subTitle}>¿Qué talla buscas?</span>
        </div>
        <section className={styles.buttonSizeContainer}>
          <button onClick={()=>selectSize(1)}className={`${styles.buttonSize} ${valueSizes[0] === 1 ? styles.selected : ''}`} disabled={isSizeDisable}>
            XS
          </button>
          <button onClick={()=>selectSize(2)}className={`${styles.buttonSize} ${valueSizes[0] === 2 ? styles.selected : ''}`} disabled={isSizeDisable}>
            S
          </button>
          <button onClick={()=>selectSize(3)}className={`${styles.buttonSize} ${valueSizes[0] === 3 ? styles.selected : ''}`} disabled={isSizeDisable}>
            M
          </button>
          <button onClick={()=>selectSize(4)}className={`${styles.buttonSize} ${valueSizes[0] === 4 ? styles.selected : ''}`} disabled={isSizeDisable}>
            L
          </button>
          <button onClick={()=>selectSize(5)}className={`${styles.buttonSize} ${valueSizes[0] === 5 ? styles.selected : ''}`} disabled={isSizeDisable}>
            XL
          </button>
          <button onClick={()=>selectSize(6)}className={`${styles.buttonSize} ${valueSizes[0] === 6 ? styles.selected : ''}`} disabled={isSizeDisable}>
            XXL
          </button>
        </section>
        <Link to={"/ayuda-medidas"} className={styles.justExploring}>
          ¿Tienes dudas?<br/> Consulta la guía de medidas
        </Link>
      </div>
    </section>
  );
};

export default SizeConfirmation;
