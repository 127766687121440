import moment from "moment";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InputSearch } from "../shared/input/Input";
import { format_currency } from "../../utils";
import { setLoading } from "../../store/slices/navigation/navigationSlice";
import { modal } from "../modalManagment";
import styles from "./Stock.module.css";
import api from "../../api/axios.config";
import ModalNewOrder from "../modal/ModalNewOrder";
import BolsaIcon from "../../assets/icons/BolsaIcon";
import Notes from "../../assets/icons/Notes";
import ModalDressNotes from '../modal/ModalDressNotes';
import PaginationComponent from "../shared/paginationNumeric/PaginationNumeric";

const RentInventory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
	const [pagination, setPagintation] = useState({});
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [sort, setSort] = useState({
		sort: "id",
		direction: "desc"
	});
    const [searchType, setSearchType] = useState("name_dress");
    const [search, setSearch] = useState("");
	const [lastSearch, setLastSearch] = useState("");
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const formConfig = useForm({
		defaultValues: {
			user_id: null,
			days: 3,
			seller: "",
			send_method: "pick_up",
			start_date: null,
			second_dress: null,
			address_num_ext: null,
			colonia: null,
			postal_code: null,
			city_id: null
		}
	});

    const createOrderModal = (row) => {
		document.getElementById("root").style.overflowY = "hidden";
		dispatch(setLoading(false));
		modal.open(<ModalNewOrder dress={row} formConfig={formConfig} createOrder={createOrder} errorMessage={errorMessage} error={error} setError={setError} />);
	};

	const updateNotesModal = (row) => {
		document.getElementById("root").style.overflowY = "hidden";
		dispatch(setLoading(false));
		modal.open(<ModalDressNotes dress={row} errorMessage={errorMessage} error={error} setError={setError} updateNotes={updateNotes} />);
	};

	const createOrder = async (data) => {
		setError(false);
		dispatch(setLoading(true));
		const response = await api.post(`api/orders`, data);
		if (response.status === 200) {
			dispatch(setLoading(false));
			document.getElementById("root").style.overflowY = "";
			modal.close();
			navigate("/pedidos");
		} else {
			setError(true);
			dispatch(setLoading(false));
			setErrorMessage(response.data?.message);
		}
	};

	const updateNotes = async (data) => {
		setError(false);
		dispatch(setLoading(true));
		const response = await api.put(`api/dresses/${data.id}/notes`, data);
		if (response.status === 200) {
			dispatch(setLoading(false));
			document.getElementById("root").style.overflowY = "";
			modal.close();
			getAllDresses();
		} else {
			setError(true);
			dispatch(setLoading(false));
			setErrorMessage(response.data?.message);
		}
	};

    const getAllDresses = async () => {
		let query = "";

		if (search !== "") {
			if (searchType === "rentle_id") {
				query += `&rentle_id=${search}`;
			} else if (searchType === "name_dress") {
				query += `&type=${searchType}&search=${search}`;
			}
		}

		if (sort.sort !== "") {
			query += `&sort=${sort.sort}&direction=${sort.direction}`;
		}

		try {
			const response = await api.get(`/api/dresses?page=${page}&per_page=${perPage}&admin=true${query}`);
			if (response.status === 200) {
				const { data, ...meta } = response.data;
				setRows(data);
				setPagintation(meta);
			}
		} catch (e) {
			console.log(e);
		}
	};

    const columns = [
		{
			sortField: "image",
			name: "",
			minWidth: "120px",
			maxWidth: "120px",
			selector: (row) => {
				return (
					<div className="image-column">
						<img src={row.dress_models?.images[0]?.url} alt="" height="120" />
					</div>
				);
			}
		},
		{
			sortField: "dress_model",
			name: "Nombre de modelo",
			minWidth: "200px",
			maxWidth: "200px",
			cell: (row) => row.dress_models?.name
		},
		{
			sortField: "size",
			name: "Talla",
			minWidth: "70px",
			maxWidth: "70px",
			center: true,
			cell: (row) => row.size?.description
		},
		{
			sortField: "id",
			name: "Código interno",
			minWidth: "140px",
			maxWidth: "140px",
			center: true,
			cell: (row) => `ES-${String(row.id).padStart(5, "0")}`
		},
		// {
		//     sortField: 'variant_id_rentle',
		//     name: 'ID de Rentle',
		//     minWidth: "230px",
		//     maxWidth: "230px",
		//     cell: (row) => row.variant_id_rentle
		// },
		{
			sortField: "store",
			name: "Ubicación",
			minWidth: "100px",
			maxWidth: "100px",
			cell: (row) => row.store
		},
		{
			sortField: "price",
			name: "Precio",
			minWidth: "120px",
			maxWidth: "120px",
			cell: (row) => format_currency(row.dress_models?.price)
		},
		{
			sortField: "discount",
			name: "Descuento",
			minWidth: "120px",
			maxWidth: "120px",
			cell: (row) => `${row.discount}%`
		},
		{
			sortField: "final_price",
			name: "Precio final",
			minWidth: "150px",
			maxWidth: "150px",
			sortable: true,
			cell: (row) => format_currency(row.final_price)
		},
		{
			sortField: "createAt_rentle",
			name: "Fecha de creación",
			minWidth: "170px",
			maxWidth: "170px",
			sortable: true,
			cell: (row) => moment(row.createAt_rentle).format("DD/MM/YYYY")
		},
		{
			sortField: "actions",
			name: "Acciones",
			minWidth: "100px",
			center: true,
			selector: (row) => {
				return (
					<div className="d-flex flex-row align-center">
						<div className="d-flex justify-content-center pointer" onClick={() => createOrderModal(row)}>
							<BolsaIcon />
						</div>
						<div className="d-flex justify-content-center pointer" onClick={() => updateNotesModal(row)}>
							<Notes />
						</div>
					</div>
				);
			}
		}
	];

    const CustomPagination = () => {
        return (
            <div className="d-flex justify-content-center mt-1">
                <PaginationComponent page={page} setPage={setPage} total={pagination?.meta?.total} last_page={pagination?.meta?.last_page} per_page={perPage} data={rows} />
            </div>
        );
    };

    const handleSort = (column, sortDirection) => {
		console.debug("handleSort event");
		setSort({
			sort: column.sortField,
			direction: sortDirection
		});
	};

	const handlerSearch = (searchValue) => {
		setSearch(searchValue);
		if (searchValue === "") {
			getAllDresses();
		}
	};

	const handleKeyDown = (event) => {
		// console.log('User pressed: ', event.key)
		if (event.key === "Enter") {
			if (search !== "") {
				setLastSearch(search);
				getAllDresses();
			}
		}
		// if (event.key === 'Backspace') {

		// }
	};

	const handleOnBlur = () => {
		console.log("Last Search:", lastSearch);
		console.log("Search:", search);
		if (lastSearch !== search) {
			getAllDresses();
			setLastSearch(search);
		}
	};

	useEffect(() => {
		getAllDresses();
	}, [page, sort]);

    return (
        <>
            <div className={styles.inlineContainerSpaced}>
                <div className={styles.inlineContainer}>
                    <select className={styles.searchSelect} value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                        <option value="name_dress">Nombre de modelo</option>
                        <option value="rentle_id">Código Interno</option>
                    </select>
                    <InputSearch placeholder="Buscar..." value={search} styles={styles.searchInput} onChange={(e) => handlerSearch(e.target.value)} onKeyDown={handleKeyDown} onBlur={handleOnBlur} />
                </div>
                {/* <CustomCheck
                                    content={"Ocultar pedidos pasados"}
                                    onChangeCallBack={() => setHidePastOrders(!hidePastOrders)}
                                    isChecked={hidePastOrders}
                                /> */}
            </div>
    
            <DataTable
                noDataComponent={
                    <div className="d-flex justify-content-center mt-1">
                        <p>No se encontraron resultados</p>
                    </div>
                }
                keyField={"id"}
                sortServer
                pagination
                responsive
                dense
                paginationServer
                columns={columns}
                onSort={handleSort}
                className="react-dataTable"
                paginationComponent={CustomPagination}
                data={rows}
                selectableRowsNoSelectAll
            />
        </>
    );
}

export default RentInventory