import React, { useLayoutEffect } from "react";
import styles from "./productDetails.module.css";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from '../navBarMobile/NavBarMobile';
import Footer from "../footer/Footer";
import FooterMobile from '../footerMobile/FooterMobile';
import DetailsAndFiltersSale from "../detailsAndFiltersSale/DetailsAndFiltersSale";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDressModelById } from "../../store/thunks/dressModelThunk";
import { getFavoritesPagination } from "../../store/thunks/favoritesThunk";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import IndicatorImgMobile from "../indicatorImgMobile/IndicatorImgMobile";
import { useState } from "react";
import PlaceholderDress from '../../assets/svg/PlaceholderDress.svg';
import { checkAvailityImg } from "../../utils";
import { useSwipeable } from "react-swipeable";
import DressImages from "../dressImages/DressImages";
import MainImageDress from "../mainImageDress/MainImageDress";
import LoaderIcon from '../../assets/svg/loader-icon.svg';
import MexicoIcon from '../../assets/icons/mexico.svg';
import { modal } from "../modalManagment";
import ModalMainImage from "../modal/ModalImageMain";
import { useRef } from "react";
import { urlSearchParams } from "../../utils";

const ProductDetails = (props) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { width } = useWindowDimensions();
  const [imgPosition, setImgPosition] = useState(0);
  const [mainImg, setMainImg] = useState({});
  const [loading, setLoading] = useState(true);
  const queryParams = urlSearchParams(location.search)
  const dressID = location.state?.id_dress ?? queryParams.object?.id_dress
  const close = queryParams.object?.close ?? false

  // console.log('Dress ID:', dressID)

  const fetchData = () => {
    dispatch(getDressModelById(params.id));
    dispatch(getFavoritesPagination());
  };

  const { dressModelDetail } = useSelector((store) => store.dressDetails);

  useEffect(() => {
    fetchData();
  }, [params.id]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1500)
    if (dressModelDetail.visible === 0) navigate('/en-venta')
  }, [dressModelDetail])

  const handledImages = (imgNumber) => {
    let srcImage = dressModelDetail?.images[imgNumber]?.url ? `${dressModelDetail?.images[imgNumber]?.url}` : PlaceholderDress;
    return srcImage;
  };

  const changeImgPosition = (position) => {
    setImgPosition(position);
    handledImages(position)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => changeImgPosition(((dressModelDetail?.images ? dressModelDetail?.images.filter(i => i.url !== '').length : 0) > (imgPosition + 1)) ? (imgPosition + 1) : 0),
    onSwipedRight: () => changeImgPosition(imgPosition > 0 ? imgPosition - 1 : (dressModelDetail?.images.filter(i => i.url !== '').length - 1)),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  const onModalMainImage = () => {
    modal.open(
      <ModalMainImage image={handledImages(imgPosition)}/>,
      () => {},
      'BackdropContainerFull',
    )
  }

  const RefSection = useRef(null)

  return (
    <>
    {width <= 1024 ?
      <div>
        <section>
          <NavBarMobile onlyClose={close}/>
        </section>
        { loading ?
          <div style={{width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div>
              <img src={LoaderIcon} alt="animation" className={styles.rotateCenter} />
            </div>
          </div>
          :
          <>
            <section>
              <div className={styles.containImgMainMobile}>
              {dressModelDetail && 
                <div {...handlers} className={styles.containerInline}>
                    <img
                      src={handledImages(imgPosition)}
                      onClick={() => onModalMainImage()}
                      alt=""
                      className={styles.additionalImagesMobile}
                    />
                  <IndicatorImgMobile indicator={imgPosition} maxPosition={dressModelDetail?.images ? dressModelDetail?.images.filter(i => i.url !== '').length : 0} changeImgPosition={changeImgPosition} />
                </div>
              }
              </div>
            </section>
          </>
        }
        <section>
            <DetailsAndFiltersSale id_dress={dressID} />
        </section>
      </div>
      :
      <>
      <div className={styles.container}>
        <section className={styles.nav}>
            <NavBarDesktop />
        </section>
      </div>
      <div style={{height: 44, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', top: '75px'}}>
        <span className={styles.textMexico} style={{marginRight: 12}}>ENVÍOS A TODA LA REPUBLICA MEXICANA </span> <img src={MexicoIcon} alt="" />
      </div>
      <div className={styles.container} style={{gridTemplateRows: '0px 1fr'}}>
        <section className={styles.section1} ref={RefSection}>
          {dressModelDetail ? (
            <div className={styles.dressSection}>
              <div className={styles.dressContainer}>
              { loading ?
                <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div>
                    <img src={LoaderIcon} alt="animation" className={styles.rotateCenter} />
                  </div>
                </div>
                :
                <>
                  {/* Sección de carousel de imágenes */}
                  <DressImages dressModelDetail={dressModelDetail} checkAvailityImg={checkAvailityImg} setMainImg={setMainImg}/>
                  {/* Sección de imagen principal */}
                  <MainImageDress dressModelDetail={dressModelDetail} mainImg={mainImg} setMainImg={setMainImg} handledImages={handledImages} checkAvailityImg={checkAvailityImg} />
                </>
              }
              </div>
            </div>
          ) : (
            <></>
          )}
        </section>

        <section className={styles.section2} style={{maxHeight: `${RefSection.current?.clientHeight ? (RefSection.current?.clientHeight + 10)+'px' : '100vh'}`}}>
          <DetailsAndFiltersSale id_dress={dressID} />
        </section>
      </div>
      </>
      }
      {width <= 1024 ? <FooterMobile /> : <Footer /> }
    </>
  );
};

export default ProductDetails;