import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import localStyles from "./faqMySize.module.css";
import { useEffect } from "react";

const FaqMySize = ({ navigate, formConfig, dataUser, disabled, show, display }) => {
  const [isDisplay, setIsDisplay] = useState(show ? show : false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const [values, setValues] = useState({
    bustSize: '',
    waist: '',
    hip: ''
  })

  useEffect(() => {
    setIsDisplay(display)
  },[display])

  useEffect(() => {
    if(dataUser?.menasurement){
      setValues({
        bustSize: dataUser?.menasurement?.bust,
        hip: dataUser?.menasurement?.hip,
        waist: dataUser?.menasurement?.waist
      })
    }
  },[dataUser])

  const setValueFormData = (name, value = '') => {
    const val = value.split('cm').join('')
    setValues({ ...values, [name]: val !== '' ? `${val.replace(/[a-zA-ZñÑ.,-]/g, '')}cm` : val.replace(/[a-zA-ZñÑ.,-]/g, '') })
  }

  return (
    <>
      <button
        type="button"
        className={localStyles.faqSize}
        onClick={toggleDisplay}
      >
        <span>MI TALLA</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <div className={localStyles.inputsArea}>
         <section className={localStyles.inputSections}>
          <span className={localStyles.textSpan}>Altura</span>
            <select name="height-values" id="height" disabled={disabled} defaultValue={dataUser?.menasurement?.height}
              className={localStyles.itemInputSelect}
              {...formConfig.register("altura")}
              >
              <option value="" disabled className={localStyles.typeOfSelection} >Seleccionar altura</option>  
              <option value="140,0 - 144,9 cm" className={localStyles.valueHeight}>140,0 - 144,9 cm</option>
              <option value="145,0 - 149,9 cm" className={localStyles.valueHeight}>145,0 - 149,9 cm</option>
              <option value="150,0 - 154,9 cm" className={localStyles.valueHeight}>150,0 - 154,9 cm</option>
              <option value="155,0 - 159,9 cm" className={localStyles.valueHeight}>155,0 - 159,9 cm</option>
              <option value="160,0 - 164,9 cm" className={localStyles.valueHeight}>160,0 - 164,9 cm</option>
              <option value="165,0 - 169,9 cm" className={localStyles.valueHeight}>165,0 - 169,9 cm</option>
              <option value="170,0 - 174,9 cm" className={localStyles.valueHeight}>170,0 - 174,9 cm</option>
              <option value="175,0 - 179,9 cm" className={localStyles.valueHeight}>175,0 - 179,9 cm</option>
              <option value="180,0 - 184,9 cm" className={localStyles.valueHeight}>180,0 - 184,9 cm</option>
              <option value="185,0 - 189,9 cm" className={localStyles.valueHeight}>185,0 - 189,9 cm</option>
              <option value="190,0 - 194.9 cm" className={localStyles.valueHeight}>190,0 - 194,9 cm</option>
              <option value="195,0 - 199,9 cm" className={localStyles.valueHeight}>195,0 - 199,9 cm</option>
            </select>
          </section>

          <section className={localStyles.inputSections}>
            <span className={localStyles.textSpan}>Talla de busto</span>
            <input
              placeholder="Ingresar medida cm"
              value={values.bustSize}
              className={localStyles.itemInput}
              defaultValue={dataUser?.menasurement?.bust}
              disabled={disabled}
              name="bustSize"
              {...formConfig.register("bustSize")}
              onChange={(e) => setValueFormData(e.target.name, e.target.value)}
            ></input>
          </section>

          <section className={localStyles.inputSections}>
            <span className={localStyles.textSpan}>Medida cintura</span>
            <input
             maskChar=''
              disabled={disabled}
              name="waist"
              value={values.waist}
              placeholder="Ingresar medida cm"
              className={localStyles.itemInput}
              defaultValue={dataUser?.menasurement?.waist}
              {...formConfig.register("waist")}
              onChange={(e) => setValueFormData(e.target.name, e.target.value)}
            ></input>
          </section>
          <section className={localStyles.inputSections}>
            <span className={localStyles.textSpan}>Medida cadera</span>
            <input
             maskChar=''
              disabled={disabled}
              placeholder="Ingresar medida cm"
              value={values.hip}
              className={localStyles.itemInput}
              defaultValue={dataUser?.menasurement?.hip}
              name="hip"
              {...formConfig.register("hip")}
              onChange={(e) => setValueFormData(e.target.name, e.target.value)}
            ></input>
          </section>

          <section className={localStyles.inputSections}>
          <span className={localStyles.textSpan}>Talla usual de vestido</span>
            <select name="height-values" id="height"  disabled={disabled} defaultValue={dataUser?.menasurement?.size}
              className={localStyles.itemInputSelect}
              {...formConfig.register("dressSize")}
              >
              <option value="" disabled className={localStyles.typeOfSelection} >Seleccionar talla</option>  
              <option value="XXS" className={localStyles.valueHeight}>XXS</option>
              <option value="XS" className={localStyles.valueHeight}>XS</option>
              <option value="S" className={localStyles.valueHeight}>S</option>
              <option value="M" className={localStyles.valueHeight}>M</option>
              <option value="L" className={localStyles.valueHeight}>L</option>
              <option value="XL" className={localStyles.valueHeight}>XL</option>
              <option value="XXL" className={localStyles.valueHeight}>XXL</option>
            </select>
          </section>

          <div className={localStyles.helpMeasureArea}>
            <span
              className={localStyles.helpMeasure}
              onClick={() => navigate("/ayuda-medidas")}
            >
              Ayuda con medidas
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqMySize;
