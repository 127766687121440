import React, { useState } from "react";
import stylesLocal from "./faqSize.module.css";
import { AllSizes } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { setAllColorsAvailables, onSetFilterValues, setSizeValue } from "../../../store/slices/filters/filtersSlice";
import { createKey, getUniqueValues } from "../../../utils";
import ToggleCustomDisplay from "../../shared/toggleCustomDisplay/ToggleDisplay";
import { useEffect } from "react";

const FaqSize = (props) => {
  const dispatch = useDispatch();
  const { allSizesAvailables, valuesFilter: { valueSizes } } = useSelector((store) => store.filters);
  const { dressModelDetail } = useSelector(store => store.dressDetails);

  const selectSize = (size) => {
    const filterBySize = dressModelDetail.dress.filter(d => d.size.description.toLowerCase() === size.toLowerCase());

    if(filterBySize.length > 0 && size){
      if(!props.valuesFilters.valueSizes.includes(filterBySize[0].size.id))
        if(props.details)
          props.setValuesFilters({ ...props.valuesFilters, valueSizes: [filterBySize[0].size.id] })
        else
          dispatch(onSetFilterValues({ 'valueSizes': [filterBySize[0].size.id] }))
      else
        if(props.details)
          props.setValuesFilters({ ...props.valuesFilters, valueSizes: props.valuesFilters.valueSizes.filter(v => v !== filterBySize[0].size.id) })
        else
          dispatch(onSetFilterValues({ 'valueSizes': props.valuesFilters.valueSizes.filter(v => v !== filterBySize[0].size.id) }))
    }

    if(props.showOnlyAvailables){
      const filterBySize = dressModelDetail.dress.filter(d => d.size.description.toLowerCase() === size.toLowerCase());
      const allColorCodes = getUniqueValues(filterBySize.map(d => d.color.code));
      dispatch(setAllColorsAvailables(allColorCodes))
    }

    dispatch(setSizeValue(size))
  }

  const checkIfDisabled = (size) => {
    if(allSizesAvailables?.length > 0){
      return allSizesAvailables?.find((siz) => siz?.toLowerCase() === size?.toLowerCase()) ? false : true;
    }
    else{
      return dressModelDetail?.dress?.find((dr) => dr.size?.description.toLowerCase() === size?.toLowerCase()) ? false : true;
    }
  }

  useEffect(() => {
    if(allSizesAvailables?.length === 1 && dressModelDetail && AllSizes){
      const size = AllSizes.filter(size => !checkIfDisabled(size))
      const filterBySize = dressModelDetail.dress.filter(d => d.size.description.toLowerCase() === size[0].toLowerCase());
      if(filterBySize.length > 0 && size.length > 0){
        if(!props.valuesFilters.valueSizes.includes(filterBySize[0].size.id))
          if(props.details)
            props.setValuesFilters({ ...props.valuesFilters, valueSizes: [filterBySize[0].size.id] })
          else
            dispatch(onSetFilterValues({ 'valueSizes': [filterBySize[0].size.id] }))
      }
    }
  },[allSizesAvailables])

  return (
    <ToggleCustomDisplay show={(props.valuesFilters.valueSizes.length > 0 && props.valuesFilters.valueSizes[0] !== '') ? false : true} label="TALLAS" checked={props.valuesFilters.valueSizes.length > 0 && props.valuesFilters.valueSizes[0] !== ''}>
        {AllSizes.map((size) => {
          if(!checkIfDisabled(size))
            return (
              <button
                key={createKey()}
                onClick={() => selectSize(size)}
                className={`${stylesLocal.buttonSize} ${props.valuesFilters.valueSizes[0] === (dressModelDetail && dressModelDetail.dress.find(d => d.size.description.toLowerCase() === size.toLowerCase()) && dressModelDetail.dress.find(d => d.size.description.toLowerCase() === size.toLowerCase()).size.id) ? stylesLocal.selected : ""}`}
                disabled={checkIfDisabled(size)}
              >
                {size}
              </button>
              )
            else return null
          })
        }
    </ToggleCustomDisplay>
  );
};

export default FaqSize;
