import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import localStyles from "./faqActiveRents.module.css";
import ItemRentActive from '../../itemRentActive/ItemRentActive';
import { useEffect } from "react";

const FaqActiveRents = ({ details }) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  useEffect(() => {
    setIsDisplay((details && details.rentsActives && details.rentsActives.length) ? true : false)
  },[details])

  console.log(details.rentsActives)
  return (
    <>
      <button
        type="button"
        className={localStyles.faqRentsAct}
        onClick={toggleDisplay}
      >
        <span>RENTAS ACTIVAS</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        {(details && details.rentsActives) &&
          details.rentsActives.map(item => (
            <ItemRentActive {...item} key={Math.random()} date={item?.rent?.appointment?.date} link={'/detalle-renta/'+item?.dress?.dress_model_id} state={{id: item?.rent?.id, id_order: item?.rent?.order_id}} />
          ))
        }
      </div>
    </>
  );
};

export default FaqActiveRents;