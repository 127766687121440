import React, { useEffect, useState } from "react";
import './pagination.css';

const PaginationComponent = ({ data, total, page, setPage, last_page, per_page }) => {
  const [itemsPerPage, setitemsPerPage] = useState(20);

  useEffect(() => {
    setitemsPerPage(per_page);
  },[per_page])

  const [pageNumberLimit, setpageNumberLimit] = useState(4);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(4);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(total / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={(+page) === (+number) ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    if(Number(page) < Number(last_page))
      setPage(Number(page) + 1);

    if (Number(page) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setPage(Number(page) - 1);

    if ((page - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  return (
        <ul className="pageNumbers">
            <li>
                <button
                    onClick={() => {
                      setPage(1);
                      setmaxPageNumberLimit(4);
                      setminPageNumberLimit(0);
                    }}
                    disabled={page === pages[0] ? true : false}
                >
                    Inicio
                </button>
            </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
            <li>
                <button
                    onClick={() => {
                      setPage(Number(last_page));
                      setmaxPageNumberLimit(12);
                      setminPageNumberLimit(8);
                    }}
                    disabled={page === pages[pages.length - 1] ? true : false}
                >
                    Final
                </button>
            </li>
        </ul>
  );
}

export default PaginationComponent;