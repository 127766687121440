import React from 'react';
import styles from './ItemRentActive.module.css';
import { useNavigate } from 'react-router-dom';
import alertImg from "../../assets/svg/provisional/alertImg.svg";
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';

const ItemRentActive = (props) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('')

    const onChangeStatus = () => {
        setStatus(`Rentado para ${moment(props.rent.start_date).format('DD - MMM')} hasta ${moment(props.rent.end_date).format('DD - MMM')}`)

        if(props.rent.status === 'cita_agendada'){
            setStatus(`${props.prefCita ? props.prefCita : 'Cita agendada'}: ${moment(props.date ? props.date : props.rent.date).format(props.format ? props.format : 'DD - MMM YYYY hh:mmA')}`)
        }

        if(props.rent.status === 'ajustes_listos'){
            setStatus(`Tus ajustes estan listos el: ${moment(props.date).format('DD - MMM YYYY')}`)
        }
    }

    useEffect(() => {
        onChangeStatus()
    },[props.rent])

    return(
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '90%', cursor: 'pointer'}} onClick={() => navigate(props.link, { state: {...props.state, rent: props.rent} })}>
            <div className={styles.rentsDescriptionArea}>
                <img alt="" src={props?.dress?.dress_models?.images[0]?.url}></img>
                <section className={styles.rentsArea}>
                    <span className={styles.modelSpan}>{props?.dress?.dress_models?.name}</span>
                    <section className={styles.dateRentsnArea}>
                        <img alt="" src={alertImg}></img>
                        <span className={styles.textRentsDate}>{status}</span>
                    </section>
                </section>
            </div>

            <Icon icon="akar-icons:chevron-right" fontSize={11} />
        </div>
    )
}

export default ItemRentActive;