import { useEffect, useState } from "react"
import CardProduct from "../shopCart/CardProduct"
import { TextDescription } from "../shared/text/Text"
import styles from './DetailsSale.module.css';

const DetailsSaleProduct = ({ product }) => {
    const [dressModel, setDressModel] = useState({})

    useEffect(() => {
        if (product) setDressModel(product.dress_models)
    }, [product])

    return (
        <section className={styles.containerProducts}>
            <CardProduct 
                data={{...dressModel, type: 'VENTA', color_name: product.color?.name, quantity: product.quantity }} 
                disabled={true} 
                key={Math.random()} 
                calendarRange={[null, null]} 
                sizeValue={ product.size?.size ?? product.size?.description }
                type={product.type}
                readonly={true}
            />
        </section>
    )
}

export default DetailsSaleProduct