import React from "react";
import styles from "./error404.module.css";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import NavbarDesktop from '../navBarDesktop/NavBarDesktop';
import FooterMobile from "../footerMobile/FooterMobile";
import imgError404 from "../../assets/icons/imgError404.svg";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Footer from "../footer/Footer";

const Error404 = (props) => {
  const { width } = useWindowDimensions();

  return (
    <>
      <div>
        <section className={styles.navMobile}>
        {width <= 1024 ? <NavBarMobile /> : <NavbarDesktop />}
        </section>
        <section className={styles.containerError}>
          <img src={imgError404} alt="" />
        </section>
      </div>
      {width <= 1024 ? <FooterMobile /> : <Footer />}
    </>
  );
};

export default Error404;
