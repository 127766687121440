import React from "react";
import styles from "./ForgotMobile.module.css";
import { useForm } from "react-hook-form";
import api from "../../api/axios.config";
import { useNavigate } from "react-router-dom";

const ForgotPasswordMobile = (props) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const user = {
        recovery_token: props.id,
        password: data.password,
        password_confirmation: data.passwordAgain,
      };
      const resp = await api.put(`/api/update-password`, user)
      if(resp.status === 200){
        navigate('/inicio-sesion', { state: { forgotPassword: true } })
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <div className={styles.cardContainerMobile}>
        <span className={styles.titleCard}>Restablecer contraseña</span>
        <form
          className={styles.registerContainer}
          style={{marginTop: 21}}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="marginBottomType1">
            <span className="spanTopInputs">Ingresa tu contraseña</span>
            <input
              autoComplete="off"
              type={"password"}
              placeholder="Contraseña"
              className={`${errors?.email ? 'inputError' : ''} inputTypeText`}
              id="inputPassword"
              {...register("password", {
                setValueAs: (v) => v.trim(),
                minLength: {
                  value: 6,
                  message: "Mínimo 6 caracteres",
                },
                validate: {
                  trim: (v) => {
                    if (!v.trim()) return "Contraseña no válida";
                    return true;
                  },
                },
              })}
            />
            {errors.password && (
              <p className={styles.errorMessage}>{errors.password.message}</p>
            )}
          </div>
          <div className="marginBottomType1">
            <span className="spanTopInputs">Confirma tu contraseña</span>
            <input
              autoComplete="off"
              type={"password"}
              placeholder="Contraseña"
              className={`${errors?.email ? 'inputError' : ''} inputTypeText`}
              {...register("passwordAgain", {
                validate: {
                  equals: (v) =>
                    v === getValues("password") || "La contraseña no coincide",
                },
              })}
            />
            {errors.passwordAgain && (
              <p className={styles.errorMessage}>
                {errors.passwordAgain.message}
              </p>
            )}
          </div>
          <button type="submit" className="primaryButton">
            GUARDAR CONTRASEÑA
          </button>
        </form>
      </div>
    </>
  );
};

export default ForgotPasswordMobile;
