import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import { loginSlice } from './slices/login/loginSlice'
import { navBarSlice } from './slices/navBar/navBarSlice'
import { navigationSlice } from './slices/navigation/navigationSlice'
import { rentalProcessSlice } from './slices/rentalProcess/rentalProcessSlice'
import { dressSearchResultsSlice } from './slices/dressSearchResults/dressSearchResultsSlice'
import { filtersSlice } from './slices/filters/filtersSlice'
import { dressDetailsSlice } from './slices/dressDetails/dressDetailsSlice'
import { shopCart } from './slices/shopCart'
import { termsAndConditionsSlice } from './slices/termsAndConditions/termsAndConditionsSlice'
import { clientsSlice } from './slices/clients/clientsSlice';
import { paymentSlice } from './slices/payment';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['rentalProcess', 'dressDetails']
}

const persistedReducer = persistReducer(persistConfig, 
  combineReducers({
    login: loginSlice.reducer,
    navBar: navBarSlice.reducer,
    navigation: navigationSlice.reducer,//this gonna be used when we need to send previous url if exist value, when specific behavior
    rentalProcess: rentalProcessSlice.reducer,
    termsAndConditions : termsAndConditionsSlice.reducer,
    dressSearchResults: dressSearchResultsSlice.reducer,
    filters: filtersSlice.reducer,
    dressDetails: dressDetailsSlice.reducer,
    shopCart: shopCart.reducer,
    clients: clientsSlice.reducer,
    payments: paymentSlice.reducer
  })
)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk]
})

export const persistor = persistStore(store)