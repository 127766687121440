import { createSlice } from '@reduxjs/toolkit';

export const rentalProcessSlice = createSlice({
    name: 'rentalProcess',
    initialState: {
        isCalendarDisable: true,
        isSizeDisable: true,
        isSearchBtnDisable: true,
        calendarDays: 3
    },
    reducers: {
        setIsCalendarEnable:(state, action)=>{
            state.isCalendarDisable = false;
        },
        setIsSizeEnable:(state, action)=>{
            state.isSizeDisable = false;
        },
        setIsSearchBtnEnable:(state,action)=>{
            state.isSearchBtnDisable = false;
        },
        setCalendarDays: (state, action) => {
            state.calendarDays = action.payload.days
        }
    }

});

export const {setIsCalendarEnable, setIsSizeEnable, setIsSearchBtnEnable, setCalendarDays } = rentalProcessSlice.actions;