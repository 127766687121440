import React from "react";
import styles from './SuccessMessage.module.css';
import { Icon } from "@iconify/react";
import { TextDescription } from "../shared/text/Text";

const SuccessMessage = (props) => {
    return(
        <>
        {props.display &&
            <div className={styles.containerSuccess}>
                <div className={styles.buttonCheck}>
                    <Icon icon={props.icon ? props.icon : "akar-icons:check"} color="#FFF" />
                </div>
                <TextDescription>{props.label ? props.label : 'Su pago se realizó exitosamente.'}</TextDescription>
            </div>
        }
        </>
    )
};

export default SuccessMessage;