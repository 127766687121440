import React, { useState } from "react";
import styles from "./FaqPage.module.css";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import NavbarDesktop from '../navBarDesktop/NavBarDesktop';
import FooterMobile from "../footerMobile/FooterMobile";
import BannerProfile from '../../assets/landing/faq-section-banner.jpeg';
import { Title, TextDescription } from '../shared/text/Text';
import { InputSearch } from "../shared/input/Input";
import Button from '../shared/button';
import Faqs from '../shared/faqs/Faqs';
import axios from '../../api/axios.config';
import { useEffect } from "react";
import { modal } from "../modalManagment";
import ModalFaq from "../modal/ModalFaq";
import SuccessMessage from "../successMessage/SucessMessage";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Footer from "../footer/Footer";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const FaqPage = (props) => {
    const [faqs, setFaqs] = useState([]);
    const { id } = useParams();
    const [rows, setRows] = useState([]);
    const { width } = useWindowDimensions();
    const [success, setSuccess] = useState(false);
    const [search, setSearch] = useState('');

    const { user } = useSelector((store) => store.login);

    const getFaqs = async () => {
        const resp = await axios.get(`/api/faqs`);
        if(resp.status === 200){
            setRows(resp.data.data);
            setFaqs(resp.data.data);
        }
    }

    const handlerSearch = (text) => {
        setSearch(text);
        setRows(faqs.filter(row => row.answer.description.toLowerCase().includes(text.toLowerCase()) || row.question.description.toLowerCase().includes(text.toLowerCase())))
    }

    useEffect(() => {
        getFaqs();
    },[])

    const onSubmit = async (email, question, category) => {
        const resp = await axios.post(`/api/faqs`, { email: window.localStorage.getItem("sorellaToken") ? user.email : email, question, category });
        if(resp.status === 200){
            modal.close();
            setSuccess(true);
        }
    }

    const onModalFaq = () => {
        modal.open(<ModalFaq onSubmit={onSubmit} />, () => {}, 'BackdropContainerCenter')
    }

    useEffect(() => {
        if(id && faqs.length){
            handlerSearch(id.replace('_',' '));
        }
    },[id, faqs])

    return (
        <>
        <div>
            <section className={styles.navMobile}>
                {width <= 1024 ? <NavBarMobile /> : <NavbarDesktop />}
            </section>
            <section className={styles.presentationImg}>
                <img alt="" src={BannerProfile} className={styles.containImgBanner}></img>
            </section>
            <div className={styles.containerDesktop}>
                <section className={styles.containerFaq}>
                    <div>
                        <Title styles={styles.title}>FAQ</Title>
                        <TextDescription styles={styles.subtitle}>Preguntas frecuentes</TextDescription>
                        <div className={styles.containtSeach}>
                            <InputSearch value={search} onChange={e => handlerSearch(e.target.value)} placeholder="Buscar" />
                        </div>
                    </div>
                </section>
                <SuccessMessage label="Pregunta realizada, se responderá a tu email directamente." display={success} />
                <section className={styles.containerFaq}>
                    <div className={styles.containtSeach}>
                        <Button onClick={onModalFaq} outline styles={styles.buttonFAQ} icon="akar-icons:plus" iconColor="rgba(151, 166, 163, 1)" stylestext={styles.textButtonFAQ} label="Realizar una nueva pregunta"></Button>
                    </div>
                    <div className={styles.itemsFAQ}>
                        <TextDescription styles={styles.subtitleItems}>RENTA</TextDescription>
                        
                        {rows.map(row => (
                            <Faqs title={row?.question?.description}>
                                <span dangerouslySetInnerHTML={{__html: row?.answer?.description}}></span>
                            </Faqs>
                        ))}
                    </div>
                </section>
            </div>
        </div>
        {width <= 1024 ? <FooterMobile /> : <Footer />}
        </>
    );
};

export default FaqPage;
