import React, { useState } from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";
import Input, { InputMoney, LabelInput } from "../shared/input/Input";

const ModalConfirmGuaranteePayment = ({ defaultValue, onClick }) => {
    const [value, setValue] = useState(defaultValue)
    return (
        <div className={styles.containerModal} style={{ marginTop: '100px' }}>
            <Title styles={styles.textModalTitle}>COBRAR GARANTÍA</Title>
            <TextDescription styles={styles.textDescription}>Se realizará el cobro de garantía</TextDescription>
            <section className={styles.inputSections} style={{ marginBottom: '24px', paddingLeft: '80px', paddingRight: '80px'}}>
                <LabelInput label="Monto" style={{ alignSelf: 'center' }}></LabelInput>
                <InputMoney value={value} style={{ textAlign: 'right', fontSize: '18px', fontFamily: 'Mulish' }} onChange={(e) => parseFloat(e.target.value) > defaultValue ? setValue(defaultValue) : setValue(e.target.value)}/>
            </section>
            <TextDescription styles={styles.textDescription}>¿Deseas continuar?</TextDescription>
            <div className={styles.containButtons}>
                <Button label="Cancelar" outline styles={styles.buttonMiddle} onClick={() => modal.close()} />
                <Button label="Cobrar garantía" styles={`${styles.buttonMiddle} ${styles.buttonPrimary}`} onClick={() => onClick(value)} />
            </div>
        </div>
    )
}

export default ModalConfirmGuaranteePayment;