import { Icon } from "@iconify/react";
import styles from './ToggleDisplay.module.css';
import React, { useEffect, useState } from "react";

const ToggleDisplay = (props) => {
    const [isDisplay, setIsDisplay] = useState(false);
    const toggleDisplay = () => {
      setIsDisplay(!isDisplay);
    };

    useEffect(() => {
        if(props.show){
            setIsDisplay(true);
        }
    },[props])

    return (
        <>
            <button
                type="button"
                className={`${styles.buttonToggle} ${props.style ? props.style : ''}`}
                onClick={toggleDisplay}
            >
                <span>{props.label}</span>
                {isDisplay ? (
                    <Icon
                        icon="akar-icons:minus"
                        color="#d6a191"
                        width="14"
                        height="14"
                    />
                ) : (
                    <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
                )}
            </button>
            <div
                className={`${styles.container} ${props.styleContainer ? props.styleContainer : ''} ${
                    isDisplay ? styles.display : styles.hidden
                }`}
            >
                {props.children}
            </div>
        </>
    )
}

export default ToggleDisplay;