import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import CustomCheck from "../../shared/customCheck/CustomCheck";
import { createKey } from "../../../utils";

const FaqStatus = (props) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const handlerChecked = (id) => {
    props.setValueStatus(id)
  }

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>ESTATUS</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <section className={styles.checkContainer}>
        {props.status &&
            props.status.map(row => (
              <div key={createKey()} className={styles.checkboxInline}>
                <CustomCheck
                  content={row}
                  onChangeCallBack={() => handlerChecked(row.toLowerCase())}
                  isChecked={
                    props.valueStatus && props.valueStatus === row.toLowerCase()
                      ? true
                      : false
                  }
                />
              </div>
            ))
          }
        </section>
      </div>
    </>
  );
};

export default FaqStatus;
