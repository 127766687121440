import React from "react";
import styles from "./footer.module.css";
import sorella from "../../assets/icons/FooterSorella.svg";
import instagramLogo from "../../assets/icons/instagram.svg"
import facebookLogo from "../../assets/icons/facebook.svg";
import TiktokLogo from "../../assets/icons/tiktok.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const onNavigate = (prevUrl, navigateUrl, state = {}) => {
    localStorage.setItem('previousURL', prevUrl)
    navigate(navigateUrl, state);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  const navigateTop = (url) => {
    navigate(url);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <section className={styles.container}>
      <section className={styles.containerGrid}>
        <section></section>
        <section>
          <div className={styles.titleFooter}>PROCESO</div>
          <span onClick={() => navigateTop('/resultados-vestidos')} className={styles.footerContent} >Encuentra vestido</span>
          <span className={styles.footerContent} onClick={() => navigateTop("/ayuda-medidas")}>Guía de tallas</span>
          <span className={styles.footerContent} onClick={() => navigateTop("/faq/ajustes")}>Ajustes al vestido</span>
          <span className={styles.footerContent} onClick={() => navigateTop("/faq/pagos")}>Pagos</span>
          <span className={styles.footerContent} onClick={() => navigateTop("/faq/regresar_vestido")}>Regresar el vestido</span>
        </section>
        <section>
          <div className={styles.titleFooter}>MI CUENTA</div>
          <span className={styles.footerContent} onClick={() => onNavigate('/perfil', localStorage.getItem('sorellaToken') ? "/perfil" : '/inicio-sesion')}>{localStorage.getItem('sorellaToken') ? 'Perfil' : 'Iniciar sesión'}</span>
          <span className={styles.footerContent} onClick={() => onNavigate('/perfil', localStorage.getItem('sorellaToken') ? "/perfil" : '/inicio-sesion', { state: { settingActive: true } })}>Configura tus medidas</span>
          <span className={styles.footerContent} onClick={() => onNavigate('/favoritos', localStorage.getItem('sorellaToken') ? "/favoritos" : '/inicio-sesion')}>Mis favoritos</span>
        </section>
        <section>
          <div className={styles.titleFooter}>AYUDA</div>
          <span className={styles.footerContent} onClick={() => navigateTop("/contacto")}>Contacto y sucursales</span>
          <span className={styles.footerContent} onClick={() => navigateTop("/faq")}>Preguntas frecuentes</span>
          {/* <span className={styles.footerContent} onClick={() => navigateTop("/faq/recolección")}>Puntos de recolección</span> */}
          <span className={styles.footerContent} onClick={() => navigateTop("/terminos-condiciones")}>Términos y Condiciones</span>
          <span className={styles.footerContent} onClick={() => navigateTop("/sobre-nosotros")}>Sobre nosotros</span>
        </section>
        <section>
          <div className={styles.titleFooter}>NUESTRAS REDES</div>
          <div className={styles.titleFooter} style={{marginTop: '8.435%'}}>
            <a href="https://www.facebook.com/etudsorella" className="buttonIcon">
              <img alt="" src={facebookLogo} style={{height: 18, width: 18}} ></img>
            </a>
            <a href="https://instagram.com/eesorella?igshid=YmMyMTA2M2Y=" className="buttonIcon">
              <img alt="" src={instagramLogo} style={{height: 18, width: 18}} ></img>
            </a>
            <a href="https://www.tiktok.com/@etudsorella" className="buttonIcon">
              <img alt="" src={TiktokLogo} style={{height: 18, width: 18}} ></img>
            </a>
          </div>
        </section>
        <section></section>
      </section>
      <div className={styles.containerSorella} style={{cursor: 'pointer'}} onClick={() => navigateTop('/principal')}>
        <img alt="sorella" src={sorella} style={{height: 20}}></img>
      </div>
    </section>
  );
};

export default Footer;
