import { createSlice } from '@reduxjs/toolkit';

export const clientsSlice = createSlice({
    name: 'clients',
    initialState: {
        data: []
    },
    reducers: {
        setClients: (state, action) => {
            state.data = action.payload.filter((client) => client.fullname.length > 0)
            // state.data = action.payload;
        }
    }
});

export const { setClients } = clientsSlice.actions;