import React, { useRef, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import styles from "./ReservationPayment.module.css";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import NavbarDesktop from "../navBarDesktop/NavBarDesktop";
import { Icon } from "@iconify/react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  TextDescription,
  TextLinking,
  TextSpanSmall,
  Title,
} from "../shared/text/Text";
import Breadcrumb from "../navigation/breadcrumb/Breadcrumb";
import Input, { LabelInput } from "../shared/input/Input";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../footer/Footer";
import FooterMobile from "../footerMobile/FooterMobile";
import Button from "../shared/button";
import ErrorMessage from "../errorMessage/ErrorMessage";
import moment from "moment";
import api from "../../api/axios.config";
import axios from 'axios';
import SuccessMessage from "../successMessage/SucessMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { removeAllProduct } from "../../store/slices/shopCart";
import { format_currency } from "../../utils";
import RadioCustom from "../shared/radio/Radio";
import Checked from "../shared/checkbox";
import { modal } from "../modalManagment";
import ModalWaiting from "../modal/ModalWaiting";
import InputCoupon from "../shared/input-coupon/InputCoupon";
import Product from "./Product";
import StripeBadge from "../../assets/stripe/stripe-badge.svg";
import { getUserDetails } from "../../store/thunks/authThunk";
import { getSchedules } from "../../store/thunks/paymentsThunk";
import Lodash from 'lodash';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const options = {
  style: {
    base: {
      "::placeholder": {
        color: "#949494",
        fontSize: "12px",
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    invalid: {
      color: "#3C4549",
    },
  },
};

const initialOptionsPaypal = {
  "client-id": process.env.REACT_APP_CLIENT_ID,
  currency: "MXN",
  intent: "capture",
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CountDown = ({ cart, type }) => {
  const navigate = useNavigate();

  const add15MinutesToCart = async () => {
    const ids = cart
      .filter((c) => c.type === "APARTADO" && (type === "AMBOS" || type === "APARTADO"))
      .map((c) => c.id);
    await api.post("api/cart/extend_time", { ids: ids.join(",") });
  };

  useEffect(() => {
    add15MinutesToCart();
  }, []);

  const [timer, setTimer] = useState(null);

  const getTimeCountDown = () => {
    const countDownDate = new Date(
      localStorage.getItem("timer-payment")
        ? localStorage.getItem("timer-payment")
        : moment().add(15, "minutes")
    ).getTime();
    return setInterval(() => {
      const now = new Date().getTime();
      const timeleft = countDownDate - now;

      const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

      if (minutes <= 0 && seconds <= 0) {
        navigate("/carrito");
      }

      setTimer(
        `${minutes < 10 ? "0" + minutes : minutes}:${
          seconds < 10 ? "0" + seconds : seconds
        }`
      );
    }, 1000);
  };

  useEffect(() => {
    localStorage.setItem("timer-payment", moment().add(15, "minutes"));
    const intervalId = getTimeCountDown();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <SuccessMessage
      label={`Completa tu pago en: ${timer ? timer : "00:00"}`}
      icon="mdi:clock-time-four-outline"
      display={cart && cart.length > 0}
    />
  );
};

const ReservationPayment = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const interval = useRef(setTimeout(() => {}, 0));
  const [name, setName] = useState("")
  const [breadcrumb, setBreadcrumb] = useState([
    { name: "Búsqueda", action: () => {} },
    { name: "Selección", action: () => {} },
    { name: "Apartado", action: () => {} }
  ])
  const elements = useElements()
  const stripe = useStripe()
  const { width } = useWindowDimensions()
  const [isDisplay, setIsDisplay] = useState(true)
  const [code, setCode] = useState("")
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };
  const [methodPayment, setMethodPayment] = useState("1")
  const [methodSend, setMethodSend] = useState(null)
  const [typeSent, setTypeSent] = useState("1")
  const [coupon, setCoupon] = useState("")
  const [checked, setChecked] = useState(false)
  const [couponError, setCouponError] = useState(false)
  const [couponErrorDescription, setCouponErrorDescription] = useState("")
  const [totalPaypal, setTotalPaypal] = useState(0)
  const [errorDescription, setErrorDescription] = useState(null)
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [isReserve, setIsReserve] = useState(false)
  const [errorInput, setErrorInput] = useState(false)
  const [subtotal, setSubtotal] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [shippingCost, setShippingCost] = useState(0)
  const [total, setTotal] = useState(0)
  const [cart, setCart] = useState([])
  const [sucursal, setSucurlar] = useState(null)
  const [scheduleSelected, setScheduleSelected] = useState(null)
  const [error, setError] = useState(false)
  const [errorMs, setErrorMs] = useState(false)
  const [confirmPayment, setConfirmPayment] = useState(false)
  const [defaultValues, setDefaultValues] = useState({
    firstName: "",
    lastName: "",
    address: "",
    interior: "",
    codePostal: "",
    city: "",
    state: "",
    colonia: "",
    phone: "",
  })
  const [municipality, setMunicipality] = useState(null)
  const [phone, setPhone] = useState('')
  const [suburbs, setSuburbs] = useState([])
  // Last day of current week in date format
  const lastDayOfWeek = moment().isoWeekday(7).hour(23).minute(59).second(59)
  // Min date unavailable when is in current week (Tuesday)
  const minDateUnavailable = moment().isoWeekday(2).hour(17).minute(0).second(0)
  // Max date unavailable when is in current week (Wednesday)
  const maxDateUnavailable = moment().isoWeekday(3).hour(17).minute(0).second(0)
  // Boolean value if current date is between date ranges
  const unavailableDate = moment().isBetween(minDateUnavailable, maxDateUnavailable)

  const { user } = useSelector((store) => store.login)
  const shopcart = useSelector((store) => store.shopCart)
  const { schedules } = useSelector((store) => store.payments)
  

  useEffect(() => {
    if (location.state?.cart) {
      setCart(location.state?.cart);
      setIsReserve(location.state?.isReserve);
    } else if (shopcart.cart.length) {
      setCart(shopcart.cart);
    }

    if (
      (!location.state?.cart || location.state?.cart.length === 0) &&
      (!shopcart.cart || shopcart.cart.length === 0) &&
      !confirmPayment
    ) {
      navigate("/carrito");
    }
  }, [cart, location]);

  const [validCoupon, setValidCoupon] = useState(null);

  const validateCoupon = async () => {
    await api
      .post("/api/coupon", { promo_code: coupon })
      .then((resp) => {
        if (resp.status === 200) {
          setValidCoupon(resp.data.data);
        }
        setCouponError(false);
      })
      .catch((err) => {
        console.log("Error:", err.response.data.message);
        setCouponError(true);
        setCouponErrorDescription(
          err.response?.data?.message ?? "Error con cupón"
        );
      });
  };

  const getStates = async () => {
    await api
      .get("/api/states")
      .then((resp) => {
        if (resp.status === 200) {
          setStates(resp.data.data);
        }
      })
      .catch((error) => console.log(error));
  };

  const getCities = async () => {
    await api
      .get(`/api/cities/${defaultValues.state}`)
      .then((resp) => {
        if (resp.status === 200) {
          setCities(resp.data.data);
        }
      })
      .catch((error) => console.log(error));
  };

  const format_prices = () => {
    let prices = { subtotal: "0", total: "0", discount: 0, coupon_discount: 0, shipping: 0, total_discount: 0 };
    if (cart && cart.length) {
      const product = cart.filter((p) => location?.state?.types?.includes(p.type) || location?.state?.types?.includes("AMBOS"));
      prices.subtotal = product.reduce(
        (prev, val) =>
          prev +
          (val.type === "APARTADO"
            ? Number(val.price) - (Number(val.price) * 0.5)
            : Number(val.price * val.quantity)),
        0
      );
      setSubtotal(prices.subtotal);

      prices.discount = product.reduce(
        (prev, val) => prev + (Number(val.price * val.quantity * (val.discount/100))),
        0
      );
      prices.total = product.reduce(
        (prev, val) =>
          prev +
          (val.type === "APARTADO"
            ? ((Number(val.price) - (Number(val.price) * 0.5)) - (Number(val.price * (val.discount/100))))
            : (Number(val.price * val.quantity) - (Number(val.price * val.quantity * (val.discount/100))))),
        0
      );
    }

    if (validCoupon) {
      if (validCoupon.type === "porcentaje") {
        prices.total_discount = Math.abs(Math.abs(prices.total * (validCoupon.discount / 100)) - prices.total)
        prices.coupon_discount = Math.abs(prices.total * (validCoupon.discount / 100))
      } else {
        prices.total_discount = Math.abs(validCoupon.discount - prices.total)
        prices.coupon_discount = Math.abs(validCoupon.discount)
      }
    } else {
      prices.total_discount = prices.total;
    }
    setTotalDiscount(prices.discount + prices.coupon_discount)

    if (methodSend === "2") {
      prices.total += typeSent === "1" ? 350 : 500;
      prices.total_discount += typeSent === "1" ? 350 : 500;
      prices.shipping = typeSent === "1" ? 350 : 500
      setShippingCost(typeSent === "1" ? 350 : 500)
    } else if (methodSend === "1") {
      setShippingCost(0)
    }

    // //se añade el descuento del vestido en curso
    // prices.total_discount = prices.total_discount - prices.discount;

    setTotal(prices.subtotal - (prices.discount + prices.coupon_discount) + prices.shipping)

    return prices;
  };

  const getStateFromGoogleMaps = async (postalCode) => {
    if (postalCode.length === 5) {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${ postalCode }|country:MX&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`)
      if (response.status === 200 && response.data?.results?.length > 0) {
        const components = response.data?.results[0]?.address_components
        const state = components.find((component) => component.types?.includes('administrative_area_level_1'));
        const municipality = components.find((component) => component.types?.includes('locality'));
        const findState = states.find((s) => s.name === state.long_name);
        if (findState && municipality) {
          setDefaultValues({
            ...defaultValues,
            state: findState.id,
            city: municipality
          })
        }
        if (municipality) {
          setMunicipality(municipality);
        }
        if (response.data?.results[0]?.postcode_localities.length > 0) {
          setSuburbs(response.data?.results[0]?.postcode_localities);
        } else {
          const street = defaultValues.address;
          const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(street)}&components=postal_code:${ postalCode }|country:MX&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`)
          if (response.status === 200 && response.data?.results?.length > 0) {
            const suburb = components.find((component) => component.types?.includes('sublocality_level_1') || component.types?.includes('sublocality'))
            if (suburb) {
              setSuburbs([suburb.long_name]);
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    getStates();
    dispatch(getUserDetails());
    topRef.current.scrollIntoView({ behavior: "smooth" });
    dispatch(getSchedules())
  }, []);

  useEffect(() => {
    if (defaultValues.codePostal) {
      interval.current = setTimeout(() => getStateFromGoogleMaps(defaultValues.codePostal), 500);
      return () => {
        clearTimeout(interval.current); // cleanup
      };
    }
  }, [defaultValues.codePostal])

  useEffect(() => {
    if (defaultValues.state) {
      getCities();
    }
  }, [defaultValues]);

  useEffect(() => {
    if (cities && cities.length > 0 && municipality) {
      const cityFound = cities.find((c) => c.name === municipality.long_name);
      if (cityFound && defaultValues.city !== cityFound.id) {
        setDefaultValues({
          ...defaultValues,
          city: cityFound.id
        });
      }
    }
  }, [cities])

  useEffect(() => {
    if(cart && cart.length > 0){
      if (cart[0].type === "RENTA") {
        setBreadcrumb([
          { name: "Búsqueda", action: () => {} },
          { name: "Selección", action: () => {} },
          { name: "Renta", action: () => {} },
        ]);
      }
      format_prices()
    }
  }, [cart])

  useEffect(() => {
    if (methodSend === "2" || methodSend === "1") {
      format_prices()
    }
  }, [methodSend])

  useEffect(() => {
    format_prices()
  }, [typeSent])

  const handlerRemove = () => dispatch(removeAllProduct());
  const topRef = useRef(null);

  const onSubmitHeap = (data) => {
    if (data.rents.length) {
      for (let i = 0; i < data.rents.length; i++) {
        window.heap.track("Purchase", {
          "Item ID": data.rents[i].dress_id,
          "Envio a domicilio": methodSend === "2" ? true : false,
          "Tipo de envio": typeSent === "1" ? "normal" : "express",
          "Mes de renta": data.rents[i].start_date,
          MXN: data.rents[i].precio,
          Cupon: data.promo_code ? data.promo_code : "",
          "Option B":
            location.state?.optionB &&
            location.state?.optionB.find(
              (op) => op.id === data.rents[i].dress_id
            )
              ? true
              : false,
          "Rent lenght": moment(data.rents[i].fecha_fin, "YYYY/MM/DD").diff(
            moment(data.rents[i].fecha_inicio, "YYYY/MM/DD"),
            "days"
          ),
        });
      }
    } else if (data.reservations.length) {
      for (let i = 0; i < data.rents.length; i++) {
        window.heap.track("Reserved", {
          "Item ID": data.rents[i].dress_id,
        });
      }
    }
  };

  const convertPrice = (total, discount) => {
    let price = Number(total);
    if(discount > 0){
        price = price * (Number(discount) / 100)
        return Math.abs(price - Number(total));
    }
    return price;
  }

  const onSubmit = () => {
    if (Lodash.isEmpty(defaultValues.firstName) || Lodash.isEmpty(defaultValues.lastName)) {
      setErrorInput(true);
      topRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }
    if (methodSend === null && location.state?.type === 'RENTA') {
      setErrorMs(true);
      window.document.getElementById('methodSend').scrollIntoView({ behavior: "smooth" });
      return;
    }
    if(methodSend === "2" && (defaultValues.address === '' || defaultValues.colonia === '' || defaultValues.phone.length < 10)) {
      setErrorInput(true);
      window.document.getElementById('adressSection').scrollIntoView({ behavior: "smooth" });
      return;
    }
    if(name === ''){
      setErrorInput(true);
      window.document.getElementById('cardHolder').scrollIntoView({ behavior: "smooth" });
      return;
    }
    setErrorInput(false);
    topRef.current.scrollIntoView({ behavior: "smooth" });
    modal.open(<ModalWaiting />, () => {}, "BackdropContainerCenter");
    const cardElement = elements.getElement(CardNumberElement);
    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { name },
      })
      .then((res) => {
        if (res.error) {
          setError(true);
        } else {
          setError(false);
        }
        if (res.paymentMethod.id) {
          let types = [];
          if (location.state) {
            types = location.state.types;
          }
          let send = {
            send_method: "pick_up",
          };
          if (methodSend === "2") {
            send = {
              send_method: "delivery",
              send_type: typeSent === "1" ? "free" : "express",
              same_data: checked,
              address: {
                name: defaultValues.firstName,
                lastname: defaultValues.lastName,
                phone: defaultValues.phone,
                address_num_ext: defaultValues.address,
                interior: defaultValues.interior,
                city_id: defaultValues.city,
                postal_code: defaultValues.codePostal,
                colonia: defaultValues.colonia,
              },
            };
          }
          if (validCoupon) {
            send = {
              ...send,
              promo_code: coupon,
            };
          }

          send = {
            ...send,
            isReserve
          }

          const data = {
            reservations: [
              ...cart
                .filter((c) => c.type === "APARTADO" && (types.includes("AMBOS") || types.includes("APARTADO")))
                .map((c) => ({
                  fecha_inicio: isReserve ? moment(c.reservation?.start_date).format("YYYY/MM/DD") : moment(c.date[0]).format("YYYY/MM/DD"),
                  fecha_fin: isReserve ? moment(c.reservation?.end_date).format("YYYY/MM/DD") : moment(c.date[1]).format("YYYY/MM/DD"),
                  precio: convertPrice(Math.abs(c.price / 2), c.discount),
                  dress_id: c.product.id,
                  quantity: 1
                })),
            ],
            rents: [
              ...cart
                .filter((c) => c.type === "RENTA" && (types?.includes("AMBOS") || types?.includes("RENTA")))
                .map((c) => ({
                  fecha_inicio: isReserve ? moment(c.reservation?.start_date).format("YYYY/MM/DD") : moment(c.date[0]).format("YYYY/MM/DD"),
                  fecha_fin: isReserve ? moment(c.reservation?.end_date).format("YYYY/MM/DD") : moment(c.date[1]).format("YYYY/MM/DD"),
                  precio: convertPrice(c.price, c.discount),
                  dress_id: c.product.id,
                  reservation_id: c.reservation
                    ? c.reservation.id
                    : null,
                  second_dress:
                    location.state?.optionB &&
                    location.state?.optionB.find((op) => op.id === c.id)
                      ? location.state?.optionB.find((op) => op.id === c.id)
                          .talla
                      : null,
                  quantity: 1
                })),
            ],
            sales: [
              ...cart
                .filter((c) => c.type === "VENTA" && (types?.includes("AMBOS") || types?.includes("VENTA")))
                .map((c) => ({
                  precio: convertPrice(c.price, c.discount),
                  product_id: c.product.id,
                  type: c.product_type,
                  quantity: c.quantity
                })),
            ],
            ...send,
            checked_terminos_condiciones: 1,
            paymentMethodId: res.paymentMethod.id,
            paymentMethod: "CC",
            name_user: defaultValues.firstName,
            lastname_user: defaultValues.lastName,
            sede: sucursal === "1" ? "Sucursal Guadalajara" : "Sucursal León",
            schedule: scheduleSelected,
            shipping: methodSend === "2" ? (typeSent === "1" ? 350 : 500) : 0,
          };
          // return data
          api
            .post(`/api/payment`, data)
            .then(async (rs) => {
              const ids = cart
                .filter((c) => c.type === "APARTADO" && (types?.includes("AMBOS") || types?.includes("APARTADO")))
                .map((c) => c.id);
              await api.post("/api/remove-cart", { ids: ids.join(",") });
              modal.close();
              if (rs.status === 200) {
                dispatch(removeAllProduct());
                setCode(rs.data.data?.code_entrega);
                onSubmitHeap(data);
                setConfirmPayment(true);
              } else setError(true);
            })
            .catch((error) => {
              setErrorDescription(error.response?.data?.message)
              modal.close();
              setError(true);
            });
        }
      })
      .catch((e) => {
        console.log('Error:', e)
        modal.close()
      });
  };

  const setValuesProfile = () => {
    let addressData = {};
    if (user.address && user.address.length > 0) {
      addressData = {
        codePostal: user.address[0]?.postal_code,
        interior: user.address[0]?.num_int,
        address: user.address[0]?.address_num_ext,
        city: user.address[0]?.city_id,
        state: user.address[0]?.city?.state_id,
        colonia: user.address[0]?.colonia,
      };
    }

    setDefaultValues({
      ...defaultValues,
      phone: user.phone,
      ...addressData,
    });

    const first = user.phone.substring(0, 2)
    const second = user.phone.substring(2, 6)
    const third = user.phone.substring(6, 10)
    setPhone(`${first} ${second} ${third}`)
  };

  useEffect(() => {
    if (checked) {
      setValuesProfile();
    }
  }, [checked]);

  const handlerChange = ({ target: { name, value } }) => {
    if (name === "phone") {
      const phoneString = value.replace(/\D/g,'')
      if (phoneString.length <= 10) {
        setDefaultValues({
          ...defaultValues,
          [name]: phoneString
        })
        const first = phoneString.substring(0, 2)
        const second = phoneString.substring(2, 6)
        const third = phoneString.substring(6, 10)
        if (phoneString.length > 6) setPhone(`${first} ${second} ${third}`)
        else if (phoneString.length > 2) setPhone(`${first} ${second}`)
        else if (phoneString.length > 0) setPhone(`${first}`)
        else setPhone('')
      }
    } else {
      setDefaultValues({ ...defaultValues, [name]: value });
    }
  }

  useEffect(() => {
    setTotalPaypal(format_prices().total_discount);
  }, [validCoupon, methodPayment, typeSent, methodSend]);

  const createOrder = (data, actions) => {
    if (Lodash.isEmpty(defaultValues.firstName) || Lodash.isEmpty(defaultValues.lastName)) {
      setErrorInput(true);
      topRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }
    if (methodSend === null && location.state?.type === 'RENTA') {
      setErrorMs(true);
      window.document.getElementById('methodSend').scrollIntoView({ behavior: "smooth" });
      return;
    }
    if(methodSend === "2" && (defaultValues.address === '' || defaultValues.colonia === '')){
      setErrorInput(true);
      window.document.getElementById('adressSection').scrollIntoView({ behavior: "smooth" });
      return;
    }
    setErrorInput(false);
    return api
      .post("/api/create-order", { total: totalPaypal })
      .then((response) => response.data.id);
  };

  const onApprove = (data, actions) => {
    return api.post(`/api/orders-capture/${data.orderID}`).then(({ data }) => {
      const transaction = data.purchase_units[0].payments.captures[0];

      topRef.current.scrollIntoView({ behavior: "smooth" });
      modal.open(<ModalWaiting />, () => {}, "BackdropContainerCenter");

      let types = [];
      if (location.state) {
        types = location.state.types;
      }
      let send = {
        send_method: "pick_up",
        isReserve,
      };
      if (methodSend === "2") {
        send = {
          send_method: "delivery",
          send_type: typeSent === "1" ? "free" : "express",
          same_data: checked,
          address: {
            name: defaultValues.firstName,
            lastname: defaultValues.lastName,
            phone: defaultValues.phone,
            address_num_ext: `${defaultValues.address} ${defaultValues.interior}`,
            city_id: defaultValues.city,
            postal_code: defaultValues.codePostal,
            colonia: defaultValues.colonia,
          },
        };
      }
      if (validCoupon) {
        send = {
          ...send,
          promo_code: coupon,
        };
      }

      send = {
        ...send,
        isReserve
      }

      const dataSend = {
        reservations: [
          ...cart
            .filter((c) => c.type === "APARTADO" && (types?.includes("AMBOS") || types?.includes("APARTADO")))
            .map((c) => ({
              fecha_inicio: isReserve
                ? moment(cart[0].reservation.start_date).format("YYYY/MM/DD")
                : moment(c.date[0]).format("YYYY/MM/DD"),
              fecha_fin: isReserve
                ? moment(cart[0].reservation.end_date).format("YYYY/MM/DD")
                : moment(c.date[1]).format("YYYY/MM/DD"),
              precio: convertPrice(isReserve ? Math.abs(c.price / 2) : Math.abs(c.final_price / 2), c.discount),
              dress_id: c.product.id,
              quantity: 1
            })),
        ],
        rents: [
          ...cart
            .filter((c) => c.type === "RENTA" && (types?.includes("AMBOS") || types?.includes("RENTA")))
            .map((c) => ({
                fecha_inicio: isReserve
                    ? moment(cart[0].reservation.start_date).format("YYYY/MM/DD")
                    : moment(c.date[0]).format("YYYY/MM/DD"),
                fecha_fin: isReserve
                    ? moment(cart[0].reservation.end_date).format("YYYY/MM/DD")
                    : moment(c.date[1]).format("YYYY/MM/DD"),
                precio: convertPrice(isReserve ? Math.abs(c.final_price / 2) : c.final_price, c.discount),
                dress_id: c.product.id,
                reservation_id: cart[0].reservation
                    ? cart[0].reservation.id
                    : null,
                second_dress:
                    location.state?.optionB &&
                    location.state?.optionB.find((op) => op.id === c.id)
                    ? location.state?.optionB.find((op) => op.id === c.id).talla
                    : null,
                quantity: 1
            })),
        ],
        sales: [
          ...cart
            .filter((c) => c.type === "VENTA" && (types?.includes("AMBOS") || types?.includes("VENTA")))
            .map((c) => ({
              precio: convertPrice(c.price, c.discount),
              product_id: c.product.id,
              type: c.product_type,
              quantity: c.quantity
            })),
        ],
        ...send,
        checked_terminos_condiciones: 1,
        paymentMethodId: transaction.id,
        paymentMethod: "paypal",
        idPaypal: transaction.id,
        name_user: defaultValues.firstName,
        lastname_user: defaultValues.lastName,
        sede: sucursal === "1" ? "Sucursal Guadalajara" : "Sucursal León",
        schedule: scheduleSelected,
        shipping: methodSend === "2" ? (typeSent === "1" ? 350 : 500) : 0,
      };
      // return dataSend
      api
        .post(`/api/payment`, dataSend)
        .then(async (rs) => {
          const ids = cart
            .filter((c) => c.type === "APARTADO" && (types?.includes("AMBOS") || types?.includes("APARTADO")))
            .map((c) => c.id);
          await api.post("/api/remove-cart", { ids: ids.join(",") });
          modal.close();
          if (rs.status === 200) {
            dispatch(removeAllProduct());
            setCode(rs.data.data?.code_entrega);
            onSubmitHeap(dataSend);
            setConfirmPayment(true);
          } else setError(true);
        })
        .catch((erro) => {
          modal.close();
          setError(true);
        });
    });
  };

  const dateUser = useSelector((store) => store.login);

  useEffect(() => {
    if(dateUser.user){
      setDefaultValues({
        ...defaultValues,
        firstName: dateUser.user.name,
        lastName: dateUser.user.lastname
      })
    }
  },[])

  useEffect(() => {
    if(error || errorInput){
      window.document.getElementsByClassName('')
    }
  },[error, errorInput])

  const [can_delivery, setCanDelivery] = useState(false);

  const canDelivery = () => {
    api.post('/api/can-delivery', {
      "dress_id": cart[0].id,
      "start_date": moment(cart[0].date[0]).format('YYYY-MM-DD'),
      "end_date": moment(cart[0].date[1]).format('YYYY-MM-DD')
    }).then(resp => {
      if(resp.data){
        setCanDelivery(!resp.data.can_delivery);
      }
    })
  }

  // useEffect(() => {
  //   if(cart.length > 0){
  //     canDelivery();
  //   }
  // },[cart])

  return (
    <>
      {width <= 1024 ? (
        <div ref={topRef}>
          <section>
            <NavBarMobile />
          </section>
          {confirmPayment ? (
            <section className={styles.container} style={{ marginTop: 16 }}>
              <Title styles={styles.titleConfirm}>
                CONFIRMACIÓN DE {location?.state?.type}
              </Title>
            </section>
          ) : (
            <section className={styles.container} style={{ marginTop: 16 }}>
              <Title>Bolsa de compras</Title>
              <Breadcrumb items={breadcrumb} />
            </section>
          )}
          {!confirmPayment && (
            <CountDown cart={cart} type={location.state?.type} />
          )}
          <ErrorMessage display={error} setError={setError} textDescription={errorDescription} />
          <SuccessMessage display={confirmPayment} />
          <section className={styles.container} >
            {!confirmPayment && (
              <div className={styles.inputGroup} style={{ marginTop: 16 }}>
                <LabelInput label="Nombre completo*" id="methodPayment" />
                <Input
                  styles={`${styles.styleInput} ${
                    errorInput && Lodash.isEmpty(defaultValues.firstName)
                      ? styles.styleInputError
                      : ""
                  }`}
                  placeholder="Nombre"
                  name="firstName"
                  value={defaultValues.firstName}
                  onChange={handlerChange}
                />
                <Input
                  styles={`${styles.styleInput} ${
                    errorInput && Lodash.isEmpty(defaultValues.lastName)
                      ? styles.styleInputError
                      : ""
                  }`}
                  placeholder="Apellido(s)"
                  name="lastName"
                  value={defaultValues.lastName}
                  style={{ marginTop: 12 }}
                  onChange={handlerChange}
                />
              </div>
            )}
            {!confirmPayment && (
              <div style={{ marginBottom: 28, marginTop: 16 }}>
                <LabelInput label="MÉTODO DE PAGO" />
                <RadioCustom
                  label="Pago con tarjeta"
                  name="paymentMethod"
                  checked={methodPayment === "1"}
                  value="1"
                  onChange={(e) => setMethodPayment(e.target.value)}
                />
                <RadioCustom
                  label="Pago con "
                  iconPaypal={true}
                  name="paymentMethod"
                  checked={methodPayment === "2"}
                  value="2"
                  onChange={(e) => setMethodPayment(e.target.value)}
                />
              </div>
            )}
            {methodPayment === "1" && (
              <>
                {!confirmPayment && (
                  <div className={styles.contianForm}>
                    <div className={styles.inputGroup}>
                      <LabelInput label="Titular de tarjeta" id="cardHolder" />
                      <Input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Ingresar nombre"
                        styles={`${styles.styleInput} ${
                          errorInput && name === ""
                            ? styles.styleInputError
                            : ""
                        }`}
                      />
                      <TextSpanSmall>
                        Ingresar como viene en tarjeta
                      </TextSpanSmall>
                    </div>
                    <div className={styles.inputGroup}>
                      <LabelInput label="Número de tarjeta" />
                      <div className={styles.styleInput}>
                        <CardNumberElement
                          className={styles.inputStripe}
                          options={{
                            ...options,
                            placeholder: "Número de tarjeta",
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.inlineInputs} style={{alignItems: 'flex-end'}}>
                      <div className={styles.inputGroupMiddle}>
                        <LabelInput label="Fecha de vencimiento" />
                        <div className={styles.styleInputMiddle}>
                          <CardExpiryElement
                            className={styles.inputStripe}
                            options={{ ...options, placeholder: "MM/AA" }}
                          />
                        </div>
                      </div>
                      <div className={styles.inputGroupMiddle}>
                        <LabelInput label="CVV" />
                        <div className={styles.styleInputMiddle}>
                          <CardCvcElement
                            className={styles.inputStripe}
                            options={{ ...options, placeholder: "CVV" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        height: 102,
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: 16,
                        marginBottom: 16,
                      }}
                    >
                      <img
                        src={StripeBadge}
                        alt="stripe"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {confirmPayment && (
              <div>
                <TextDescription>
                  Se le ha enviado un comprobante de pago a su correo.
                </TextDescription>
                {location?.state?.type && location?.state?.type !== "RENTA" && (
                  <TextDescription style={{ marginBottom: 20 }}>
                    Esta prenda quedará apartada por 5 días. Una vez se cumpla
                    este plazo, deberá realizar el pago del monto restante para
                    completar el proceso de renta.{" "}
                  </TextDescription>
                )}
                {location?.state?.type && location?.state?.type !== "RENTA" ? (
                  <Button
                    outline
                    label="Consultar mis apartados"
                    onClick={() => {
                      navigate("/perfil");
                      handlerRemove();
                    }}
                  />
                ) : (
                  <>
                    <Button
                      outline
                      label="Consultar mis rentas activas"
                      onClick={() => {
                        navigate("/perfil");
                        handlerRemove();
                      }}
                    />

                    <TextDescription style={{ marginTop: 20 }}>
                      Código para entrega: {code}
                    </TextDescription>
                  </>
                )}
              </div>
            )}

            {(location.state.type === "RENTA" ||
              cart.filter((c) => c.type === "RENTA").length === cart.length) &&
              !confirmPayment && (
                <>
                  <div>
                    <LabelInput label="MÉTODO DE ENVÍO" id="methodSend" />
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <RadioCustom
                        label="Recoger en tienda"
                        name="envio"
                        checked={methodSend === "1"}
                        value="1"
                        onChange={(e) => setMethodSend(e.target.value)}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <RadioCustom
                        label="Envío a domicilio"
                        name="envio"
                        checked={methodSend === "2"}
                        value="2"
                        onChange={(e) => setMethodSend(e.target.value)}
                      />
                      {can_delivery &&
                        <TextDescription
                          style={{
                            fontSize: 11,
                            marginLeft: 8,
                          }}
                        >
                          El envío a domicilio podría demorarse en las fechas seleccionadas
                        </TextDescription>
                      }
                    </div>

                    {errorMs && methodSend === null && (
                      <TextDescription
                        style={{ color: "#DF585F", marginTop: 10 }}
                      >
                        Debe seleccionar un opción
                      </TextDescription>
                    )}
                  </div>

                  {methodSend === "1" && (
                    <>
                      <div style={{ marginTop: 33, marginBottom: 30 }}>
                        <LabelInput label="SELECCIONA LA SUCURSAL" />
                        <RadioCustom
                          label="Sucursal Guadalajara"
                          customFormControl={styles.formControl}
                          description="Av Pablo Neruda 2273, Providencia, 44630 Guadalajara, Jal."
                          name="sucursal"
                          checked={sucursal === "1"}
                          value="1"
                          onChange={(e) => setSucurlar(e.target.value)}
                        />
                        <RadioCustom
                          label="Sucursal León"
                          customFormControl={styles.formControl}
                          description="Av. Guanajuato 1109, Local 20, Jardines del Moral, 37160 León, Gto."
                          name="sucursal"
                          checked={sucursal === "2"}
                          value="2"
                          onChange={(e) => setSucurlar(e.target.value)}
                        />
                      </div>
                      { schedules.length > 0 && sucursal !== null && (
                        <div style={{ marginTop: 33, marginBottom: 30 }}>
                          <LabelInput label="ELIGE EL HORARIO DE RECOLECCIÓN" />
                          { schedules.map((schedule) => (
                            <RadioCustom
                              label={`${schedule.day} de ${schedule.start_hour} hrs a ${schedule.end_hour} hrs`}
                              customFormControl={styles.formControl}
                              name="schedule"
                              checked={scheduleSelected === schedule.id}
                              value={schedule.id}
                              onChange={(e) => setScheduleSelected(e.target.value)}
                            />
                          ))}
                        </div>
                      )}
                    </>
                  )}

                  {methodSend === "2" && (
                    <div>
                      <div style={{ marginTop: 33 }}>
                        <LabelInput label="TIPO DE ENVÍO" />
                        {
                          moment().isSameOrBefore(lastDayOfWeek) && unavailableDate &&
                          <TextDescription style={{ fontSize: '12px', color: '#cd595a' }}>Los envíos para rentas de este fin ya salieron el día de hoy, en caso de querer continuar con tu pedido te recomendamos seleccionar el servicio de envío express para evitar demoras</TextDescription>
                        }
                        <RadioCustom
                          label="Envío estándar $350 2 a 4 días hábiles"
                          name="type-envio"
                          checked={typeSent === "1"}
                          value="1"
                          onChange={(e) => setTypeSent(e.target.value)}
                        />
                        <RadioCustom
                          label="Envío express $500 1 a 2 días hábiles"
                          name="type-envio"
                          checked={typeSent === "2"}
                          value="2"
                          onChange={(e) => setTypeSent(e.target.value)}
                        />
                      </div>

                      <div style={{ marginTop: 33, maxWidth: "100%" }}>
                        <LabelInput label="DATOS DE ENVÍO" />
                        <Checked
                          label="Usar los mismos datos de Mi Perfil"
                          onCheck={setChecked}
                          checked={checked}
                        />
                        <div style={{ marginTop: 12, maxWidth: "100%" }} id="adressSection">
                          <LabelInput label="Dirección" />
                          <Input
                            styles={`${styles.styleInput} ${
                              errorInput && defaultValues.address === ""
                                ? styles.styleInputError
                                : ""
                            }`}
                            placeholder="Calle y número exterior"
                            name="address"
                            value={defaultValues.address}
                            onChange={handlerChange}
                          />
                          <Input
                            styles={`${styles.styleInput}`}
                            placeholder="Número interior (opcional)"
                            name="interior"
                            value={defaultValues.interior}
                            style={{ marginTop: 12 }}
                            onChange={handlerChange}
                          />
                          <div
                            className={styles.inlineInputs}
                            style={{ marginTop: 12, maxWidth: "92%" }}
                          >
                            <Input
                              styles={`${styles.styleInput} ${
                              errorInput && defaultValues.codePostal === ""
                                ? styles.styleInputError
                                : ""
                            }`}
                              placeholder="Código postal"
                              name="codePostal"
                              value={defaultValues.codePostal}
                              onChange={handlerChange}
                              style={{ width: "48%" }}
                            />
                            <select
                              className={styles.styleInput}
                              style={{ width: "48%" }}
                              name="state"
                              disabled={true}
                              defaultValue={defaultValues.state}
                              onChange={handlerChange}
                            >
                              <option disabled selected hidden>Estado</option>
                              {states.map((state) => (
                                <option
                                  key={state.id}
                                  selected={defaultValues.state === state.id}
                                  value={state.id}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            className={styles.inlineInputs}
                            style={{ marginTop: 12, maxWidth: "92%" }}
                          >
                            <select
                              className={styles.styleInput}
                              style={{ width: "48%" }}
                              name="city"
                              defaultValue={defaultValues.city}
                              onChange={handlerChange}
                              disabled={true}
                            >
                              <option disabled selected hidden>Ciudad</option>
                              {cities.map((city) => (
                                <option
                                  key={city.id}
                                  selected={defaultValues.city === city.id}
                                  value={city.id}
                                >
                                  {city.name}
                                </option>
                              ))}
                            </select>
                            <select 
                              name="colonia" 
                              id="colonia"
                              style={{width: '48%'}}
                              className={`${styles.styleInput} ${
                                errorInput && defaultValues.colonia === ""
                                  ? styles.styleInputError
                                  : ""
                              }`}
                              onChange={handlerChange}
                            >
                              <option disabled selected hidden>Colonia</option>
                              {suburbs.map((suburb) => (
                                <option key={suburb} selected={defaultValues.colonia ? defaultValues.colonia === suburb : false } value={suburb}>{suburb}</option>
                              ))}
                            </select>
                          </div>

                          <Input
                            styles={`${styles.styleInput} ${
                              errorInput && defaultValues.phone.length < 10
                                ? styles.styleInputError
                                : ""
                            }`}
                            type="text"
                            placeholder="Teléfono de contacto"
                            name="phone"
                            value={defaultValues.phone}
                            onChange={handlerChange}
                            style={{ marginTop: 12 }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            <div className={styles.containerCart}>
              <button
                type="button"
                className={styles.collapsible}
                onClick={toggleDisplay}
              >
                <span>RESUMEN</span>
                {isDisplay ? (
                  <Icon
                    icon="akar-icons:minus"
                    color="#d6a191"
                    width="14"
                    height="14"
                  />
                ) : (
                  <Icon
                    icon="akar-icons:plus"
                    color="#D6A191"
                    width="14"
                    height="14"
                  />
                )}
              </button>
              <div
                className={`${styles.container} ${
                  isDisplay ? styles.display : styles.hidden
                }`}
              >
                <section className={styles.flexColumCenterContent}>
                  {cart &&
                    cart.length > 0 &&
                    cart.map((row) => {
                      if (
                        location?.state?.types?.includes(row.type) ||
                        location?.state?.types?.includes("AMBOS")
                      )
                        return (
                          <Product
                            data={row}
                            disabled
                            key={Math.random()}
                            isReserve={location.state?.isReserve}
                            calendarRange={
                              cart[0].reservation
                                ? [
                                    cart[0].reservation.start_date,
                                    cart[0].reservation.end_date,
                                  ]
                                : row.date
                            }
                            sizeValue={row.size}
                            colorValue={
                              cart[0].reservation
                                ? cart[0].product?.color?.code
                                : row.color
                            }
                            colorName={row.product?.color.name}
                          />
                        );
                      else return null;
                    })}
                </section>
              </div>
            </div>
            {(location.state?.type === 'RENTA' && !confirmPayment) &&
            <div>
              <ErrorMessage
                display={couponError}
                setError={setCouponError}
                textDescription={couponErrorDescription}
              />
              <InputCoupon
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                onClick={() => validateCoupon()}
              />
            </div>
            }
            <div className={styles.containPrices}>
              <div className={styles.inlineInputs}>
                <TextDescription>Subtotal</TextDescription>
                <TextDescription>
                    { format_currency(subtotal) }
                </TextDescription>
              </div>
              <div className={styles.inlineInputs}>
                <TextDescription>Descuento</TextDescription>
                <TextDescription style={{ color: "#DF585F" }}>
                    { format_currency(totalDiscount) }
                </TextDescription>
              </div>
              <div className={styles.inlineInputs}>
                <TextDescription>Envío</TextDescription>
                <TextDescription>
                    { format_currency(shippingCost) }
                </TextDescription>
              </div>
              <div className={styles.inlineInputs}>
                <TextDescription>Costo de reserva</TextDescription>
                <TextDescription>
                  {format_currency(0)}
                </TextDescription>
              </div>

              <div className={styles.inlineInputs} style={{ marginTop: 16 }}>
                <TextDescription>TOTAL</TextDescription>
                <TextDescription>
                    { format_currency(total) }
                </TextDescription>
              </div>
            </div>

            {!confirmPayment && (
              <>
                {methodPayment === "1" && (
                  <Button label="Realizar pago" onClick={() => onSubmit()} />
                )}
                {methodPayment === "2" && (
                  <PayPalScriptProvider options={initialOptionsPaypal}>
                    <PayPalButtons
                      forceReRender={[
                        totalPaypal,
                        methodSend,
                        typeSent,
                        defaultValues,
                      ]}
                      style={{ layout: "horizontal", height: 40 }}
                      createOrder={createOrder}
                      onApprove={onApprove}
                    />
                  </PayPalScriptProvider>
                )}
                <div style={{ marginTop: 16, marginBottom: 20 }}>
                  <TextDescription>
                    Al continuar estará aceptando las{" "}
                    <TextLinking
                      onClick={() =>
                        window.open(
                          `${process.env.PUBLIC_URL}/terminos-condiciones`
                        )
                      }
                    >
                      Condiciones de{" "}
                      {cart && cart.length > 0
                        ? cart[0].type.toLowerCase()
                        : ""}
                    </TextLinking>
                  </TextDescription>
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div ref={topRef}>
          <section style={{ position: 'sticky', top: 0, boxShadow: '0px 0px 6px #e0e0e0' }}>
            <NavbarDesktop />
          </section>
          <div style={{ padding: "0px 64px" }}>
            {confirmPayment ? (
              <section className={styles.container} style={{ marginTop: 16 }}>
                <Title styles={styles.titleConfirm}>
                  CONFIRMACIÓN DE {location?.state?.type}
                </Title>
              </section>
            ) : (
              <section className={styles.container} style={{ marginTop: 16 }}>
                <Title>Bolsa de compras</Title>
                <Breadcrumb items={breadcrumb} />
              </section>
            )}
            {!confirmPayment && (
              <CountDown cart={cart} type={location.state?.type} />
            )}
            <ErrorMessage
              display={error && !confirmPayment}
              setError={setError}
              textDescription={errorDescription}
            />
            <SuccessMessage display={confirmPayment} />
            <section className={styles.container} style={{ marginTop: 34 }}>
              <div className={styles.inlineContainer} >
                <div style={{ width: "45%" }}>
                  {!confirmPayment && (
                    <div className={styles.inputGroup}>
                      <LabelInput label="Nombre completo*" id="methodPayment" />
                      <Input
                        styles={`${styles.styleInput} ${
                          errorInput && Lodash.isEmpty(defaultValues.firstName)
                            ? styles.styleInputError
                            : ""
                        }`}
                        placeholder="Nombre"
                        name="firstName"
                        value={defaultValues.firstName}
                        onChange={handlerChange}
                      />
                      <Input
                        styles={`${styles.styleInput} ${
                          errorInput && Lodash.isEmpty(defaultValues.lastName)
                            ? styles.styleInputError
                            : ""
                        }`}
                        placeholder="Apellido(s)"
                        name="lastName"
                        value={defaultValues.lastName}
                        style={{ marginTop: 12 }}
                        onChange={handlerChange}
                      />
                    </div>
                  )}
                  {!confirmPayment && (
                    <div style={{ marginBottom: 28 }}>
                      <LabelInput label="MÉTODO DE PAGO" />
                      <RadioCustom
                        label="Pago con tarjeta"
                        name="paymentMethod"
                        checked={methodPayment === "1"}
                        value="1"
                        onChange={(e) => setMethodPayment(e.target.value)}
                      />
                      <RadioCustom
                        label="Pago con "
                        iconPaypal={true}
                        name="paymentMethod"
                        checked={methodPayment === "2"}
                        value="2"
                        onChange={(e) => setMethodPayment(e.target.value)}
                      />
                    </div>
                  )}
                  {methodPayment === "1" && (
                    <>
                      {!confirmPayment && (
                        <div className={styles.contianForm}>
                          <Title style={{ fontSize: 12, color: "#353535" }}>
                            DATOS DE PAGO CON TARJETA
                          </Title>
                          <div className={styles.inputGroup}>
                            <LabelInput label="Titular de tarjeta" id="cardHolder" />
                            <Input
                              type="text"
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Ingresar nombre"
                              styles={`${styles.styleInput} ${
                                errorInput && name === ""
                                  ? styles.styleInputError
                                  : ""
                              }`}
                            />
                            <TextSpanSmall>
                              Ingresar como viene en tarjeta
                            </TextSpanSmall>
                          </div>
                          <div className={styles.inputGroup}>
                            <LabelInput label="Número de tarjeta" />
                            <div className={styles.styleInput}>
                              <CardNumberElement
                                className={styles.inputStripe}
                                options={{
                                  ...options,
                                  placeholder: "Número de tarjeta",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className={styles.inlineInputs}
                            style={{ maxWidth: "100%" }}
                          >
                            <div className={styles.inputGroupMiddle}>
                              <LabelInput label="Fecha de vencimiento" />
                              <div className={styles.styleInputMiddle}>
                                <CardExpiryElement
                                  className={styles.inputStripe}
                                  options={{ ...options, placeholder: "MM/AA" }}
                                />
                              </div>
                            </div>
                            <div className={styles.inputGroupMiddle}>
                              <LabelInput label="CVV" />
                              <div className={styles.styleInputMiddle}>
                                <CardCvcElement
                                  className={styles.inputStripe}
                                  options={{ ...options, placeholder: "CVV" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              height: 102,
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-start",
                              marginTop: 16,
                              marginBottom: 16,
                            }}
                          >
                            <img
                              src={StripeBadge}
                              alt="stripe"
                              style={{ height: "100%", objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {confirmPayment && (
                    <div>
                      <TextDescription>
                        Se le ha enviado un comprobante de pago a su correo.
                      </TextDescription>
                      {location?.state?.type &&
                        location?.state?.type !== "RENTA" && (
                          <TextDescription style={{ marginBottom: 20 }}>
                            Esta prenda quedará apartada por 5 días. Una vez se
                            cumpla este plazo, deberá realizar el pago del monto
                            restante para completar el proceso de renta.{" "}
                          </TextDescription>
                        )}
                      {location?.state?.type &&
                      location?.state?.type !== "RENTA" ? (
                        <Button
                          outline
                          label="Consultar mis apartados"
                          onClick={() => {
                            navigate("/perfil");
                            handlerRemove();
                          }}
                        />
                      ) : (
                        <>
                          <Button
                            outline
                            label="Consultar mis rentas activas"
                            onClick={() => {
                              navigate("/perfil");
                              handlerRemove();
                            }}
                          />

                          <TextDescription style={{ marginTop: 20 }}>
                            Código para entrega: {code}
                          </TextDescription>
                        </>
                      )}
                    </div>
                  )}
                  { (location.state?.types?.includes("RENTA") || location.state?.types?.includes("VENTA")) && !confirmPayment && (
                      <>
                        <div
                          style={
                            methodSend === null ? { marginBottom: 30 } : {}
                          }
                        >
                          <LabelInput label="MÉTODO DE ENVÍO" id="methodSend"/>
                          <div
                            style={{ display: "flex", alignItems: "flex-end" }}
                          >
                            <RadioCustom
                              label="Recoger en tienda"
                              name="envio"
                              checked={methodSend === "1"}
                              value="1"
                              onChange={(e) => setMethodSend(e.target.value)}
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <RadioCustom
                              label="Envío a domicilio"
                              name="envio"
                              checked={methodSend === "2"}
                              value="2"
                              onChange={(e) => setMethodSend(e.target.value)}
                            />
                            {can_delivery &&
                              <TextDescription
                                style={{
                                  fontSize: 11,
                                  marginLeft: 8,
                                }}
                              >
                                El envío a domicilio podría demorarse en las fechas seleccionadas
                              </TextDescription>
                            }
                          </div>

                          {errorMs && methodSend === null && (
                            <TextDescription
                              style={{ color: "#DF585F", marginTop: 10 }}
                            >
                              Debe seleccionar un opción
                            </TextDescription>
                          )}
                        </div>

                        { methodSend === "1" && (
                          <>
                            <div style={{ marginTop: 33, marginBottom: 30 }}>
                              <LabelInput label="SELECCIONA LA SUCURSAL" />
                              <RadioCustom
                                label="Sucursal Guadalajara"
                                customFormControl={styles.formControl}
                                description="Av Pablo Neruda 2273, Providencia, 44630 Guadalajara, Jal."
                                name="sucursal"
                                checked={sucursal === "1"}
                                value="1"
                                onChange={(e) => setSucurlar(e.target.value)}
                              />
                              <RadioCustom
                                label="Sucursal León"
                                customFormControl={styles.formControl}
                                description=" Av. Guanajuato 1109, Local 20, Jardines del Moral, 37160 León, Gto."
                                name="sucursal"
                                checked={sucursal === "2"}
                                value="2"
                                onChange={(e) => setSucurlar(e.target.value)}
                              />
                            </div>
                            { schedules.length > 0 && sucursal !== null && (
                              <div style={{ marginTop: 33, marginBottom: 30 }}>
                                <LabelInput label="ELIGE EL HORARIO DE RECOLECCIÓN" />
                                { schedules.map((schedule) => (
                                  <RadioCustom
                                    label={`${schedule.day} de ${schedule.start_hour} hrs a ${schedule.end_hour} hrs`}
                                    customFormControl={styles.formControl}
                                    name="schedule"
                                    checked={scheduleSelected === schedule.id}
                                    value={schedule.id}
                                    onChange={(e) => setScheduleSelected(parseInt(e.target.value))}
                                  />
                                ))}
                              </div>
                            )}
                          </>
                        )}

                        {methodSend === "2" && (
                          <>
                            <div style={{ marginTop: 33 }}>
                              <LabelInput label="TIPO DE ENVÍO" />
                              {
                                moment().isSameOrBefore(lastDayOfWeek) && unavailableDate &&
                                <TextDescription style={{ fontSize: '14px', color: '#cd595a', fontWeight: '600' }}>Los envíos para rentas de este fin ya salieron el día de hoy, en caso de querer continuar con tu pedido te recomendamos seleccionar el servicio de envío express para evitar demoras</TextDescription>
                              }
                              <RadioCustom
                                label="Envío estándar $350 2 a 4 días hábiles"
                                name="type-envio"
                                checked={typeSent === "1"}
                                value="1"
                                onChange={(e) => setTypeSent(e.target.value)}
                              />
                              <RadioCustom
                                label="Envío express $500 1 a 2 días hábiles"
                                name="type-envio"
                                checked={typeSent === "2"}
                                value="2"
                                onChange={(e) => setTypeSent(e.target.value)}
                              />
                            </div>

                            <div style={{ marginTop: 33, marginBottom: 100 }}>
                              <LabelInput label="DATOS DE ENVÍO" />
                              <Checked
                                label="Usar los mismos datos de Mi Perfil"
                                onCheck={setChecked}
                                checked={checked}
                              />
                              <div style={{ marginTop: 12, maxWidth: 462 }} id="adressSection">
                                <LabelInput label="Dirección" />
                                <Input
                                  styles={`${styles.styleInput} ${
                                      errorInput && defaultValues.address === ""
                                        ? styles.styleInputError
                                        : ""
                                    }`}
                                  placeholder="Calle y número exterior"
                                  name="address"
                                  value={defaultValues.address}
                                  onChange={handlerChange}
                                />
                                <Input
                                  styles={`${styles.styleInput}`}
                                  placeholder="Número interior (opcional)"
                                  name="interior"
                                  value={defaultValues.interior}
                                  style={{ marginTop: 12 }}
                                  onChange={handlerChange}
                                />
                                <div
                                  className={styles.inlineInputs}
                                  style={{
                                    marginTop: 12,
                                    maxWidth: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Input
                                    styles={`${styles.styleInput} ${
                                      errorInput && defaultValues.codePostal === ""
                                        ? styles.styleInputError
                                        : ""
                                    }`}
                                    placeholder="Código postal"
                                    name="codePostal"
                                    value={defaultValues.codePostal}
                                    onChange={handlerChange}
                                    style={{ width: "46%" }}
                                  />
                                  <select
                                    className={styles.styleInput}
                                    style={{ width: "46%" }}
                                    name="state"
                                    defaultValue={defaultValues.state}
                                    onChange={handlerChange}
                                    disabled={true}
                                  >
                                    <option disabled selected hidden>Estado</option>
                                    {states.map((state) => (
                                      <option
                                        key={state.id}
                                        selected={
                                          defaultValues.state === state.id
                                        }
                                        value={state.id}
                                      >
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div
                                  className={styles.inlineInputs}
                                  style={{
                                    marginTop: 12,
                                    maxWidth: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <select
                                    className={styles.styleInput}
                                    style={{ width: "48%" }}
                                    name="city"
                                    defaultValue={defaultValues.city}
                                    onChange={handlerChange}
                                    disabled={true}
                                  >
                                    <option disabled selected hidden>Ciudad</option>
                                    {cities.map((city) => (
                                      <option
                                        key={city.id}
                                        selected={
                                          defaultValues.city === city.id
                                        }
                                        value={city.id}
                                      >
                                        {city.name}
                                      </option>
                                    ))}
                                  </select>
                                  <select 
                                    name="colonia" 
                                    id="colonia"
                                    style={{width: '48%'}}
                                    className={`${styles.styleInput} ${
                                      errorInput && defaultValues.colonia === ""
                                        ? styles.styleInputError
                                        : ""
                                    }`}
                                    onChange={handlerChange}
                                  >
                                    <option disabled selected hidden>Colonia</option>
                                    {suburbs.map((suburb) => (
                                      <option key={suburb} selected={defaultValues.colonia ? defaultValues.colonia === suburb : false } value={suburb}>{suburb}</option>
                                    ))}
                                  </select>
                                </div>
                                <Input
                                  styles={`${styles.styleInput} ${
                                    errorInput && defaultValues.phone.length < 10
                                      ? styles.styleInputError
                                      : ""
                                  }`}
                                  type="text"
                                  placeholder="Teléfono de contacto"
                                  name="phone"
                                  value={phone}
                                  onChange={handlerChange}
                                  style={{ marginTop: 12 }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                </div>
                <div style={{ width: "50%" }}>
                  <Title>RESUMEN</Title>
                  <div
                    style={{
                      borderBottom: "1px solid #D1D1D1",
                      marginTop: 9,
                      marginBottom: 23,
                    }}
                  />
                  <section className={styles.flexColumCenterContent}>
                    {cart &&
                      cart.length > 0 &&
                      cart.map((row) => {
                        if (
                          location?.state?.types?.includes(row.type) ||
                          location?.state?.types?.includes("AMBOS")
                        )
                          return (
                            <Product
                              data={row}
                              disabled
                              key={Math.random()}
                              isReserve={location.state?.isReserve}
                              calendarRange={
                                cart[0].reservation
                                  ? [
                                      cart[0].reservation.start_date,
                                      cart[0].reservation.end_date,
                                    ]
                                  : row.date
                              }
                              sizeValue={
                                cart[0].reservation
                                  ? cart[0].product?.size?.description
                                  : row.size[0]
                              }
                              colorValue={
                                cart[0].reservation
                                  ? cart[0].product?.color?.code
                                  : row.color
                              }
                              colorName={row.product?.color?.name}
                            />
                          );
                        else return null;
                      })}
                  </section>
                  <div
                    style={{
                      borderBottom: "1px solid #D1D1D1",
                      marginTop: 25,
                      marginBottom: 47,
                    }}
                  />
                  
                  <div>
                  {(location.state?.type === 'RENTA' && !confirmPayment) &&
                    <div>
                      <ErrorMessage
                        display={couponError}
                        setError={setCouponError}
                        textDescription={couponErrorDescription}
                      />
                      <InputCoupon
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        onClick={() => validateCoupon()}
                      />
                    </div>
                  }
                    <div
                      style={{
                        borderBottom: "1px solid #D1D1D1",
                        marginTop: 9,
                        marginBottom: 15,
                      }}
                    />
                    <div className={styles.containPrices}>
                      <div className={styles.inlineInputs}>
                        <TextDescription>Subtotal</TextDescription>
                        <TextDescription>
                          { format_currency(subtotal) }
                        </TextDescription>
                      </div>
                      <div className={styles.inlineInputs}>
                        <TextDescription>Descuento</TextDescription>
                        <TextDescription style={{ color: "#DF585F" }}>
                            { format_currency(totalDiscount) }
                        </TextDescription>
                      </div>
                      <div className={styles.inlineInputs}>
                        <TextDescription>Envío</TextDescription>
                        <TextDescription>
                            { format_currency(shippingCost) }
                        </TextDescription>
                      </div>
                      <div className={styles.inlineInputs}>
                        <TextDescription>Costo de reserva</TextDescription>
                        <TextDescription>
                            {format_currency(0)}
                        </TextDescription>
                      </div>
                      <div
                        className={styles.inlineInputs}
                        style={{ marginTop: 16 }}
                      >
                        <TextDescription>TOTAL</TextDescription>
                        <TextDescription>
                            { format_currency(total) }
                        </TextDescription>
                      </div>
                      <div
                        style={{
                          borderBottom: "1px solid #D1D1D1",
                          marginTop: 7,
                          marginBottom: 17,
                        }}
                      />

                      {!confirmPayment && (
                        <>
                          {methodPayment === "1" && (
                            <Button
                              label="Realizar pago"
                              onClick={() => onSubmit()}
                            />
                          )}
                          {methodPayment === "2" && (
                            <PayPalScriptProvider
                              options={initialOptionsPaypal}
                            >
                              <PayPalButtons
                                forceReRender={[
                                  totalPaypal,
                                  methodSend,
                                  typeSent,
                                  defaultValues,
                                ]}
                                style={{ layout: "horizontal", height: 40 }}
                                createOrder={createOrder}
                                onApprove={onApprove}
                              />
                            </PayPalScriptProvider>
                          )}
                          <div style={{ marginTop: 16, marginBottom: 20 }}>
                            <TextDescription>
                              Al continuar estará aceptando las{" "}
                              <TextLinking
                                onClick={() =>
                                  window.open(
                                    `${process.env.PUBLIC_URL}/terminos-condiciones`
                                  )
                                }
                              >
                                Condiciones de{" "}
                                {cart && cart.length > 0
                                  ? cart[0].type.toLowerCase()
                                  : ""}
                              </TextLinking>
                            </TextDescription>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
      {width <= 1024 ? <FooterMobile /> : <Footer />}
    </>
  );
};

const Provider = (props) => (
  <Elements stripe={stripePromise}>
    <ReservationPayment />
  </Elements>
);

export default Provider;
