import React from "react";
import Button from "../shared/button";
import { Title, TextDescription, TextLinking } from "../shared/text/Text";
import styles from "./Modal.module.css";
import localStyles from "./modalWelcome.module.css";
import { modal } from "../modalManagment";
import closeModal from "../../assets/icons/closeModal.svg";
import burgerWelcomeMenu from "../../assets/icons/burgerWelcomeMenu.svg";
import user from "../../assets/icons/user.svg";
import heartWelcome from "../../assets/icons/heartWelcome.svg";
import bagWelcome from "../../assets/icons/bagWelcome.svg";
import sliderWelcome1 from "../../assets/icons/sliderWelcome1.svg";
import sliderWelcome2 from "../../assets/icons/sliderWelcome2.svg";
import buttonSliderFull from "../../assets/icons/buttonSliderFull.svg";
import buttonSliderEmpty from "../../assets/icons/buttonSliderEmpty.svg";
import { useState } from "react";
import { Link } from "react-router-dom";

const ModalWelcome = ({ onClick, navigate }) => {
  const [buttonsActive, setButtonsActive] = useState([true, false]);
  return (
    <div className={styles.containerModal}>
      <button className={localStyles.buttonCloseModal} onClick={() => modal.close()}> 
        <img src={closeModal} alt="" />
      </button>
      {buttonsActive[0] ? (
        <section>
          <Title styles={localStyles.modalTitle}>¡QUÉ GUSTO TENERTE!</Title>
          <img src={sliderWelcome1} alt="" className={localStyles.welcome1} />
          

          {/* <div className={localStyles.welcomeItems}>
            <section className={localStyles.textContent}>
              <span className={localStyles.subTitleText}>MENÚ PRINCIPAL</span>
              <img src={burgerWelcomeMenu} alt="" />
            </section>
            <span className={localStyles.textWelcome}>
              Ver más opciones de navegación
            </span>
          </div>

          <div className={localStyles.welcomeItems}>
            <section className={localStyles.textContent}>
              <span className={localStyles.subTitleText}>TU PERFIL</span>
              <img src={user} alt="" />
            </section>
            <span className={localStyles.textWelcome}>
              Mantén tu información actualizada
            </span>
          </div>

          <div className={localStyles.welcomeItems}>
            <section className={localStyles.textContent}>
              <span className={localStyles.subTitleText}>FAVORITOS</span>
              <img src={heartWelcome} alt="" />
            </section>
            <span className={localStyles.textWelcome}>
              Agrega vestidos a favoritos
            </span>
          </div>

          <div className={localStyles.welcomeItems}>
            <section className={localStyles.textContent}>
              <span className={localStyles.subTitleText}>BOLSA DE COMPRAS</span>
              <img src={bagWelcome} alt="" />
            </section>
            <span className={localStyles.textWelcome}>
              Aquí verás los artículos en tu bolsa
            </span>
          </div> */}
        </section>
      ) : (
        <></>
      )}
      {buttonsActive[1] ? (
        <section>
          <Title styles={localStyles.modalTitle}>RENTAR ES MUY FÁCIL</Title>

          <img src={sliderWelcome2} alt="" className={localStyles.welcome2} />

          {/* <div className={localStyles.welcomeItems}>
            <section className={localStyles.textContent}>
              <span className={localStyles.subTitleText}>OCASIÓN</span>
            </section>
            <span className={localStyles.textWelcome}>
              ¿Para qué evento buscas vestido?
            </span>
          </div>

          <div className={localStyles.welcomeItems}>
            <section className={localStyles.textContent}>
              <span className={localStyles.subTitleText}>FECHA</span>
            </section>
            <span className={localStyles.textWelcome}>
              ¿En qué fecha lo necesitas?
            </span>
          </div>

          <div className={localStyles.welcomeItems}>
            <section className={localStyles.textContent}>
              <span className={localStyles.subTitleText}>FAVORITOS</span>
            </section>
            <span className={localStyles.textWelcome}>
              Agrega vestidos a favoritos
            </span>
          </div>

          <div className={localStyles.welcomeItems}>
            <section className={localStyles.textContent}>
              <span className={localStyles.subTitleText}>TALLA</span>
            </section>
            <span className={localStyles.textWelcome}>
              ¿Cuál es tu talla? Checa nuestra guía
            </span>
          </div>
          <div className={localStyles.welcomeItems}>
            <section className={localStyles.textContent}>
              <span className={localStyles.subTitleText}>BÚSQUEDA</span>
            </section>
            <span className={localStyles.textWelcome}>
              Explora nuestro catálogo
            </span>
          </div> */}
        </section>
      ) : (
        <></>
      )}
      <div className={localStyles.slider}>
        <button
          className={localStyles.buttonSlider}
          onClick={() => setButtonsActive([true, false])}
        >
          <img
            src={buttonsActive[0] ? buttonSliderFull : buttonSliderEmpty}
            alt=""
          />
        </button>
        <button
          className={localStyles.buttonSlider}
          onClick={() => setButtonsActive([false, true])}
        >
          <img
            src={buttonsActive[1] ? buttonSliderFull : buttonSliderEmpty}
            alt=""
          />
        </button>
      </div>

      {buttonsActive[0] ? (
        <>
          <div className={styles.containButtons}>
            <Button
              label="Renta ahora"
              outline
              styles={styles.buttonMiddle}
              onClick={() => {
                modal.close()
                navigate("/resultados-vestidos");
              }}
            />
            <Button
              label="Siguiente"
              styles={`${styles.buttonMiddle} ${styles.buttonDanger}`}
              onClick={() => setButtonsActive([false, true])}
            />
          </div>
          <TextLinking>
            <span
              to="/principal"
              className={localStyles.bottomLink}
              onClick={() => modal.close()}
            >
              Saltar introducción
            </span>
          </TextLinking>
        </>
      ) : (
        <></>
      )}
      {buttonsActive[1] ? (
        <>
          <div className={styles.containButtons}>
            <Button 
              label="RENTA AHORA"
              onClick={() => {
                modal.close()
                navigate("/resultados-vestidos");
              }}
             />
          </div>
          <TextLinking>
            <span
              className={localStyles.bottomLink}
              onClick={() => {navigate("/perfil", { state: { settingActive: true } }); modal.close();}}
            >
              Completa tu perfil
            </span>
          </TextLinking>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ModalWelcome;
