import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import localStyles from "./faqSaleHistory.module.css";
import alertImg from "../../../assets/svg/provisional/alertImg.svg";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const FaqSaleHistory = ({ details }) => {
  const navigate = useNavigate()
  const [isDisplay, setIsDisplay] = useState(false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };
  return (
    <>
      <button
        type="button"
        className={localStyles.faqReservesAct}
        onClick={toggleDisplay}
      >
        <span>HISTORIAL DE COMPRAS</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        // style={{marginBottom: 50}}
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        {(details && details.sales) &&
          details.sales.map(sale => {
            let dressModel = null
            if (sale.sale_dress_id) {
              dressModel = sale.sale_dress?.dress_models
            } else if (sale.shoe_id) {
              dressModel = sale.shoes?.dress_models
            } else if (sale.complement_id) {
              dressModel = sale.complement?.dress_models
            }
            return (
              <div 
                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '90%', cursor: 'pointer'}}
                key={Math.random()} 
                className={localStyles.reservesDescriptionArea}
                onClick={() => navigate(`/detalle-historial-venta/${sale.group_id}`)}
              >
                <div className={styles.reservesDescriptionArea} style={{display: 'flex'}}>
                  <img alt="" src={dressModel?.images[0]?.url} style={{width:35}}></img>
                  <section className={localStyles.reservationArea}>
                    <span className={localStyles.modelSpan}>{dressModel?.name}</span>
                    <section className={localStyles.dateReservationArea}>
                        <img alt="" src={alertImg}></img>
                        <span className={localStyles.textReservationDate}>Comprado el: {moment(sale?.created_at).format('DD - MMM YYYY')}</span>
                    </section>
                  </section>
                </div>
                <Icon icon="akar-icons:chevron-right" fontSize={11} />
              </div>
              )
          })}
      </div>
    </>
  );
};

export default FaqSaleHistory;