import React from "react";
import { TextDescription, Title } from "../shared/text/Text";
import styles from './Modal.module.css';

const ModalWaiting = ({ label, description }) => {
    return (
        <div className={styles.containerModal}>
            <Title styles={styles.titleModal} style={{marginButton: 28, textAlign: 'center'}}>{label ? label : 'PROCESANDO PAGO'}</Title>
            <div style={{display: 'flex', justifyContent: 'center', margin: '16px 0px'}}>
                <div className={styles.loaderSpinner}></div>
            </div>
            <TextDescription style={{textAlign: 'center', marginTop: 16}}>{description ? description : 'Espera un momento, la transacción está en proceso....'}</TextDescription>
        </div>
    )
}

export default ModalWaiting;