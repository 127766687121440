import { Icon } from '@iconify/react'
import { useEffect, useState } from 'react'
import styles from './dressImages.module.css'
import Video from '../../assets/icons/Video'

const DressImages = ({dressModelDetail, checkAvailityImg, setMainImg}) => {
    const [currentImage, setCurrentImage] = useState(0)
    const [offsetTops, setOffsetTops] = useState([])
    const [rendered, setRendered] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            if (document.getElementById("images-container")) {
                setRendered(true)
                clearInterval(interval)
            }
        }, 500)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (dressModelDetail?.images.length > 0 && rendered) {
            const array = []
            dressModelDetail?.images.forEach((value, key) => {
                if (document.getElementById(`model-details-${key}`)?.offsetTop) {
                    array.push(document.getElementById(`model-details-${key}`).offsetTop)
                }
            })
            console.log('Array:', array)
            setOffsetTops(array)
        }
    }, [dressModelDetail?.images, rendered])

    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    }

    const scrollTo = (element, to) => {
        element.scrollTop = to;
    }

    const nextImage = () => {
        scrollTo(document.getElementById("images-container"), offsetTops[currentImage + 1]);
        setCurrentImage(currentImage + 1)
    }

    const prevImage = () => {
        scrollTo(document.getElementById("images-container"), offsetTops[currentImage - 1] - 30);
        setCurrentImage(currentImage - 1)
    }

    return (
        <div className={styles.dressImagesSection}>
            { dressModelDetail?.images.length > 0 &&
                <div className={styles.dressImagesContainer} id="images-container">
                    { dressModelDetail?.images.map((img, key) => {
                        if (img.type === 'image') {
                            return (
                                <div className={styles.dressImageContent}>
                                    <img
                                        className={styles.dressImage}
                                        src={img?.url}
                                        key={key} 
                                        id={`model-details-${key}`} 
                                        onError={() => checkAvailityImg(`model-details-${key}`)} 
                                        onClick={() => setMainImg({ img: img?.url, id: key, type: img?.type })} 
                                        alt=""
                                    />
                                </div>
                            )
                        } else {
                            return (
                                <div className={styles.dressImageContent}>
                                    <img
                                        className={styles.dressImage}
                                        src={img?.url.replace('mp4', 'jpg')}
                                        key={key} 
                                        id={`model-details-${key}`} 
                                        onError={() => checkAvailityImg(`model-details-${key}`)} 
                                        onClick={() => setMainImg({ img: img?.url, id: key, type: img?.type })} 
                                        alt=""
                                    />
                                    <div className={styles.videoIcon} onClick={() => setMainImg({ img: img?.url, id: key, type: img?.type })}>
                                        <Video />
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            }
            {   currentImage > 0 &&
                <div className={styles.dressImagesUpArrow}>
                    <Icon icon="material-symbols:keyboard-arrow-up-rounded" fontSize={20} style={{cursor: 'pointer'}} 
                        onClick={prevImage} 
                    />
                </div>
            }
            {   currentImage + 3 < dressModelDetail?.images.length &&
                <div className={styles.dressImagesDownArrow}>
                    <Icon icon="material-symbols:keyboard-arrow-down-rounded" fontSize={20} style={{cursor: 'pointer'}} 
                        onClick={nextImage} 
                    />
                </div>
            }
        </div>
    )
}

export default DressImages