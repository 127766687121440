import React from "react";
import styles from './Radio.module.css';
import Paypal from '../../../assets/icons/paypal.png';

const RadioCustom = (props) => (
    <div className={`${styles.containt} ${props.customContainer ? props.customContainer : ''} ${props.disabled ? styles.disabledText : ''}`}>
        <label className={`${styles.formControl} ${props.customFormControl ? props.customFormControl : ''}`}>
            <input type="radio" {...props} />
            {props.label}
            <br/>{props.description}
            {props.iconPaypal && <img src={Paypal} style={{marginLeft: 10}} alt="paypal icon" />}
        </label>
    </div>
)

export default RadioCustom;