import { Icon } from "@iconify/react";
import React from "react";
import styles from './Table.module.css';
import leftArrow from "../../../assets/icons/chevron-left.svg";
import rightArrow from "../../../assets/icons/chevron-right.svg";
import { useState } from "react";

const Table = ({ data, headers, setPage, page, length, current, onModalRmoveClient, to, total, onModalInfoProfile }) => {
    return (
        <div>
            <div className={styles.tableResponsive}>
                <div className={styles.inlineContainer}>
                    {headers &&
                        headers.map(item => (
                            <Headers name={item.name} width={item?.width} />
                        ))
                    }
                </div>
                <div>
                    {data &&
                        data.map((item, key) => (
                            <div className={styles.inlineContainer}>
                                {
                                    headers.map((h, i) => (
                                    <>{headers[i].id === 'action' ? <ButtonAction id={item.id} row={item} onModalInfoProfile={onModalInfoProfile} onModalRmoveClient={onModalRmoveClient} header={h} key={Math.random()} /> : <Item value={(headers[i].name !== 'Ciudad' ? item[headers[i].id] : (item.address ? item.address[0]?.city?.name : 'Sin datos')) || 'Sin datos'} key={Math.random()} header={h} id={headers[i].id} />}</>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.containerDescription}>
                Mostrando {to} elementos de {total} encontrados
            </div>
            <Pagination
                setPage={setPage}
                page={page}
                length={length}
                current={current}
            />
        </div>
    )
}

const Headers = ({ name, width }) => (
    <div className={styles.headerItem} style={{minWidth: width ? width : 100}}>
        {name}
    </div>
)

const Item = ({ value, header, id }) => (
    <div className={styles.itemRow} style={{minWidth: header.width ? header.width : 100}}>
        {id === 'status' && <ColorStatus status={value} />}
        {value}
    </div>
)

const ButtonAction = ({ header, onModalRmoveClient, id, onModalInfoProfile, row }) => {
    const [show, setShow] = useState(false)
    return(
        <div className={`${styles.itemRow} ${styles.tooltip}`} style={{minWidth: header.width ? header.width : 100}} data-direction="bottom">
            <div className={styles.tooltip__initiator}>
                <Icon icon="akar-icons:more-vertical-fill" onClick={() => setShow(!show)} />
            </div>
            <div className={`${styles.tooltip__item} ${show ? styles.active : ''}`}>
                <div style={{background: '#FFF', width: '100%', color: '#425466', fontFamily: 'Mulish', fontSize: 12, cursor: 'pointer', marginBottom: 10}} onClick={() => {setShow(false);onModalInfoProfile(row)}}>
                    Ver info de perfil
                </div>
                <div style={{background: '#FFF', width: '100%', color: '#DF585F', fontFamily: 'Mulish', fontSize: 12, cursor: 'pointer'}} onClick={() => {setShow(false);onModalRmoveClient(id)}}>
                    Eliminar
                </div>
            </div>
        </div>
    )
}

const ColorStatus = ({ status }) => (
    <div className={styles.pointColor} style={{backgroundColor: status === 'activo' ? '#3DA558' : status === 'ausente' ? '#EBCA33' : status === 'inactivo' ? '#EBCA33' : '#3F7FB5'}} />
)

export const Pagination = ({ setPage, page, length, current }) => (
    <section className={styles.paginationWrapper}>
        <span className={styles.spanPage}>Página:</span>
        <div className={styles.paginationContainer}>
            <img src={leftArrow} alt="" className={styles.arrows} onClick={() => (+page) > 1 ? setPage((+page) - 1) : {}}></img>
            <div className={styles.pages}>
                <span>{current}</span>
                <span>&nbsp;/&nbsp;</span>
                <span>{length}</span>
            </div>
            <img src={rightArrow} alt="" className={styles.arrows} onClick={() => (+page) < (+length) ? setPage((+page) + 1) : {}}></img>
        </div>
    </section>
)

export default Table;