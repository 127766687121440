import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import { Title } from "../shared/text/Text";
import styles from './ClientList.module.css';
import filterSvg from "../../assets/icons/tuner.svg";
import { InputSearch } from "../shared/input/Input";
import Table from "../shared/table/Table";
import api from '../../api/axios.config';
import { useEffect } from "react";
import { useState } from "react";
import { modal } from "../modalManagment";
import ModalConfirmDelete from '../modal/ModalConfirmDelete';
import ModalFilterItemsAdmin from "../modal/ModalFilterItemsAdmin";
import Footer from "../footer/Footer";
import FooterMobile from '../footerMobile/FooterMobile';
import ModalInfoProfile from "../modal/ModalInfoProfile";
import { useForm } from "react-hook-form";

const ClientList = () => {
    const { width } = useWindowDimensions();
    const [rows, setRows] = useState([])
    const [clients, setClients] = useState([])
    const [pagination, setPagintation] = useState({})
    const [page, setPage] = useState('1')
    const [perPage, setPerPage] = useState('10');
    const [search, setSearch] = useState('')

    const [valuesFilter, setValuesFilter] = useState({
        status: '',
        age: '',
        city: ''
    })

    const getAllClients = async () => {
        let query = '';

        if(valuesFilter.age !== ''){
            query += '&age='+valuesFilter.age
        }

        if(valuesFilter.city){
            query += '&city_id='+valuesFilter.city
        }

        if(valuesFilter.status){
            query += '&status='+valuesFilter.status
        }

        try{
            const resp = await api.get(`/api/clients?page=${page}&per_page=${perPage}${query}`)
            if(resp.status === 200){
                setPagintation(resp.data)
                if(typeof resp.data.data === 'object'){
                    setClients(Object.values(resp.data.data))
                    setRows(Object.values(resp.data.data))
                  }
            }
        }catch(e){
            console.log(e)
        }
    }

    const handlerSearch = (value = '') => {
        setSearch(value)

        if(value !== '')
            setRows([...clients.filter(c => c?.name?.toUpperCase().includes(value.toUpperCase()))])
        else
            setRows(clients)
    }

    useEffect(() => {
        getAllClients()
    },[page, valuesFilter])

    const removeClient = async (id) => {
        modal.close();
        await api.delete(`/api/clients/${id}`)
        .then(resp => {
            if(resp.status === 200){
                getAllClients()
            }
        })
    }

    const onModalRmoveClient = (id) => {
        modal.open(<ModalConfirmDelete onClick={() => removeClient(id)} />, () => {}, 'BackdropContainerCenter')
    }

    const onModalFilter = () => {
        modal.open(<ModalFilterItemsAdmin valuesFilter={valuesFilter} setValuesFilter={setValuesFilter} />)
    }
    const formConfig = useForm();
    const onModalInfoProfile = (row) => {
        console.log(row)
        modal.open(
        <ModalInfoProfile row={row} formConfig={formConfig} />
      )
    }

    return (
        <>
            <div className={styles.container}>
                <section className={styles.nav}>
                    {width <= 1024 ? (
                        <NavBarMobile />
                    ): (
                        <NavBarDesktop />
                    )}
                </section>
                {width < 774 ? (
                    <div className={styles.containerMobile}>
                        <Title>Clientes</Title>

                        <div className={styles.inlineContainer}>
                            <section className={styles.btnFilterWrapper} onClick={() => onModalFilter()}>
                                <img src={filterSvg} alt="" className={styles.filterSvg} />
                                <span className={styles.spanFilter}>Filtros</span>
                            </section>

                            <InputSearch placeholder="Buscar..." value={search} onChange={(e) => handlerSearch(e.target.value)} />
                        </div>

                        <Table 
                            data={rows}
                            headers={[{name: 'Nombre', id: 'name', width: 200}, {id: 'status', name: 'Status'}, {id: 'age', name: 'EDAD'}, {id: '', name: 'Ciudad'}, {id: 'email', name: 'Correo', width: 200}, {id: 'phone', name: 'Teléfono'}, {id: 'action', name: ''}]}
                            setPage={setPage}
                            page={page}
                            length={pagination.last_page}
                            current={pagination.current_page}
                            to={pagination.to}
                            total={pagination.total}
                            onModalRmoveClient={onModalRmoveClient}
                            onModalInfoProfile={onModalInfoProfile}
                        />
                    </div>
                ): (
                    <div>
                        <div className={styles.containerMobile} style={{padding: 64}}>
                            <Title>Clientes</Title>

                            <div className={styles.inlineContainer}>
                                <InputSearch placeholder="Buscar..." value={search} onChange={(e) => handlerSearch(e.target.value)} />
                            </div>

                            <Table 
                                data={rows}
                                headers={[{name: 'Nombre', id: 'name', width: 200}, {id: 'status', name: 'Status'}, {id: 'age', name: 'EDAD'}, {id: 'address[0].city.name', name: 'Ciudad'}, {id: 'email', name: 'Correo', width: 200}, {id: 'phone', name: 'Teléfono'}, {id: 'action', name: ''}]}
                                setPage={setPage}
                                page={page}
                                length={pagination.last_page}
                                current={pagination.current_page}
                                to={pagination.to}
                                total={pagination.total}
                                onModalRmoveClient={onModalRmoveClient}
                                onModalInfoProfile={onModalInfoProfile}
                            />
                        </div>
                    </div>
                )}
            </div>
            {width <= 1024 ? <FooterMobile /> : <Footer /> }
        </>
    )
}

export default ClientList