import React, { useState } from "react";
import Button from "../shared/button";
import { Title } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";
import { Icon } from "@iconify/react";
import Select, { LabelSelect } from "../shared/select/Select";
import Textarea from "../shared/textarea/Textarea";
import Input from '../shared/input/Input';

const ModalFaq = ({ onSubmit }) => {
    const [category, setCategory] = useState('Renta');
    const [question, setQuestion] = useState('');
    const [email, setEmail] = useState('');

    return (
        <div className={styles.containerModal}>
            <div className={styles.inlineContainer}>
                <Title styles={styles.titleModal}>REALIZAR NUEVA PREGUNTA</Title>
                <Icon icon="clarity:times-line" onClick={() => modal.close()} />
            </div>

            <div className={styles.containerInputs}>
                <LabelSelect>Selecciona la categoría de tu pregunta</LabelSelect>
                <Select defaultValue={category} onChange={(e) => setCategory(e.target.value)}>
                    <option value="Renta">Renta</option>
                    <option value="Reserva">Reserva</option>
                    <option value="Envios">Envíos</option>
                    <option value="Devoluciones">Devoluciones</option>
                </Select>
            </div>

            <div className={styles.containerInputs}>
                <LabelSelect>Escribe tu pregunta</LabelSelect>
                <Textarea onChange={(e) => setQuestion(e.target.value)} rows={5} placeholder="Aquí va un texto largo...">{question}</Textarea>
            </div>

            {!window.localStorage.getItem("sorellaToken") &&
                <div className={styles.containerInputs}>
                    <LabelSelect>Ingresa tu email</LabelSelect>
                    <Input type="text" placeholder="Correo electronico" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
            }

            <div className={styles.inlineContainer} style={{marginTop: 20, justifyContent: 'space-between'}}>
                <Button style={{maxWidth: '46%'}} onClick={() => modal.close()} label="Regresar" outline></Button>
                <Button style={{maxWidth: '46%', background: '#87655B'}} onClick={() => onSubmit(email, question, category)} label="Enviar"></Button>
            </div>
        </div>
    )
}

export default ModalFaq;