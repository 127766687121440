import { Icon } from "@iconify/react";
import styles from './ToggleDisplay.module.css';
import React, { useState } from "react";

const ToggleCustomDisplay = (props) => {
    const [isDisplay, setIsDisplay] = useState(props.show);
    const toggleDisplay = () => {
      setIsDisplay(!isDisplay);
    };

    return (
        <>
            <button
                type="button"
                className={`${styles.buttonToggle} ${props.style ? props.style : ''}`}
                onClick={toggleDisplay}
            >
                <span>{props.label}</span>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {props.checked ? 
                        <span className={styles.textSelected}>{ props.value ?? '' }</span>
                        :
                        <p className={styles.textPending}>Pendiente</p>
                    }
                    {isDisplay ? (
                        <Icon
                            icon="akar-icons:minus"
                            color="#5F6867"
                            width="14"
                            height="14"
                        />
                    ) : (
                        <Icon icon="akar-icons:plus" color="#5F6867" width="14" height="14" />
                    )}
                </div>
            </button>
            <div
                className={`${styles.container} ${props.styleContainer ? props.styleContainer : ''} ${
                    isDisplay ? styles.display : styles.hidden
                }`}
            >
                {props.children}
            </div>
        </>
    )
}

export default ToggleCustomDisplay;