import React from "react";
import stylesLocal from "./faqHashtags.module.css";
import CustomCheck from "../../shared/customCheck/CustomCheck";
import { createKey } from "../../../utils";
import { useDispatch } from "react-redux";
import { onSetFilterValues } from "../../../store/slices/filters/filtersSlice";

const FaqHashtags = (props) => {
  const dispath = useDispatch();

  const handlerChecked = (id) => {
    let hashtags = [];
    console.log("hashtags:", props.valueHashtags);
    if (props.valueHashtags?.includes(id)) {
      hashtags = [...props.valueHashtags.filter((v) => v !== id)];
    } else if (props.valueHashtags !== undefined && props.valueHashtags !== null) {
      hashtags = [...props.valueHashtags, id];
    } else {
      hashtags = [id]
    }
    dispath(onSetFilterValues({ valueHashtags: hashtags }));
  };

  return (
    <section className={stylesLocal.buttonSizeContainer}>
      {props.AllHashTags?.map((row) => (
        <div key={createKey()} className={stylesLocal.checkboxInline}>
          <CustomCheck
            content={row}
            onChangeCallBack={() => handlerChecked(row)}
            isChecked={
              props.valueHashtags &&
              props.valueHashtags.find((nl) => nl === row)
                ? true
                : false
            }
          />
        </div>
      ))}
    </section>
  );
};

export default FaqHashtags;
