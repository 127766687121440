import React, { useEffect, useState } from "react"
import styles from "./Modal.module.css"
import { Icon } from "@iconify/react"
import { modal } from "../modalManagment"
import Button from "../shared/button"
import { useForm } from "react-hook-form"
import { LabelInput } from "../shared/input/Input"
import { Editor, EditorState} from 'draft-js'
import { convertFromHTML, convertToHTML } from 'draft-convert'
import 'draft-js/dist/Draft.css'
import ErrorMessage from "../errorMessage/ErrorMessage"

const ModalNotes = ({ dress, error, setError, errorMessage, updateNotes }) => {
    const formConfig = useForm({
        defaultValues: {
            notes: dress?.notes
        }
    })
    const [editor, setEditor] = useState(EditorState.createEmpty())

    const onChangeEditor = (e) => {
        setEditor(e)
    }

    useEffect(() => {
        if (editor) {
            formConfig.setValue('notes', convertToHTML(editor.getCurrentContent()))
        }
    }, [editor])

    useEffect(() => {
        if (dress?.notes) {
            setEditor(EditorState.push(editor, convertFromHTML(dress.notes)))
        }
    }, [dress])

    return (
        <div
            className={styles.containerFiltersPadding}
            style={{ maxWidth: 500, maxHeight: "80vh", marginTop: "40px" }}
        >
            <div className={styles.inlineContainer}>
                <span className={styles.titleFilters}>Notas de pedido { dress?.code }</span>
                <Icon
                    icon="eva:close-outline"
                    fontSize={25}
                    style={{ paddingLeft: 40, marginRight: 20, cursor: "pointer" }}
                    onClick={() => {
                        document.getElementById('root').style.overflowY = ''
                        modal.close()
                    }}
                />
            </div>
            <form
                className="form-order" 
                onSubmit={
                    formConfig.handleSubmit(updateNotes)
                }
            >
                <div className={styles.modalContainter}>
                    <section className={styles.inputSections}>
                        <LabelInput label="Notas"></LabelInput>
                        <Editor editorState={editor} onChange={onChangeEditor} required />
                    </section>
                </div>
                <div className={styles.spaceBetween} style={{ marginTop: '24px'}}>
                    <ErrorMessage display={error} setError={setError} textDescription={errorMessage} />
                    <Button label="Cancelar" outline onClick={() => {
                        document.getElementById('root').style.overflowY = ''
                        modal.close()
                    }}/>
                    <Button label="Guardar notas" type="submit"/>
                </div>
            </form>
        </div>
    )
}

export default ModalNotes
