import React from "react";
import localStyles from "./faqPriceDetails.module.css";
import {useDispatch, useSelector} from "react-redux";
import Radio from '../../shared/radio/Radio';
import { format_currency } from "../../../utils";
import { setCalendarDays } from "../../../store/slices/rentalProcess/rentalProcessSlice";

const FaqPriceDetails = (props) => {
  const dispatch = useDispatch()
  const { dressModelDetail } = useSelector((store)=> store.dressDetails)
 
  return (
    <div className={`${localStyles.containerPriceElement}`}>
      <span className={localStyles.spanTitle}>PRECIO <span style={{color: '#A02C47'}}>{props.discount > 0 ? `(${props.discount}%)` : ''}</span></span>
      <div className={localStyles.priceArea}>
        <section className={localStyles.inputSections}>
          <div className={localStyles.inlineContaint}>
            <Radio label="Renta 4 días" checked={props.pricesRent === 1 ? 'checked' : false} name="price-rent" onClick={() => {
              props.setPricesRent(1);
              dispatch(setCalendarDays({days: 3}))
            }} customContainer={localStyles.customContainer} customFormControl={localStyles.customFormControl} />
            <span className={localStyles.priceDescription} style={{color: props.discount > 0 ? '#A02C47' : '#1E202C'}}>{format_currency(props.discount > 0 ? Math.abs((dressModelDetail.price * (props.discount / 100)) - dressModelDetail.price) : dressModelDetail.price)}</span>
          </div>
          <div className={localStyles.inlineContaint}>
            <Radio label="Renta 8 días" checked={props.pricesRent === 2 ? 'checked' : false} name="price-rent" onClick={() => {
              props.setPricesRent(2)
              dispatch(setCalendarDays({days: 7}))
            }} customContainer={localStyles.customContainer} customFormControl={localStyles.customFormControl} />
            <span className={localStyles.priceDescription} style={{color: props.discount > 0 ? '#A02C47' : '#1E202C'}}>{format_currency(props.discount > 0 ? Math.abs(((Number(dressModelDetail.price) + 800) * (props.discount / 100)) - (Number(dressModelDetail.price) + 800)) : (Number(dressModelDetail.price) + 800))}</span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FaqPriceDetails;