import React, { useRef } from "react";
import styles from "./favoritesDesktop.module.css";
import { Icon } from "@iconify/react";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import Footer from "../footer/Footer";
import CardDress from "../cardDress/CardDress";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SearchFilters from "../searchFiltersFav/SearchFilters";
import {
  onSetFilterFavouritesValues,
} from "../../store/slices/filters/filtersSlice";
import { useState } from "react";
import { createKey, setParamsToUrl, urlSearchParams } from "../../utils";
import leftArrow from "../../assets/icons/chevron-left.svg";
import rightArrow from "../../assets/icons/chevron-right.svg";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PaginationComponent from "../shared/paginationNumeric/PaginationNumeric";
import FavoriteEmpty from "../../assets/svg/empty-favorite.svg";
import Button from "../shared/button";
import { getAllFavorites, getFavoritesPagination } from "../../store/thunks/favoritesThunk";

const FavoritesDesktop = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [dataDress, setDataDress] = useState([]);
    const { paginationFavorite } = useSelector((store) => store.dressDetails); 
    const refPage = useRef(null);
    const [page, setPage] = useState('1')
    const [perPage, setPerPage] = useState('8');
    const [itemView, setItemView] = useState([]);
    const section2 = useRef(null);
    const [heightSection, setHeightSection] = useState('')
    const { 
      valuesFilterFavourites: {
        valueColor,
        valueTypeEvent, 
        valueCalendar,
        valueBrand,
        valueLength,
        valueSizes,
        valueNecklines,
        valuePrices
      }
     } = useSelector((store) => store.filters)
  
    const{ valuesFilterFavourites } = useSelector((store) => store.filters)
  
     const getMapQueryDress = () => {
      const items = [];
  
      if(valueColor){
        items.push({ name: 'colors', params: valueColor })
      }
  
      if(valueTypeEvent.length > 0){
        const vte = []
        for(let i = 0; i < valueTypeEvent.length; i++)
          vte.push(valueTypeEvent[i])
        items.push({ name: 'type_event_ids', params: vte.join(',') })
      }
  
      if(valueCalendar.length > 0 && valueCalendar[0] !== ''){
        items.push({ name: 'date_start', params: moment(valueCalendar[0]).format('YYYY/MM/DD') })
        items.push({ name: 'date_end', params: moment(valueCalendar[1]).format('YYYY/MM/DD') })
      }
  
      if(valueBrand.length > 0){
        const brd = []
        for(let i = 0; i < valueBrand.length; i++)
          brd.push(valueBrand[i])
        items.push({ name: 'marca_ids', params: brd.join(',') })
      }
  
      if(valueLength.length > 0){
        const vlg = []
        for(let i = 0; i < valueLength.length; i++)
          vlg.push(valueLength[i])
        items.push({ name: 'largo_ids', params: vlg.join(',') })
      }
  
      if(valueSizes.length > 0){
        const vsz = []
        for(let i = 0; i < valueSizes.length; i++)
          vsz.push(valueSizes[i])
        items.push({ name: 'talla_ids', params: vsz.join(',') })
      }
  
      if(valueNecklines.length > 0){
        const vnk = []
        for(let i = 0; i < valueNecklines.length; i++)
          vnk.push(valueNecklines[i])
        items.push({ name: 'escote_ids', params: vnk.join('') })
      }
  
      if(valuePrices.length > 0 && valuePrices[0] !== ''){
        items.push({ name: 'min_precio', params: valuePrices[0] })
        items.push({ name: 'max_precio', params: valuePrices[1] })
      }
  
      return items
    }
  
    useEffect(() => {
      if(getMapQueryDress().length > 0){
        setParamsToUrl(navigate, "/favoritos", getMapQueryDress());
      }
    },[
      valueColor,
      valueTypeEvent, 
      valueCalendar,
      valueBrand,
      valueLength,
      valueSizes,
      valueNecklines,
      valuePrices
    ])
  
    useEffect(() => {
      if(paginationFavorite && paginationFavorite?.data && typeof paginationFavorite.data === 'object'){
        refPage.current.scrollIntoView({ behavior: 'smooth' })
        setDataDress(Object.values(paginationFavorite.data))
      }
    },[paginationFavorite])
  
    const fetchData = () => {
      const search = urlSearchParams(window.location.href)
      let query = ''
      if(search && search.object){
        Object.keys(search.object).map(item => {
          query += `&${item}=${search.object[item]}`
          return null
        })
      }
      //`?favorite=true&page=${page}&per_page=${perPage}${query}`
      dispatch(getFavoritesPagination(`?page=${page}&per_page=${perPage}${query}`));
    };
  
    useEffect(() => {
      if(location.state?.itemView && itemView.length <= 0){
        setItemView(location.state?.itemView)
      }
    },[location])
  
    useEffect(
      () => {
        fetchData();
      },
      [page, perPage, window.location.href]
    );
  
    useEffect(() => {
      if(dataDress.length)
        setHeightSection((Number(section2.current?.clientHeight) + 80))
    },[paginationFavorite, page, perPage, dataDress])

    useEffect(() => {
      dispatch(getAllFavorites())
    },[])
  
    return (
      <>
        <div ref={refPage} className={styles.container}>
          <section className={styles.nav}>
            <NavBarDesktop />
          </section>
          <section className={styles.rowsSections}>
            <section className={styles.section1} style={{ maxHeight: heightSection ? (heightSection)+'px': '100%'}}>
              <SearchFilters
                setItemView={setItemView}
                navigate={navigate}
                setFilter={onSetFilterFavouritesValues}
              />
            </section>
            <section className={styles.section2} ref={section2}>
              <section className={styles.topSection}>
                <div className={styles.topLeftArea}>
                  <span className={styles.titleStyles}>Estilos para ti</span>
                  <div className={styles.inlineWrap}>
                    {(itemView) && itemView.map(item => 
                    <div className={styles.filtersArea} key={createKey()}>
                      <section className={styles.filterItem}>
                        {item.value}
                        <button
                          className={styles.close}
                          onClick={() => {
                            const values = valuesFilterFavourites[item.id];
                            dispatch(onSetFilterFavouritesValues({
                              [item.id]: item.id === 'valueColor' ? '' : (item.id === 'valueCalendar' || item.id === 'valuePrices') ? ['', ''] : [...values.filter(v => v !== item.key)]
                            }))
                            setItemView([...itemView.filter(v => v.id !== item.id)])
                          }}
                        >
                          <Icon
                            icon="eva:close-outline"
                            color={'#434A48'}
                            width="10px"
                            height="10px"
                          />
                        </button>
                      </section>
                    </div>
                    )}
                  </div>
                </div>
                <div className={styles.topRightArea}>
                  <section className={styles.paginationWrapper}>
                    <span className={styles.spanPage}>Página:</span>
                    <div className={styles.paginationContainer}>
                      <img src={leftArrow} alt="" className={styles.arrows} onClick={() => page > 1 ? setPage((+page) - 1) : {}}></img>
                      <div className={styles.pages}>
                        <span>{paginationFavorite ? paginationFavorite?.current_page : '1'}</span>
                        <span>&nbsp;/&nbsp;</span>
                        <span>{paginationFavorite ? paginationFavorite?.last_page : '1'}</span>
                      </div>
                      <img src={rightArrow} alt="" className={styles.arrows} onClick={() => page < (+paginationFavorite?.last_page || 0) ? setPage((+page) + 1) : {}}></img>
                    </div>
                  </section>
                  <section className={styles.modelsForEachPage}>
                    <span className={styles.titlePaginationModels}>
                      Modelos por página:
                    </span>
                    <select className={styles.qtyModels} defaultValue={perPage} onChange={(e) => setPerPage(e.target.value)}>
                      <option value="8">8</option>
                      <option value="12">12</option>
                      <option value="16">16</option>
                      <option value="20">20</option>
                  </select>
                  </section>
                </div>
              </section>
  
              <section className={styles.allDressSearchContainer}>
                {dataDress && dataDress.length === 0 ?
                <>
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <img src={FavoriteEmpty} style={{width: 420}} alt="" />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <Button label="Regresar a la búsqueda" onClick={() => navigate('/resultados-vestidos')} outline style={{maxWidth: '40%'}} />
                    </div>
                </>
                  :
                  dataDress.map((dress, index) => (
                    <div key={index}>
                      <CardDress
                        dressModel={dress.dress_models ? dress.dress_models : {}}
                        url={dress.dress_models.images ? dress.dress_models?.images[0]?.url : ''}
                        id={dress.dress_models ? dress.dress_models.id : null}
                        id_shop={dress.id}
                      />
                    </div>
                  ))
                }
              </section>
              {dataDress && dataDress.length > 0 &&
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <PaginationComponent page={page} setPage={setPage} total={paginationFavorite.total} data={dataDress} />
                </div>
              }
            </section>
          </section>
  
        </div>
        <Footer />
      </>
    );
};

export default FavoritesDesktop;
