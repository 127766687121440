import React, {useState} from 'react';
import styles from './Faqs.module.css';
import { Icon } from "@iconify/react";

const Faqs = (props) => {
    const [isDisplay, setIsDisplay] = useState(false);
    const toggleDisplay = () => {
        setIsDisplay(!isDisplay);
    };

    return(
        <>
            <button
                type="button"
                className={styles.faqs}
                onClick={toggleDisplay}
            >
                <span>{props.title}</span>
                {isDisplay ? (
                    <Icon
                        icon="akar-icons:minus"
                        color="#d6a191"
                        width="14"
                        height="14"
                    />
                ) : (
                    <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
                )}
            </button>
            <div
                className={`${styles.container} ${
                    isDisplay ? styles.display : styles.hidden
                }`}
            >
                <span>{props.children}</span>
            </div>
        </>
    ) 
}

export default Faqs;