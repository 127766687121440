
import React, { useEffect, useState } from "react";
import { Title, TextDescription } from "../shared/text/Text";
import styles from '../shopCart/shopCart.module.css';
import { useSelector } from "react-redux";
import moment from "moment";
import { format_currency } from "../../utils";

const Product = (props) => {
    const [price, setPrice] = useState('')
    const [percent, setPercent] = useState('')

    const getImage = (data) => {
        let srcImage = ''
        if (data.images) srcImage = `${data?.images[0]?.url}`;
        return srcImage;
    };

    const getNameColor = (data, color) => {
        let value = {}
        if (data?.dress && color) value = data?.dress.find(v => v.color.id === color)
        return value?.color ? value.color : { name: '' }
    }

    const getFormatDate = (date) => {
        let date_format = ''
        if (date && date.length === 2 && date[0] !== null) date_format = `${moment(date[0]).format('DD-MMM')} - ${moment(date[1]).format('DD-MMM')}`
        return date_format
    }

    const convertPrice = () => {
        let price = Number(props.data?.price * (props.data?.quantity ?? 1));
        if (props.data?.dress && props.data?.product?.discount > 0) {
            price = price * (Number(props.data?.product?.discount) / 100)
            return Math.abs(price - Number(props.data?.price * (props.data?.quantity ?? 1)));
        }
        return price;
    }

    useEffect(() => {
        if (props.data) {
            if (props.data?.type === 'RENTA' || props.data?.type === 'VENTA' || props.isReserve) setPrice(format_currency(convertPrice()))
            else setPrice(format_currency(Number(convertPrice()) - (Number(convertPrice()) * 0.5)))
            if ((props.data?.type === 'RENTA' || props.data?.type === 'VENTA') && !props.isReserve) setPercent('')
            else setPercent('(50% Renta)')
        }
    }, [props.data])

    return (
        <div className={styles.containerCard}>
            <div className={styles.containerCardProduct}>
                <div className={styles.containImg}>
                    {/* eslint-disable  jsx-a11y/alt-text */}
                    <img src={getImage(props.data)} className={styles.imgStyle} />
                </div>
                <div className={styles.containTexs}>
                    <Title styles={styles.titleCard}>{props.data?.type}</Title>
                    <TextDescription>{props.data?.name} ({props.colorName ? props.colorName : getNameColor(props.data, props.colorValue).name})</TextDescription>
                    <TextDescription>Talla: { props.sizeValue }</TextDescription>
                    <TextDescription>{getFormatDate(props.calendarRange)}</TextDescription>
                    {props.data?.dress && props.data?.product?.discount > 0 && <TextDescription><s>{format_currency(props.data?.price)}</s> <span style={{color: '#A02C47'}}>({props.data?.product?.discount}%)</span></TextDescription>}
                    { props.data?.quantity && props.data?.product_type === 'complements' && <TextDescription>Cantidad: <b>{ props.data?.quantity }</b></TextDescription> }
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <TextDescription style={{color: props.data?.dress && props.data?.product?.discount > 0  ? '#A02C47' : '#353535'}}>
                            {price} {percent}
                        </TextDescription>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product;