import React from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";

const ModalDeleteCartItem = ({onClick}) => {
    return (
        <div className={styles.containerModal}>
            <Title styles={styles.textModalTitle}>VESTIDO VENCIDO</Title>
            <TextDescription styles={styles.textDescription}>Algunos artículos de tu bolsa han sobrepasado el límite de tiempo y han sido eliminados. Para recuperarlos deberás regresar al catálogo a buscarlos.</TextDescription>
            <TextDescription styles={styles.textDescription}>¿Deseas continuar?</TextDescription>
            <div className={styles.containButtons}>
                <Button label="Regresar" outline styles={styles.buttonMiddle} onClick={() => modal.close()} />
                <Button label="Continuar" styles={`${styles.buttonMiddle}`} onClick={onClick} />
            </div>
        </div>
    )
}

export default ModalDeleteCartItem;