import { useState, useEffect } from "react"
import DataTable from "react-data-table-component"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import NavBarDesktop from "../navBarDesktop/NavBarDesktop"
import NavBarMobile from "../navBarMobile/NavBarMobile"
import { Title } from "../shared/text/Text"
import styles from './Orders.module.css'
import filterSvg from "../../assets/icons/tuner.svg"
import { InputSearch } from "../shared/input/Input"
import api from '../../api/axios.config'
import ModalInfoProfile from "../modal/ModalInfoProfile"
import { modal } from "../modalManagment"
import Footer from "../footer/Footer"
import FooterMobile from '../footerMobile/FooterMobile'
import moment from "moment"
import fileDownload from 'js-file-download'
import { format_currency } from "../../utils"
import { useForm } from "react-hook-form"
import "./orders.css"
import ProfileIcon from "../../assets/icons/ProfileIcon"
import PaginationComponent from "../shared/paginationNumeric/PaginationNumeric"
import CustomCheck from "../shared/customCheck/CustomCheck"
import Trash from "../../assets/icons/Trash"
import ModalDeleteOrder from "../modal/ModalDeleteOrder"
import Notes from "../../assets/icons/Notes"
import NewNote from "../../assets/icons/NewNote"
import ModalNotes from "../modal/ModalNotes"
import Button from "../shared/button"
import CreditCard from "../../assets/icons/CreditCard"
import ModalCreateGuaranteePayment from "../modal/ModalCreateGuaranteePayment"
import AddIcon from "../../assets/icons/AddIcon"
import AddRoundedIcon from "../../assets/icons/AddRoundedIcon"
import RemoveRoundedIcon from "../../assets/icons/RemoveRoundedIcon"
import ModalConfirmGuaranteePayment from "../modal/ModalConfirmGuaranteePayment"
import ModalCancelGuaranteePayment from "../modal/ModalCancelGuaranteePayment"

const Orders = () => {
    const { width } = useWindowDimensions();
    const [rows, setRows] = useState([])
    // const [orders, setOrders] = useState([])
    const [pagination, setPagintation] = useState({})
    const [page, setPage] = useState('1')
    const [perPage, setPerPage] = useState('10')
    const [sort, setSort] = useState({
        sort: 'id',
        direction: 'desc'
    })
    const [searchType, setSearchType] = useState('code')
    const [search, setSearch] = useState('')
    const [lastSearch, setLastSearch] = useState('')
    const [filterType, setFilterType] = useState(null)
    const [exportType, setExportType] = useState('created_at')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [hidePastOrders, setHidePastOrders] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [valuesFilter, setValuesFilter] = useState({
        status: '',
        age: '',
        city: ''
    })

    const formConfig = useForm()

    const onModalInfoProfile = (row) => {
        console.log(row)
        modal.open(
        <ModalInfoProfile row={row} formConfig={formConfig} />
      )
    }

    const deleteConfirmation = (row) => {
        modal.open(
            <ModalDeleteOrder row={row} delete={async () => {
                try {
                    const response = await api.delete(`/api/orders/${row.id}`)
                    if (response.status === 200) {
                        await getAllOrders()
                        console.log('Pedido eliminado')
                        modal.close()
                    }
                } catch (e) {
                    console.log(e)
                }
            }}/>
        )
    }

    const createGuaranteePayment = (row) => {
        modal.open(
            <ModalCreateGuaranteePayment onClick={async () => {
                try {
                    const response = await api.post(`/api/orders/${row.id}/guarantee`)
                    if (response.status === 200) {
                        await getAllOrders()
                        modal.close()
                    }
                } catch (e) {
                    console.log(e)
                }
            }}/>
        )
    }

    const confirmGuaranteePayment = (row) => {
        modal.open(
            <ModalConfirmGuaranteePayment defaultValue={row.guarantee.amount} onClick={async (value) => {
                console.log('Value:', value)
                try {
                    const response = await api.put(`/api/orders/${row.id}/guarantee`, { amount: value })
                    if (response.status === 200) {
                        await getAllOrders()
                        modal.close()
                    }
                } catch (e) {
                    console.log(e)
                }
            }}/>
        )
    }

    const cancelGuaranteePayment = (row) => {
        modal.open(
            <ModalCancelGuaranteePayment onClick={async () => {
                try {
                    const response = await api.delete(`/api/orders/${row.id}/guarantee`)
                    if (response.status === 200) {
                        await getAllOrders()
                        modal.close()
                    }
                } catch (e) {
                    console.log(e)
                }
            }}/>
        )
    }

    const openNotes = (row) => {
        modal.open(
            <ModalNotes 
                errorMessage={errorMessage} 
                error={error} 
                setError={setError}
                dress={row}
                updateNotes={async (data) => {
                    try {
                        const response = await api.put(`/api/orders/${row.id}`, data)
                        if (response.status === 200) {
                            await getAllOrders()
                            console.log('Pedido actualizado')
                            modal.close()
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }}
            />
        )
    }

    const columns = [
        { 
            sortField: 'id', 
            name: 'ID', 
            omit: true,
            cell: (row) => row.id
        }, 
        { 
            sortField: 'image', 
            name: '', 
            minWidth: "120px",
            selector: (row) => {
                return (
                  <div className="image-column">
                    <img src={row.dress?.dress_model?.images[0]} alt="" height="120"/>
                  </div>
                )
            }    
        }, 
        { 
            sortField: 'code', 
            name: 'Código de renta', 
            minWidth: "140px",
            cell: (row) => row.code
        }, 
        { 
            sortField: 'type', 
            name: 'Tipo de pedido', 
            minWidth: "140px",
            cell: (row) => row.type
        },
        { 
            sortField: 'created_at', 
            name: 'Fecha de creación',
            sortable: true,
            minWidth: "180px",
            cell: (row) => moment(row.created_at).format('DD/MM/YYYY')
        }, 
        { 
            sortField: 'client', 
            name: 'Nombre de clienta', 
            minWidth: "180px",
            selector: (row) => {
                return (<div className={styles.link} onClick={() => onModalInfoProfile(row.client)}>{ row.client.full_name } <ProfileIcon /></div>)
            }
        }, 
        { 
            sortField: 'dress_model', 
            name: 'Nombre de modelo', 
            minWidth: "160px",
            cell: (row) => row.dress?.dress_model?.name
        }, 
        { 
            sortField: 'size', 
            name: 'Talla', 
            minWidth: "70px",
            maxWidth: "70px",
            center: true,
            cell: (row) => row.dress?.size?.description
        }, 
        { 
            sortField: 'store', 
            name: 'Ubicación de vestido', 
            minWidth: "170px",
            cell: (row) => row.dress?.store ?? 'No registrada'
        }, 
        { 
            sortField: 'option_b', 
            name: 'Opción B', 
            minWidth: "100px",
            maxWidth: "100px",
            cell: (row) => row.option_b
        }, 
        { 
            sortField: 'send_method', 
            name: 'Logística de pedido', 
            minWidth: "170px",
            cell: (row) => `${row.send_method} ${row.schedule !== "" ? `Recolección: ${row.schedule}` : ''}`
        }, 
        { 
            sortField: 'address', 
            name: 'Dirección', 
            minWidth: "200px",
            cell: (row) => row.type === 'Renta' && row.address ? row.address?.full_address : 'N/A'
        }, 
        // { 
        //     sortField: 'ready_and_shipping_date', 
        //     name: 'Fecha para preparación y envío', 
        //     minWidth: "250px",
        //     cell: (row) => row.ready_and_shipping_date
        // }, 
        { 
            sortField: 'rent_dates', 
            name: 'Fechas de renta', 
            minWidth: "200px",
            sortable: true,
            cell: (row) => row.rent_dates
        }, 
        // { 
        //     sortField: 'return_and_washing_date', 
        //     name: 'Fecha de regreso y lavado', 
        //     minWidth: "210px",
        //     cell: (row) => row.return_and_washing_date
        // }, 
        { 
            sortField: 'payment_method', 
            name: 'Tipo de pago', 
            minWidth: "200px",
            cell: (row) => row.payment_method === 'CC' ? 'Tarjeta de crédito/débito' : row.payment_method === 'paypal' ? 'Paypal' : 'Pedido Manual'
        }, 
        { 
            sortField: 'reservation', 
            name: 'Apartado previo', 
            minWidth: "200px",
            cell: (row) => row.reservation
        }, 
        { 
            sortField: 'coupon', 
            name: 'Cupón', 
            minWidth: "150px",
            maxWidth: "150px",
            cell: (row) => row.coupon ?? 'N/A'
        }, 
        { 
            sortField: 'seller', 
            name: 'Vendedora', 
            minWidth: "200px",
            maxWidth: "200px",
            cell: (row) => row.seller ?? 'N/A'
        }, 
        { 
            sortField: 'subtotal', 
            name: 'Subtotal', 
            minWidth: "100px",
            cell: (row) => format_currency(row.subtotal)
        }, 
        { 
            sortField: 'shipping', 
            name: 'Envío', 
            minWidth: "100px",
            cell: (row) => format_currency(row.shipping)
        }, 
        { 
            sortField: 'total', 
            name: 'Total', 
            minWidth: "100px",
            cell: (row) => format_currency(row.total)
        },
        {
            sortField: 'guarantee',
            name: 'Garantía',
            minWidth: "180px",
            selector: (row) => {
                if (row.guarantee === null) return <div>Sin cobro de garantía</div>
                else if (row.guarantee && row.guarantee.status === 'on_hold') return <div>{ format_currency(row.guarantee.amount) } retenidos</div>
                else if (row.guarantee && row.guarantee.status === 'charged') return <div>{ format_currency(row.guarantee.amount) } cobrados</div>
                else if (row.guarantee && row.guarantee.status === 'refund') return <div>Cobro devuelto</div>
            }
        },
        { 
            sortField: 'notes', 
            name: 'Notas', 
            minWidth: "100px",
            selector: (row) => {
                return <div className="d-flex justify-content-center pointer" onClick={() => openNotes(row)}>{ row.notes ? <Notes /> : <NewNote /> }</div>
            }
        },
        {
            sortField: 'actions',
            name: 'Acciones',
            minWidth: '130px',
            center: true,
            selector: (row) => {
                return <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-center pointer" onClick={() => deleteConfirmation(row)}><Trash /></div>
                    { row.client.cards.length > 0 && row.guarantee === null && <div className="d-flex justify-content-center pointer" onClick={() => createGuaranteePayment(row)}><CreditCard /></div> }
                    { row.guarantee && row.guarantee.status === 'on_hold' && <div className="d-flex justify-content-center pointer" onClick={() => confirmGuaranteePayment(row)}><AddRoundedIcon /></div> }
                    { row.guarantee && row.guarantee.status === 'on_hold' && <div className="d-flex justify-content-center pointer" onClick={() => cancelGuaranteePayment(row)}><RemoveRoundedIcon /></div> }
                </div>
            }
        }
    ]

    const getAllOrders = async () => {
        console.log('GetAllOrders')
        let query = '';

        if (valuesFilter.age !== '') {
            query += '&age=' + valuesFilter.age
        }

        if (valuesFilter.city) {
            query += '&city_id=' + valuesFilter.city
        }

        if (valuesFilter.status) {
            query += '&status=' + valuesFilter.status
        }

        if (search !== '') {
            query += `&type=${searchType}&search=${search}`
        }

        if (sort.sort !== '') {
            query += `&sort=${sort.sort}&direction=${sort.direction}`
        }

        if (filterType !== null && startDate !== null && endDate !== null) {
            query += `&filter=${filterType}&from=${startDate}&to=${endDate}`
            if (filterType === 'date') {
                query += `&date_type=${exportType}`
            }
        }

        if (hidePastOrders) {
            query += "&hide_closed=true"
        }

        try {
            const response = await api.get(`/api/orders?page=${page}&per_page=${perPage}${query}`)
            if (response.status === 200) {
                setRows(response.data.data)
                setPagintation(response.data.meta)
                setFilterType(null)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const CustomPagination = () => {
        return (
            <div className="d-flex justify-content-center mt-1">
                <PaginationComponent page={page} setPage={setPage} total={pagination.total} last_page={pagination.last_page} per_page={perPage} data={rows} />
            </div>
        )
    }

    const handleSort = (column, sortDirection) => {
        console.debug("handleSort event")
        setSort({
            sort: column.sortField,
            direction: sortDirection
        })
    }

    const handlerSearch = (searchValue) => {
        setSearch(searchValue)
        if (searchValue === '') {
            getAllOrders()
        }
    }

    const handleKeyDown = event => {
        // console.log('User pressed: ', event.key)
        if (event.key === 'Enter') {
          if (search !== '') {
            setLastSearch(search)
            getAllOrders()
          }
        }
        // if (event.key === 'Backspace') {
            
        // }
    }

    const handleOnBlur = () => {
        console.log('Last Search:', lastSearch)
        console.log('Search:', search)
        if (lastSearch !== search) {
            getAllOrders()
            setLastSearch(search)
        }
    }

    const filterOrders = () => {
        setFilterType('date')
    }

    const exportOrders = async () => {
        try {
            const filename = `Ordenes ${startDate} - ${endDate}.xlsx`
            const params = {
                start_date: startDate,
                end_date: endDate,
                date_type: exportType
            }
            const response = await api.post(`api/orders/export`, params, { responseType: 'blob' })
            fileDownload(response.data, filename)
            return {
              data: response.data,
              params
            }
          } catch (err) {
            console.error(err)
          }
    }

    useEffect(() => {
        if (filterType) getAllOrders()
    }, [filterType])

    useEffect(() => {
        getAllOrders()
    }, [page, valuesFilter, sort, hidePastOrders])

    return (
        <>
            <div className={styles.container}>
                <section className={styles.nav}>
                    {width <= 1024 ? (
                        <NavBarMobile />
                    ) : (
                        <NavBarDesktop />
                    )}
                </section>
                {width < 774 ? (
                    <div className={styles.containerMobile}>
                        <Title>Pedidos</Title>

                        <div className={styles.inlineContainer}>
                            <select className={styles.searchSelect} value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                                <option value="code">Código de renta</option>
                                <option value="client_name">Clienta</option>
                                <option value="dress_model">Modelo vestido</option>
                                <option value="seller">Vendedora</option>
                            </select>
                            <InputSearch placeholder="Buscar..." value={search} styles={styles.searchInput} onChange={(e) => handlerSearch(e.target.value)} onKeyDown={handleKeyDown} onBlur={handleOnBlur}/>
                        </div>

                        <DataTable
                            noDataComponent={<div className="d-flex justify-content-center mt-1" ><p>No se encontraron resultados</p></div>}
                            keyField={'id'}
                            sortServer
                            pagination
                            responsive
                            dense
                            paginationServer
                            columns={columns}
                            onSort={handleSort}
                            className="react-dataTable"
                            paginationComponent={CustomPagination}
                            data={rows}
                            selectableRowsNoSelectAll
                        />
                    </div>
                ) : (
                    <div>
                        <div className={styles.containerMobile} style={{ padding: 64 }}>
                            <div className={styles.inlineContainerSpaced}>
                                <Title>Pedidos</Title>
                                <div className={styles.inlineDatesContainer}>
                                    <select className={styles.searchSelect} value={exportType} onChange={(e) => setExportType(e.target.value)} style={{ width: '160px'}}>
                                        <option value="created_at">Fecha de creación</option>
                                        <option value="rent_date">Fecha de renta</option>
                                    </select>
                                    <input className={styles.dateInput} type="date" name="start_date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                    <input className={styles.dateInput} type="date" name="end_date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                    <Button label="Filtrar" styles={styles.buttonFilter} disabled={startDate === null || endDate === null} onClick={filterOrders}/> 
                                    <Button label="Exportar" styles={styles.buttonFilter} disabled={startDate === null || endDate === null} onClick={exportOrders}/> 
                                </div>
                            </div>

                            <div className={styles.inlineContainerSpaced}>
                                <div className={styles.inlineContainer}>
                                    <select className={styles.searchSelect} value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                                        <option value="code">Código de renta</option>
                                        <option value="client_name">Clienta</option>
                                        <option value="dress_model">Modelo vestido</option>
                                        <option value="seller">Vendedora</option>
                                    </select>
                                    <InputSearch placeholder="Buscar..." value={search} styles={styles.searchInput} onChange={(e) => handlerSearch(e.target.value)} onKeyDown={handleKeyDown} onBlur={handleOnBlur}/>
                                </div>
                                
                                <CustomCheck
                                    content={"Ocultar pedidos pasados"}
                                    onChangeCallBack={() => setHidePastOrders(!hidePastOrders)}
                                    isChecked={hidePastOrders}
                                />
                            </div>

                            <DataTable
                                noDataComponent={<div className="d-flex justify-content-center mt-1" ><p>No se encontraron resultados</p></div>}
                                keyField={'id'}
                                sortServer
                                pagination
                                responsive
                                dense
                                paginationServer
                                columns={columns}
                                onSort={handleSort}
                                className="react-dataTable"
                                paginationComponent={CustomPagination}
                                data={rows}
                                selectableRowsNoSelectAll
                            />
                        </div>
                    </div>
                )}
            </div>
            {width <= 1024 ? <FooterMobile /> : <Footer />}
        </>
    )
}

export default Orders