import React, {useState} from "react";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import NavbarDesktop from '../navBarDesktop/NavBarDesktop';
import Footer from '../footer/Footer';
import FooterMobile from '../footerMobile/FooterMobile';
import styles from "./termsConditionsSettingMobile.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import { getTermsAndConditions } from "../../store/thunks/termsAndConditionsThunk";
import SuccessMessage from '../successMessage/SucessMessage';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  ContentState,
  convertFromHTML
} from 'draft-js';
import { convertToHTML } from 'draft-convert';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from "../shared/button";
import api from "../../api/axios.config";

const Selected = {
  RENTA: 'RENTA',
  RESERVA: 'RESERVA'
}
const TermsConditionsSettingMobile = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [display, setDisplay] = useState(false)

  const checkIfUserHasRoleAndRedirect = ()=>{
    let data = window.localStorage.getItem("user-sorella");
    let userObj = data && data.length > 0 ? JSON.parse(data) : null;
    if(userObj){
      if(userObj.role?.toLowerCase() !== 'administrator'){
        navigate('/principal');
      }else{
        return;
      }
    }else{
      navigate('/principal');
    }
  }

  checkIfUserHasRoleAndRedirect();
  const [editorRent, setEditorRent] = useState('');
  const [editorReserve, setEditorReserve] = useState('');

  const [currentSelected, setCurrentSelected] = useState(Selected.RENTA);
  const { dataTermsConditions } = useSelector((store) => store.termsAndConditions);
  const [type, setType] = useState('reservation');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTermsAndConditions(currentSelected === Selected.RENTA ? 'rent' : 'reservation'));
  },[currentSelected]);

  useEffect(() => {
    if(dataTermsConditions){
      convertStateEditor();
    }
  },[dataTermsConditions])

  const convertStateEditor = (value) => { 
    const blocksFromHTML = convertFromHTML(dataTermsConditions);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    if(currentSelected === Selected.RENTA)
      setEditorRent(EditorState.createWithContent(state))
    else
      setEditorReserve(EditorState.createWithContent(state))
}

	const handleSubmit = async (type) => {
    const blocks = convertToHTML(type === 'rent' ? editorRent.getCurrentContent() : editorReserve.getCurrentContent());
    await api.post('/api/terms-conditions', { type, data: blocks })
    .then(result => {
      if(result.status === 200){
        setDisplay(true);
        setType(type)
      }
    })
  };

  return (
    <>
      <div style={{minHeight: '100%'}}>
        <section className={styles.navMobile}>
          {width <= 1024 ? <NavBarMobile /> : <NavbarDesktop />}
        </section>
        
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <section className={styles.containerTerms}>
            <div className={styles.titleTermsContainer}>
              <span className={styles.titleTerms}>Términos y Condiciones</span>
            </div>
            <section className={styles.rentBookingButtonsArea}>
              <div style={{minWidth: '50%', display: 'block', justifyContent: 'center'}}>
                <button style={{textAlign: 'center', width: '100%'}} className={`${styles.rentBookingButton} ${currentSelected === Selected.RENTA ? styles.colorSelected : ''}` } onClick={() => setCurrentSelected(Selected.RENTA)}>
                  RENTA
                </button>
                <div className={currentSelected === Selected.RENTA ? styles.selected : ""}></div>
              </div>
              <div style={{minWidth: '50%', display: 'block', justifyContent: 'center'}}>
                <button style={{textAlign: 'center', width: '100%'}} className={`${styles.rentBookingButton} ${currentSelected === Selected.RESERVA ? styles.colorSelected : ''}`} onClick={() => setCurrentSelected(Selected.RESERVA)}>
                  RESERVA
                </button>
                <div className={ currentSelected === Selected.RESERVA ? styles.selected : ""}></div>
              </div>
            </section>
            {currentSelected === Selected.RENTA ?
            <>
              <SuccessMessage label="El archivo se actualizó correctamente" display={display && type === 'rent'} />
              <div>
                <Editor
                
                  editorState={editorRent}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  localization={{ locale: 'es' }}
                  onEditorStateChange={(changes) => setEditorRent(changes)}
                />
                <Button label="Guardar cambios" onClick={() => handleSubmit('rent')} />
              </div>
            </>
            :
            <>
              <SuccessMessage label="El archivo se actualizó correctamente" display={display && type === 'reservation'} />
              <div>
                <Editor
                  editorState={editorReserve}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  localization={{ locale: 'es' }}
                  onEditorStateChange={(changes) => setEditorReserve(changes)}
                />
                <Button label="Guardar cambios" onClick={() => handleSubmit('reservation')} />
              </div>
            </>
            }
          </section>
        </div>
      </div>
      {width <= 1024 ? <FooterMobile /> : <Footer /> }
    </>
  );
};

export default TermsConditionsSettingMobile;
