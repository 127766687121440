import React from "react";
import styles from "./newestDressesMobile.module.css";
import { Icon } from "@iconify/react";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import FooterMobile from "../footerMobile/FooterMobile";
import CardDress from "../cardDress/CardDress";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllDresses } from "../../store/thunks/dressThunk";
import {
  onSetFilterValues
} from "../../store/slices/filters/filtersSlice";
import { createKey, getMonths, setParamsToUrl, urlSearchParams } from "../../utils";
import filterSvg from "../../assets/icons/tuner.svg";
import leftArrow from "../../assets/icons/chevron-left.svg";
import rightArrow from "../../assets/icons/chevron-right.svg";
import { modal } from '../modalManagment';
import ModalFilterItems from "../modal/ModalFilterItems";
import { useState } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const NewestDressesMobile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [itemView, setItemView] = useState([]);

  const { 
    valuesFilter: {
      valueColor,
      valueTypeEvent, 
      valueCalendar,
      valueBrand,
      valueLength,
      valueSizes,
      valueNecklines,
      valuePrices
    }
   } = useSelector((store) => store.filters)
  
  // const [valuesFilters, setValuesFilters] = useState({
  //   valueColor: '',
  //   valueTypeEvent: [], 
  //   valueCalendar: ['',''],
  //   valueBrand: [],
  //   valueLength: [],
  //   valueSizes: [],
  //   valueNecklines: [],
  //   valuePrices: ['','']
  // })

  const refPage = useRef(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState('1')
  const [perPage, setPerPage] = useState('8');
  //const [params, setParams] = useState({})
  const [dataDress, setDataDress] = useState([]);
  const { allDresses } = useSelector((store) => store.dressSearchResults);
  // const {
  //   occasionValue,
  //   sizeValue,
  //   calendarRange,
  //   largeValue,
  //   neckLineValue,
  //   brandValue,
  // } = useSelector((store) => store.filters);

  const getMapQueryDress = () => {
    const items = [];

    if(valueColor){
      items.push({ name: 'colors', params: valueColor })
    }

    if(valueTypeEvent.length > 0){
      items.push({ name: 'type_event_ids', params: valueTypeEvent[0] })
    }

    if(valueCalendar.length > 0 && valueCalendar[0] !== ''){
      items.push({ name: 'date_start', params: moment(valueCalendar[0]).format('YYYY/MM/DD') })
      items.push({ name: 'date_end', params: moment(valueCalendar[1]).format('YYYY/MM/DD') })
    }

    if(valueBrand.length > 0){
      items.push({ name: 'marca_ids', params: valueBrand[0] })
    }

    if(valueLength.length > 0){
      items.push({ name: 'largo_ids', params: valueLength[0] })
    }

    if(valueSizes.length > 0){
      items.push({ name: 'talla_ids', params: valueSizes[0] })
    }

    if(valueNecklines.length > 0){
      items.push({ name: 'escote_ids', params: valueNecklines[0] })
    }

    if(valuePrices.length > 0 && valuePrices[0] !== ''){
      items.push({ name: 'min_precio', params: valuePrices[0] })
      items.push({ name: 'max_precio', params: valuePrices[1] })
    }

    return items
  }

  const fetchData = () => {
    const search = urlSearchParams(window.location.href)
    let query = ''
    if(search && search.object){
      Object.keys(search.object).map(item => {
        query += `&${item}=${search.object[item]}`
        return null
      })
    }

    dispatch(getAllDresses(`?page=${page}&per_page=${perPage}${query}&newest=true`));
  };

  useEffect(
    () => {
      fetchData();
    },
    [page, perPage, window.location.href]
  );

  useEffect(() => {
    if(getMapQueryDress().length > 0){
      setParamsToUrl(navigate, "/resultados-vestidos", getMapQueryDress());
    }else{
      navigate('/lo-mas-nuevo')
    }
  },[
    valueColor,
    valueTypeEvent, 
    valueCalendar,
    valueBrand,
    valueLength,
    valueSizes,
    valueNecklines,
    valuePrices
  ])

  useEffect(() => {
    if(location.state?.itemView && itemView.length <= 0){
      setItemView(location.state?.itemView)
    }
  },[location])

  useEffect(() => {
    if(allDresses.data){
      if(typeof allDresses.data === 'object'){
        refPage.current.scrollIntoView({ behavior: 'smooth' })
        setDataDress(Object.values(allDresses.data))
      }
    }
  },[allDresses])

  const onModalFilter = () => {
    modal.open(
      <ModalFilterItems 
        setItemView={setItemView}
        navigate={navigate} />, 
      () => {}, 
      'BackdropContainerHeightTop'
    )
  }

  return (
    <>
      <div ref={refPage} className={styles.containerResultsMobile}>
        <section className={styles.navMobile}>
          <NavBarMobile />
        </section>
        <section className={styles.topSection}>
          <div className={styles.titleAndFilter}>
            <span className={styles.titleStyles}>Lo más nuevo</span>
            <div className={styles.inlineWrap}>
              {(itemView) && itemView.map(item => 
              <div className={styles.filtersArea} key={createKey()}>
                <section className={styles.filterItem}>
                  {item.value}
                  <button
                    className={styles.close}
                    onClick={() => {
                      dispatch(onSetFilterValues({
                        [item.id]: item.id === 'valueColor' ? '' : (item.id === 'valueCalendar' || item.id === 'valuePrices') ? ['', ''] : []
                      }))
                      setItemView([...itemView.filter(v => v.id !== item.id)])
                    }}
                  >
                    <Icon
                      icon="eva:close-outline"
                      color={'#434A48'}
                      width="10px"
                      height="10px"
                    />
                  </button>
                </section>
              </div>
              )}
            </div>
          </div>
          <div className={styles.btnFilterAndView}>
            <section className={styles.btnFilterWrapper} onClick={onModalFilter}>
              <img src={filterSvg} alt="" className={styles.filterSvg} />
              <span className={styles.spanFilter}>Filtros</span>
            </section>

            <section className={styles.modelsForEachPage}>
              <span className={styles.titlePaginationModels}>Vista:</span>
              <select className={styles.qtyModels} defaultValue={perPage} onChange={(e) => setPerPage(e.target.value)}>
                <option value="8">8</option>
                <option value="12">12</option>
                <option value="16">16</option>
                <option value="20">20</option>
              </select>
            </section>
          </div>
        </section>

        {/* middle top section --end*/}

        <section className={styles.allDressSearchContainer}>
          {dataDress && 
            dataDress.map((dress, index) => (
            <div key={index} className={styles.cardBackGround}>
              <CardDress
                dressModel={dress.dress_models}
                url={dress.dress_models?.images[0]?.url}
                id={dress.id}
              />
            </div>
          ))}
        </section >

        <section className={styles.paginationWrapper}>
            <span className={styles.spanPage}>Página:</span>
            <div className={styles.paginationContainer}>
              <img src={leftArrow} alt="" className={styles.arrows} onClick={() => page > 1 ? setPage((+page) - 1) : {}}></img>
              <div className={styles.pages}>
                <span>{allDresses.current_page}</span>
                <span>&nbsp;/&nbsp;</span>
                <span>{allDresses.last_page}</span>
              </div>
              <img src={rightArrow} alt="" className={styles.arrows} onClick={() => page < (+allDresses.last_page) ? setPage((+page) + 1) : {}}></img>
            </div>
        </section>
      </div>
      <FooterMobile />
    </>
  );
};

export default NewestDressesMobile;
