import { createSlice } from '@reduxjs/toolkit';

export const shopCart = createSlice({
    name: 'shopCart',
    initialState: {
        cart: []
    },
    reducers: {
        initShopCart: (state, action) => {
            state.cart = action.payload ?? []
        },
        setShopCart: (state, action) => {
            state.cart.push(action.payload);
        },
        removeProduct: (state, action) => {
            state.cart = [...state.cart.filter(v => action.payload.ids.includes(v.cart_id) === false)]
        },
        shopCartArray: (state, action) => {
            state.cart = [...state.cart, ...action.payload.cart]
        },
        removeAllProduct: (state) => {
            state.cart = []
        },
        updateQuantity: (state, action) => {
            const auxProduct = state.cart.find((c) => c.cart_id === action.payload?.cart_id)
            auxProduct.quantity = action.payload?.quantity
            const products = [...state.cart.filter((c) => c.cart_id !== action.payload?.cart_id), auxProduct].sort((a, b) => a.cart_id - b.cart_id)
            state.cart = products
        }
    }
});

export const { initShopCart, setShopCart, removeProduct, shopCartArray, removeAllProduct, updateQuantity } = shopCart.actions;