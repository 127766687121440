import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styles from './DetailsAppointment.module.css';
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from '../navBarMobile/NavBarMobile';
import Footer from "../footer/Footer";
import FooterMobile from '../footerMobile/FooterMobile';
import { TextDescription, Title, TextLinking } from "../shared/text/Text";
import Breadcrumb from "../navigation/breadcrumb/Breadcrumb";
import CardProduct from "../shopCart/CardProduct";
import moment from 'moment';
import SuccessMessage from '../successMessage/SucessMessage';
import Button from '../shared/button';
import axios from '../../api/axios.config';
import { useState } from 'react';
import { useEffect } from 'react';
import { modal } from '../modalManagment';
import ToggleDisplay from '../shared/toggleDisplay/ToggleDisplay';
import ModalSettingDress from '../modal/ModalSettingDress';
import ModalSettingOk from '../modal/ModalSettingOk';

const DetailsAppointment = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { width } = useWindowDimensions();
    const [dressModel, setDressModel] = useState({});
    const [status, setStatus] = useState('');
    const [appointment, setAppointment] = useState(false);
    const { id } = useParams();
    const [user, setUser] = useState({});

    const onGetDress = async () => {
        await axios.get(`/api/dress-models/${location.state?.modelid}`)
        .then(resp => {
          if(resp.status === 200){
            setDressModel(resp.data.data)
          }
        })
        .catch(err => console.log(err))
    }

    const getRent = async () => {
      await axios.get(`/api/rent/${location.state?.id}`)
      .then(resp => {
        if(resp.data.data){
          const appmt = resp.data.data

          setStatus(`Rentado para ${moment(appmt.end_date).format('DD - MMM YYYY')}`)

          if(appmt.status === 'cita_agendada'){
            setStatus(`Cita agendada: ${moment(appmt.appointment.date).format('DD - MMM YYYY hh:mmA')}`)
            setUser(appmt.order?.user)
            setAppointment(appmt.appointment)
          }

          if(appmt.status === 'ajustes_listos'){
            setStatus(`Tus ajustes estan listos el: ${moment(appmt.appointment.ajustes_at).format('DD - MMM YYYY hh:mmA')}`)
          }

        }
      })
      .catch(error => console.log(error))
    }

    const onModalSettings = () => {
      modal.open(<ModalSettingDress app={id} navigate={navigate} />, () => {}, 'BackdropContainerCenter')
    }

    const onSubmitSettingOk = async () => {
      modal.close()
      await axios.post(`/api/ajustes-listos/${id}`)
      .then(resp => {
        if(resp.status === 200){
          navigate('/citas-agendadas')
        }
      })
      .catch(e => console.log(e))
    }

    const onModalSettingOk = () => {
      modal.open(
        <ModalSettingOk onClick={() => onSubmitSettingOk()} />, () => {}, 'BackdropContainerCenter'
      )
    }

    useEffect(() => {
      console.log(user)
      getRent()
      onGetDress();
    },[location.state])

    return (
        <>
        {width <= 1024 ?
          <div>
            <section>
              <NavBarMobile isSelectedProfile/>
            </section>
            <section className={styles.container}>
              <Title>DETALLES DE {location.state?.pref ? location.state?.pref : 'CITA'}</Title>
    
              <Breadcrumb items={[{name: 'Citas agendadas', action: () => navigate('/citas-agendadas') }, {name: dressModel.name, action: () => {}}]} />
            </section>
            {location.state?.pref !== 'AJUSTES' && 
              <SuccessMessage display={true} label={status} />
            }
            <section className={styles.container}>
              <div className={styles.containerCart}>
                <CardProduct data={{...dressModel, type: 'RENTA'}} disabled={true} key={Math.random()} calendarRange={[moment().toISOString(),moment().add(1, 'd').toISOString()]} sizeValue={dressModel.dress ? dressModel.dress[0].size.description : ''} colorValue={dressModel.dress ? dressModel.dress[0].color.name : ''} />
              </div>
              <ToggleDisplay label="TALLAS CLIENTA" show={true}>
                {user &&
                  <>
                  <ul style={{margin: 0, padding: 0, marginLeft: 15}}>
                    <li>
                      <TextDescription>Altura: {user?.menasurement ? `${user?.menasurement?.height}` : ''}</TextDescription>
                    </li>
                    <li>
                      <TextDescription>Talla de busto: {user?.menasurement ? `${user?.menasurement?.bust}` : ''}</TextDescription>
                    </li>
                    <li>
                      <TextDescription>Medida cintura: {user?.menasurement ? `${user?.menasurement?.waist}` : ''}</TextDescription>
                    </li>
                    <li>
                      <TextDescription>Medida cadera: {user?.menasurement ? `${user?.menasurement?.hip}` : ''}</TextDescription>
                    </li>
                    <li>
                      <TextDescription>Talla usual de vestido: {user?.menasurement ? `${user?.menasurement?.size}` : ''}</TextDescription>
                    </li>
                  </ul>
                  </>
                  }
              </ToggleDisplay>

              <ToggleDisplay label="DATOS CLIENTA" show={true}>
                {user &&
                <>
                  <TextDescription>{user.name ? `${user.name} ${user.lastname}` : 'Sin nombre registrado'}</TextDescription>
                  <TextDescription>{user.email ? `${user.email}` : ''}</TextDescription>
                  <TextDescription>{user.phone ? `${user.phone}` : ''}</TextDescription>
                </>
                }
              </ToggleDisplay>

              {location.state?.pref === 'AJUSTES' && 
                <ToggleDisplay label="AJUSTES PENDIENTES" show={true}>
                  <TextDescription>{location.state?.appointment?.ajustes_pendientes}</TextDescription>
                  <TextDescription style={{marginTop: 20}}>{'Ajustes listos para: '+moment(location.state?.date).format('DD/MM/YYYY')}</TextDescription>
                </ToggleDisplay>
              }

              {location.state?.pref !== 'DETALLE' && <Button onClick={() => location.state?.pref === 'AJUSTES' ? onModalSettingOk() : onModalSettings()} label={location.state?.pref === 'AJUSTES' ? 'AJUSTES LISTOS' : "INICIO DE AJUSTES"}></Button>}
            </section>
          </div>
          :
          <div>
            <section>
                <NavBarDesktop />
            </section>
            <section className={styles.container}>
              <Title>DETALLES DE {location.state?.pref ? location.state?.pref : 'CITA'}</Title>
    
              <Breadcrumb items={[{name: 'Citas agendadas', action: () => navigate('/citas-agendadas') }, {name: dressModel.name, action: () => {}}]} />
            </section>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{width: 648}}>
                <SuccessMessage display={true} label={status} />
                <section className={styles.container}>
                <div className={styles.containerCart}>
                  <CardProduct data={{...dressModel, type: 'RENTA'}} disabled={true} key={Math.random()} calendarRange={[moment().toISOString(),moment().add(1, 'd').toISOString()]} sizeValue={dressModel.dress ? dressModel.dress[0].size.description : ''} colorValue={dressModel.dress ? dressModel.dress[0].color.name : ''} />
                </div>
                <ToggleDisplay label="TALLAS CLIENTA" show={true}>
                  {user &&
                  <>
                  <ul style={{margin: 0, padding: 0, marginLeft: 15}}>
                    <li>
                      <TextDescription>Altura: {user?.menasurement ? `${user?.menasurement?.height}` : ''}</TextDescription>
                    </li>
                    <li>
                      <TextDescription>Talla de busto: {user?.menasurement ? `${user?.menasurement?.bust}` : ''}</TextDescription>
                    </li>
                    <li>
                      <TextDescription>Medida cintura: {user?.menasurement ? `${user?.menasurement?.waist}` : ''}</TextDescription>
                    </li>
                    <li>
                      <TextDescription>Medida cadera: {user?.menasurement ? `${user?.menasurement?.hip}` : ''}</TextDescription>
                    </li>
                    <li>
                      <TextDescription>Talla usual de vestido: {user?.menasurement ? `${user?.menasurement?.size}` : ''}</TextDescription>
                    </li>
                  </ul>
                  </>
                  }
                </ToggleDisplay>

                <ToggleDisplay label="DATOS CLIENTA" show={true}>
                  {user &&
                  <>
                    <TextDescription>{user.name ? `${user.name} ${user.lastname}` : 'Sin nombre registrado'}</TextDescription>
                    <TextDescription>{user.email ? `${user.email}` : ''}</TextDescription>
                    <TextDescription>{user.phone ? `${user.phone}` : ''}</TextDescription>
                  </>
                  }
                </ToggleDisplay>

                  {location.state?.pref === 'AJUSTES' && 
                    <ToggleDisplay label="AJUSTES PENDIENTES" show={true}>
                      <TextDescription>{location.state?.appointment?.ajustes_pendientes}</TextDescription>
                      <TextDescription style={{marginTop: 20}}>{'Ajustes listos para: '+moment(location.state?.date).format('DD/MM/YYYY')}</TextDescription>
                    </ToggleDisplay>
                  }

                  {location.state?.pref !== 'DETALLE' && <Button onClick={() => location.state?.pref === 'AJUSTES' ? onModalSettingOk() : onModalSettings()} label={location.state?.pref === 'AJUSTES' ? 'AJUSTES LISTOS' : "INICIO DE AJUSTES"}></Button>}
                </section>
              </div>
            </div>
          </div>
          }
          {width <= 1024 ? <FooterMobile /> : <Footer /> }
        </>
      );
}

export default DetailsAppointment;