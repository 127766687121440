import NavBarDesktop from "../components/navBarDesktop/NavBarDesktop";
import { Title } from "../components/shared/text/Text";
import styles from "../components/stock/Stock.module.css";
import "../components/stock/stock.css";
import Footer from "../components/footer/Footer";
import { ConfigProvider, Tabs } from "antd";
import RentInventory from "../components/stock/RentInventory";
import SaleInventory from "../components/stock/SaleInventory";

const Stock = () => {
	const items = [
		{
			key: "1",
			label: `Renta`,
			children: <RentInventory />
		},
		{
			key: "2",
			label: "Venta",
			children: <SaleInventory />
		}
	];

	return (
		<>
			<div className={styles.container}>
				<section className={styles.nav}>
					<NavBarDesktop />
				</section>
				<div>
					<div className={styles.containerMobile} style={{ padding: 64 }}>
						<Title>Inventario</Title>
						<ConfigProvider
							theme={{
								token: {
									colorPrimary: '#5F4840',
								}
							}}
						>
							<Tabs defaultActiveKey="1" items={items} />
						</ConfigProvider>
						
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Stock;
