import React from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';

const ModalOptionB = ({onClick}) => {
    return (
        <div className={styles.containerModal}>
            <Title styles={styles.textModalTitle}>VESTIDO OPCIÓN B</Title>
            <TextDescription styles={styles.textDescription}>
                Tu opción B es un modelo de vestido diferente que te mandamos sin ningún costo extra, en caso de que tu selección original no te quede.<br/><br/>
                Te recomendamos seleccionar una talla más grande o chica, dependiendo de tu complexión.<br/><br/>
                Te enviaremos un catálogo especial a tu correo para que puedas seleccionar esta opción.
            </TextDescription>
            <Button label="ENtendido" onClick={(onClick)} />
        </div>
    )
}

export default ModalOptionB;