import React from "react";
import styles from "./eventDate.module.css";
import "react-calendar/dist/Calendar.css";
import CustomCalendar from "../customCalendar/CustomCalendar"
import { useSelector } from "react-redux";

const EventDate = (props) => {
  const { isCalendarDisable } = useSelector((store) => store.rentalProcess)

  return (
    <>
      <section style={{position: 'relative'}} className={styles.subTitleContainer}>
        {isCalendarDisable && <div style={{background: 'rgba(255, 255, 255, 0.4)', height: '100%', width: '100%', position: 'absolute'}} />}
        <div className={styles.collapsableHeader}>
          <span className={styles.subTitle}>¿Para qué fecha?</span>
        </div>
        <CustomCalendar enableSinceFirstLoad={false} {...props} />
      </section>
    </>
  );
};

export default EventDate;
