import axios from "axios"


export const front_base_url = process.env.REACT_APP_FRONT_URL ? process.env.REACT_APP_FRONT_URL : "http://localhost:8000"
const api = axios.create({
  baseURL: front_base_url
})

api
  .interceptors
  .request
  .use(function (config) {
    config.headers.common['Accept'] = 'application/json';
    config.headers.common['Content-Type'] = 'application/json';
    const accessToken = getToken();
    if (accessToken) {
      config.headers.common['Authorization'] = `Bearer ${getToken()}`;
    }
    return config
  }, function (error) {
    return ""
  })

api.interceptors.response.use(
  response => response,
  error => {

    if (error.response.status === 401 && error.config.method !== "get") {
      //window.location = ('/inicio-sesion');
    }
    if (error.response.status === 401 && window.location.pathname === "/favoritos") {
      localStorage.removeItem("sorellaToken");
      localStorage.removeItem("user");
      window.location = ('/inicio-sesion');
    }
    return Promise.reject(error);
  }
)

const getToken = () => {
  return window.localStorage.getItem('sorellaToken')?.replace(/['"]+/g, '');
}

export default api
