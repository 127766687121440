import React from "react";
import styles from "./ContactPage.module.css";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import NavbarDesktop from '../navBarDesktop/NavBarDesktop';
import FooterMobile from "../footerMobile/FooterMobile";
import { Title, TextDescription } from '../shared/text/Text';
import Footer from "../footer/Footer";
import useWindowDimensions from '../../hooks/useWindowDimensions'

const ContactPage = (props) => {
    const { width } = useWindowDimensions();

    return (
        <>
        <div style={{minHeight: '80%'}}>
            <section className={styles.navMobile}>
                {width <= 1024 ? <NavBarMobile /> : <NavbarDesktop />}
            </section>
            <div className={styles.containerDesktop}>
                <section className={styles.containerFaq}>
                    <div>
                        <Title styles={styles.title}>Contacto y sucursales</Title>
                    </div>
                </section>
                <section className={styles.containerFaq}>
                    <h2 className={styles.titleContact}>SUCURSAL GUADALAJARA</h2>
                    <TextDescription>Av Pablo Neruda 2273, Providencia, 44630 Guadalajara, Jal.</TextDescription>
                    <a href="https://www.google.com/maps/place/Av+Pablo+Neruda+2273,+Circunvalaci%C3%B3n+Americas,+44630+Guadalajara,+Jal.,+M%C3%A9xico/@20.6992158,-103.3777891,17z/data=!3m1!4b1!4m6!3m5!1s0x8428ae30a5700579:0x9ad362ace9d0546e!8m2!3d20.6992108!4d-103.3755951!16s%2Fg%2F11bw3zfcvk?hl=es-419" target="_blank" rel="noreferrer" className={styles.textMinLink}>Consulta el mapa</a>


                    <h2 className={styles.titleContact} style={{marginTop: 32}}>SUCURSAL LEÓN</h2>
                    <TextDescription>Av. Guanajuato 1201, Local 20 (dentro de Plaza Norte Dos), Jardines del Moral, 37160 León, Gto.</TextDescription>
                    <a href="https://www.google.com/maps/search/Av.+Guanajuato+1201,+Local+20,+Jardines+del+Moral,+37160+Le%C3%B3n,+Gto./@21.1478293,-101.6948021,17z/data=!3m1!4b1?hl=es-419" target="_blank" rel="noreferrer" className={styles.textMinLink}>Consulta el mapa</a>

                    <h2 className={styles.titleContact} style={{marginTop: 32}}>CORREO</h2>
                    <TextDescription>info@e-sorella.com</TextDescription>

                    <h2 className={styles.titleContact} style={{marginTop: 32}}>TELÉFONO</h2>
                    <TextDescription>(+52) 33 1435 6797</TextDescription>
                </section>
            </div>
        </div>
        {width <= 1024 ? <FooterMobile /> : <Footer />}
        </>
    );
};

export default ContactPage;
