import React, { useCallback, useRef } from "react";
import styles from './Modal.module.css';
import { Icon } from "@iconify/react";
import { modal } from "../modalManagment";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

const ModalMainImage = ({ image }) => {

    const imgRef = useRef();
    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;
        if (img) {
            const value = make3dTransformValue({ x, y, scale });
            img.style.setProperty("transform", value);
        }
    }, []);

    return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 70}}>
            <div>
                <div className={styles.inlineContainer}>
                    <div styles={styles.textModalTitle}></div>
                    <Icon icon="eva:close-outline" color="#FFFFFF" fontSize={25} style={{paddingLeft: 40, marginRight: 20, marginBottom: 20}} onClick={() => modal.close()} />
                </div>
                <div>
                    <QuickPinchZoom onUpdate={onUpdate}>
                        <img
                            ref={imgRef}
                            src={image}
                            style={{width: '100%'}}
                            alt=""
                        />
                    </QuickPinchZoom>
                </div>
            </div>
        </div>
    )
}

export default ModalMainImage;