import api from "../../api/axios.config";
import {setAllFavorite, setAllPaginationFavorite} from "../slices/dressDetails/dressDetailsSlice";

export const postFavoritesAddFavorite = (id) => {
    return async (dispatch, getState) => {
        if(localStorage.getItem('sorellaToken')){
            const result = await api.post(`/api/add-favourite/${id}`);
            if(result.status === 200)
                dispatch(setAllFavorite(result.data));
        }else{
            window.location.href = '/inicio-sesion'
        }
    }
}

export const postFavoritesDeleteFavorite = (id) => {
    return async (dispatch, getState) => {
        const result = await api.post(`/api/delete-favourite/${id}`);
        if(result.status === 200)
            dispatch(getAllFavorites());
    }
}

export const getAllFavorites = () =>{
    return async (dispatch, getState) => {
        const result = await api.get(`/api/favourites-all`);
        if(result.status === 200)
            dispatch(setAllFavorite(result.data));
    }
}

export const getFavoritesPagination = (query = '') =>{
    return async (dispatch, getState) => {
        const result = await api.get(`/api/favourites${query}`);
        if(result.status === 200)
            dispatch(setAllPaginationFavorite(result.data));
    }
}