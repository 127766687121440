import React, { useRef, useState, useEffect } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import localStyles from "./faqProfileInfo.module.css";
import api from "../../../api/axios.config";
import axios from 'axios';
import Input, { LabelInput } from "../../shared/input/Input";

const FaqProfileInfo = ({ navigate, formConfig, dataUser, disabled, show, display }) => {
  const [isDisplay, setIsDisplay] = useState(show? show : false);
  const { watch } = formConfig;
  const interval = useRef(setTimeout(() => {}, 0));
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [suburbs, setSuburbs] = useState([]);
  const [state, setState] = useState('');
  const [phone, setPhone] = useState('');
  const [municipality, setMunicipality] = useState(null);

  const postalCodeWatcher = watch('postalCode');

  useEffect(() => {
    setIsDisplay(display)
  },[display])

  const getStateFromGoogleMaps = async (postalCode) => {
    if (postalCode.length === 5) {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${ postalCode }|country:MX&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`)
      if (response.status === 200 && response.data?.results?.length > 0) {
        const components = response.data?.results[0]?.address_components
        const state = components.find((component) => component.types.includes('administrative_area_level_1'));
        const municipality = components.find((component) => component.types.includes('locality'));
        const findState = states.find((s) => s.name === state.long_name);
        if (findState) {
          formConfig.setValue("estado", findState.id);
        }
        if (municipality) {
          setMunicipality(municipality);
        }
        if (response.data?.results[0]?.postcode_localities.length > 0) {
          setSuburbs(response.data?.results[0]?.postcode_localities);
        } else {
          const street = formConfig.getValues("address") ?? dataUser?.address[0]?.address_num_ext;
          const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(street)}&components=postal_code:${ postalCode }|country:MX&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`)
          if (response.status === 200 && response.data?.results?.length > 0) {
            const suburb = components.find((component) => component.types.includes('sublocality_level_1') || component.types.includes('sublocality'))
            if (suburb) {
              setSuburbs([suburb.long_name]);
            }
          }
        }
      }
    }
  }

  const getStates = async () => {
    await api.get('/api/states')
    .then(resp => {
        if(resp.status === 200){
          setStates(resp.data.data);
        }
    })
    .catch(error => console.log(error))
  }

  const getCities = async () => {
    await api.get(`/api/cities/${state || dataUser?.address[0]?.city?.state_id}`)
    .then(resp => {
        if(resp.status === 200){
          setCities(resp.data.data);
        }
    })
    .catch(error => console.log(error))
  }

  useEffect(() => {
    getStates()
    setPhone(dataUser?.phone)
  },[])

  useEffect(() => {
    getCities()
  }, [state, dataUser])

  useEffect(() => {
    if (dataUser) {
      getStateFromGoogleMaps(dataUser?.address[0]?.postal_code)
    }
  }, [dataUser])

  useEffect(() => {
    if (cities.length > 0 && municipality !== null) {
      const findCity = cities.find((c) => c.name === municipality.long_name)
      if (findCity) {
        formConfig.setValue('city', findCity.id);
      }
    }
  }, [cities, municipality])

  useEffect(() => {
    interval.current = setTimeout(() => getStateFromGoogleMaps(postalCodeWatcher), 500);
    return () => {
      clearTimeout(interval.current); // cleanup
    };
  }, [postalCodeWatcher])

  const onChangeValues = ({ target: { name, value }}) => {
    if(name === 'phone') {
      const phoneString = value.replace(/\D/g,'')
      if (phoneString.length <= 10) {
        formConfig.setValue(name, phoneString)
        const first = phoneString.substring(0, 2)
        const second = phoneString.substring(2, 6)
        const third = phoneString.substring(6, 10)
        if (phoneString.length > 6) setPhone(`${first} ${second} ${third}`)
        else if (phoneString.length > 2) setPhone(`${first} ${second}`)
        else if (phoneString.length > 0) setPhone(`${first}`)
        else setPhone('')
      }
    } else {
      formConfig.setValue(name, value)
    }
  }

  return (
    <>
      <button
        type="button"
        className={localStyles.profileInformation}
        onClick={toggleDisplay}
      >
        <span>INFORMACIÓN DE PERFIL</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <div>
          <section className={localStyles.inputSections}>
            <LabelInput label="Nombre(s)"></LabelInput>
            <Input
              placeholder="Nombre(s)"
              styles={localStyles.itemInput}
              defaultValue={dataUser?.name}
              disabled={disabled}
              name="userName"
              {...formConfig.register("userName")}
              onChange={(e) => onChangeValues(e)}
            ></Input>
          </section>
          <section className={localStyles.inputSections}>
            <LabelInput label="Apellido(s)"></LabelInput>
            <Input
              placeholder="Apellido(s)"
              styles={localStyles.itemInput}
              defaultValue={dataUser?.lastname}
              disabled={disabled}
              name="lastName"
              {...formConfig.register("lastName")}
              onChange={onChangeValues}
            ></Input>
          </section>
          <section className={localStyles.inputSections}>
            <LabelInput label="Fecha de nacimiento"></LabelInput>
            <Input
              placeholder="DD/MM/AAA"
              styles={localStyles.itemInput}
              defaultValue={dataUser?.birthday_date}
              type="date"
              disabled={disabled}
              name="birthday"
              {...formConfig.register("birthday")}
              onChange={onChangeValues}
              ></Input>
          </section>
          <section className={localStyles.inputSections}>
            <LabelInput label="Teléfono celular"></LabelInput>
            <Input
              placeholder="(+52)"
              styles={localStyles.itemInput}
              disabled={disabled}
              defaultValue={dataUser?.phone}
              value={phone}
              name="phone"
              {...formConfig.register("phone")}
              onChange={onChangeValues}
              ></Input>
          </section>
          <section className={localStyles.inputSections}>
            <LabelInput label="Dirección"></LabelInput>
            <Input
              autoComplete="off"
              placeholder="Calle y número exterior"
              styles={localStyles.itemInput}
              disabled={disabled}
              defaultValue={dataUser?.address ? dataUser?.address[0]?.address_num_ext : ''}
              name="address"
              {...formConfig.register("address")}
              onChange={onChangeValues}
              ></Input>
          </section>
          <section className={localStyles.inputSections}>
            <Input
                autoComplete="off"
                placeholder="Número interior (opcional)"
                styles={localStyles.itemInput}
                disabled={disabled}
                defaultValue={dataUser?.address ? dataUser?.address[0]?.num_int : ''}
                name="insideNumber"
                {...formConfig.register("insideNumber")}
              onChange={onChangeValues}
              ></Input>
          </section>
          <section className={localStyles.inputSections}>
            <div className={localStyles.numberAndCode} style={{maxWidth: '92%'}}>
              <Input
                placeholder="Código postal"
                style={{width: '48%'}}
                styles={localStyles.itemInput1}
                disabled={disabled}
                defaultValue={dataUser?.address ? dataUser?.address[0]?.postal_code : ''}
                name="postalCode"
                {...formConfig.register("postalCode")}
                onChange={onChangeValues}
                ></Input>
              <select 
                name="estado" 
                id="estado" 
                disabled={true} 
                style={{width: '48%'}}
                className={localStyles.itemInputSelect}
                {...formConfig.register("estado")}
                onChange={e => {
                  setState(e.target.value)
                  formConfig.setValue('estado', e.target.value)
                }}
                >
                  <option>Estado</option>
                  {states.map(e => (
                    <option key={e.id} selected={dataUser?.address ? dataUser?.address[0]?.city?.state_id === Number(e.id) : ''} value={e.id}>{e.name}</option>
                  ))}
              </select>
            </div>
            <div className={localStyles.numberAndCode} style={{maxWidth: '92%'}}>
              <select 
                name="city" 
                id="city" 
                disabled={true} 
                style={{width: '48%'}}
                className={localStyles.itemInputSelect}
                {...formConfig.register("city")}
                onChange={onChangeValues}
                >
                <option>Ciudad</option>
                {cities.map(c => (
                  <option key={c.id} selected={dataUser?.address ? dataUser?.address[0]?.city_id === Number(c.id) : ''} value={c.id}>{c.name}</option>
                ))}
              </select>
              <select 
                name="colonia" 
                id="colonia" 
                disabled={disabled} 
                style={{width: '48%'}}
                className={localStyles.itemInputSelect}
                {...formConfig.register("colonia")}
                onChange={onChangeValues}
                >
                <option disabled selected hidden>Colonia</option>
                {suburbs.map((suburb) => (
                  <option key={suburb} selected={dataUser?.address ? dataUser?.address[0]?.colonia === suburb : false } value={suburb}>{suburb}</option>
                ))}
              </select>
            </div>
          </section>
          <section className={localStyles.inputSections}>
            <LabelInput label="Correo electrónico"></LabelInput>
            <Input
              placeholder="marialopez@gmail.com"
              styles={localStyles.itemInput}
              defaultValue={dataUser?.email}
              disabled={true}
              name="mail"
              {...formConfig.register("mail")}
                onChange={onChangeValues}
                ></Input>
          </section>
          <section className={localStyles.inputSections}>
            <LabelInput label="Contraseña"></LabelInput>
            <Input
              placeholder="******************"
              styles={localStyles.itemInput}
              defaultValue=""
              disabled={disabled}
              name="password"
              {...formConfig.register("password")}
              onChange={onChangeValues}
              ></Input>
          </section>
        </div>
      </div>
    </>
  );
};

export default FaqProfileInfo;
