import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import localStyles from "./faqRentalHistory.module.css";
import alertImg from "../../../assets/svg/provisional/alertImg.svg";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const FaqRentalHistory = ({ details }) => {
  const navigate = useNavigate()
  const [isDisplay, setIsDisplay] = useState(false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };
  return (
    <>
      <button
        type="button"
        className={localStyles.faqReservesAct}
        onClick={toggleDisplay}
      >
        <span>HISTORIAL DE RENTAS</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        // style={{marginBottom: 50}}
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        {(details && details.orders) &&
          details.orders.map(rent => (
            <div 
              style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '90%', cursor: 'pointer'}} 
              key={Math.random()} 
              className={localStyles.reservesDescriptionArea} 
              onClick={() => navigate('/detalle-historial-renta/'+rent?.dress?.dress_model_id, { state: { id: rent?.rent?.id, id_order: rent?.rent?.order_id } })}>
              <div className={styles.reservesDescriptionArea} style={{display: 'flex'}}>
                <img alt="" src={rent?.dress?.dress_models?.images[0]?.url} style={{width:35}}></img>
                <section className={localStyles.reservationArea}>
                  <span className={localStyles.modelSpan}>{rent?.dress?.dress_models?.name}</span>
                  <section className={localStyles.dateReservationArea}>
                      <img alt="" src={alertImg}></img>
                      <span className={localStyles.textReservationDate}>Rentado el: {moment(rent?.rent?.end_date).format('DD - MMM YYYY')}</span>
                  </section>
                </section>
              </div>
              <Icon icon="akar-icons:chevron-right" fontSize={11} />
            </div>
          ))}
      </div>
    </>
  );
};

export default FaqRentalHistory;