import Vestidos from '../../assets/cards-home/vestidos.svg'
import Tacones from '../../assets/cards-home/tacones.svg'
import Complementos from '../../assets/cards-home/complementos.svg'
import style from './rentalProcess.module.css'
import { useCallback, useEffect, useRef, useState } from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import ArrowNext from '../../assets/icons/ArrowNext'
import ArrowPrevious from '../../assets/icons/ArrowPrevious'
import { useNavigate } from 'react-router-dom'

const SaleSection = () => {
    const parent = useRef(null)
    const navigate = useNavigate();
    const { width } = useWindowDimensions()
    const [leftScrollWidth, setLeftScrollWidth] = useState(0);
    const [scrollWidth, setScrollWidth] = useState(0)
    const [clientWidth, setClientWidth] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [positions, setPositions] = useState([])

    const moveToright = () => {
        if (parent.current.scrollLeft < parent.current.scrollWidth) {
            if (currentIndex < 2) parent.current.scrollLeft = positions[currentIndex + 1];
            else parent.current.scrollLeft += 400
            setCurrentIndex(currentIndex + 1)
        }
    }

    const moveToLeft = () => {
        if (currentIndex > 1) parent.current.scrollLeft = positions[currentIndex - 1];
        else parent.current.scrollLeft -= 400
        setCurrentIndex(currentIndex - 1)
    }

    const handleScroll = useCallback(() => {
        setClientWidth(parent.current.clientWidth);
        setLeftScrollWidth(parent.current.scrollLeft)
        setScrollWidth(parent.current.scrollWidth)
    }, [])
    
    useEffect(() => {
        const div = parent.current
        div.addEventListener("scroll", handleScroll)
    }, [handleScroll])

    useEffect(() => {
        let position1 = document.getElementById("card-square").offsetLeft - 10
        let position2 = document.getElementById("card-rectangle-1").offsetLeft - 10
        let position3 = document.getElementById("card-rectangle-2").offsetLeft - 10
        setPositions([position1, position2, position3])
        console.log('Width:', width)
    }, [])

    return (
        <div style={{position: 'relative'}}>
            <div className={style.sectionSalesContainer}>
                <div className={style.titleSection}><p>Nuestros productos en venta</p></div>
                <div className={style.row} ref={parent} id="horizontal-scroller">
                    <div className={style.squareCard} id="card-square">
                        <div style={{position: 'absolute', zIndex: 9, left: 24}}><h2>Vestidos en venta</h2></div>
                        <img src={Vestidos} alt="" style={{objectFit: 'cover', height: '100%', width: '100%', borderRadius: 24}} />
                        <div style={{position: 'absolute', zIndex: 9, bottom: 24, left: 24}} onClick={() => navigate('/en-venta?type_product_ids=dresses')}><p>VER MÁS</p></div>
                    </div>
                    <div className={style.column}>
                        <div className={style.rectangleCard} id="card-rectangle-1">
                            <div style={{position: 'absolute', zIndex: 9, left: 24}}><h2>Tacones</h2></div>
                            <img src={Tacones} alt="" style={{objectFit: 'cover', height: '100%', width: '100%', borderRadius: 24}} />
                            <div style={{position: 'absolute', zIndex: 9, bottom: 24, left: 24}} onClick={() => navigate('/en-venta?type_product_ids=shoes')}><p>VER MÁS</p></div>
                        </div>
                        <div className={style.rectangleCard} id="card-rectangle-2">
                            <div style={{position: 'absolute', zIndex: 9, left: 24}}><h2>Complementos</h2></div>
                            <img src={Complementos} alt="" style={{objectFit: 'cover', height: '100%', width: '100%', borderRadius: 24, filter: 'brightness(0.8)'}} />
                            <div style={{position: 'absolute', zIndex: 9, bottom: 24, left: 24}} onClick={() => navigate('/en-venta?type_product_ids=complements')}><p>VER MÁS</p></div>
                        </div>
                    </div>
                </div>
                { width <= 900 && ((leftScrollWidth + clientWidth) < scrollWidth || leftScrollWidth === 0) &&
                    <div onClick={moveToright} style={{ cursor: 'pointer', position: 'absolute', height: '92%', width: 60, right: 0, top: 60, background: 'linear-gradient(to right, transparent 30%, #FFFFFF 70%)', zIndex: 9, display: 'flex', alignItems: 'center', justifyContent: 'right', paddingRight: '10px' }}>
                        <ArrowNext />
                    </div>
                }
                { width <= 900 && (leftScrollWidth > 0) &&
                    <div onClick={moveToLeft} style={{ cursor: 'pointer', position: 'absolute', height: '92%', width: 60, left: 0, top: 60, background: 'linear-gradient(to left, transparent 30%, #FFFFFF 70%)', zIndex: 9, display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: '10px'}}>
                        <ArrowPrevious />
                    </div>
                }
            </div>
        </div>
    )
}

export default SaleSection