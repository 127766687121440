import React from "react";
import styles from "./login.module.css";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setLogin, setReLoginPerformed } from "../../store/slices/login/loginSlice";
import api from "../../api/axios.config";
import { removeAllProduct, setShopCart } from "../../store/slices/shopCart";
import SuccessMessage from '../successMessage/SucessMessage';
import logoGoogle from '../../assets/icons/LogoGoogle.png'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'

const Login = () => {
  const dispatch = useDispatch()
  const { sorellaToken } = useSelector((store)=>store.login);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation()

  useEffect(()=>{
    if(sorellaToken.length > 0 && typeof localStorage.getItem('previousURL') === 'string'){
      navigate(localStorage.getItem('previousURL'));
      localStorage.setItem('previousURL', null);
      dispatch(setReLoginPerformed(true));
    }
  },[sorellaToken])

  const onSubmit = async (data) => {
    try {
      const user = {email: data.email, password:data.password}
      const result = await api.post("/api/auth/login", user);
      dispatch(removeAllProduct())
      dispatch(setLogin({token: result.data.access_token, user: result.data.user}));
      window.localStorage.setItem('sorellaToken', JSON.stringify(result.data.access_token));//save in local storage
      window.localStorage.setItem('user-sorella', JSON.stringify(result.data.user));

      const cartMemorie = JSON.parse(localStorage.getItem('memorie-dress'))
      if(cartMemorie) {
        dispatch(setShopCart({...cartMemorie}))
        localStorage.removeItem('memorie-dress')
        navigate(`/carrito`, { state: { type: cartMemorie.type } })
      } else {
        navigate('/principal')
      }
    } catch (error) {
      if(error.response.data){
        setError(error.response.data.input, {
          message: error.response.data.error,
        });
      }
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      console.log('Response:', response)
      try {
        const res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
            Accept: 'application/json'
          }
        })
        const data = res.data
        const user = { email: data.email, google_id: data.id }
        const result = await api.post("/api/auth/login", user);
        dispatch(removeAllProduct())
        dispatch(setLogin({token: result.data.access_token, user: result.data.user}));
        window.localStorage.setItem('sorellaToken', JSON.stringify(result.data.access_token));//save in local storage
        window.localStorage.setItem('user-sorella', JSON.stringify(result.data.user));

        const cartMemorie = JSON.parse(localStorage.getItem('memorie-dress'))
        if (cartMemorie) {
          dispatch(setShopCart({...cartMemorie}))
          localStorage.removeItem('memorie-dress')
          navigate(`/carrito`, { state: { type: cartMemorie.type } })
        } else {
          navigate('/principal')
        }
      } catch (error) {
        setError('email', {
          message: error.response.data.errors.correo_electronico ? 'El usuario ya existe, intenta recuperar tu contraseña.' : 'Algo salio mal.'
        })
      }
    },
    onError: error => console.log('Error:', error)
  });

  return (
    <>
      <div className="cardContainer">
        <span className="cardTitle">Únete para comenzar</span>

        <form
          className={styles.registerContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <SuccessMessage display={location.state?.forgotPassword} label="Contraseña actualizada correctamente!" />
          <div className="marginBottomType1">
            <span className="spanTopInputs">Ingresa tu correo electrónico</span>
            <input
              autoComplete="off"
              type="text"
              placeholder="Correo"
              className={`${errors?.email ? 'inputError' : ''} inputTypeText`}
              id="inputEmail"
              {...register("email", {
                required: 'required',
                pattern: {
                  value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Correo electrónico no válido",
                },
              })}
            />
            {errors.email && (
              <p className={styles.errorMessage}>{errors.email.message}</p>
            )}
          </div>
          <div className="marginBottomType1">
            <span className="spanTopInputs">Ingresa tu contraseña</span>
            <input
              autoComplete="off"
              type={"password"}
              placeholder="Contraseña"
              className={`${errors?.password ? 'inputError' : ''} inputTypeText`}
              id="inputPassword"
              {...register("password", {
                setValueAs: (v) => v.trim(),
                minLength: {
                  value: 6,
                  message: "Mínimo 6 caracteres",
                },
                validate: {
                  trim: (v) => {
                    if (!v.trim()) return "Contraseña no válida";
                    return true;
                  },
                },
              })}
            />
            {errors.password && (
              <p className={styles.errorMessage}>{errors.password.message}</p>
            )}
            <Link to={"/recuperar-contrasena"} className={styles.forgetPassword}>
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
          <button type="submit" style={{background: '#000'}} className="primaryButton">
            Iniciar sesión
          </button>
          <div className={styles.centerDivider}>
            O
          </div>
          <div className="socialMediaButton" onClick={googleLogin}>
            <img src={logoGoogle} width="25px" height="25px" alt=""/>
            Ingresa con Google
          </div>
          <div className={styles.noAccountYetRegister} style={{cursor: 'pointer'}} onClick={()=> navigate('/registro')}>
            ¿No tienes cuenta? Regístrate aquí
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
