import React, { useState } from "react";
import stylesLocal from "./sizes.module.css";
import styles from "./detailsAndFilters.module.css"
import { AllDressSizes, AllShoesSizes, AllComplementsSizes } from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import {
    setAllColorsAvailables,
    onSetFilterValues,
    setSizeValue
} from "../../store/slices/filters/filtersSlice";
import { createKey, getUniqueValues } from "../../utils";
import ToggleCustomDisplay from "../shared/toggleCustomDisplay/ToggleDisplay";
import { useEffect } from "react";

const Sizes = ({ type, valuesFilters, setValuesFilters, showOnlyAvailables = true }) => {
    const dispatch = useDispatch();
    const { dressModelDetail: model } = useSelector((store) => store.dressDetails);
    const { colorValue } = useSelector((store) => store.filters)
    const [allSizes, setAllSizes] = useState([])
    const [availableSizes, setAvailableSizes] = useState([])

    const selectSize = (size) => {
        const filterBySize = model[type]?.find((d) => d.size.description?.toLowerCase() === size.toLowerCase() || d.size.size?.toLowerCase() === size.toLowerCase())
        if (filterBySize && size) setValuesFilters({ valueSize: filterBySize.size?.size ?? filterBySize.size?.description })
        dispatch(setSizeValue(size))
    }

    const checkIfDisabled = (size) => !availableSizes.includes(size)

    useEffect(() => {
        if (model) {
            setAvailableSizes(
                model[type]?.filter((d) => parseInt(d.color.id) === parseInt(colorValue) || d.color.code === colorValue)
                .map((d) => d.size?.size ?? d.size?.description)
                .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
            )
        }
    }, [colorValue, model])

    useEffect(() => {
        if (type === 'sale_dresses') setAllSizes(AllDressSizes)
        else if (type === 'shoes') setAllSizes(AllShoesSizes)
        else if (type === 'complements') setAllSizes(AllComplementsSizes)
    }, [type])

    return (
        <ToggleCustomDisplay
            show={valuesFilters.valueSize === ""}
            label="TALLAS"
            checked={valuesFilters.valueSize !== ""}
            style={stylesLocal.buttonSizeContainer}
            styleContainer={stylesLocal.buttonSizeContainer}
            value={valuesFilters.valueSize ?? null}
        >
            <div className={stylesLocal.buttonsContainer}>
            { colorValue !== '' ? 
                allSizes.map((size) => {
                    if (!checkIfDisabled(size))
                        return (
                            <button
                                key={createKey()}
                                onClick={() => selectSize(size)}
                                className={`${stylesLocal.buttonSize} ${ valuesFilters.valueSize === size ? stylesLocal.selected : "" }`}
                            >
                                {size}
                            </button>
                        ) 
                    else if (!showOnlyAvailables) {
                        return (
                            <button
                                key={createKey()}
                                className={`${stylesLocal.buttonSize}`}
                                disabled={true}
                            >
                                {size}
                            </button>
                        )
                    }
                    else return null
                })
            :
                <span className={styles.textDescription}>Selecciona un color para ver las tallas disponibles</span>
            }
            </div>
        </ToggleCustomDisplay>
    );
};

export default Sizes;
