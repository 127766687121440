import React from "react";

 const BolsaIcon = ({ color, isSelected }) => 
    {
        return (<>{
        isSelected ? 
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 19.5H15C17.2091 19.5 19 17.7091 19 15.5V4.50001C19 2.29087 17.2091 0.500014 15 0.500012L5 0.5C2.79086 0.499997 1 2.29086 1 4.5L1 15.5C1 17.7091 2.79086 19.5 5 19.5Z" fill="#5F4840"/>
            <path d="M15 19.5L15 19H15V19.5ZM5 19.5L5 20H5V19.5ZM19 4.50001L18.5 4.50001V4.50001H19ZM19 15.5L19.5 15.5V15.5H19ZM1 4.5L1.5 4.5L1 4.5ZM15 0.500012L15 1.00001L15 0.500012ZM5 0.5L5.00001 0L5 0.5ZM14.5 5.61539L14 5.61539L14 5.61539L14.5 5.61539ZM5.49996 5.61539L4.99996 5.61539L4.99996 5.61539L5.49996 5.61539ZM5.99996 4.15385C5.99996 3.87771 5.77611 3.65385 5.49996 3.65385C5.22382 3.65385 4.99996 3.87771 4.99996 4.15385H5.99996ZM15 4.15385C15 3.87771 14.7761 3.65385 14.5 3.65385C14.2238 3.65385 14 3.87771 14 4.15385H15ZM15 19H5V20H15V19ZM18.5 4.50001V15.5H19.5V4.50001H18.5ZM0.500001 4.5L0.5 15.5L1.5 15.5L1.5 4.5L0.500001 4.5ZM15 1.15835e-05L5.00001 0L5 1L15 1.00001L15 1.15835e-05ZM19.5 4.50001C19.5 2.01473 17.4853 1.44623e-05 15 1.15835e-05L15 1.00001C16.933 1.00001 18.5 2.56702 18.5 4.50001L19.5 4.50001ZM1.5 4.5C1.5 2.567 3.06701 0.999998 5 1L5.00001 0C2.51472 -2.87882e-06 0.500001 2.01472 0.500001 4.5L1.5 4.5ZM5 19C3.067 19 1.5 17.433 1.5 15.5L0.5 15.5C0.5 17.9853 2.51472 20 5 20L5 19ZM15 20C17.4853 20 19.5 17.9852 19.5 15.5L18.5 15.5C18.5 17.433 16.933 19 15 19L15 20ZM14 5.61539C14 7.74863 12.2214 9.5 9.99996 9.5L9.99996 10.5C12.7491 10.5 15 8.32525 15 5.61539L14 5.61539ZM9.99996 9.5C7.7785 9.5 5.99996 7.74863 5.99996 5.61539L4.99996 5.61539C4.99996 8.32525 7.25086 10.5 9.99996 10.5L9.99996 9.5ZM5.99996 5.61539V4.15385H4.99996V5.61539H5.99996ZM15 5.61539V4.15385H14V5.61539H15Z" fill="white"/>
        </svg>
        :
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 19.5L15 19H15V19.5ZM5 19.5L5 20H5V19.5ZM19 4.50001L18.5 4.50001V4.50001H19ZM19 15.5L19.5 15.5V15.5H19ZM1 4.5L1.5 4.5L1 4.5ZM15 0.500012L15 1.00001L15 0.500012ZM5 0.5L5.00001 0L5 0.5ZM14.5 5.61539L14 5.61539L14 5.61539L14.5 5.61539ZM5.49996 5.61539L4.99996 5.61539L4.99996 5.61539L5.49996 5.61539ZM5.99996 4.15385C5.99996 3.87771 5.77611 3.65385 5.49996 3.65385C5.22382 3.65385 4.99996 3.87771 4.99996 4.15385H5.99996ZM15 4.15385C15 3.87771 14.7761 3.65385 14.5 3.65385C14.2238 3.65385 14 3.87771 14 4.15385H15ZM15 19H5V20H15V19ZM18.5 4.50001V15.5H19.5V4.50001H18.5ZM0.500001 4.5L0.5 15.5L1.5 15.5L1.5 4.5L0.500001 4.5ZM15 1.15835e-05L5.00001 0L5 1L15 1.00001L15 1.15835e-05ZM19.5 4.50001C19.5 2.01473 17.4853 1.44623e-05 15 1.15835e-05L15 1.00001C16.933 1.00001 18.5 2.56702 18.5 4.50001L19.5 4.50001ZM1.5 4.5C1.5 2.567 3.06701 0.999998 5 1L5.00001 0C2.51472 -2.87882e-06 0.500001 2.01472 0.500001 4.5L1.5 4.5ZM5 19C3.067 19 1.5 17.433 1.5 15.5L0.5 15.5C0.5 17.9853 2.51472 20 5 20L5 19ZM15 20C17.4853 20 19.5 17.9852 19.5 15.5L18.5 15.5C18.5 17.433 16.933 19 15 19L15 20ZM14 5.61539C14 7.74863 12.2214 9.5 9.99996 9.5L9.99996 10.5C12.7491 10.5 15 8.32525 15 5.61539L14 5.61539ZM9.99996 9.5C7.7785 9.5 5.99996 7.74863 5.99996 5.61539L4.99996 5.61539C4.99996 8.32525 7.25086 10.5 9.99996 10.5L9.99996 9.5ZM5.99996 5.61539V4.15385H4.99996V5.61539H5.99996ZM15 5.61539V4.15385H14V5.61539H15Z" fill="#5F4840"/>
        </svg>
        }
        </>
    )
}

export default BolsaIcon;
