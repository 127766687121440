import styles from './card.module.css'
import visaLogo from '../../assets/icons/visa-logo.png'
import mastercardLogo from '../../assets/icons/mastercard-logo.png'
import Trash from '../../assets/icons/Trash'

const Card = ({ cardObject, onClick }) => (
    <div className={`${styles.cardContainer} ${ cardObject.brand === 'visa' ? styles.visaCard : styles.masterCard }`}>
        <div className={styles.cardNumber}>**** **** **** { cardObject.last_digits }</div>
        <div className={styles.cardInfo}>
            <p>{ cardObject.month_exp }/{ cardObject.year_exp?.slice(2) }</p>
            <img src={ cardObject.brand === 'visa' ? visaLogo : mastercardLogo } alt=""  width="60" height="auto"/>
        </div>
        { cardObject.default ? ( 
            <div className={styles.default}>
                PREDETERMINADA
            </div>
        ) : null }
        <div className={styles.delete} onClick={onClick}>
            <Trash color="white"/>
        </div>
    </div>
)

export default Card