import React from "react";
import styles from './Button.module.css';

const Button = (props) => (
    <button disabled={props.disabled ? props.disabled : false} onClick={props.onClick} style={props.style ? props.style : {}} type={props.type ? props.type : 'button'} className={`${props.outline ? styles.buttonOutline : styles.buttonPrimary} ${props.styles ?? props.styles} ${props.disabled ? styles.buttonDisabled : ''}`}>
        {props.children}
        <span style={props.styleText ? props.styleText : {}} className={`${props.outline ? styles.textOutline : styles.buttonText} ${props.stylestext}`}>{props.label}</span>
    </button>
)

export default Button;