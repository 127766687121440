import React, { useRef } from "react";
import styles from "./dressSearchResultsDesktop.module.css";
import { Icon } from "@iconify/react";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import Footer from "../footer/Footer";
import CardDress from "../cardDress/CardDress";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SearchFilters from "../searchFilters/SearchFilters";
import LoaderIcon from "../../assets/svg/loader-icon.svg";
import { onSetFilterValues } from "../../store/slices/filters/filtersSlice";
import { useState } from "react";
import { createKey, setParamsToUrl, urlSearchParams } from "../../utils";
import leftArrow from "../../assets/icons/chevron-left.svg";
import rightArrow from "../../assets/icons/chevron-right.svg";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PaginationComponent from "../shared/paginationNumeric/PaginationNumeric";
import DressNotFound from "../dressNotFound/DressNotFound";
import { getAllFavorites } from "../../store/thunks/favoritesThunk";
import api from "../../api/axios.config";
import { setURLBeforeLeave } from "../../store/slices/navigation/navigationSlice";
import UpArrow from "../../assets/icons/UpArrow";

const DressSearchResultsDesktop = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [dataDress, setDataDress] = useState([]);
	const { previousURL } = useSelector((store) => store.navigation);
	const [allDresses, setAllDresses] = useState([]);
	const refPage = useRef(null);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(20);
	const [itemView, setItemView] = useState([]);
	const section2 = useRef(null);
	const [heightSection, setHeightSection] = useState("");
	const [fadeOut, setFadeOut] = useState(false);
	const [loading, setLoading] = useState(false);
	const [prevFilters, setPrevFilters] = useState([]);
	const [scrollUp, setScrollUp] = useState(false);
	const [collaboration, setCollaboration] = useState("Estilos para ti");
	const [isLoading, setIsLoading] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [isLastPage, setIsLastPage] = useState(false);
	const {
		valuesFilter: { valueColor, valueTypeEvent, valueCalendar, valueBrand, valueLength, valueSizes, valueNecklines, valuePrices, valueHashtags }
	} = useSelector((store) => store.filters);

	useEffect(() => {
		window.localStorage.setItem("first-init", true);
	}, []);

	const { valuesFilter } = useSelector((store) => store.filters);

	const getMapQueryDress = () => {
		const items = [];

		if (valueColor?.length > 0) {
			const vlc = [];
			for (let i = 0; i < valueColor.length; i++) vlc.push(valueColor[i]);
			items.push({ name: "colors", params: vlc.join(",") });
		}

		if (valueHashtags?.length > 0) {
			const hst = [];
			for (let i = 0; i < valueHashtags.length; i++) hst.push(valueHashtags[i]);
			items.push({ name: "hashtags_ids", params: hst.join(",") });
		}

		if (valueTypeEvent?.length > 0) {
			const vte = [];
			for (let i = 0; i < valueTypeEvent.length; i++) vte.push(valueTypeEvent[i]);
			items.push({ name: "type_event_ids", params: vte.join(",") });
		}

		if (valueCalendar?.length > 0 && valueCalendar[0] !== "") {
			items.push({ name: "date_start", params: moment(valueCalendar[0]).format("YYYY/MM/DD") });
			items.push({ name: "date_end", params: moment(valueCalendar[1]).format("YYYY/MM/DD") });
		}

		if (valueBrand?.length > 0) {
			const brd = [];
			for (let i = 0; i < valueBrand.length; i++) brd.push(valueBrand[i]);
			items.push({ name: "marca_ids", params: brd.join(",") });
		}

		if (valueLength?.length > 0) {
			const vlg = [];
			for (let i = 0; i < valueLength.length; i++) vlg.push(valueLength[i]);
			items.push({ name: "largo_ids", params: vlg.join(",") });
		}

		if (valueSizes?.length > 0) {
			const vsz = [];
			for (let i = 0; i < valueSizes.length; i++) vsz.push(valueSizes[i]);
			items.push({ name: "talla_ids", params: vsz.join(",") });
		}

		if (valueNecklines?.length > 0) {
			const vnk = [];
			for (let i = 0; i < valueNecklines.length; i++) vnk.push(valueNecklines[i]);
			items.push({ name: "escote_ids", params: vnk.join(",") });
		}

		if (valuePrices?.length > 0 && valuePrices[0] !== "") {
			items.push({ name: "min_precio", params: valuePrices[0] });
			items.push({ name: "max_precio", params: valuePrices[1] });
		}

		if (items.length > 0 && JSON.stringify(prevFilters) !== JSON.stringify(items)) {
			setPrevFilters(items);
			setPage(1);
		}

		return items;
	};

	const getUrlParamsDress = () => {
		const queryParams = urlSearchParams(location.search);
		let search = null;
		let colors = [];
		let hashtags = [];
		let typeEvents = [];
		let calendar = [];
		let brands = [];
		let lengths = [];
		let sizes = [];
		let necklines = [];
		let prices = [];
		const items = [];

		if (queryParams.object?.search && queryParams.object?.search !== "") {
			search = queryParams.object?.search;
		}
		if (queryParams.object?.colors?.split(",").length > 0) {
			items.push({ name: "colors", params: queryParams.object?.colors });
			colors = queryParams.object?.colors?.split(",").map((type) => parseInt(type));
		}
		if (queryParams.object?.hashtags_ids?.split(",").length > 0) {
			items.push({ name: "hashtags_ids", params: queryParams.object?.hashtags_ids });
			hashtags = queryParams.object?.hashtags_ids?.split(",");
		}
		if (queryParams.object?.type_event_ids?.split(",").length > 0) {
			items.push({ name: "type_event_ids", params: queryParams.object?.type_event_ids });
			typeEvents = queryParams.object?.type_event_ids?.split(",").map((type) => parseInt(type));
		}
		if (queryParams.object?.date_start && queryParams.object?.date_end) {
			items.push({ name: "date_start", params: moment(queryParams.object?.date_start).format("YYYY/MM/DD") });
			items.push({ name: "date_end", params: moment(queryParams.object?.date_end).format("YYYY/MM/DD") });
			calendar = [moment(queryParams.object?.date_start).format("YYYY/MM/DD"), moment(queryParams.object?.date_end).format("YYYY/MM/DD")];
		}
		if (queryParams.object?.marca_ids?.split(",").length > 0) {
			items.push({ name: "marca_ids", params: queryParams.object?.marca_ids });
			brands = queryParams.object?.marca_ids?.split(",").map((brand) => parseInt(brand));
		}
		if (queryParams.object?.largo_ids?.split(",").length > 0) {
			items.push({ name: "largo_ids", params: queryParams.object?.largo_ids });
			lengths = queryParams.object?.largo_ids?.split(",").map((length) => parseInt(length));
		}
		if (queryParams.object?.talla_ids?.split(",").length > 0) {
			items.push({ name: "talla_ids", params: queryParams.object?.talla_ids });
			sizes = queryParams.object?.talla_ids?.split(",").map((size) => parseInt(size));
		}
		if (queryParams.object?.escote_ids?.split(",").length > 0) {
			items.push({ name: "escote_ids", params: queryParams.object?.escote_ids });
			necklines = queryParams.object?.escote_ids?.split(",").map((neckline) => parseInt(neckline));
		}
		if (queryParams.object?.min_precio && queryParams.object?.max_precio) {
			items.push({ name: "min_precio", params: queryParams.object?.min_precio });
			items.push({ name: "max_precio", params: queryParams.object?.max_precio });
			prices = [queryParams.object?.min_precio, queryParams.object?.max_precio];
		}

		if ((items.length > 0 || search) && JSON.stringify(prevFilters) !== JSON.stringify(items)) {
			setPrevFilters(items);
		}

		return {
			search,
			items,
			colors,
			hashtags,
			typeEvents,
			calendar,
			brands,
			lengths,
			necklines,
			sizes,
			prices
		};
	};

	useEffect(() => {
		const mapQuery = getMapQueryDress();
		if (location.search.includes("search") && location.search !== "") {
			dispatch(
				onSetFilterValues({
					valueColor: [],
					valueTypeEvent: [],
					valueCalendar: ["", ""],
					valueBrand: [],
					valueLength: [],
					valueSizes: [],
					valueNecklines: [],
					valuePrices: ["", ""],
					valueHashtags: []
				})
			);
		} else if (location.search.includes("collaboration") && location.search !== "") {
			dispatch(
				onSetFilterValues({
					valueColor: [],
					valueTypeEvent: [],
					valueCalendar: ["", ""],
					valueBrand: [],
					valueLength: [],
					valueSizes: [],
					valueNecklines: [],
					valuePrices: ["", ""],
					valueHashtags: []
				})
			);
		} else if (mapQuery.length > 0 && (!location.search.includes("search") || location.search === "")) {
			setParamsToUrl(navigate, "/resultados-vestidos", mapQuery);
		} else if (mapQuery.length === 0 && (!location.search.includes("search") || location.search === "")) {
			navigate("/resultados-vestidos");
		}
	}, [valueColor, valueTypeEvent, valueCalendar, valueBrand, valueLength, valueSizes, valueNecklines, valuePrices, valueHashtags]);

	const fetchData = async (reset) => {
		if (reset) {
			setIsLastPage(false);
		} else if (isLastPage) {
			return;
		}

		setIsLoading(true);
		if (window.localStorage.getItem("first-init") === null) {
			setFadeOut(true);
		}

		const search = urlSearchParams(window.location.href);
		let query = "";
		if (search && search.object) {
			Object.keys(search.object).map((item) => {
				query += `&${item}=${search.object[item]}`;
				return null;
			});
		}

		setTimeout(() => {
			setLoading(true);
		}, 500);

		if (reset) toTop();

		const auxPage = reset ? 1 : page;

		const result = await api.get(`/api/dresses?page=${auxPage}&per_page=${perPage}${query}`);
		if (result.status === 200) {
			setAllDresses(result.data);
			if (result.data?.meta?.last_page === auxPage) {
				setIsLastPage(true);
			}
			if (result.data?.meta?.collaboration) {
				setCollaboration(result.data?.meta?.collaboration);
			}
			if (result.data.data && typeof result.data.data === "object") {
				if (reset) setDataDress(Object.values(result.data.data));
				else if (Object.values(result.data.data).length > 0) setDataDress([...dataDress, ...Object.values(result.data.data)]);
			}
		}
		setIsLoading(false);

		setTimeout(() => {
			setLoading(false);
			window.localStorage.removeItem("first-init");
			setFadeOut(false);
		}, 2000);
	};

	const handleScroll = () => {
		const position = window.scrollY;
		setScrollPosition(position);
		if (window.innerHeight + document.documentElement.scrollTop <= document.getElementById("dressSection").offsetHeight || isLoading) {
			return;
		}
		fetchData(false);
	};

	const toTop = () => {
		document.documentElement.scrollTop = 0;
	};

	useEffect(() => {
		console.log("Page:", page);
	}, [page]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [isLoading]);

	useEffect(() => {
		if (previousURL !== window.location.href) {
			setURLBeforeLeave(window.location.href);
		}
		if (previousURL !== null) fetchData(true);
	}, [perPage, window.location.href]);

	useEffect(() => {
		if (allDresses && allDresses.data) {
		  const data = Object.values(allDresses.data)
		  if (data.length !== 0) setPage(allDresses.meta?.current_page + 1);
		}
	}, [allDresses])

	useEffect(() => {
		if (dataDress.length) {
			setTimeout(() => {
				setHeightSection(Number(section2.current?.clientHeight) + 120);
			}, 3500);
		}
	}, [allDresses, page, perPage, dataDress]);

	useEffect(() => {
		const urlQuery = getUrlParamsDress();
		if (urlQuery.items.length > 0) {
			setPage(1);
			dispatch(
				onSetFilterValues({
					valueColor: urlQuery.colors,
					valueTypeEvent: urlQuery.typeEvents,
					valueCalendar: urlQuery.calendar,
					valueBrand: urlQuery.brands,
					valueLength: urlQuery.lengths,
					valueSizes: urlQuery.sizes,
					valueNecklines: urlQuery.necklines,
					valuePrices: urlQuery.prices,
					valueHashtags: urlQuery.hashtags
				})
			);
		}
		if (localStorage.getItem("sorellaToken")) dispatch(getAllFavorites());
		setTimeout(() => {
			document.documentElement.scrollTop = 0;
		}, 1000);
	}, []);

	return (
		<>
			<div ref={refPage} className={styles.container}>
				<section className={styles.nav}>
					<NavBarDesktop />
				</section>
				<section className={styles.rowsSections}>
					<section className={styles.section1} style={{ maxHeight: "100%" }}>
						<SearchFilters setItemView={setItemView} navigate={navigate} setFilter={onSetFilterValues} />
					</section>
					<section className={styles.section2} ref={section2} id="dressSection">
						<section className={styles.topSection}>
							<div className={styles.topLeftArea}>
								<span className={styles.titleStyles}>{collaboration}</span>
								<div className={styles.inlineWrap}>
									{itemView &&
										itemView.map((item) => (
											<div className={styles.filtersArea} key={createKey()}>
												<section className={styles.filterItem}>
													<p>{item.value}</p>
													<button
														className={styles.close}
														onClick={() => {
															const values = valuesFilter[item.id];
															dispatch(
																onSetFilterValues({
																	[item.id]: item.id === "valueCalendar" || item.id === "valuePrices" ? ["", ""] : [...values.filter((v) => v !== item.key)]
																})
															);
															setItemView([...itemView.filter((v) => v.id !== item.id)]);
														}}
													>
														<Icon icon="eva:close-outline" color={"#434A48"} width="10px" height="10px" />
													</button>
												</section>
											</div>
										))}
								</div>
							</div>
						</section>

						<section className={styles.allDressSearchContainer}>
							{dataDress && dataDress.length === 0 ? (
								<DressNotFound />
							) : (
								dataDress.map((row, i) => (
									// <div key={i} className={fadeOut ? styles.itemFadeOut : styles.itemFadeIn}>
									<div key={i}>
										<CardDress
											dressModel={row.dress_models ? row.dress_models : {}}
											dress={row}
											url={row.dress_models.images ? row.dress_models?.images[0]?.url : ""}
											id={row.dress_models ? row.dress_models.id : null}
											id_shop={row.id}
										/>
									</div>
								))
							)}
							{loading && (
								<div style={{ width: "100%", height: dataDress.length === 0 ? "70vh" : "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
									<div>
										<img src={LoaderIcon} alt="animation" className={styles.rotateCenter} />
									</div>
								</div>
							)}
						</section>
						{/* {dataDress && dataDress.length > 0 && (
							<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<PaginationComponent page={page} setPage={setPage} total={allDresses.meta?.total} last_page={allDresses.meta?.last_page} per_page={perPage} data={dataDress} />
							</div>
						)} */}
					</section>
				</section>
			</div>
			<Footer />
			{scrollPosition > 600 && (
				<div className={styles.toTop} onClick={toTop}>
					<UpArrow color="white" />
				</div>
			)}
		</>
	);
};

export default DressSearchResultsDesktop;
