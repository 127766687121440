import React from "react";
import styles from "./dressSearchResultsMobile.module.css";
import { Icon } from "@iconify/react";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import FooterMobile from "../footerMobile/FooterMobile";
import CardDress from "../cardDress/CardDress";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  onSetFilterValues
} from "../../store/slices/filters/filtersSlice";
import { createKey, setParamsToUrl, urlSearchParams } from "../../utils";
import filterSvg from "../../assets/icons/tuner.svg";
import { modal } from '../modalManagment';
import ModalFilterItems from "../modal/ModalFilterItems";
import { useState } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DressNotFound from "../dressNotFound/DressNotFound"
import moment from "moment";
import { getAllFavorites } from "../../store/thunks/favoritesThunk";
import LoaderIcon from '../../assets/svg/loader-icon.svg';
import api from "../../api/axios.config";
import PaginationComponent from "../shared/paginationNumeric/PaginationNumeric";
import { setURLBeforeLeave } from "../../store/slices/navigation/navigationSlice";
import UpArrow from "../../assets/icons/UpArrow";

const DressSearchResultsMobile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [itemView, setItemView] = useState([]);
  const { previousURL } = useSelector((store) => store.navigation);
  const { 
    valuesFilter: {
      valueColor,
      valueTypeEvent, 
      valueCalendar,
      valueBrand,
      valueLength,
      valueSizes,
      valueNecklines,
      valuePrices,
      valueHashtags
    }
  } = useSelector((store) => store.filters)
  const { valuesFilter } = useSelector((store) => store.filters)

  const refPage = useRef(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20);
  const [fadeOut, setFadeOut] = useState(false)
  const [loading, setLoading] = useState(false);
  const [dataDress, setDataDress] = useState([]);
  const [prevFilters, setPrevFilters] = useState([]);
  const [allDresses, setAllDresses] = useState([]);
  const [collaboration, setCollaboration] = useState('Estilos para ti')
  const [isLoading, setIsLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);

  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [lengths, setLengths] = useState([]);
  const [necklines, setNecklines] = useState([]);


  const getMapQueryDress = () => {
    const items = [];

    if(valueColor?.length > 0){
      const vlc = []
      for(let i = 0; i < valueColor.length; i++)
        vlc.push(valueColor[i])
      items.push({ name: 'colors', params: vlc.join(',') })
    }

    if(valueHashtags?.length > 0){
      const hst = []
      for(let i = 0; i < valueHashtags.length; i++)
        hst.push(valueHashtags[i])
      items.push({ name: 'hashtags_ids', params: hst.join(',') })
    }

    if(valueTypeEvent?.length > 0){
      const vte = []
      for(let i = 0; i < valueTypeEvent.length; i++)
        vte.push(valueTypeEvent[i])
      items.push({ name: 'type_event_ids', params: vte.join(',') })
    }

    if(valueCalendar?.length > 0 && valueCalendar[0] !== ''){
      items.push({ name: 'date_start', params: moment(valueCalendar[0]).format('YYYY/MM/DD') })
      items.push({ name: 'date_end', params: moment(valueCalendar[1]).format('YYYY/MM/DD') })
    }

    if(valueBrand?.length > 0){
      const brd = []
      for(let i = 0; i < valueBrand.length; i++)
        brd.push(valueBrand[i])
      items.push({ name: 'marca_ids', params: brd.join(',') })
    }

    if(valueLength?.length > 0){
      const vlg = []
      for(let i = 0; i < valueLength.length; i++)
        vlg.push(valueLength[i])
      items.push({ name: 'largo_ids', params: vlg.join(',') })
    }

    if(valueSizes?.length > 0){
      const vsz = []
      for(let i = 0; i < valueSizes.length; i++)
        vsz.push(valueSizes[i])
      items.push({ name: 'talla_ids', params: vsz.join(',') })
    }

    if(valueNecklines?.length > 0){
      const vnk = []
      for(let i = 0; i < valueNecklines.length; i++)
        vnk.push(valueNecklines[i])
      items.push({ name: 'escote_ids', params: vnk.join(',') })
    }

    if(valuePrices?.length > 0 && valuePrices[0] !== ''){
      items.push({ name: 'min_precio', params: valuePrices[0] })
      items.push({ name: 'max_precio', params: valuePrices[1] })
    }

    if(items.length > 0 && JSON.stringify(prevFilters) !== JSON.stringify(items)){
      setPrevFilters(items);
      setPage(1);
    }

    return items
  }

  const getUrlParamsDress = () => {
    const queryParams = urlSearchParams(location.search)
    // let colors = []
    let search = null
    let colors = []
    let hashtags = []
    let typeEvents = []
    let calendar = []
    let brands = []
    let lengths = []
    let sizes = []
    let necklines = []
    let prices = []
    const items = []

    if (queryParams.object?.search && queryParams.object?.search !== '') {
      search = queryParams.object?.search
    }
    if (queryParams.object?.colors?.split(',').length > 0) {
      items.push({ name: 'colors', params: queryParams.object?.colors })
      colors = queryParams.object?.colors?.split(',').map((type) => parseInt(type))
    }
    if (queryParams.object?.hashtags_ids?.split(',').length > 0) {
      items.push({ name: 'hashtags_ids', params: queryParams.object?.hashtags_ids })
      hashtags = queryParams.object?.hashtags_ids?.split(',')
    }
    if (queryParams.object?.type_event_ids?.split(',').length > 0) {
      items.push({ name: 'type_event_ids', params: queryParams.object?.type_event_ids })
      typeEvents = queryParams.object?.type_event_ids?.split(',').map((type) => parseInt(type))
    }
    if (queryParams.object?.date_start && queryParams.object?.date_end) {
      items.push({ name: 'date_start', params: moment(queryParams.object?.date_start).format('YYYY/MM/DD') })
      items.push({ name: 'date_end', params: moment(queryParams.object?.date_end).format('YYYY/MM/DD') })
      calendar = [
        moment(queryParams.object?.date_start).format('YYYY/MM/DD'),
        moment(queryParams.object?.date_end).format('YYYY/MM/DD')
      ]
    }
    if (queryParams.object?.marca_ids?.split(',').length > 0) {
      items.push({ name: 'marca_ids', params: queryParams.object?.marca_ids })
      brands = queryParams.object?.marca_ids?.split(',').map((brand) => parseInt(brand))
    }
    if (queryParams.object?.largo_ids?.split(',').length > 0) {
      items.push({ name: 'largo_ids', params: queryParams.object?.largo_ids })
      lengths = queryParams.object?.largo_ids?.split(',').map((length) => parseInt(length))
    }
    if (queryParams.object?.talla_ids?.split(',').length > 0) {
      items.push({ name: 'talla_ids', params: queryParams.object?.talla_ids })
      sizes = queryParams.object?.talla_ids?.split(',').map((size) => parseInt(size))
    }
    if (queryParams.object?.escote_ids?.split(',').length > 0) {
      items.push({ name: 'escote_ids', params: queryParams.object?.escote_ids })
      necklines = queryParams.object?.escote_ids?.split(',').map((neckline) => parseInt(neckline))
    }
    if (queryParams.object?.min_precio && queryParams.object?.max_precio) {
      items.push({ name: 'min_precio', params: queryParams.object?.min_precio })
      items.push({ name: 'max_precio', params: queryParams.object?.max_precio })
      prices = [
        queryParams.object?.min_precio,
        queryParams.object?.max_precio
      ]
    }

    if((items.length > 0 || search) && JSON.stringify(prevFilters) !== JSON.stringify(items)){
      setPrevFilters(items);
    }

    return {
      search,
      items,
      colors,
      hashtags,
      typeEvents,
      calendar,
      brands,
      lengths,
      necklines,
      sizes,
      prices
    }
  }

  const getColorsCatalog = async () => {
    const resp = await api.get(`/api/colors`);
    if(resp.status === 200){
      setColors(resp.data.data)
    }
  }
  const getSizesCatalog = async () => {
    const resp = await api.get(`/api/sizes`);
    if(resp.status === 200){
      setSizes(resp.data.data)
    }
  }
  const getLengthsCatalog = async () => {
    const resp = await api.get(`/api/lengths`);
    if(resp.status === 200){
      setLengths(resp.data.data)
    }
  }
  const getNecklinesCatalog = async () => {
    const resp = await api.get(`/api/neckilnes`);
    if(resp.status === 200){
      setNecklines(resp.data.data)
    }
  }

  const fetchData = async (reset) => {
    if (reset) {
			setIsLastPage(false);
		} else if (isLastPage) {
			return;
		}

    setIsLoading(true);
		if (window.localStorage.getItem("first-init") === null) {
			setFadeOut(true);
		}

		const search = urlSearchParams(window.location.href);
		let query = "";
		if (search && search.object) {
			Object.keys(search.object).map((item) => {
				query += `&${item}=${search.object[item]}`;
				return null;
			});
		}

    setTimeout(() => {
			setLoading(true);
		}, 500);

		if (reset) toTop();

		const auxPage = reset ? 1 : page;

		const result = await api.get(`/api/dresses?page=${auxPage}&per_page=${perPage}${query}`);
		if (result.status === 200) {
			setAllDresses(result.data);
      if (result.data?.meta?.last_page === auxPage) {
				setIsLastPage(true);
			}
			if (result.data?.meta?.collaboration) {
				setCollaboration(result.data?.meta?.collaboration);
			}
			if (result.data.data && typeof result.data.data === "object") {
				if (reset) setDataDress(Object.values(result.data.data));
				else if (Object.values(result.data.data).length > 0) setDataDress([...dataDress, ...Object.values(result.data.data)]);
			}
		}
    setIsLoading(false);

		setTimeout(() => {
			setLoading(false);
			window.localStorage.removeItem("first-init");
			setFadeOut(false);
		}, 2000);
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
    if ((window.innerHeight + document.documentElement.scrollTop) <= (document.getElementById('dressSection').offsetHeight) || isLoading) {
      return;
    }
    fetchData(false);
  };

  const toTop = () => {
    document.documentElement.scrollTop = 0;
  }

  const getNameItems = () => {
    const items = [];

    if(colors?.length && valueColor.length){
      for(let i = 0; i < valueColor.length; i++) {
        if (colors.find(vt => String(vt.id) === String(valueColor[i])))
          items.push({id: 'valueColor', value: colors.find(c => String(c.id) === String(valueColor[i])) ? colors.find(c => String(c.id) === String(valueColor[i])).name : '', key: String(valueColor[i])})
      }
    }

    if(valueCalendar.length && valueCalendar[0] !== ''){
      items.push({id: 'valueCalendar', value: `${moment(valueCalendar[0]).format('DD-MMM')}-${moment(valueCalendar[1]).format('DD-MMM')}`})
    }

    if(lengths.length && valueLength.length){
      for(let i = 0; i < valueLength.length; i++)
        items.push({id: 'valueLength', value: lengths.find(lg => lg.id === valueLength[i]).description, key: valueLength[i]})
    }

    if(sizes.length && valueSizes.length){
      for(let i = 0; i < valueSizes.length; i++)
        items.push({id: 'valueSizes', value: 'Talla '+sizes.find(zs => zs.id === valueSizes[i]).description, key: valueSizes[i]})
    }

    if(necklines.length && valueNecklines.length){
      for(let i = 0; i < valueNecklines.length; i++)
        items.push({id: 'valueNecklines', value: necklines.find(nk => nk.id === valueNecklines[i]).description, key: valueNecklines[i]})
    }

    if(valuePrices.length && valuePrices[0] !== ''){
      items.push({id: 'valuePrices', value: `${valuePrices[0]}-${valuePrices[1]}`})
    }

    if(valueHashtags.length){
      for(let i = 0; i < valueHashtags.length; i++)
        items.push({id: 'valueHashtags', value: valueHashtags[i], key: valueHashtags[i]})
    }

    return items
  }

  const onModalFilter = () => {
    modal.open(
      <ModalFilterItems 
        setItemView={setItemView}
        navigate={navigate} />, 
      () => {}, 
      'BackdropContainerHeightTop'
    )
  }

  useEffect(() => {
    if (colors.length > 0 && sizes.length > 0 && lengths.length > 0 && necklines.length > 0) {
      setItemView(getNameItems())
    }
  }, [colors, sizes, lengths, necklines])

  useEffect(() => {
    console.log('Page:', page)
  }, [page])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading]);

  useEffect(() => {
    if (previousURL !== window.location.href) {
			setURLBeforeLeave(window.location.href);
		}
		if (previousURL !== null) fetchData(true);
    console.log('URL changed');
  }, [perPage, window.location.href]);

  useEffect(() => {
    const mapQuery = getMapQueryDress()
    // setItemView(mapQuery)
    if (location.search.includes('search') && location.search !== '') {
      dispatch(onSetFilterValues({
        valueColor: [],
        valueTypeEvent: [],
        valueCalendar: ['', ''],
        valueBrand: [],
        valueLength: [],
        valueSizes: [],
        valueNecklines: [],
        valuePrices: ['', ''],
        valueHashtags: []
      }))
    } else if (location.search.includes('collaboration') && location.search !== '') {
      dispatch(onSetFilterValues({
        valueColor: [],
        valueTypeEvent: [],
        valueCalendar: ['', ''],
        valueBrand: [],
        valueLength: [],
        valueSizes: [],
        valueNecklines: [],
        valuePrices: ['', ''],
        valueHashtags: []
      }))
    } else if (mapQuery.length > 0 && (!location.search.includes('search') || location.search === '')) {
      setParamsToUrl(navigate, "/resultados-vestidos", mapQuery);
    } else if (mapQuery.length === 0 && (!location.search.includes('search') || location.search === '')) {
      navigate('/resultados-vestidos')
    }
  },[
    valueColor,
    valueTypeEvent, 
    valueCalendar,
    valueBrand,
    valueLength,
    valueSizes,
    valueNecklines,
    valuePrices,
    valueHashtags
  ])

  // useEffect(() => {
  //   if(location.state?.itemView && itemView.length <= 0){
  //     setItemView(location.state?.itemView)
  //   }
  // },[location])

  useEffect(() => {
    if (allDresses && allDresses.data) {
      const data = Object.values(allDresses.data)
      if (data.length !== 0) setPage(allDresses.meta?.current_page + 1);
    }
  }, [allDresses])

  useEffect(() => {
    console.log('First load')
    window.localStorage.setItem('first-init', true)
    getColorsCatalog()
    getLengthsCatalog()
    getSizesCatalog()
    getNecklinesCatalog()
    const urlQuery = getUrlParamsDress()
    if (urlQuery.items.length > 0) {
      dispatch(onSetFilterValues({
        valueColor: urlQuery.colors,
        valueTypeEvent: urlQuery.typeEvents,
        valueCalendar: urlQuery.calendar,
        valueBrand: urlQuery.brands,
        valueLength: urlQuery.lengths,
        valueSizes: urlQuery.sizes,
        valueNecklines: urlQuery.necklines,
        valuePrices: urlQuery.prices,
        valueHashtags: urlQuery.hashtags
      }))
    }
    if (localStorage.getItem("sorellaToken")) dispatch(getAllFavorites())
    setTimeout(() => {
      document.documentElement.scrollTop = 0;
    }, 1000);
  },[])

  return (
    <>
      <div ref={refPage} className={styles.containerResultsMobile}>
        <section className={styles.navMobile}>
          <NavBarMobile />
        </section>
        <section className={styles.topSection}>
          <div className={styles.titleAndFilter}>
            <span className={styles.titleStyles}>{ collaboration }</span>
            { itemView && itemView.length > 0 &&
              <div className={styles.inlineWrap}>
                { itemView.map((item) => 
                  <div className={styles.filtersArea} key={createKey()}>
                    <section className={styles.filterItem}>
                      {item.value}
                      <button
                        className={styles.close}
                        onClick={() => {
                          const values = valuesFilter[item.id];
															dispatch(
																onSetFilterValues({
																	[item.id]: item.id === "valueCalendar" || item.id === "valuePrices" ? ["", ""] : [...values.filter((v) => v !== item.key)]
																})
															);
															setItemView([...itemView.filter((v) => v.id !== item.id)]);
                        }}
                      >
                        <Icon
                          icon="eva:close-outline"
                          color={'#434A48'}
                          width="10px"
                          height="10px"
                        />
                      </button>
                    </section>
                  </div>
                )}
              </div>
            }
          </div>
          <div className={styles.btnFilterAndView}>
            <section className={styles.btnFilterWrapper} onClick={onModalFilter}>
              <img src={filterSvg} alt="" className={styles.filterSvg} />
              <span className={styles.spanFilter}>Filtros</span>
            </section>
          </div>
        </section>

        {/* middle top section --end*/}

        <section className={styles.allDressSearchContainer} id="dressSection">
          {dataDress && dataDress.length === 0 ?
            <DressNotFound/>
            :
            dataDress.map((dress, index) => (
              <CardDress
                dressModel={dress.dress_models}
                dress={dress}
                url={dress.dress_models?.images[0]?.url}
                id={dress.dress_models?.id}
                id_shop={dress.id}
                key={index}
              />
          ))}
          {loading &&
            <div style={{width: '100%', height: dataDress.length === 0 ? "70vh" : "200px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div>
                <img src={LoaderIcon} alt="animation" className={styles.rotateCenter} />
              </div>
            </div>
          }
        </section >

        {/* {dataDress && dataDress.length > 0 &&
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '100%', marginBottom: '36px'}}>
            <PaginationComponent page={page} setPage={setPage} total={allDresses.meta?.total} last_page={allDresses.meta?.last_page} per_page={perPage} data={dataDress} />
          </div>
        } */}

      </div>
      <FooterMobile />
      { scrollPosition > 600 &&
        <div className={styles.toTop} onClick={toTop}>
          <UpArrow color="white"/>
        </div>
      }
    </>
  );
};

export default DressSearchResultsMobile;
