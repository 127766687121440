import React from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";
import { Icon } from "@iconify/react";
import Close from "../../assets/icons/Close";

const ModalConfirmLogOut = ({onClick}) => {
    return (
        <div className={styles.containerModal}>
            <div className={styles.centerContainer}>
                <Title styles={styles.titleFilters} style={{marginTop: 26, marginBottom: 26}}>CERRAR SESIÓN</Title>
                <div style={{ position: 'absolute', right: 0, top: 0, paddingLeft: 40, marginTop: 24, marginBottom: 26, cursor: 'pointer'}} onClick={() => modal.close()}>
                    <Close />
                </div>
            </div>
            <TextDescription styles={styles.textDescription} style={{fontSize: 14}}>Al continuar se cerrará tu sesión y tendrás que volver a ingresar tus datos para entrar.</TextDescription>
            <TextDescription styles={styles.textDescription}>¿Deseas continuar?</TextDescription>
            <div className={styles.containButtons} style={{marginTop: 26}}>
                <Button label="CANCELAR" outline styles={styles.buttonMiddle} onClick={() => modal.close()} />
                <Button label="CERRAR SESIÓN" styles={`${styles.buttonMiddle} ${styles.buttonPrimary}`} onClick={onClick} />
            </div>
        </div>
    )
}

export default ModalConfirmLogOut;