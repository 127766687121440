import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import DressSearchResultsDesktop from "../dressSearchResultsSaleDesktop/DressSearchResultsDesktop";
import DressSearchResultsMobile from "../dressSearchResultsSaleMobile/DressSearchResultsMobile";

const DressSearchResultsSale = () => {
  const { height, width } = useWindowDimensions();

  return (
    <>
      {width <= 1024 ? <DressSearchResultsMobile/> : <DressSearchResultsDesktop/>}
    </>
  );
};

export default DressSearchResultsSale;
