import React, { useEffect, useRef } from "react";
import styles from "./registerLanding.module.css";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Footer from '../footer/Footer';
import circleSorellaMobile from "../../assets/svg/group-dark.svg";
import UserRegister from "../userRegister/UserRegister";
import UserRegisterMobile from '../userRegisterMobile/UserRegisterMobile';
import NavBarMobile from "../navBarMobile/NavBarMobile";
import FooterMobile from "../footerMobile/FooterMobile";
import BannerDesktop from "../../assets/landing/login-banner.jpg";

const RegisterLanding = () => {
  const { width } = useWindowDimensions();
  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  },[])

  return (
    <div ref={ref}>
    {width <= 1024 ?
      <div className={styles.containerResultsMobile}>
        <section className={styles.navMobile}>
          <NavBarMobile />
        </section>

        <section>
          <div className={styles.containerMobileImgMain}>
            <img
              src={BannerDesktop}
              style={{ width: '100%' }}
              alt=""
            ></img>
            <UserRegisterMobile />
          </div>
          {/* <div className={styles.containerImgCircleMobile}>
            <img
              src={circleSorellaMobile}
              alt="circleSorella"
              className={styles.imgCircleMobile}
            ></img>
          </div> */}
        </section>
        <FooterMobile />
      </div>
    :
    <>
      <div className={styles.container}>
        <section className={styles.nav}>
          <NavBarDesktop />
        </section>

        <section className={styles.leftArea}>
          <div className={styles.containerLeftImg}>
            <img
              src={BannerDesktop}
              style={{width: '100%', height: 'calc(100vh - 16px)', objectFit: 'cover' }}
              alt=""
            ></img>
          </div>
        </section>

        <section className={styles.rightArea}>
          <UserRegister />
        </section>
      </div>
      {/* <section style={{maxHeight: 128, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FFF'}}>
        <img src={circleSorellaMobile} alt="" style={{height: 80, width: 80, margin: '24px 0px'}} />
      </section> */}

      <Footer />
    </>
    }
    </div>
  );
};

export default RegisterLanding;
