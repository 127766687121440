import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import localStyles from "./faqActiveReserves.module.css";
import ItemReservationActive from "../../itemReservationActive/ItemReservationActive";
import { useEffect } from "react";

const FaqActiveReserves = ({ details }) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  useEffect(() => {
    setIsDisplay((details && details.reservationsActives && details.reservationsActives.length) ? true : false)
  },[])

  return (
    <>
      <button
        type="button"
        className={localStyles.faqReservesAct}
        onClick={toggleDisplay}
      >
        <span>RESERVAS ACTIVAS</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        {(details && details.reservationsActives) && 
          details.reservationsActives.map(reservation => (
            <ItemReservationActive {...reservation} link={'/detalle-reserva/'+reservation.dress?.dress_models?.id} state={reservation} />
          ))
        }
      </div>
    </>
  );
};

export default FaqActiveReserves;
