import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        schedules: []
    },
    reducers: {
        setSchedules: (state, action)=>{
            state.schedules = action.payload;
        },
    }

});

export const { setSchedules } = paymentSlice.actions;