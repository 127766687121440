import './App.css';
import { Routes, Route, BrowserRouter, Navigate, useLocation } from "react-router-dom";
import RentalProcess from './components/rentalProcess/RentalProcess';
import LoginLanding from './components/loginLanding/LoginLanding';
import Contact from './components/contact/Contact';
import DressSearchResults from './components/dressSearchResults/DressSearchResults';
import DressDetails from "./components/dressDetails/DressDetails";
import DressSearchResultsMobile from "./components/dressSearchResultsMobile/DressSearchResultsMobile";
import TermsAndConditions from "./components/termsAndConditions/TermsAndConditions";
import ProfileScreen from "./components/profileScreen/ProfileScreen";
import ShopCart from './components/shopCart/ShopCart';
import DetailsRent from './components/detailsRent/DetailsRent';
import ReservationPayment from './components/reservationPayment/ReservationPayment';
import TermsConditionsSettingMobile from "./components/termsConditionsSettingMobile/TermsConditionsSettingMobile";
import Favorites from "./components/favorites/Favorites";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin } from "./store/slices/login/loginSlice"
import FaqPage from './components/faq-page/FaqPage';
import HelpWithMeasures from "./components/helpWithMeasurements/HelpWithMeasures";
import NewestDresses from "./components/newestDresses/NewestDresses";
import Error404 from './components/error404/Error404';
import DetailsReserve from './components/detailsReserve/DetailsReserve';
import RegisterLanding from './components/registerLanding/RegisterLanding';
import ForgotLanding from './components/forgotLanding/ForgotLanding';
import ClientList from './components/clientList/ClientList';
import ModalWelcome from './components/modal/ModalWelcome';
import ScheduleAppointment from './components/scheduleAppointment/ScheduleAppointment';
import DetailsHistoryRent from './components/detailsHistoryRent/DetailsHistoryRent';
import AppointmentSchedule from './components/appointmentSchedules/AppointmentSchedules';
import DetailsAppointment from './components/detailsAppointment/DetailsAppointment';
import Orders from './components/orders/Orders'
import { getCartBack } from './store/thunks/shopCart';
import Stock from './pages/Stock';
import AboutPage from './components/about';
import DressSearchResultsSale from './components/dressSearchResultsSale/DressSearchResultsSale';
import ProductDetails from './components/productDetails/ProductDetails';
import DetailsSale from './components/detailsHistorySale/DetailsSale';

function App() {
  const dispatch = useDispatch()
  const { user } = useSelector(store => store.login)

  useEffect(() => {
    const result = getDataFromLocalStorage();
    dispatch(setLogin(result))
    dispatch(getCartBack())
  },[])

  const getDataFromLocalStorage = () => {
    const token = window.localStorage.getItem('sorellaToken');
    const data = window.localStorage.getItem('user-sorella') !== 'undefined' ? window.localStorage.getItem('user-sorella') : {};
    if(token && token.length > 0){
      return {token: JSON.parse(token), user: JSON.parse(data)}
    }
    return {token: '', user: {}};
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/principal" />} />
          <Route exact path="/principal" element={<RentalProcess />} />
          <Route exact path="/inicio-sesion" element={<LoginLanding />} />
          <Route exact path="/contacto" element={<Contact />} />
          <Route exact path="/registro" element={<RegisterLanding />} />
          <Route exact path="/recuperar-contrasena" element={<ForgotLanding />} />
          <Route exact path="/recuperar-contrasena/:id" element={<ForgotLanding />} />
          <Route exact path="/resultados-vestidos" element={<DressSearchResults />} />
          <Route exact path="/en-venta" element={<DressSearchResultsSale />} />
          <Route exact path="/en-venta/:id" element={<ProductDetails />} />
          <Route exact path="/detalles-vestido/:id" element={<DressDetails />} />
          <Route exact path="/busqueda-vestido" element={<DressSearchResultsMobile />} />
          <Route exact path="/carrito" element={<ShopCart />} />
          <Route exact path="/pago" element={<ReservationPayment />} />
          <Route exact path="/terminos-condiciones" element={<TermsAndConditions />} />
          <Route exact path="/perfil" element={<ProfileScreen />} />
          <Route exact path="/detalle-renta/:id" element={<DetailsRent />} />
          <Route exact path="/detalle-historial-renta/:id" element={<DetailsHistoryRent />} />
          <Route exact path="/detalle-historial-venta/:id" element={<DetailsSale />} />
          <Route exact path="/agendar-cita/:id" element={<ScheduleAppointment />} />
          <Route exact path="/detalle-reserva/:id" element={<DetailsReserve />} />
          <Route exact path="/favoritos" element={<Favorites />}/>
          <Route exact path="/ayuda-medidas" element={<HelpWithMeasures />} />
          <Route exact path="/lo-mas-nuevo" element={<NewestDresses />} />
          <Route exact path="/eliminar" element={<ModalWelcome />} />
          <Route exact path="/faq" element={<FaqPage />} />
          <Route exact path="/faq/:id" element={<FaqPage />} />
          <Route exact path="/sobre-nosotros" element={<AboutPage />} />
          {user?.role === 'administrator' &&
            <>
              <Route exact path='/clientes' element={<ClientList />} />
              <Route exact path="/config-terminos-condiciones" element={<TermsConditionsSettingMobile />} />
              <Route exact path="/citas-agendadas" element={<AppointmentSchedule />} />
              <Route exact path="/detalle-cita/:id" element={<DetailsAppointment />} />
              <Route exact path="/pedidos" element={<Orders />} />
              <Route exact path="/inventario" element={<Stock />} />
            </>
          }
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;