import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import CustomCalendar from "../../customCalendar/CustomCalendar"

const FaqEventDate = (props) => {
  const [isDisplay, setIsDisplay] = useState(props.display);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>FECHA DE EVENTO</span>
        {isDisplay? <Icon icon="akar-icons:minus" color="#d6a191" width="14" height="14" />:<Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <section className={`${styles.flexColumCenterContent} ${props.customClass ? props.customClass : ''}`}>
          <CustomCalendar enableSinceFirstLoad={true} {...props} />
        </section>
      </div>
    </>
  );
};

export default FaqEventDate;
