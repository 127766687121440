import React from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";
import { Icon } from "@iconify/react";

const ModalSelectType = ({onClick}) => {
    return (
        <div className={styles.containerModal}>
            <div className={styles.textCenter}>
                <Title styles={styles.textModalTitle}>CONTINUAR CON PAGO</Title>
                <Icon icon="eva:close-outline" fontSize={25} style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} onClick={() => modal.close()} />
            </div>
            <TextDescription styles={styles.textDescription}>Tienes artículos en ambas bolsas de Reserva y Apartado.</TextDescription>
            <TextDescription styles={styles.textDescription}>¿Con qué pago deseas continuar?</TextDescription>
            <div className={styles.containButtonsColumn}>
                <Button label="PAGAR RENTA" outline onClick={() => onClick('RENTA')} />
                <Button label="PAGAR APARTADO" outline onClick={() => onClick('APARTADO')} />
            </div>
        </div>
    )
}

export default ModalSelectType;