import { Icon } from "@iconify/react";
import React from "react";
import { modal } from "../modalManagment";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';

const ModalConfirmActionAvatar = ({onClickRemove, onClickUpload}) => {
    return (
        <div className={styles.containerModal}>
            <div className={styles.inlineContainer}>
                <Title styles={styles.textModalTitle}>Foto de perfil</Title>
                <Icon icon="eva:close-outline" fontSize={25} style={{paddingLeft: 40, marginRight: 20, marginBottom: 20, cursor: 'pointer'}} onClick={() => modal.close()} />
            </div>
            <TextDescription styles={styles.textDescription}>Al continuar, se modificará la información relacionada con este perfil.</TextDescription>
            <div className={styles.containButtons}>
                <Button label="Eliminar foto" styles={`${styles.buttonMiddle} ${styles.buttonDanger}`} onClick={onClickRemove} />
                <Button label="Subir foto" styles={styles.buttonMiddle} onClick={onClickUpload} />
            </div>
        </div>
    )
}

export default ModalConfirmActionAvatar;