import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import TermsAndConditionsDesktop from "../termsAndConditionsDesktop/TermsAndConditionsDesktop";
import TermsAndConditionsMobile from "../termsAndConditionsMobile/TermsAndConditionsMobile";

const TermsAndConditions = () => {
  const { height, width } = useWindowDimensions();

  return (
    <>
      {width <= 1024 ? <TermsAndConditionsMobile/> : <TermsAndConditionsDesktop/>}
    </>
  );
};

export default TermsAndConditions;