import React, { useEffect, useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import localStyles from "./faqPrice.module.css";
import { onSetFilterValues } from "../../../store/slices/filters/filtersSlice";
import { useDispatch } from "react-redux";
import PriceInput from "../../shared/price-input/PriceInput";
import Button from "../../shared/button";

const FaqPrice = (props) => {
  const dispatch = useDispatch()
  const [isDisplay, setIsDisplay] = useState(false);
  const [minPrice, setMinPrice] = useState(props.valuePrices[0] ?? 0)
  const [maxPrice, setMaxPrice] = useState(props.valuePrices[1] ?? 0)
  
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const filter = () => {
    if (rawValue(minPrice) < rawValue(maxPrice)) {
      dispatch(onSetFilterValues({ valuePrices: [rawValue(minPrice), rawValue(maxPrice)] }))
    }
  }

  const rawValue = (value) => {
    if (value && value.length > 0) {
      return parseFloat(value?.replace(/,/g, '') ?? 0)
    }
    return 0
  }

  const formatValue = (value) => {
    const options = { style: 'currency', currency: 'MXN', maximumFractionDigits: 0, minimumFractionDigits: 0 }
    const format = new Intl.NumberFormat('es-MX', options)
    return format.format(value).replace('$', '')
  }

  const [invalid, setInvalid] = useState(rawValue(props.valuePrices[0]) >= rawValue(props.valuePrices[1]) && rawValue(props.valuePrices[0]) > 0)

  useEffect(() => {
    if (props.valuePrices[0] !== '' && props.valuePrices[1] !== '') {
      setMinPrice(formatValue(props.valuePrices[0] ?? 0))
      setMaxPrice(formatValue(props.valuePrices[1] ?? 0))
    }
  }, [props.valuePrices])

  useEffect(() => {
    if (minPrice !== '' && maxPrice !== '') {
      console.log('Validating:', minPrice, maxPrice)
      if (rawValue(minPrice) >= rawValue(maxPrice) && rawValue(minPrice) > 0) {
        setInvalid(true)
      } else {
        setInvalid(false)
      }
    }
  }, [minPrice, maxPrice])

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>PRECIO</span>
        {isDisplay?<Icon icon="akar-icons:minus" color="#d6a191" width="14" height="14" />:<Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <div className={localStyles.priceArea}>
          <section className={localStyles.inputSections}>
            <span className={localStyles.priceSpan}>Precio mínimo</span>
            <PriceInput placeholder={"0"} value={minPrice} onChange={(value) => {
              setMinPrice(value)
            }} className={localStyles.priceInput} invalid={invalid} />
          </section>
          <section className={localStyles.inputSections}>
            <span className={localStyles.priceSpan}>Precio máximo</span>
            <PriceInput placeholder={"0"} value={maxPrice} onChange={(value) => {
                setMaxPrice(value)
              }} className={localStyles.priceInput} invalid={invalid} />
          </section>
          <Button style={{ maxWidth: '70px' }} onClick={filter}>Buscar</Button>
        </div>
      </div>
    </>
  );
};

export default FaqPrice;
