import { createSlice } from '@reduxjs/toolkit';

export const dressSearchResultsSlice = createSlice({
    name: 'dressSearchResults',
    initialState: {
        allDresses: {
            data: []
        }
    },
    reducers: {
        setAllDresses: (state, action)=>{
            state.allDresses = action.payload.dresses;
        },
        setFilterDresses:(state, action)=>{
            state.allDresses = action.payload.size ? state.allDresses.filter((dress)=>dress.size.description.toLowerCase()===action.payload.size.toLowerCase()) : state.allDresses;
            state.allDresses = action.payload.occasion ? state.allDresses.filter((dress)=>dress?.occasion?.description.toLowerCase()===action.payload.occasion.toLowerCase()) : state.allDresses;
        },
    }

});

export const {setAllDresses, setFilterDresses} = dressSearchResultsSlice.actions;