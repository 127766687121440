import React from "react";
import styles from "./Modal.module.css";
import Button from '../shared/button';
import { Icon } from "@iconify/react";
import { modal } from '../modalManagment';
import axios from '../../api/axios.config';
import { useEffect } from "react";
import { useState } from "react";
import FaqPrice from "../faq/faqPrice/FaqPrice";
import { onSetFilterValues } from "../../store/slices/filters/filtersSlice";
import { useDispatch, useSelector } from "react-redux";
import FaqHashtags from "../faq/faqHashtags/FaqHashtags";
import FaqSaleSize from "../faq/faqSaleSize/FaqSaleSize";
import { AllTypes, AllHashTagsSale } from "../../constants";
import FaqProductType from "../faq/faqProductType/FaqProductType";

const ModalFilterItems = (props) => {
    const dispatch = useDispatch()
    const [dressSizes, setDressSizes] = useState([])
    const [shoesSizes, setShoesSizes] = useState([])
    const [complementsSizes, setComplementsSizes] = useState([])
    const [sizes, setSizes] = useState([]);

    const [valueColor, setValueColor] = useState([]);
    const [valueSizes, setValueSizes] = useState([]);
    const [valuePrices, setValuePrices] = useState(['','']);
    const [valueHashtags, setValueHashtags] = useState([]);
    const [valueProductTypes, setValueProductTypes] = useState([])

    const { valuesFilter } = useSelector((store) => store.filters)

    useEffect(() => {
        setValueColor(valuesFilter.valueColor)
        setValueSizes(valuesFilter.valueSizes)
        setValuePrices(valuesFilter.valuePrices)
        setValueHashtags(valuesFilter.valueHashtags)
        setValueProductTypes(valuesFilter.valueProductTypes)
    },[valuesFilter])

    const handlerApplyFilter = () => {
        props.setItemView(getNameItems())
        dispatch(onSetFilterValues({
          valueColor,
          valueSizes,
          valuePrices,
          valueHashtags,
          valueProductTypes
        }))
        modal.close()
    }

    const getNameItems = () => {
        const items = [];

        if(sizes?.length && valueSizes.length){
        for(let i = 0; i < valueSizes.length; i++)
            items.push({id: 'valueSizes', value: sizes.find(zs => zs.id === Number(valueSizes[i])) ? 'Talla '+sizes.find(zs => zs.id === Number(valueSizes[i])).description : '', key: Number(valueSizes[i])})
        }

        if(valuePrices?.length && valuePrices[0] !== ''){
        items.push({id: 'valuePrices', value: `${valuePrices[0]}-${valuePrices[1]}`})
        }

        if(valueHashtags?.length){
        for(let i = 0; i < valueHashtags.length; i++)
            items.push({id: 'valueHashtags', value: valueHashtags[i], key: valueHashtags[i]})
        }

        if(valueProductTypes?.length){
        for(let i = 0; i < valueProductTypes.length; i++) {
            const value = AllTypes.find((type) => type.value === valueProductTypes[i])
            items.push({id: 'valueProductTypes', value: value?.display ?? valueProductTypes[i], key: valueProductTypes[i]})
        }
        }

        return items
    }

    const getSizesCatalog = async () => {
        const resp = await axios.get(`/api/sale-sizes`);
        if(resp.status === 200) {
        setDressSizes(resp.data.dress_sizes)
        setShoesSizes(resp.data.shoes_sizes)
        setComplementsSizes(resp.data.complements_sizes)
        }
    }

    useEffect(() => {
        getSizesCatalog()
    },[])

    return (
        <div className={styles.containerFiltersPadding}>
            <div className={styles.inlineContainer}>
            <span className={styles.titleFilters}>FILTROS DE BÚSQUEDA</span>
            <Icon icon="eva:close-outline" fontSize={25} style={{paddingLeft: 40, marginRight: 20}} onClick={() => modal.close()} />
            </div>
            <FaqHashtags valueHashtags={valueHashtags} setValueHashtags={setValueHashtags} apply={() => handlerApplyFilter()} AllHashTags={AllHashTagsSale} />
            <FaqProductType valueProductTypes={valueProductTypes} setValueProductTypes={setValueProductTypes} apply={() => handlerApplyFilter()} display={true} />
            <FaqSaleSize allDresses={props?.allDresses ?? []} valueSizesArray={valueSizes} setValueSizes={setValueSizes} showOnlyAvailables={false} display={true} dressSizes={dressSizes} shoesSizes={shoesSizes} complementsSizes={complementsSizes} filterProducts={valueProductTypes} navigate={props.navigate} apply={() => handlerApplyFilter()} />
            <FaqPrice valuePrices={valuePrices} setValuePrices={setValuePrices} navigate={props.navigate} />
            <div className={styles.inlineContainer} style={{marginTop: 26, padding: '0px 12px', justifyContent: 'space-between'}}>
            <Button outline label="Borrar" style={{width: '48%'}} onClick={() => {
                props.setItemView([])
                dispatch(onSetFilterValues({
                    valueSizes: [],
                    valueHashtags: [],
                    valueProductTypes: [],
                    valuePrices: ['','']
                }))
                modal.close()
            }} />
            <Button label="Aplicar" style={{width: '48%'}} onClick={() => handlerApplyFilter()} />
            </div>
        </div>
    );
};

export default ModalFilterItems;