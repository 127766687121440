import React from "react";
import './IndicatorImgMobile.css';

const IndicatorImgMobile = (props) => {
    return (
        <div className='containVertical'>
            {Array.from(Array(props.maxPosition).keys()).map(item => (
                <div onClick={() => props.changeImgPosition(item)} className={`pointIndicator ${props.indicator === item ? 'active' : ''}`}></div>
            ))}
        </div>
    )
}

export default IndicatorImgMobile;