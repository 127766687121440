import React from "react";
import localStyles from "./priceDetails.module.css";
import { useSelector } from "react-redux";
import { format_currency } from "../../utils";

const PriceDetails = (props) => {
  const { dressModelDetail } = useSelector((store)=> store.dressDetails)
 
  return (
    <div className={`${localStyles.containerPriceElement}`}>
      <span className={localStyles.spanTitle}>PRECIO <span style={{color: '#A02C47'}}>{props.discount > 0 ? `(${props.discount}%)` : ''}</span></span>
      <span className={localStyles.priceDescription} style={{color: props.discount > 0 ? '#A02C47' : '#1E202C'}}>{format_currency(props.discount > 0 ? Math.abs((dressModelDetail.price * (props.discount / 100)) - dressModelDetail.price) : dressModelDetail.price)}</span>
    </div>
  );
};

export default PriceDetails;