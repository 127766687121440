import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styles from './ScheduleAppointment.module.css';
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from '../navBarMobile/NavBarMobile';
import Footer from "../footer/Footer";
import FooterMobile from '../footerMobile/FooterMobile';
import { TextDescription, Title } from "../shared/text/Text";
import Breadcrumb from "../navigation/breadcrumb/Breadcrumb";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Button from '../shared/button';
import axios from '../../api/axios.config';
import { useState } from 'react';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import { getMonths } from "../../utils";
import moment from 'moment';
import { modal } from '../modalManagment';
import ModalConfirmationAppointment from '../modal/ModalConfirmationAppointment';
import { useRef } from 'react';
import api from '../../api/axios.config';
import ModalWaiting from '../modal/ModalWaiting';

const ScheduleAppointment = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { width } = useWindowDimensions();
    const [dressModel, setDressModel] = useState({});
    const [date, setDate] = useState(null);
    const [hour, setHour] = useState('');
    const topRef = useRef(null)
    const { id } = useParams();

    const onGetDress = async () => {
        await axios.get(`/api/dress-models/${location.state?.id}`)
        .then(resp => {
          if(resp.status === 200){
            setDressModel(resp.data.data)
          }
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
      onGetDress();
    },[location.state])

    const selectDate = (e) => {
        const date = new Date(e);
        setDate(date);
    };

    const formatMonthY = (locale, value) => {
        let resultMonth = getMonths(locale, value);
        return `${resultMonth} ${value.getFullYear()}`;
    };

    const timelineLabels = (desiredStartTime, interval, period) => {
        const periodsInADay = moment.duration(7.5, 'hour').as(period);
      
        const timeLabels = [];
        const startTimeMoment = moment(desiredStartTime, 'hh:mm');
        for (let i = 0; i <= periodsInADay; i += interval) {
          startTimeMoment.add(i === 0 ? 0 : interval, period);
          timeLabels.push(startTimeMoment.format('hh:mm A'));
        }

        return timeLabels;
    };

    const convertTime12To24 = (time) => {
        var hours   = Number(time.match(/^(\d+)/)[1]);
        var minutes = Number(time.match(/:(\d+)/)[1]);
        var AMPM    = time.match(/\s(.*)$/)[1];
        if (AMPM === "PM" && hours < 12) hours = hours + 12;
        if (AMPM === "AM" && hours === 12) hours = hours - 12;
        var sHours   = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        return (sHours + ":" + sMinutes);
    }

    const onSubmitAppointment = async () => {
        modal.close()
        modal.open(<ModalWaiting label="AGENDANDO" description="Espere un momento..." />, () => {}, 'BackdropContainerCenter')
        const resp = await api.post(`/api/appointment`, { rent_id: id, fecha_inicio: `${moment(moment(date).format('YYYY-MM-DD')+' '+convertTime12To24(hour), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')}` })
        modal.close()
        if(resp.status === 200){
            navigate('/detalle-renta/'+location.state?.id, { state: { id } })
        }
    }

    const onModalConfirmationAppointment = () => {
        topRef.current.scrollIntoView({ behavior: 'smooth' })
        modal.open(<ModalConfirmationAppointment onClick={onSubmitAppointment} date={date} hour={hour} />, () => {}, 'BackdropContainerCenter')
    }

    return (
        <div ref={topRef}>
        {width <= 1024 ?
          <div>
            <section>
              <NavBarMobile isSelectedProfile/>
            </section>
            <section className={styles.container}>
              <Title>AGENDAR PRUEBA</Title>
              <Breadcrumb items={[{name: 'Perfil', action: () => navigate('/perfil') }, {name: dressModel?.name, action: () => navigate('/detalle-renta/'+location.state?.id)}, {name: 'Agendar prueba', action: () => {}}]} />
            </section>
            <section className={styles.container}>
                <div className={styles.inlineContainer} style={{marginBottom: 20}}>
                    <Icon icon="ant-design:exclamation-circle-outline" style={{marginRight: 12}} />
                    <TextDescription>Selecciona fecha y hora para tu cita</TextDescription>
                </div>

                <div style={{marginBottom: 36}}>
                    <Calendar
                        onClickDay={selectDate}
                        value={date}
                        calendarType={"US"}
                        minDate={new Date()}
                        nextLabel=""
                        prevLabel=""
                        formatMonth={getMonths}
                        formatMonthYear={formatMonthY}
                        formatShortWeekday={(locale, value) =>
                            ["D", "L", "M", "M", "J", "V", "S"][value.getDay()]
                        }
                        tileDisabled={true ? 
                            ({ activeStartDate, date, view }) => (date < (new Date()).getDate() - 1) || date.getDay() === 0 : //active calendar from today
                            ({ activeStartDate, date, view }) => (date < new Date("1/1/99999")) || date.getDay() === 0  //inactive calendar
                        }
                    />

                    {date &&
                        <div className={styles.inlineContainer} style={{flexWrap: 'wrap', justifyContent: 'space-between', marginTop: 24}}>
                            {timelineLabels('11:00', 30, 'm').map(h => (
                                <Button outline={h === hour ? false : true} onClick={() => setHour(h)} label={h} style={{width: '45%', marginBottom: 20}} />
                            ))}
                        </div>
                    }
                </div>

                <Button label="Agendar prueba" disabled={!(date && hour)} onClick={onModalConfirmationAppointment} />
            </section>
          </div>
          :
          <div>
            <section>
                <NavBarDesktop />
            </section>
            <section className={styles.container}>
              <Title>AGENDAR PRUEBA</Title>
              <Breadcrumb items={[{name: 'Perfil', action: () => navigate('/perfil') }, {name: dressModel?.name, action: () => navigate('/detalle-renta/'+location.state?.id)}, {name: 'Agendar prueba', action: () => {}}]} />
            </section>
            <section className={styles.container}>
                <div className={styles.inlineContainer} style={{marginBottom: 20}}>
                    <Icon icon="ant-design:exclamation-circle-outline" style={{marginRight: 12}} />
                    <TextDescription>Selecciona fecha y hora para tu cita</TextDescription>
                </div>

                <div style={{marginBottom: 36, display: 'flex', justifyContent: 'center', marginTop: 24}}>
                    <div style={{width: '40%'}}>
                      <Calendar
                          onClickDay={selectDate}
                          value={date}
                          calendarType={"US"}
                          minDate={new Date()}
                          nextLabel=""
                          prevLabel=""
                          formatMonth={getMonths}
                          formatMonthYear={formatMonthY}
                          formatShortWeekday={(locale, value) =>
                              ["D", "L", "M", "M", "J", "V", "S"][value.getDay()]
                          }
                          tileDisabled={true ? 
                              ({ activeStartDate, date, view }) => date < (new Date()).getDate() - 1 || date.getDay() === 0 || ((moment(date).isBefore(moment(location.state?.rent?.start_date).subtract(4, 'days')) || moment(date).isSameOrAfter(moment(location.state?.rent?.start_date)))) : //active calendar from today
                              ({ activeStartDate, date, view }) => date < new Date("1/1/99999") || date.getDay() === 0 || ((moment(date).isBefore(moment(location.state?.rent?.start_date).subtract(4, 'days')) || moment(date).isSameOrAfter(moment(location.state?.rent?.start_date))))  //inactive calendar
                          }
                      />
                    </div>

                    <div style={{width: '30%'}}>
                    {date &&
                        <>
                        <div className={styles.inlineContainer} style={{flexWrap: 'wrap', justifyContent: 'space-between', marginTop: 24, minHeight: 390}}>
                            {timelineLabels('11:00', 30, 'm').map(h => {
                                    if((moment(h , ['hh:mm A']).isAfter(moment(new Date(), ['hh:mm A']))) || (moment(date).isAfter(moment())))
                                        return <Button outline={h === hour ? false : true} onClick={() => setHour(h)} label={h} style={{width: '45%', marginBottom: 20}} />
                                    else return null
                                })}
                        </div>
                        <Button label="Agendar prueba" disabled={!(date && hour)} onClick={onModalConfirmationAppointment} />
                      </>
                    }
                    </div>
                </div>

                
            </section>
          </div>
          }
          {width <= 1024 ? <FooterMobile /> : <Footer /> }
        </div>
      );
}

export default ScheduleAppointment;