import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getUserDetails } from "../../store/thunks/authThunk";
import ProfileScreenDesktop from "../profileScreenDesktop/ProfileScreenDesktop";
import ProfileScreenMobile from "../profileScreenMobile/ProfileScreenMobile";

const ProfileScreen = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch()
  const { user, sorellaToken } = useSelector((store) => store.login);
  const location = useLocation()
  const navigate = useNavigate()
  
  const onInit = () => {
    try {
      dispatch(getUserDetails(user?.id))
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if(sorellaToken)
      onInit()
    else{
      localStorage.clear()
      localStorage.setItem('previousURL', window.location.href)
      navigate('/inicio-sesion')
    }
  },[])

  return (
    <>
      {width <= 1024 ? <ProfileScreenMobile details={user?.id ? user : {}} isActiveSetting={location.state ? location.state.settingActive : false} /> : <ProfileScreenDesktop isActiveSetting={location.state ? location.state.settingActive : false} details={user?.id ? user : {}} />}
    </>
  );
};

export default ProfileScreen;