import React from 'react';

export const HeartIcon = ({color, isSelected}) => {
    
    return(<>{
        isSelected ?
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.0984 1.90318C16.2296 0.0322748 13.1996 0.0322746 11.3308 1.90318L10 3.23545L8.6692 1.90318C6.80039 0.0322764 3.77043 0.0322756 1.90161 1.90318C0.0327952 3.77408 0.0327954 6.80741 1.90161 8.67831L10 17.4643L18.0984 8.67831C19.9672 6.80741 19.9672 3.77408 18.0984 1.90318Z" fill="#5F4840"/>
        </svg>
        :
        <svg width="20" height="18" viewBox="0 0 20 18" fill={"none"} xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3308 1.90318L11.6845 2.25653L11.3308 1.90318ZM18.0984 8.67831L17.7446 8.32495C17.7399 8.32968 17.7353 8.33451 17.7307 8.33943L18.0984 8.67831ZM10 17.4643L9.63235 17.8032C9.72702 17.9059 9.86032 17.9643 10 17.9643C10.1397 17.9643 10.273 17.9059 10.3676 17.8032L10 17.4643ZM8.6692 1.90318L8.31545 2.25653L8.31545 2.25653L8.6692 1.90318ZM1.90161 1.90318L2.25536 2.25653L1.90161 1.90318ZM1.90161 8.67831L2.26926 8.33943C2.26473 8.33451 2.26009 8.32968 2.25536 8.32495L1.90161 8.67831ZM10 3.23545L9.64625 3.58881C9.74004 3.6827 9.8673 3.73545 10 3.73545C10.1327 3.73545 10.26 3.6827 10.3538 3.58881L10 3.23545ZM11.6845 2.25653C13.3581 0.581156 16.0711 0.581156 17.7446 2.25653L18.4521 1.54982C16.388 -0.516606 13.0412 -0.516607 10.977 1.54982L11.6845 2.25653ZM17.7446 2.25653C19.4185 3.93222 19.4185 6.64927 17.7446 8.32495L18.4521 9.03166C20.516 6.96555 20.516 3.61594 18.4521 1.54982L17.7446 2.25653ZM17.7307 8.33943L9.63235 17.1254L10.3676 17.8032L18.466 9.01718L17.7307 8.33943ZM1.54786 1.54982C-0.515955 3.61594 -0.515954 6.96555 1.54786 9.03166L2.25536 8.32495C0.581545 6.64927 0.581545 3.93222 2.25536 2.25653L1.54786 1.54982ZM1.53397 9.01718L9.63235 17.8032L10.3676 17.1254L2.26926 8.33943L1.53397 9.01718ZM10.977 1.54982L9.64625 2.88209L10.3538 3.58881L11.6845 2.25653L10.977 1.54982ZM9.02295 1.54982C6.95883 -0.516605 3.61199 -0.516606 1.54786 1.54982L2.25536 2.25653C3.92887 0.581157 6.64194 0.581157 8.31545 2.25653L9.02295 1.54982ZM10.3538 2.88209L9.02295 1.54982L8.31545 2.25653L9.64625 3.58881L10.3538 2.88209Z" fill={color ? color : "#5F4840"} />
        </svg>
        }
        </>
    )
}    