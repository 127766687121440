import React from "react";
import styles from "./loginLanding.module.css";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Footer from '../footer/Footer';
import circleSorellaMobile from "../../assets/svg/group-dark.svg";
import BannerDesktop from "../../assets/landing/login-banner.jpg";
import Login from "../login/Login";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import FooterMobile from "../footerMobile/FooterMobile";
import LoginMobile from '../loginMobile/LoginMobile';

const LoginLanding = () => {
  const { width } = useWindowDimensions();

  return (
    <>
    {width <= 1024 ?
      <div className={styles.containerResultsMobile}>
        <section className={styles.navMobile}>
          <NavBarMobile />
        </section>

        <section>
          <div className={styles.containerMobileImgMain}>
            <img
              src={BannerDesktop}
              style={{ width: '100%' }}
              alt=""
            ></img>
            <LoginMobile />
          </div>
        </section>
        <FooterMobile />
      </div>
    :
    <>
      <div className={styles.container}>
        <section className={styles.nav}>
          <NavBarDesktop />
        </section>

        <section className={styles.leftArea}>
          <div className={styles.containerLeftImg}>
            <img
              src={BannerDesktop}
              style={{width: 756, height: 'calc(100vh - 73px)', objectFit: 'cover' }}
              alt=""
            ></img>
          </div>
        </section>
        
        <section className={styles.rightArea}>
          <Login/>
        </section>
      </div>

      <Footer />
      </>
    }
    </>
  );
};

export default LoginLanding;
