import React from "react";
import './Checkbox.css';

const Checkbox = (props) => (
    <label className={`container`}>
        <input type="checkbox" {...props} onChange={() => props.onCheck(!props.checked)} />
        <span className={`checkmark`}></span>
        {props.label}
    </label>
)

export default Checkbox;