import React from "react";
import styles from "./Modal.module.css";
import FaqEventDate from "../faq/faqEventDate/FaqEventDate";
import FaqSize from "../faq/faqSize/FaqSize";
import FaqColor from "../faq/faqColor/FaqColor";
import Button from '../shared/button';
import { Icon } from "@iconify/react";
import { modal } from '../modalManagment';
import axios from '../../api/axios.config';
import { useEffect } from "react";
import FaqTypeEvent from "../faq/faqTypeEvent/FaqTypeEvent";
import { useState } from "react";
import FaqLenghts from "../faq/faqLengths/FaqLengths";
import FaqNeckLine from "../faq/faqNeckLine/FaqNeckLine";
import FaqBrand from "../faq/faqBrand/FaqBrand";
import FaqPrice from "../faq/faqPrice/FaqPrice";
import moment from "moment/moment";
import { onSetFilterValues } from "../../store/slices/filters/filtersSlice";
import { useDispatch, useSelector } from "react-redux";
import FaqHashtags from "../faq/faqHashtags/FaqHashtags";

const ModalFilterItems = (props) => {
  const dispatch = useDispatch()
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [typeEvents, setTypeEvents] = useState([]);
  const [lengths, setLengths] = useState([]);
  const [brands, setBrands] = useState([]);
  const [necklines, setNecklines] = useState([]);

  const [valueColor, setValueColor] = useState([]);
  const [valueSizes, setValueSizes] = useState([]);
  const [valueTypeEvent, setValueTypeEvent] = useState([]);
  const [valueCalendar, setValueCalendar] = useState(['','']);
  const [valueBrand, setValueBrand] = useState([]);
  const [valueLength, setValueLength] = useState([]);
  const [valueNecklines, setValueNecklines] = useState([]);
  const [valuePrices, setValuePrices] = useState(['','']);
  const [valueHashtags, setValueHashtags] = useState([]);

  const { valuesFilter } = useSelector((store) => store.filters)

  useEffect(() => {
    setValueColor(valuesFilter.valueColor)
    setValueTypeEvent(valuesFilter.valueTypeEvent)
    setValueCalendar(valuesFilter.valueCalendar)
    setValueBrand(valuesFilter.valueBrand)
    setValueLength(valuesFilter.valueLength)
    setValueSizes(valuesFilter.valueSizes)
    setValueNecklines(valuesFilter.valueNecklines)
    setValuePrices(valuesFilter.valuePrices)
    setValueHashtags(valuesFilter.valueHashtags)
  },[valuesFilter])

  const handlerApplyFilter = () => {
    props.setItemView(getNameItems())
    dispatch(onSetFilterValues({
      valueColor,
      valueTypeEvent,
      valueCalendar,
      valueBrand,
      valueLength,
      valueSizes,
      valueNecklines,
      valuePrices,
      valueHashtags
    }))
    modal.close()
  }

  const getNameItems = () => {
    const items = [];

    if(colors?.length && valueColor.length){
      for(let i = 0; i < valueColor.length; i++) {
        if (colors.find(vt => String(vt.id) === String(valueColor[i])))
          items.push({id: 'valueColor', value: colors.find(c => String(c.id) === String(valueColor[i])) ? colors.find(c => String(c.id) === String(valueColor[i])).name : '', key: String(valueColor[i])})
      }
    }

    if(typeEvents.length && valueTypeEvent.length){
      for(let i = 0; i < valueTypeEvent.length; i++)
        items.push({id: 'valueTypeEvent', value: typeEvents.find(vt => vt.id === valueTypeEvent[i]).description, key: valueTypeEvent[i]})
    }

    if(valueCalendar.length && valueCalendar[0] !== ''){
      items.push({id: 'valueCalendar', value: `${moment(valueCalendar[0]).format('DD-MMM')}-${moment(valueCalendar[1]).format('DD-MMM')}`})
    }

    if(brands.length && valueBrand.length){
      for(let i = 0; i < valueBrand.length; i++)
        items.push({id: 'valueBrand', value: brands.find(br => br.id === valueBrand[i]).description, key: valueBrand[i]})
    }

    if(lengths.length && valueLength.length){
      for(let i = 0; i < valueLength.length; i++)
        items.push({id: 'valueLength', value: lengths.find(lg => lg.id === valueLength[i]).description, key: valueLength[i]})
    }

    if(sizes.length && valueSizes.length){
      for(let i = 0; i < valueSizes.length; i++)
        items.push({id: 'valueSizes', value: 'Talla '+sizes.find(zs => zs.id === valueSizes[i]).description, key: valueSizes[i]})
    }

    if(necklines.length && valueNecklines.length){
      for(let i = 0; i < valueNecklines.length; i++)
        items.push({id: 'valueNecklines', value: necklines.find(nk => nk.id === valueNecklines[i]).description, key: valueNecklines[i]})
    }

    if(valuePrices.length && valuePrices[0] !== ''){
      items.push({id: 'valuePrices', value: `${valuePrices[0]}-${valuePrices[1]}`})
    }

    if(valueHashtags.length){
      for(let i = 0; i < valueHashtags.length; i++)
        items.push({id: 'valueHashtags', value: valueHashtags[i], key: valueHashtags[i]})
    }

    return items
  }

  const getColorsCatalog = async () => {
    const resp = await axios.get(`/api/colors`);
    if(resp.status === 200){
      setColors(resp.data.data)
    }
  }
  const getTypeEventCatalog = async () => {
    const resp = await axios.get(`/api/type-events`);
    if(resp.status === 200){
      setTypeEvents(resp.data.data)
    }
  }
  const getSizesCatalog = async () => {
    const resp = await axios.get(`/api/sizes`);
    if(resp.status === 200){
      setSizes(resp.data.data)
    }
  }
  const getLengthsCatalog = async () => {
    const resp = await axios.get(`/api/lengths`);
    if(resp.status === 200){
      setLengths(resp.data.data)
    }
  }
  const getBrandsCatalog = async () => {
    const resp = await axios.get(`/api/brands`);
    if(resp.status === 200){
      setBrands(resp.data.data)
    }
  }
  const getNecklinesCatalog = async () => {
    const resp = await axios.get(`/api/neckilnes`);
    if(resp.status === 200){
      setNecklines(resp.data.data)
    }
  }

  useEffect(() => {
    getColorsCatalog()
    getTypeEventCatalog()
    getLengthsCatalog()
    getBrandsCatalog()
    getSizesCatalog()
    getNecklinesCatalog()
  },[])

  return (
    <div className={styles.containerFiltersPadding}>
        <div className={styles.inlineContainer}>
          <span className={styles.titleFilters}>FILTROS DE BÚSQUEDA</span>
          <Icon icon="eva:close-outline" fontSize={25} style={{paddingLeft: 40, marginRight: 20}} onClick={() => modal.close()} />
        </div>
        <FaqHashtags valueHashtags={valueHashtags} setValueHashtags={setValueHashtags} apply={() => handlerApplyFilter()} />
        <FaqColor valueColor={valueColor} setValueColor={setValueColor} display={false} colors={colors} navigate={props.navigate} />
        <FaqSize valueSizes={valueSizes} setValueSizes={setValueSizes} showOnlyAvailables={true} sizes={sizes} navigate={props.navigate} />
        {/* <FaqTypeEvent valueTypeEvent={valueTypeEvent} setValueTypeEvent={setValueTypeEvent} typeEvents={typeEvents} navigate={props.navigate} /> */}
        <FaqEventDate valueCalendar={valueCalendar} setValueCalendar={setValueCalendar} display={false} navigate={props.navigate} />
        <FaqLenghts valueLength={valueLength} setValueLength={setValueLength} lengths={lengths} navigate={props.navigate} />
        {/* <FaqNeckLine valueNecklines={valueNecklines} setValueNecklines={setValueNecklines} necklines={necklines} navigate={props.navigate} />
        <FaqBrand valueBrand={valueBrand} setValueBrand={setValueBrand} brands={brands} navigate={props.navigate} /> */}
        <FaqPrice valuePrices={valuePrices} setValuePrices={setValuePrices} navigate={props.navigate} />

        <div className={styles.inlineContainer} style={{marginTop: 26, padding: '0px 12px', justifyContent: 'space-between'}}>
          <Button outline label="Borrar" style={{width: '48%'}} onClick={() => {
              props.setItemView([])
              dispatch(onSetFilterValues({
                valueColor: [],
                valueTypeEvent: [],
                valueCalendar: ['',''],
                valueBrand: [],
                valueLength: [],
                valueSizes: [],
                valueNecklines: [],
                valuePrices: ['','']
              }))
              modal.close()
            }} />
          <Button label="Aplicar" style={{width: '48%'}} onClick={() => handlerApplyFilter()} />
        </div>
    </div>
  );
};

export default ModalFilterItems;