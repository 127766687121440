import React from "react";
import styles from "./searchFilters.module.css";
import { useState } from "react";
import FaqLenghts from "../faq/faqLengths/FaqLengths";
import FaqTypeEvent from "../faq/faqTypeEvent/FaqTypeEvent";
import FaqNeckLine from "../faq/faqNeckLine/FaqNeckLine";
import FaqBrand from "../faq/faqBrand/FaqBrand";
import FaqPrice from "../faq/faqPrice/FaqPrice";
import FaqEventDate from "../faq/faqEventDate/FaqEventDate";
import FaqSize from "../faq/faqSize/FaqSize";
import FaqColor from "../faq/faqColor/FaqColor";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../api/axios.config";
import moment from "moment";
import FaqHashtags from "../faq/faqHashtags/FaqHashtags";
import { AllHashTags } from "../../constants";

const SearchFilters = (props) => {
  const dispatch = useDispatch()
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [typeEvents, setTypeEvents] = useState([]);
  const [lengths, setLengths] = useState([]);
  const [brands, setBrands] = useState([]);
  const [necklines, setNecklines] = useState([]);

  const [valueColor, setValueColor] = useState([]);
  const [valueSizes, setValueSizes] = useState([]);
  const [valueTypeEvent, setValueTypeEvent] = useState([]);
  const [valueCalendar, setValueCalendar] = useState(['','']);
  const [valueBrand, setValueBrand] = useState([]);
  const [valueLength, setValueLength] = useState([]);
  const [valueNecklines, setValueNecklines] = useState([]);
  const [valuePrices, setValuePrices] = useState(['','']);
  const [valueHashtags, setValueHashtags] = useState([]);

  const { valuesFilter } = useSelector((store) => store.filters)

  useEffect(() => {
    setValueColor(valuesFilter.valueColor)
    setValueTypeEvent(valuesFilter.valueTypeEvent)
    setValueCalendar(valuesFilter.valueCalendar)
    setValueBrand(valuesFilter.valueBrand)
    setValueLength(valuesFilter.valueLength)
    setValueSizes(valuesFilter.valueSizes)
    setValueNecklines(valuesFilter.valueNecklines)
    setValuePrices(valuesFilter.valuePrices)
    setValueHashtags(valuesFilter.valueHashtags)
  },[valuesFilter])

  const handlerApplyFilter = () => {
    dispatch(props.setFilter({
      valueColor,
      valueTypeEvent,
      valueCalendar,
      valueBrand,
      valueLength,
      valueSizes,
      valueNecklines,
      valuePrices,
      valueHashtags
    }))
  }

  const getParamsUrl = () => {
    const url = window.location.href;

    let query = {};
    let pairs = (url[0] === '?' ? url.substr(1) : url).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return {
        valueColor: query['colors'] ? query['colors'].split(',') : [], 
        valueTypeEvent: query['type_event_ids'] ? query['type_event_ids'].split(',') : [], 
        valueCalendar: query['date_start'] ? [query['date_start'], query['date_end']] : ['',''], 
        valueBrand: query['marca_ids'] ? query['marca_ids'].split(',') : [], 
        valueLength: query['largo_ids'] ? query['largo_ids'].split(',') : [], 
        valueSizes: query['talla_ids'] ? query['talla_ids'].split(',') : [], 
        valueNecklines: query['escote_ids'] ? query['escote_ids'].split(',') : [], 
        valueHashtags: query['hashtags_ids'] ? query['hashtags_ids'].split(',') : [], 
        valuePrices: query['max_precio'] ? [moneyFormat(query['min_precio']), moneyFormat(query['max_precio'])] : ['','']
      }
  }

  const moneyFormat = (value) => {
    const options = { style: 'currency', currency: 'MXN', maximumFractionDigits: 0, minimumFractionDigits: 0 }
    const format = new Intl.NumberFormat('es-MX', options)
    return format.format(value)
  }

  const getNameItems = ({ valueColor, valueTypeEvent, valueCalendar, valueBrand, valueLength, valueSizes, valueNecklines, valuePrices, valueHashtags }) => {
    const items = [];

    // if(colors?.length && valueColor){
    //   items.push({id: 'valueColor', value: colors.find(c => String(c.id) === String(valueColor)) ? colors.find(c => String(c.id) === String(valueColor)).name : ''})
    // }

    if(colors?.length && valueColor.length){
      for(let i = 0; i < valueColor.length; i++) {
        if (colors.find(vt => String(vt.id) === String(valueColor[i])))
          items.push({id: 'valueColor', value: colors.find(c => String(c.id) === String(valueColor[i])) ? colors.find(c => String(c.id) === String(valueColor[i])).name : '', key: String(valueColor[i])})
      }
    }

    if(typeEvents?.length && valueTypeEvent.length){
      for(let i = 0; i < valueTypeEvent.length; i++) {
        if (typeEvents.find(vt => vt.id === Number(valueTypeEvent[i])))
          items.push({id: 'valueTypeEvent', value: typeEvents.find(vt => vt.id === Number(valueTypeEvent[i])) ? typeEvents.find(vt => vt.id === Number(valueTypeEvent[i])).description : '', key: Number(valueTypeEvent[i])})
      }
    }

    if(valueCalendar?.length && valueCalendar[0] !== ''){
      items.push({id: 'valueCalendar', value: `${moment(valueCalendar[0]).format('DD-MMM')}-${moment(valueCalendar[1]).format('DD-MMM')}`})
    }

    if(brands?.length && valueBrand.length){
      for(let i = 0; i < valueBrand.length; i++)
        items.push({id: 'valueBrand', value: brands.find(br => br.id === Number(valueBrand[i])) ? brands.find(br => br.id === Number(valueBrand[i])).description : '', key: Number(valueBrand[i])})
    }

    if(lengths?.length && valueLength.length){
      for(let i = 0; i < valueLength.length; i++)
        items.push({id: 'valueLength', value: lengths.find(lg => lg.id === Number(valueLength[i])) ? lengths.find(lg => lg.id === Number(valueLength[i])).description : '', key: Number(valueLength[i])})
    }

    if(sizes?.length && valueSizes.length){
      for(let i = 0; i < valueSizes.length; i++)
        items.push({id: 'valueSizes', value: sizes.find(zs => zs.id === Number(valueSizes[i])) ? 'Talla '+sizes.find(zs => zs.id === Number(valueSizes[i])).description : '', key: Number(valueSizes[i])})
    }

    if(necklines?.length && valueNecklines.length){
      for(let i = 0; i < valueNecklines.length; i++)
        items.push({id: 'valueNecklines', value: necklines.find(nk => nk.id === Number(valueNecklines[i])) ? necklines.find(nk => nk.id === Number(valueNecklines[i])).description : '', key: Number(valueNecklines[i])})
    }

    if(valuePrices?.length && valuePrices[0] !== ''){
      items.push({id: 'valuePrices', value: `${valuePrices[0]}-${valuePrices[1]}`})
    }

    if(valueHashtags?.length){
      for(let i = 0; i < valueHashtags.length; i++)
        items.push({id: 'valueHashtags', value: valueHashtags[i], key: valueHashtags[i]})
    }

    return items
  }

  const getColorsCatalog = async () => {
    const resp = await axios.get(`/api/colors`);
    if(resp.status === 200){
      setColors(resp.data.data)
    }
  }
  const getTypeEventCatalog = async () => {
    const resp = await axios.get(`/api/type-events`);
    if(resp.status === 200){
      setTypeEvents(resp.data.data)
    }
  }
  const getSizesCatalog = async () => {
    const resp = await axios.get(`/api/sizes`);
    if(resp.status === 200){
      setSizes(resp.data.data)
    }
  }
  const getLengthsCatalog = async () => {
    const resp = await axios.get(`/api/lengths`);
    if(resp.status === 200){
      setLengths(resp.data.data)
    }
  }
  const getBrandsCatalog = async () => {
    const resp = await axios.get(`/api/brands`);
    if(resp.status === 200){
      setBrands(resp.data.data)
    }
  }
  const getNecklinesCatalog = async () => {
    const resp = await axios.get(`/api/neckilnes`);
    if(resp.status === 200){
      setNecklines(resp.data.data)
    }
  }

  useEffect(() => {
    getColorsCatalog()
    getTypeEventCatalog()
    getLengthsCatalog()
    getBrandsCatalog()
    getSizesCatalog()
    getNecklinesCatalog()
  },[])

  useEffect(() => {
    props.setItemView(getNameItems(getParamsUrl()))
  }, [
    valueColor,
    valueTypeEvent, 
    valueCalendar,
    valueBrand,
    valueLength,
    valueSizes,
    valueNecklines,
    valuePrices,
    typeEvents,
    sizes,
    brands,
    lengths,
    necklines,
    colors,
    valueHashtags,
    window.location.href
  ])

  return (
    <div className={styles.scrollContaint}>
      <span className={styles.titleFilters}>FILTROS DE BÚSQUEDA</span>
      <FaqHashtags valueHashtags={valueHashtags} setValueHashtags={setValueHashtags} apply={() => handlerApplyFilter()} AllHashTags={AllHashTags}/>
      <FaqColor valueColor={valueColor} setValueColor={setValueColor} colors={colors} navigate={props.navigate} apply={() => handlerApplyFilter()} />
      <FaqSize valueSizes={valueSizes} setValueSizes={setValueSizes} showOnlyAvailables={true} sizes={sizes} navigate={props.navigate} apply={() => handlerApplyFilter()} />
      {/* <FaqTypeEvent valueTypeEvent={valueTypeEvent} setValueTypeEvent={setValueTypeEvent} display={false} typeEvents={typeEvents.sort(function (a, b) {if (a.description < b.description) {return -1;}if (a.description > b.description) {return 1;}return 0;})} navigate={props.navigate} apply={() => handlerApplyFilter()} /> */}
      <FaqEventDate valueCalendar={valueCalendar} setValueCalendar={setValueCalendar} navigate={props.navigate} apply={() => handlerApplyFilter()} />
      <FaqLenghts valueLength={valueLength} setValueLength={setValueLength} lengths={lengths} navigate={props.navigate} apply={() => handlerApplyFilter()} />
      {/* <FaqNeckLine valueNecklines={valueNecklines} setValueNecklines={setValueNecklines} necklines={necklines.sort(function (a, b) {if (a.description < b.description) {return -1;}if (a.description > b.description) {return 1;}return 0;})} navigate={props.navigate} apply={() => handlerApplyFilter()} /> */}
      {/* <FaqBrand valueBrand={valueBrand} setValueBrand={setValueBrand} brands={brands.sort(function (a, b) {if (a.description < b.description) {return -1;}if (a.description > b.description) {return 1;}return 0;})} navigate={props.navigate} apply={() => handlerApplyFilter()} /> */}
      <FaqPrice valuePrices={valuePrices} setValuePrices={setValuePrices} navigate={props.navigate} apply={() => handlerApplyFilter()} />
    </div>
  );
};

export default SearchFilters;
