import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styles from './DetailsHistoryRent.module.css';
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import NavBarMobile from '../navBarMobile/NavBarMobile';
import Footer from "../footer/Footer";
import FooterMobile from '../footerMobile/FooterMobile';
import { TextDescription, Title, TextLinking } from "../shared/text/Text";
import Breadcrumb from "../navigation/breadcrumb/Breadcrumb";
import CardProduct from "../reservationPayment/Product";
import moment from 'moment';
import SuccessMessage from '../successMessage/SucessMessage';
import axios from '../../api/axios.config';
import { useState } from 'react';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';

const DetailsRent = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { width } = useWindowDimensions();
    const [dressModel, setDressModel] = useState({});
    const [status, setStatus] = useState('')
    const [appointment, setAppointment] = useState(false)
    const { id } = useParams();
    const [rentData, setRentData] = useState({});
    const [isDisplay, setIsDisplay] = useState(true);
    const toggleDisplay = () => {
      setIsDisplay(!isDisplay);
    };
    const [orderCode, setOrderCode] = useState('')

    const onGetOrder = async () => {
      await axios.get(`/api/orders/${location.state?.id_order}`)
      .then(resp => {
        if(resp.status === 200){
          setOrderCode(resp.data.data.code)
        }
      })
      .catch(err => console.log(err))
    }


    const onGetDress = async () => {
      await axios.get(`/api/dress-models/${id}`)
      .then(resp => {
        if(resp.status === 200){
          setDressModel(resp.data.data)
        }
      })
      .catch(err => console.log(err))
    }

    const getRent = async () => {
      await axios.get(`/api/rent/${location.state?.id}`)
      .then(resp => {
        if(resp.data.data){
          const appmt = resp.data.data
          setRentData(appmt)

          setStatus(`Rentado para ${moment(appmt.end_date).format('DD - MMM YYYY')}`)

          if(appmt.status === 'cita_agendada'){
            setStatus(`Cita agendada: ${moment(appmt.appointment.date).format('DD - MMM YYYY hh:mmA')}`)
            setAppointment(appmt.appointment)
          }

          if(appmt.status === 'ajustes_listos'){
            setStatus(`Tus ajustes estan listos el: ${moment(appmt?.appointment?.ajustes_at).format('DD - MMM YYYY')}`)
          }
        }
      })
      .catch(error => console.log(error))
    }

    useEffect(() => {
      getRent();
      onGetDress();
      onGetOrder();
    },[location.state])

    return (
        <>
        {width <= 1024 ?
          <div>
            <section>
              <NavBarMobile isSelectedProfile/>
            </section>
            <section className={styles.container}>
              <Title>DETALLES DE RENTA</Title>
    
              <Breadcrumb items={[{name: 'Perfil', action: () => navigate('/perfil') }, {name: dressModel.name, action: () => {}}]} />
            </section>
            <SuccessMessage display={true} label={status} />
            <section className={styles.container}>
              <div className={styles.containerCart}>
                <CardProduct data={{...dressModel, type: 'RENTA'}} disabled={true} key={Math.random()} calendarRange={rentData?.start_date ? [rentData.start_date, rentData.end_date] : ['', '']} sizeValue={dressModel.dress ? dressModel.dress[0].size.description : ''} colorName={dressModel.dress ? dressModel.dress[0].color.name : ''} />
              </div>
            
                <div style={{marginBottom: 60}}>
                    <TextDescription>Código para entrega: {orderCode}</TextDescription>
                    <TextLinking style={{color: 'rgba(116, 128, 126, 1)'}} onClick={() => navigate('/terminos-condiciones', {state: { optionB: null, type: 'RENTA', isDetails: true }})}>Leer Términos y Condiciones de Renta</TextLinking>
                </div>

                {appointment && appointment.date &&
                  <div>
                    <button
                      type="button"
                      className={styles.collapsible}
                      onClick={toggleDisplay}
                    >
                      <span>CITA AGENDADA</span>
                      {isDisplay ? (
                        <Icon
                          icon="akar-icons:minus"
                          color="#d6a191"
                          width="14"
                          height="14"
                        />
                      ) : (
                        <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
                      )}
                    </button>
                    <div
                      className={`${styles.container} ${
                        isDisplay ? styles.display : styles.hidden
                      }`}
                    >
                      <div>
                        <TextDescription>Cita de prueba</TextDescription>

                        <TextDescription>Fecha y hora:</TextDescription>
                        <TextDescription>{moment(appointment.date).format('DD - MMM YYYY, hh:mm a')}</TextDescription>

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 16}}>
                          <TextDescription>
                            Ubicación:
                          </TextDescription>

                          <TextLinking onClick={() => window.open('https://goo.gl/maps/ZwuTQfzeWxDEKFbeA', '_blank')} style={{color: '#97A6A3', textDecorationLine: 'underline'}}>ver mapa</TextLinking>
                        </div>

                        <TextDescription>{appointment?.store?.ubication ? appointment?.store?.ubication : 'Sin dirección'}</TextDescription>
                      </div>
                    </div>
                  </div>
                }
            </section>
          </div>
          :
          <div>
            <section>
                <NavBarDesktop />
            </section>
            <section className={styles.container}>
              <Title>DETALLES DE RENTA</Title>
    
              <Breadcrumb items={[{name: 'Perfil', action: () => navigate('/perfil') }, {name: dressModel.name, action: () => {}}]} />
            </section>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{width: 648}}>
                <SuccessMessage display={true} label={status} />
                <section className={styles.container}>
                  <div className={styles.containerCart}>
                    <CardProduct data={{...dressModel, type: 'RENTA'}} disabled={true} key={Math.random()} calendarRange={rentData?.start_date ? [rentData.start_date, rentData.end_date] : ['', '']} sizeValue={dressModel.dress ? dressModel.dress[0].size.description : ''} colorName={dressModel.dress ? dressModel.dress[0].color.name : ''} />
                  </div>
                
                    <div style={{marginBottom: 60}}>
                        <TextDescription>Código para entrega: {orderCode}</TextDescription>
                        <TextLinking style={{color: 'rgba(116, 128, 126, 1)'}} onClick={() => navigate('/terminos-condiciones', {state: { optionB: null, type: 'RENTA', isDetails: true }})}>Leer Términos y Condiciones de Renta</TextLinking>
                    </div>

                    {appointment && appointment.date &&
                      <div>
                        <button
                          type="button"
                          className={styles.collapsible}
                          onClick={toggleDisplay}
                        >
                          <span>CITA AGENDADA</span>
                          {isDisplay ? (
                            <Icon
                              icon="akar-icons:minus"
                              color="#d6a191"
                              width="14"
                              height="14"
                            />
                          ) : (
                            <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
                          )}
                        </button>
                        <div
                          className={`${styles.container} ${
                            isDisplay ? styles.display : styles.hidden
                          }`}
                        >
                          <div>
                            <TextDescription>Cita de prueba</TextDescription>

                            <TextDescription>Fecha y hora:</TextDescription>
                            <TextDescription>{moment(appointment.date).format('DD - MMM YYYY, hh:mm a')}</TextDescription>

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 16}}>
                              <TextDescription>
                                Ubicación:
                              </TextDescription>

                              <TextLinking onClick={() => window.open('https://goo.gl/maps/ZwuTQfzeWxDEKFbeA', '_blank')} style={{color: '#97A6A3', textDecorationLine: 'underline'}}>ver mapa</TextLinking>
                            </div>

                            <TextDescription>{appointment?.store?.ubication ? appointment?.store?.ubication : 'Sin dirección'}</TextDescription>
                          </div>
                        </div>
                      </div>
                    }
                </section>
              </div>
            </div>
          </div>
          }
          {width <= 1024 ? <FooterMobile /> : <Footer /> }
        </>
      );
}

export default DetailsRent;