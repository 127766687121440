import React, { useState } from "react";
import styles from "./Calendar.module.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { setIsSizeEnable }  from "../../../store/slices/rentalProcess/rentalProcessSlice"
import { onSetFilterValues } from "../../../store/slices/filters/filtersSlice"
import { getMonths } from "../../../utils";
import ToggleCustomDisplay from '../../shared/toggleCustomDisplay/ToggleDisplay';
import moment from "moment";
import api from "../../../api/axios.config";

const FaqCalendarDressDetails = (props) => {
  const [disabledDates, setDisabledDates] = useState([]);
  const [dateChange, setDateChange] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();

  const { isCalendarDisable, calendarDays } = useSelector((store) => store.rentalProcess)
  const { valuesFilter: { valueCalendar } } = useSelector((store) => store.filters)

  useEffect(() => {
    if(props.valuesFilters.valueCalendar[0] !== ''){
      const initialDate = new Date(props.valuesFilters.valueCalendar[0]);
      const finalDate = new Date(props.valuesFilters.valueCalendar[1]);
      if(disabledDates.find(d => (moment(initialDate).isSame(moment(d)) || moment(finalDate).isSame(moment(d))))){
        setDate(initialDate);
        props.setValuesFilters({ ...props.valuesFilters, valueCalendar: [initialDate.toISOString(), ''] })
        return;
      }
      setDate([initialDate, finalDate])
    }
  },[disabledDates])

  const newRange = (range) => {
    if (date[1]) {
      const initialDate = date[0];
      const finalDate = addDays(new Date(initialDate), calendarDays);
      if(disabledDates.find(d => (moment(initialDate).isSame(moment(d)) || moment(finalDate).isSame(moment(d))))){
        setDate(initialDate);
        if(props.details)
            props.setValuesFilters({ ...props.valuesFilters, valueCalendar: [initialDate.toISOString(), ''] })
          else
            dispatch(onSetFilterValues({ valueCalendar:[initialDate.toISOString(), '']}));
        return;
      }
      setDate([initialDate, finalDate])

      if(props.details)
        props.setValuesFilters({ ...props.valuesFilters, valueCalendar: [initialDate.toISOString(), finalDate.toISOString()] })
      else
        dispatch(onSetFilterValues({valueCalendar:[initialDate.toISOString(), finalDate.toISOString()]}));
    }
  };

  useEffect(() => {
    newRange()
  },[calendarDays])

  const selectDate = (e) => {
    const initialDate = new Date(e);
    const finalDate = new Date(addDays(e, calendarDays));

    if(disabledDates.find(d => (moment(initialDate).isSame(moment(d)) || moment(finalDate).isSame(moment(d))))){
      setDate(initialDate);
      if(props.details)
          props.setValuesFilters({ ...props.valuesFilters, valueCalendar: [initialDate.toISOString(), ''] })
        else
          dispatch(onSetFilterValues({ valueCalendar:[initialDate.toISOString(), '']}));
      return;
    }
    setDate([initialDate, finalDate]);
    if(props.details)
      props.setValuesFilters({ ...props.valuesFilters, valueCalendar: [initialDate.toISOString(), finalDate.toISOString()] })
    else
      dispatch(onSetFilterValues({ valueCalendar:[initialDate.toISOString(), finalDate.toISOString()]}));
    dispatch(setIsSizeEnable());
  };

  const addDays = (theDate, days) => {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  };

  const toCalendarType = (weekStartDay) =>
    weekStartDay === 0 ? "US" : "ISO 8601"; //set from Sunday to Saturday

  const formatMonthY = (locale, value) => {
    let resultMonth = getMonths(locale, value);
    return `${resultMonth} ${value.getFullYear()}`;
  };
  

  const onGetAbilityDressDate = async () => {
    await api.post(`/api/dress-availability`, { year: +moment(dateChange ? dateChange : date).format('YYYY'), month: +moment(dateChange ? dateChange : date).format('MM'), dress_id: props.idDress, days: props.pricesRent === 1 ? 4 : 8 })
    .then(resp => {
      if(resp.status === 200){
        setDisabledDates(resp.data.disable_dates)
      }
    })
    .catch(e => {
      console.log(e)
    })
  }

  useEffect(() => {
    onGetAbilityDressDate();
  },[dateChange, props.idDress, props.pricesRent])

  const disabledTitle = (date) => {
    return disabledDates.find(d => moment(date).isSame(moment(d)) ? true : false)
  }

  return (
    <ToggleCustomDisplay label="FECHA DEL EVENTO" style={styles.backgroundContainer} show={(props.valuesFilters.valueCalendar[0] && props.valuesFilters.valueCalendar[1]) ? false : true} checked={props.valuesFilters.valueCalendar[0] && props.valuesFilters.valueCalendar[1]}>
      <div style={{ overflowX: 'hidden' }}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 12}}>
          <Calendar
            onClickDay={selectDate}
            value={date}
            calendarType={toCalendarType(0)}
            minDate={new Date()}
            maxDetail="month"
            nextLabel=""
            prevLabel=""
            formatMonthYear={formatMonthY}
            onActiveStartDateChange={({activeStartDate}) => setDateChange(activeStartDate)}
            formatShortWeekday={(locale, value) =>
              ["D", "L", "M", "M", "J", "V", "S"][value.getDay()]
            }
            tileDisabled={(!isCalendarDisable || props.enableSinceFirstLoad) ? 
              ({ activeStartDate, date, view }) => (date < (new Date()).getDate() - 1) || date.getDay() === 0 || disabledTitle(date) || moment(date).isAfter(moment().add(90, 'days')):
              ({ activeStartDate, date, view }) => (date < new Date("1/1/99999")) || date.getDay() === 0 || disabledTitle(date) || moment(date).isAfter(moment().add(90, 'days'))
            }
          />
        </div>
        {(props.valuesFilters.valueCalendar[1] && new Date(props.valuesFilters.valueCalendar[1]).getDay() === 0) && <span className={styles.textDescription}>El vestido deberá devolverse en lunes debido a que el domingo nuestras tiendas se encuentran cerradas</span>}
      </div>
    </ToggleCustomDisplay>
  );
};

export default FaqCalendarDressDetails;
