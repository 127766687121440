import React, { useState } from "react";
import styles from "./customCalendar.module.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { setIsSizeEnable }  from "../../store/slices/rentalProcess/rentalProcessSlice"
import { onSetFilterValues } from "../../store/slices/filters/filtersSlice"
import { getMonths } from "../../utils";
import moment from "moment";

const CustomCalendar = (props) => {
  const [isNormalDateRange, setIsNormalDateRange] = useState(true);
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();

  const { isCalendarDisable } = useSelector((store) => store.rentalProcess)
  const { valuesFilter: { valueCalendar } } = useSelector((store) => store.filters)

  useEffect(()=>{
    if(valueCalendar.length === 2 && valueCalendar[0] !== ''){
      const initialDate = new Date(valueCalendar[0]);
      const finalDate = new Date(valueCalendar[1]);
      setIsNormalDateRange(finalDate.getDate()-initialDate.getDate() === 3 ? true : false);
      setDate([initialDate, finalDate])
    }
  },[])

  const newRange = () => {
    if (date[1]) {
      const initialDate = date[0];
      const finalDate = addDays(new Date(initialDate), !isNormalDateRange ? 3 : 7);
      setDate([initialDate, finalDate])
      dispatch(onSetFilterValues({valueCalendar:[initialDate.toISOString(), finalDate.toISOString()]}));
    }
    setIsNormalDateRange(!isNormalDateRange);
  };

  const selectDate = (e) => {
    const initialDate = new Date(e);
    const finalDate = new Date(addDays(e, isNormalDateRange ? 3 : 7));
    setDate([initialDate, finalDate]);

    dispatch(onSetFilterValues({ valueCalendar:[initialDate.toISOString(), finalDate.toISOString()]}));
    dispatch(setIsSizeEnable());
    props.setValueCalendar([initialDate.toISOString(), finalDate.toISOString()]);
  };

  const addDays = (theDate, days) => {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  };

  const toCalendarType = (weekStartDay) =>
    weekStartDay === 0 ? "US" : "ISO 8601"; //set from Sunday to Saturday

  const formatMonthY = (locale, value) => {
    let resultMonth = getMonths(locale, value);
    return `${resultMonth} ${value.getFullYear()}`;
  };

  return (
    <>
      <Calendar
        onClickDay={selectDate}
        value={date}
        calendarType={toCalendarType(0)}
        minDate={new Date()}
        nextLabel=""
        prevLabel=""
        formatMonth={getMonths}
        formatMonthYear={formatMonthY}
        formatShortWeekday={(locale, value) =>
          ["D", "L", "M", "M", "J", "V", "S"][value.getDay()]
        }
        tileDisabled={(!isCalendarDisable || props.enableSinceFirstLoad) ? 
          ({ activeStartDate, date, view }) => (date < (new Date()).getDate() - 1) || date.getDay() === 0 || moment(date).isAfter(moment().add(90, 'days')) : //active calendar from today
          ({ activeStartDate, date, view }) => (date < new Date("1/1/99999")) || date.getDay() === 0 || moment(date).isAfter(moment().add(90, 'days'))  //inactive calendar
        }
      />
      <section className={styles.needMoreDays}>
        <span onClick={newRange} className={styles.spanNeedMoreDays}>
          ¿Necesitas una renta de {11 - (isNormalDateRange ? 3 : 7)} días?
        </span>
      </section>
    </>
  );
};

export default CustomCalendar;
