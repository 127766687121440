import api from "../../api/axios.config"
import { setAllDresses } from "../slices/dressSearchResults/dressSearchResultsSlice"

export const getAllDresses = (query) => {
  return async (dispatch, getState) => {
    const result = await api.get("/api/dresses"+query);
    dispatch(setAllDresses({ dresses: result.data }));
  }
}


export const getDressesById = (dress) => {
    return async (dispatch, getState) => {
        const result = await api.get(`api/dress/${dress.id}/edit`);
    }
}
