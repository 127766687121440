import React from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";

const ModalConfirmRemoveCart = ({onClick}) => {
    return (
        <div className={styles.containerModal}>
            <Title styles={styles.textModalTitle}>REMOVER DE BOLSA</Title>
            <TextDescription styles={styles.textDescription}>Este vestido será eliminado de tu bolsa de compras</TextDescription>
            <TextDescription styles={styles.textDescription}>¿Deseas continuar?</TextDescription>
            <div className={styles.containButtons}>
                <Button label="Cancelar" outline styles={styles.buttonMiddle} onClick={() => modal.close()} />
                <Button label="Eliminar" styles={`${styles.buttonMiddle} ${styles.buttonDanger}`} onClick={onClick} />
            </div>
        </div>
    )
}

export default ModalConfirmRemoveCart;