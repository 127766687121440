import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import styles2 from "../faqColor/faqColor.module.css";
import { Icon } from "@iconify/react";
import ColorPicker from "../../shared/colorPicker/ColorPicker"
import { createKey, getUniqueValues } from "../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { setColorValue, setAllSizesAvailables, onSetFilterValues } from "../../../store/slices/filters/filtersSlice";


const FaqColor = (props) => {
  const [isDisplay, setIsDisplay] = useState(!!props.display);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const { colorValue, allColorsAvailables, valuesFilter: { valueColor } } = useSelector((store)=>store.filters);
  const { dressModelDetail } = useSelector((store)=>store.dressDetails);
  const dispatch = useDispatch();

  const updateStorageFilters = (colorCode) =>{
    const filterByColor = dressModelDetail.dress.filter(d => d.color.code === colorCode);

    if(filterByColor.length && valueColor.includes(filterByColor[0].color_id)){
      dispatch(onSetFilterValues({ valueColor: valueColor.filter(v => v !== filterByColor[0].color_id) }));
    }else{
      dispatch(onSetFilterValues({  valueColor: [...valueColor, filterByColor[0].color_id] }));
    }

    dispatch(setColorValue(colorCode));
    const allSizes = getUniqueValues(filterByColor.map(d=>d.size.description));
    dispatch(setAllSizesAvailables(allSizes))
  }

  // const onChangeFilter = (id) => {
  //   setParamsToUrl(props.navigate, window.location.href, {name: 'color_id', params: id})
  // }

  const handlerChange = (id) => {
    console.log(id)
    // let valueColor = []
    // if(props.valueColor === id){
    //   valueColor = '';
    // }else{
    //   valueColor = id;
    // }
    let valueColor = []
    if(props.valueColor.includes(String(id))){
      valueColor = props.valueColor.filter(v => v !== String(id));
    }else{
      valueColor = [...props.valueColor, String(id)];
    }

    dispatch(onSetFilterValues({ valueColor }))
  }

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>COLORES</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <div>
          <section className={`${styles2.colorContainer} ${props.containerStyle ? props.containerStyle : ''}`}>
            {props.colors ?
              props.colors.map((color)=>
                <React.Fragment key={createKey()}>
                  <ColorPicker isPressed={props.valueColor === color.id} colorCode={color.code} colorPickerCallBack={() => handlerChange(color.id)}/>
                </React.Fragment>
              )
            :
              allColorsAvailables.map((colorCode)=>
                <React.Fragment key={createKey()}>
                  <ColorPicker isPressed={colorValue === colorCode} colorCode={colorCode} colorPickerCallBack={()=>updateStorageFilters(colorCode)}/>
                </React.Fragment>
              )
            }
          </section>
        </div>
      </div>
    </>
  );
};

export default FaqColor;
