import React from "react";
import styles from './ErrorMessage.module.css';
import { Icon } from "@iconify/react";
import { TextDescription } from "../shared/text/Text";

const ErrorMessage = (props) => {
    return(
        <>
        {props.display &&
            <div className={styles.containerError}>
                <div className={styles.buttonClose} onClick={() => props.setError(false)}>
                    <Icon icon="clarity:times-line" color="#FFF" width="14" height="14" />
                </div>
                <TextDescription styles={styles.textDescriptionError}>{ props.textDescription ?  props.textDescription : 'Hubo un error en su pago. Intente de nuevo o contacte con nuestra tienda.' }</TextDescription>
            </div>
        }
        </>
    )
};

export default ErrorMessage;