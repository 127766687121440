import React from "react";
import styles from "./cardDress.module.css"
import { useNavigate } from "react-router-dom";
import { format_currency } from "../../utils";
import FavoriteHeart from "../shared/favoritHeart/FavoriteHeart";
import PlaceholderDress from '../../assets/svg/PlaceholderDress.svg';
import Hashtag from "../shared/hashtag/hashtag";

const CardDress = (props) => {
    const navigate = useNavigate();
    return(
        <div className={styles.containerCardDress}>
            <section className={styles.containerImage}>
                <div onClick={() => {
                    if (!props.dressModel?.situation || props.dressModel?.situation === 'rent') window.open(`/detalles-vestido/${props.id}?id_dress=${props.id_shop}&close=true`, '_blank')
                    else if (props.dressModel?.situation === 'sale') window.open(`/en-venta/${props.id}?id_dress=${props.id_shop}&close=true`, '_blank')
                    // navigate(`/detalles-vestido/${props.id}`, { state: { id_dress: props.id_shop } })
                    
                }}>
                    <img src={props?.url ? `${props?.url}` : PlaceholderDress} alt="Imagen de vestido" className={styles.dressSearchImages}></img>
                    <Hashtag discount={props.dress?.discount} new={props.dress?.now_tag} bestseller={props.dress?.bestseller_tag} />
                    <section style={{position: 'absolute', zIndex: 9, bottom: 4, width: '100%', borderBottomRightRadius: 24, borderBottomLeftRadius: 24, background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)'}}>
                        <div style={{display: 'flex', flexDirection: 'column', padding: 16}}>
                            <span className={styles.dressModelName}>{props.dressModel?.name}</span>
                            {props.dress?.discount > 0 && <span className={styles.discountText}><s>{format_currency(props.dressModel?.price)} MXN</s>  (-{props.dress?.discount}%)</span>}
                            <span className={styles.dressModelPrice}>{format_currency(props.dress?.discount > 0 ? props.dress?.final_price : props.dressModel?.price)} MXN</span>
                        </div>
                    </section>
                </div>
                {/* REACT_APP_FRONT_URL */}
                <FavoriteHeart id={props.id_shop} className={styles.heartBtn} customFavHeartBehavior={props.customFavHeartBehavior}/>
            </section>
            
        </div>
    )
}



export default CardDress;