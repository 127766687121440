import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./customCheck.module.css";

const CustomCheck = (props) => {
  

  return (
    <>
      <label className={styles.container}>
        {props.content}
        <input type="checkbox" onChange={()=>props.onChangeCallBack()} checked={props.isChecked}/>
        <span className={styles.checkmark}></span>
      </label>
    </>
  );
};

export default CustomCheck;
