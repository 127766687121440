import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        sorellaToken: "",
        isRegistered: true,
        user: {},
        reLoginPerformed: false
    },
    reducers: {
        setIsRegister: (state, action) => {
            state.isRegistered = action.payload;
        },
        setLogin: (state, action)=>{
            state.sorellaToken = action.payload.token;
            state.user = action.payload.user
            window.localStorage.setItem('user-sorella', JSON.stringify(action.payload.user ? action.payload.user : {} ))
        },
        setLogout: (state, action)=>{
            state.sorellaToken = "";
            state.user = {};
            window.localStorage.clear()
        },
        setReLoginPerformed: (state, action)=>{
            state.reLoginPerformed = action.payload;
        },
        checkCredentials:(state)=>{

        },
        setUserDetails: (state, action) => {
            state.user = action.payload.user
            window.localStorage.setItem('user-sorella', JSON.stringify(action.payload.user ? action.payload.user : {} ))
        }
    }

});

export const { setIsRegister, setLogin, setUserDetails, setLogout, setReLoginPerformed } = loginSlice.actions;