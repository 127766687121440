import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import stylesLocal from "./faqSaleSize.module.css";
import { Icon } from "@iconify/react";
// import { AllDressSizes, AllShoesSizes, AllComplementsSizes } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { setAllColorsAvailables, onSetFilterValues, setSizeValue } from "../../../store/slices/filters/filtersSlice";
import { createKey, getUniqueValues } from "../../../utils";

const FaqSaleSize = ({ dressSizes, shoesSizes, complementsSizes, valueSizesArray, filterProducts, allDresses = [], showOnlyAvailables, display = false, containerStyle }) => {
  const dispatch = useDispatch();
  const { sizeValue, valuesFilter: { valueSizes } } = useSelector((store) => store.filters);
  const { dressModelDetail } = useSelector(store => store.dressDetails);

  const [isDisplay, setIsDisplay] = useState(!!display);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const selectSize = (size) => {
    const filterBySize = dressModelDetail.dress.filter(d=>d.size.description.toLowerCase() === size.toLowerCase());

    if(filterBySize.length > 0 && size) {
      if(!valueSizes.includes(filterBySize[0].size.id))
        dispatch(onSetFilterValues({ 'valueSizes': [filterBySize[0].size.id] }))
      else
        dispatch(onSetFilterValues({ 'valueSizes': valueSizes.filter(v => v !== filterBySize[0].size.id) }))
    }

    if(showOnlyAvailables){
      const filterBySize = dressModelDetail.dress.filter(d=>d.size.description.toLowerCase() === size.toLowerCase());
      const allColorCodes = getUniqueValues(filterBySize.map(d=>d.color.code));
      dispatch(setAllColorsAvailables(allColorCodes))
    }

    dispatch(setSizeValue(size))
  };

  const checkIfDisabled = (size, type = 'dresses') => {
    let found = 0
    allDresses.filter((dressModel) => dressModel.type === type).forEach(dressModel => {
      if (dressModel?.type === 'dresses' && dressModel?.sale_dresses.find((saleDress) => saleDress.size_id === size.id)) found++
      else if (dressModel?.type === 'shoes' && dressModel?.shoes.find((shoes) => shoes.size_id === size.id)) found++
      else if (dressModel?.type === 'complements' && dressModel?.complements.find((complement) => complement.size_id === size.id)) found++
    })
    return found === 0
  }

  const handlerChecked = (id) => {
    let valueSizes = []
    if(valueSizesArray.includes(id)){
      valueSizes = [...valueSizesArray.filter(v => v !== id)]
    }else{
      valueSizes = [...valueSizesArray, id]
    }
    dispatch(onSetFilterValues({ valueSizes }))
  }

  // const onChangeFilter = (id) => {
  //   setParamsToUrl(props.navigate, window.location.href, {name: 'talla_ids', params: id})
  // }

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>TALLA</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <>
          { (filterProducts.length === 0 || (filterProducts.length > 0 && filterProducts.includes("shoes"))) &&
            <>
              <span className={stylesLocal.sizeCategory}>Calzado</span>
              { shoesSizes && !showOnlyAvailables ?
                <section className={stylesLocal.buttonSizeContainer}>
                  {
                    shoesSizes.map((size) => (
                      <button
                        key={createKey()}
                        onClick={() => handlerChecked(size.size)}
                        className={`${stylesLocal.buttonSize} ${ valueSizesArray.includes(size.size) ? stylesLocal.selected : "" }`}
                      >
                        {size.size}
                      </button>
                    ))
                  }
                </section>
              :
                <section className={`${stylesLocal.buttonSizeContainer}  ${containerStyle ? containerStyle : ''}`}>
                  { shoesSizes.map((size) => {
                      if(!checkIfDisabled(size, 'shoes'))
                        return (
                          <button
                            key={createKey()}
                            onClick={() => handlerChecked(size.size)}
                            className={`${stylesLocal.buttonSize} ${ valueSizesArray.includes(size.size) ? stylesLocal.selected : "" } }`}
                            disabled={checkIfDisabled(size, 'shoes')}
                          >
                            {size.size}
                          </button>
                        )
                      else return null
                    })
                  }
                </section>
          }
            </>
          }
          { (filterProducts.length === 0 || (filterProducts.length > 0 && filterProducts.includes("dresses"))) &&
            <>
              <span className={stylesLocal.sizeCategory}>Vestidos</span>
              { dressSizes && !showOnlyAvailables ?
                <section className={stylesLocal.buttonSizeContainer}>
                  {
                    dressSizes.map((size) => (
                      <button
                        key={createKey()}
                        onClick={() => handlerChecked(size.description)}
                        className={`${stylesLocal.buttonSize} ${
                          valueSizesArray.includes(size.description) ? stylesLocal.selected : ""
                        }`
                        }
                      >
                        {size.description}
                      </button>
                    ))
                  }
                </section>
              :
                <section className={`${stylesLocal.buttonSizeContainer}  ${ containerStyle ? containerStyle : ''}`}>
                  { dressSizes.map((size) => {
                    if(!checkIfDisabled(size, 'dresses'))
                      return (
                        <button
                          key={createKey()}
                          onClick={() => handlerChecked(size.description)}
                          className={`${stylesLocal.buttonSize} ${
                            valueSizesArray.includes(size.description) ? stylesLocal.selected : ""
                          }`
                          }
                          disabled={checkIfDisabled(size, 'dresses')}
                        >
                          {size.description}
                        </button>
                        )
                    else return null
                    })
                  }
                </section>
              }
            </>
          }
          { (filterProducts.length === 0 || (filterProducts.length > 0 && filterProducts.includes("complements"))) &&
            <>
              <span className={stylesLocal.sizeCategory}>Complementos</span>
              { complementsSizes && !showOnlyAvailables ?
                <section className={stylesLocal.buttonSizeContainer}>
                  {
                    complementsSizes.map((size) => (
                      <button
                        key={createKey()}
                        onClick={() => handlerChecked(size.size)}
                        className={`${stylesLocal.buttonSize} ${
                          valueSizesArray.includes(size.size) ? stylesLocal.selected : ""
                        }`
                        }
                      >
                        {size.size}
                      </button>
                    ))
                  }
                </section>
              :
                <section className={`${stylesLocal.buttonSizeContainer}  ${containerStyle ? containerStyle : ''}`}>
                  { complementsSizes.map((size) => {
                      if(!checkIfDisabled(size, 'complements'))
                        return (
                          <button
                            key={createKey()}
                            onClick={() => handlerChecked(size.size)}
                            className={`${stylesLocal.buttonSize} ${
                              valueSizesArray.includes(size.size) ? stylesLocal.selected : ""
                            }`
                            }
                            disabled={checkIfDisabled(size, 'complements')}
                          >
                            {size.size}
                          </button>
                          )
                      else return null
                    })
                  }
                </section>
              }
            </>
          }
        </>
      </div>
    </>
  );
};

export default FaqSaleSize;
