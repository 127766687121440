import React from "react";
import styles from "./UserRegisterMobile.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { postAuthLogin } from "../../store/thunks/authThunk";
import api from "../../api/axios.config";
import logoGoogle from '../../assets/icons/LogoGoogle.png'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'

const Register = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors
  } = useForm();

  const onSubmit = async (data) => {
    try {
        const user = {
          correo_electronico: data.email,
          password: data.password,
          password_confirmation: data.passwordAgain,
          role: "client",
        };
        const result = await api.post("/api/auth/register", user);
        if(result.status === 200){
          localStorage.setItem('init-register', true);
          dispatch(postAuthLogin({ email: user.correo_electronico, password: user.password }))
        }
      } catch (error) {
        setError('email', {
          message: error.response.data.errors.correo_electronico ? 'El usuario ya existe, intenta recuperar tu contraseña.' : 'Algo salio mal.'
        })
      }
  };

  const googleSignUp = useGoogleLogin({
    onSuccess: async (response) => {
      console.log('Response:', response)
      try {
        const res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
            Accept: 'application/json'
          }
        })
        const data = res.data
        const result = await api.post("/api/auth/register/google", data);
        if(result.status === 200){
          localStorage.setItem('init-register', true);
          dispatch(postAuthLogin({ email: data.email, google_id: data.id }))
        }
      } catch (error) {
        setError('email', {
          message: error.response.data.errors.correo_electronico ? 'El usuario ya existe, intenta recuperar tu contraseña.' : 'Algo salio mal.'
        })
      }
    },
    onError: error => console.log('Error:', error)
  });

  return (
    <>
      <div className={styles.cardContainerMobile}>
        <span className="cardTitle">Únete para comenzar</span>

        <form
          className={styles.registerContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="marginBottomType1">
            <span className="spanTopInputs">Ingresa tu correo electrónico</span>
            <input
              autoComplete="off"
              type="text"
              placeholder="Correo"
              onKeyUp={(e) => {
                if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e.target.value))
                  setError('email', { message: 'Correo electronico no válido' })
                else
                  clearErrors('email')
              }}
              className={`${errors?.email ? 'inputError' : !errors?.email && getValues().email !== '' ? 'success' : ''} inputTypeText`}
              id="inputEmail"
              {...register("email", {
                required: 'Ingresa tu correo',
                pattern: {
                  value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Correo electrónico no válido",
                },
              })}
            />
            {errors.email && (
              <p className={styles.errorMessage}>{errors.email.message}</p>
            )}
          </div>
          <div className="marginBottomType1">
            <span className="spanTopInputs">Ingresa tu contraseña</span>
            <input
              autoComplete="off"
              type={"password"}
              placeholder="Contraseña"
              onKeyUp={(e) => {
                if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(e.target.value))
                  setError('password', {
                    message: 'La contraseña debe tener por lo menos 8 caracteres entre ellos una mayúscula, minúscula y número.'
                  })
                else
                  clearErrors('password')
              }}
              className={`${errors?.password ? 'inputError' : !errors?.password && getValues().password !== '' ? 'success' : ''} inputTypeText`}
              id="inputPassword"
              {...register("password", {
                setValueAs: (v) => v.trim(),
                pattern: {
                  value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
                  message: "La contraseña debe tener por lo menos 8 caracteres entre ellos una mayúscula, minúscula y número.",
                },
                minLength: {
                  value: 8,
                  message: "Mínimo 8 caracteres",
                },
                validate: {
                  trim: (v) => {
                    if (!v.trim()) return "Contraseña no válida";
                    return true;
                  },
                },
              })}
            />
            {errors.password && (
              <p className={styles.errorMessage}>{errors.password.message}</p>
            )}
        </div>
        <div className="marginBottomType1">
            <span className="spanTopInputs">Confirma tu contraseña</span>
            <input
              autoComplete="off"
              type={"password"}
              placeholder="Contraseña"
              className={`${errors?.passwordAgain ? 'inputError' : !errors?.passwordAgain && getValues().passwordAgain !== '' ? 'success' : ''} inputTypeText`}
              {...register("passwordAgain", {
                pattern: {
                  value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
                  message: "La contraseña debe tener por lo menos 8 caracteres entre ellos una mayúscula, minúscula y número.",
                },
                onChange: (e) => {
                  if(e.target.value !== getValues().password)
                    setError('passwordAgain', {
                      message: 'La contraseña debe coincidir.'
                    })
                  else
                    clearErrors('passwordAgain')
                },
                validate: {
                  equals: (v) =>
                    v === getValues("password") || "La contraseña no coincide",
                },
              })}
            />
            {errors.passwordAgain && (
                <p className={styles.errorMessage}>
                  {errors.passwordAgain.message}
                </p>
              )}
            <span className={styles.spanTermsAndConditions}>Al registrate estás aceptando los </span>
            <Link to={"#"} className={styles.linkTermsAndConditions}>Términos y Condiciones</Link>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <button type="submit" className="primaryButton">
              Registrarte
            </button>
            <div className={styles.centerDivider}>
              O
            </div>
            <div className="socialMediaButton" onClick={googleSignUp}>
              <img src={logoGoogle} width="25px" height="25px" alt=""/>
              Ingresa con Google
            </div>
          </div>
          <div className={styles.noAccountYetRegister} style={{cursor: 'pointer'}} onClick={()=> navigate('/inicio-sesion')}>
            ¿Ya tienes cuenta? Ingresa aquí
          </div>
        </form>
      </div>
    </>
  );
};

export default Register;
