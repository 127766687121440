import { createSlice } from '@reduxjs/toolkit';

export const navBarSlice = createSlice({
    name: 'navBar',
    initialState: {
        isLogin:false,
    },


    reducers: {
        setIsLogged: (state) => {
            state.isLogin = true;
        },
    }

});

export const {setIsLogged} = navBarSlice.actions;