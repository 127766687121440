import React from "react";
import styles from "./newestDressesDesktop.module.css";
import { Icon } from "@iconify/react";
import NavBarDesktop from "../navBarDesktop/NavBarDesktop";
import Footer from "../footer/Footer";
import CardDress from "../cardDress/CardDress";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SearchFilters from "../searchFilters/SearchFilters";
import { getAllDresses } from "../../store/thunks/dressThunk";
import { setFilterDresses } from "../../store/slices/dressSearchResults/dressSearchResultsSlice";
import {
  clearSizeFilter,
  removeOccasion,
  clearCalendarFilter,
  clearLargeFilter,
  removeLarge,
  removeNeckLine,
  removeBrand,
} from "../../store/slices/filters/filtersSlice";
import { useState } from "react";
import { createKey, getMonths } from "../../utils";
import leftArrow from "../../assets/icons/chevron-left.svg";
import rightArrow from "../../assets/icons/chevron-right.svg";
// import PaginationRounded from "../../components/pagination/Pagination";


const NewestDressesDesktop = () => {
  const dispatch = useDispatch();
  const { allDresses } = useSelector((store) => store.dressSearchResults);
  const {
    occasionValue,
    sizeValue,
    calendarRange,
    largeValue,
    neckLineValue,
    brandValue,
  } = useSelector((store) => store.filters);

  const [filteredDresses, setFilteredDresses] = useState([]);

  const fetchData = async () => {
    await dispatch(getAllDresses());
    if (sizeValue.length > 0) {
      dispatch(setFilterDresses({ size: sizeValue }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [sizeValue]);
  return (
    <>
      <div className={styles.container}>
        <section className={styles.nav}>
          <NavBarDesktop />
        </section>

        <section className={styles.section1}>
          <SearchFilters />
        </section>

        <section className={styles.section2}>
          <section className={styles.topSection}>
            <div className={styles.topLeftArea}>
              <span className={styles.titleStyles}>Estilos para ti</span>
              <div className={styles.filtersArea}>
                {calendarRange.length > 1 ? (
                  <section className={styles.filterItem}>
                    {new Date(calendarRange[0]).getDate()}-
                    {new Date(calendarRange[1]).getDate()}{" "}
                    {getMonths("", new Date(calendarRange[1]))}
                    <button
                      className={styles.close}
                      onClick={() => dispatch(clearCalendarFilter())}
                    >
                      <Icon
                        icon="eva:close-outline"
                        color="white"
                        width="10px"
                        height="10px"
                      />
                    </button>
                  </section>
                ) : (
                  <></>
                )}
                {occasionValue.length > 0 ? (
                  occasionValue.map((occasion, index) => (
                    <section key={createKey()} className={styles.filterItem}>
                      {occasion}
                      <button
                        className={styles.close}
                        onClick={() => dispatch(removeOccasion(occasion))}
                      >
                        <Icon
                          icon="eva:close-outline"
                          color="white"
                          width="10px"
                          height="10px"
                        />
                      </button>
                    </section>
                  ))
                ) : (
                  <></>
                )}
                {sizeValue.length > 0 ? (
                  <section className={styles.filterItem}>
                    Talla {sizeValue}
                    <button
                      className={styles.close}
                      onClick={() => dispatch(clearSizeFilter())}
                    >
                      <Icon
                        icon="eva:close-outline"
                        color="white"
                        width="10px"
                        height="10px"
                      />
                    </button>
                  </section>
                ) : (
                  <></>
                )}
                {largeValue.length > 0 ? (
                  largeValue.map((large, index) => (
                    <section key={createKey()} className={styles.filterItem}>
                      {large}
                      <button
                        className={styles.close}
                        onClick={() => dispatch(removeLarge(large))}
                      >
                        <Icon
                          icon="eva:close-outline"
                          color="white"
                          width="10px"
                          height="10px"
                        />
                      </button>
                    </section>
                  ))
                ) : (
                  <></>
                )}
                {neckLineValue.length > 0 ? (
                  neckLineValue.map((neckLine, index) => (
                    <section key={createKey()} className={styles.filterItem}>
                      {neckLine}
                      <button
                        className={styles.close}
                        onClick={() => dispatch(removeNeckLine(neckLine))}
                      >
                        <Icon
                          icon="eva:close-outline"
                          color="white"
                          width="10px"
                          height="10px"
                        />
                      </button>
                    </section>
                  ))
                ) : (
                  <></>
                )}
                {brandValue.length > 0 ? (
                  brandValue.map((brand, index) => (
                    <section key={createKey()} className={styles.filterItem}>
                      {brand}
                      <button
                        className={styles.close}
                        onClick={() => dispatch(removeBrand(brand))}
                      >
                        <Icon
                          icon="eva:close-outline"
                          color="white"
                          width="10px"
                          height="10px"
                        />
                      </button>
                    </section>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={styles.topRightArea}>
              <section className={styles.paginationWrapper}>
                <span className={styles.spanPage}>Página:</span>
                <div className={styles.paginationContainer}>
                  <img src={leftArrow} alt="" className={styles.arrows}></img>
                  <div className={styles.pages}>
                    <span>1</span>
                    <span>&nbsp;/&nbsp;</span>
                    <span>1</span>
                  </div>
                  <img src={rightArrow} alt="" className={styles.arrows}></img>
                </div>
              </section>
              <section className={styles.modelsForEachPage}>
                <span className={styles.titlePaginationModels}>
                  Modelos por página:
                </span>
                <select className={styles.qtyModels}>
                  <option value=""></option>
                  <option value="8">8</option>
                  <option value="12">12</option>
                  <option value="16">16</option>
                  <option value="20">20</option>
                </select>
              </section>
            </div>
          </section>

          <section className={styles.allDressSearchContainer}>
            {allDresses.map((dress, index) => (
              <div key={index}>
                <CardDress
                  dressModel={dress.dress_models}
                  url={dress.dress_models?.images[0]?.url}
                  id={dress.id}
                />
              </div>
            ))}
          </section>
        </section>

      </div>
      <Footer />
    </>
  );
};

export default NewestDressesDesktop;