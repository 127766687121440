import React from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";
import moment from "moment";

const ModalConfirmationAppointment = ({onClick, date, hour, cancel}) => {
    return (
        <div className={styles.containerModal}>
            <Title styles={styles.textModalTitle}>{cancel ? 'CANCELAR CITA' : `AGENDAR PRUEBA`}</Title>
            <TextDescription styles={styles.textDescription}>
                {cancel ? 'Se cancelará la cita del:' : 'Tu prueba será agendada para la fecha:'}<br/>
                {moment(date).format('DD - MMM YYYY')}<br/>
                {hour.toLowerCase()}
            </TextDescription>
            <TextDescription styles={styles.textDescription}>¿Deseas continuar?</TextDescription>
            <div className={styles.containButtons}>
                <Button label="Cancelar" outline styles={styles.buttonMiddle} onClick={() => modal.close()} />
                <Button label={cancel ? 'CANCELAR CITA' : "Continuar"} styles={`${styles.buttonMiddle} ${cancel ? styles.buttonDanger : ''}`} onClick={onClick} />
            </div>
        </div>
    )
}

export default ModalConfirmationAppointment;