import React, { useState } from "react";
import styles from "../../faq/faq.module.css";
import { Icon } from "@iconify/react";
import CustomCheck from "../../shared/customCheck/CustomCheck";
import { createKey } from "../../../utils";

const FaqCity = (props) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const toggleDisplay = () => {
    setIsDisplay(!isDisplay);
  };

  const handlerChecked = (id) => {
    props.setValueCity(id)
  }

  return (
    <>
      <button
        type="button"
        className={styles.collapsible}
        onClick={toggleDisplay}
      >
        <span>CIUDAD</span>
        {isDisplay ? (
          <Icon
            icon="akar-icons:minus"
            color="#d6a191"
            width="14"
            height="14"
          />
        ) : (
          <Icon icon="akar-icons:plus" color="#D6A191" width="14" height="14" />
        )}
      </button>
      <div
        className={`${styles.container} ${
          isDisplay ? styles.display : styles.hidden
        }`}
      >
        <section className={styles.checkContainer}>
        {props.cities &&
            props.cities.map(row => {
              if(row){
                return(
                <div key={createKey()} className={styles.checkboxInline}>
                  <CustomCheck
                    content={row.name}
                    onChangeCallBack={() => handlerChecked(row.id)}
                    isChecked={
                      props.valueCity && props.valueCity === row.id
                        ? true
                        : false
                    }
                  />
                </div>
                )
              }else return null
            })
          }
        </section>
      </div>
    </>
  );
};

export default FaqCity;
