import React from "react";
import styles from "./favoritesMobile.module.css";
import NavBarMobile from "../navBarMobile/NavBarMobile";
import FooterMobile from "../footerMobile/FooterMobile";
import filtersIcon from "../../assets/icons/filtersIcon.svg";
import CardDress from "../cardDress/CardDress";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFavoritesPagination } from "../../store/thunks/favoritesThunk";
import { setParamsToUrl, urlSearchParams } from "../../utils";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

const FavoritesMobile = (props) => {
  const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [dataDress, setDataDress] = useState([]);
    const { paginationFavorite } = useSelector((store) => store.dressDetails); 
    const refPage = useRef(null);
    const [page, setPage] = useState('1')
    const [perPage, setPerPage] = useState('8');
    const [itemView, setItemView] = useState([]);
    const { 
      valuesFilterFavourites: {
        valueColor,
        valueTypeEvent, 
        valueCalendar,
        valueBrand,
        valueLength,
        valueSizes,
        valueNecklines,
        valuePrices
      }
     } = useSelector((store) => store.filters)
  
    const{ valuesFilterFavourites } = useSelector((store) => store.filters)
  
     const getMapQueryDress = () => {
      const items = [];
  
      if(valueColor){
        items.push({ name: 'colors', params: valueColor })
      }
  
      if(valueTypeEvent.length > 0){
        const vte = []
        for(let i = 0; i < valueTypeEvent.length; i++)
          vte.push(valueTypeEvent[i])
        items.push({ name: 'type_event_ids', params: vte.join(',') })
      }
  
      if(valueCalendar.length > 0 && valueCalendar[0] !== ''){
        items.push({ name: 'date_start', params: moment(valueCalendar[0]).format('YYYY/MM/DD') })
        items.push({ name: 'date_end', params: moment(valueCalendar[1]).format('YYYY/MM/DD') })
      }
  
      if(valueBrand.length > 0){
        const brd = []
        for(let i = 0; i < valueBrand.length; i++)
          brd.push(valueBrand[i])
        items.push({ name: 'marca_ids', params: brd.join(',') })
      }
  
      if(valueLength.length > 0){
        const vlg = []
        for(let i = 0; i < valueLength.length; i++)
          vlg.push(valueLength[i])
        items.push({ name: 'largo_ids', params: vlg.join(',') })
      }
  
      if(valueSizes.length > 0){
        const vsz = []
        for(let i = 0; i < valueSizes.length; i++)
          vsz.push(valueSizes[i])
        items.push({ name: 'talla_ids', params: vsz.join(',') })
      }
  
      if(valueNecklines.length > 0){
        const vnk = []
        for(let i = 0; i < valueNecklines.length; i++)
          vnk.push(valueNecklines[i])
        items.push({ name: 'escote_ids', params: vnk.join('') })
      }
  
      if(valuePrices.length > 0 && valuePrices[0] !== ''){
        items.push({ name: 'min_precio', params: valuePrices[0] })
        items.push({ name: 'max_precio', params: valuePrices[1] })
      }
  
      return items
    }
  
    useEffect(() => {
      if(getMapQueryDress().length > 0){
        setParamsToUrl(navigate, "/favoritos", getMapQueryDress());
      }
    },[
      valueColor,
      valueTypeEvent, 
      valueCalendar,
      valueBrand,
      valueLength,
      valueSizes,
      valueNecklines,
      valuePrices
    ])
  
    useEffect(() => {
      if(paginationFavorite && paginationFavorite?.data && typeof paginationFavorite.data === 'object'){
        refPage.current.scrollIntoView({ behavior: 'smooth' })
        setDataDress(Object.values(paginationFavorite.data))
      }
    },[paginationFavorite])
  
    const fetchData = () => {
      const search = urlSearchParams(window.location.href)
      let query = ''
      if(search && search.object){
        Object.keys(search.object).map(item => {
          query += `&${item}=${search.object[item]}`
          return null
        })
      }
      //`?favorite=true&page=${page}&per_page=${perPage}${query}`
      dispatch(getFavoritesPagination(`?page=${page}&per_page=${perPage}${query}`));
    };
  
    useEffect(() => {
      if(location.state?.itemView && itemView.length <= 0){
        setItemView(location.state?.itemView)
      }
    },[location])
  
    useEffect(
      () => {
        fetchData();
      },
      [page, perPage, window.location.href]
    );

  return (
    <>
      <div ref={refPage}>
        <section className={styles.navMobile}>
          <NavBarMobile isSelectedFavorite={true} />
        </section>
        <section className={styles.containerFavorites}>
          <span className={styles.titleFavorites}>
             Tus favoritos
          </span>
          <section className={styles.filterTopContainer}>
            <button className={styles.filtersButtons}>
                <img src={filtersIcon} alt="" />
                Filtros
            </button>
            <button>

            </button>
          </section>
          <section className={styles.allFavoritesContainer}>
          {dataDress &&
            dataDress.map((dress, index) => (
            <div key={index} className={styles.cardBackGround}>
              <CardDress
                dressModel={dress.dress_models ? dress.dress_models : {}}
                url={dress.dress_models.images ? dress.dress_models?.images[0]?.url : ''}
                id={dress.dress_models ? dress.dress_models.id : null}
                id_shop={dress.id}
              />
            </div>
          ))}
        </section >
        </section>
      </div>
      <FooterMobile />
    </>
  );
};

export default FavoritesMobile;
