import React from "react";
import Button from "../shared/button";
import { Title, TextDescription } from "../shared/text/Text";
import styles from './Modal.module.css';
import { modal } from "../modalManagment";
import moment from "moment";
import Input, { LabelInput } from "../shared/input/Input";
import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { getMonths } from "../../utils";
import api from "../../api/axios.config";

const ModalSettingDress = (props) => {
    const [setting, setSetting] = useState('');
    const [date, setDate] = useState('')
    const [step, setStep] = useState(1)

    const selectDate = (e) => {
        const date = new Date(e);
        setDate(date);
    };

    const formatMonthY = (locale, value) => {
        let resultMonth = getMonths(locale, value);
        return `${resultMonth} ${value.getFullYear()}`;
    };

    const onSubmit = async () => {
        await api.post(`/api/ajustes-pendientes/${props.app}`, { ajustes_prox: moment(date).format('YYYY-MM-DD'), ajustes_pendientes: setting })
        .then(resp => {
            if(resp.status === 200){
                modal.close()
                props.navigate('/citas-agendadas')
            }
        })
        .catch(e => {
            console.log(e)
        })
    }

    return (
        <div className={styles.containerModal}>
            <Title styles={styles.textModalTitle}>INICIO DE AJUSTES</Title>
            
            <TextDescription styles={styles.textDescription}>
                {step === 1 ? 'Para iniciar la etapa de ajustes, ingrese los ajustes que se harán al vestido.' : 'Por último, ingrese una fecha aproximada para la finalización de los ajustes.'}
            </TextDescription>

            {step === 1 &&
                <div style={{marginBottom: 24, marginTop: 24}}>
                    <LabelInput>Ajustes pendientes</LabelInput>
                    <Input type="text" placeholder="Escriba aquí" value={setting} onChange={(e) => setSetting(e.target.value)} />
                </div>
            }

            {step === 2 &&
                <div style={{marginBottom: 24, marginTop: 24}}>
                    <Calendar
                        onClickDay={selectDate}
                        value={date}
                        calendarType={"US"}
                        minDate={new Date()}
                        nextLabel=""
                        prevLabel=""
                        formatMonth={getMonths}
                        formatMonthYear={formatMonthY}
                        formatShortWeekday={(locale, value) =>
                            ["S", "M", "T", "W", "T", "F", "S"][value.getDay()]
                        }
                        tileDisabled={true ? 
                            ({ activeStartDate, date, view }) => date < (new Date()).getDate() - 1 : //active calendar from today
                            ({ activeStartDate, date, view }) => date < new Date("1/1/99999")  //inactive calendar
                        }
                    />
                </div>
            }

            <div className={styles.containButtons}>
                <Button label="Cancelar" outline styles={styles.buttonMiddle} onClick={() => modal.close()} />
                <Button label={step === 1 ? "Siguiente" : 'Continuar'} disabled={(step === 1 && !setting) || (step === 2 && !date)} styles={`${styles.buttonMiddle}`} onClick={() => step === 1 ? setStep(2) : onSubmit()} />
            </div>
        </div>
    )
}

export default ModalSettingDress;