import Placeholder from './assets/svg/PlaceholderDress.svg';

export const createKey = function(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export const getUniqueValues = function(array){
    return array.filter((value, index, self) => self.indexOf(value) === index);
}

export const format_currency = (value = 0) => {
  return Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(value)
}


export const getMonths = (locale, value) => {
    let Month = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ][value.getMonth()];
    return Month;
};

const paramsToObject = (entries) => {
  const result = {}
  for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export const urlSearchParams = (query = '') => {
  let params = query
  if(query.includes('?')){
    params = query.split('?')[1]
  }else{
    params = '';
  }

  const urlParams = new URLSearchParams(params);
  const entries = urlParams.entries();

  const paramsObject = paramsToObject(entries);

  return {
    object: paramsObject,
    keys: Object.keys(paramsObject),
    values: Object.values(paramsObject)
  }
}

const serialize = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const setParamsToUrl = (navigate, base = '', params = []) => {
  let search = ''

  for(let i = 0; i < params.length; i++){
    search = search + '&'+serialize({[params[i].name]: params[i].params })
  }

  navigate(`${base}?${search}`)
}

export const scrollToGoSection = (id) => {
  const section = document.querySelector('#'+id);
  section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
};

export const checkAvailityImg = (id) => {
    const image = document.querySelector(`#${id}`);
    const isLoaded = image.complete && image.naturalHeight !== 0;
    if(!isLoaded){
      image.src = Placeholder;
    }
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export const getType = (type) => {
  switch (type) {
    case 'dress':
      return 'Vestido'
    default:
      break;
  }
}