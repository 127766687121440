import React from 'react';
import styles from './Input.module.css';
import { Icon } from "@iconify/react";
import classNames from 'classnames';

const Input = (props) => (
    <input {...props} className={`${styles.styleInput} ${props.styles}`} />
)

const LabelInput = (props) => (
    <label {...props} className={`${styles.labelInput} ${props.styles}`}>{props.label}</label>
)

const InputSearch = (props) => (
    <div className={classNames(styles.containerInputSearch, props.styles)}>
        <Icon icon="akar-icons:search" color='rgba(182, 182, 182, 1)' />
        <input type="text" {...props} />
    </div>
)

const InputMoney = (props) => (
    <div className={classNames(styles.containerInputSearch, props.styles)}>
        $
        <input type="number" {...props} />
    </div>
)

export { LabelInput, InputSearch, InputMoney };
export default Input;