import React from "react";
import styles from "./termsAndConditionsDesktop.module.css";
import Navbar from "../navBarDesktop/NavBarDesktop";
import Footer from "../footer/Footer";
import CustomCheck from "../shared/customCheck/CustomCheck";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import api from "../../api/axios.config";
import { useEffect } from "react";

const TermsAndConditionsMobile = (props) => {
  const location = useLocation()
  const navigate = useNavigate();
  const [termsAvailable, setTermsAvailable] = useState(false);
  const [dataTerms, setDataTerms] = useState(null);

  const getTermAndCondition = () => {
    api.get(`/api/terms-conditions/${(location.state?.type === 'RENTA' || location.state === null) ? 'rent' : 'reservation'}`)
    .then(resp => {
      if(resp.status === 200){
        setDataTerms(resp.data.data)
      }
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    getTermAndCondition();
    window.scrollTo(0, 0);
  },[])

  console.log(location.state)
  return (
    <>
      <div>
        <section className={styles.navMobile}>
          <Navbar />
        </section>
        <section className={styles.containerTerms}>
          <span className={styles.titleTerms}>
            TÉRMINOS Y CONDICIONES DE {location.state?.type ? location.state?.type : "RENTA"}
          </span>
          <span className={styles.conditions}>Sobre estas condiciones</span>
          <div style={{overflow: 'hidden', maxWidth: '100%'}}>
              <div dangerouslySetInnerHTML={{__html: dataTerms}}></div>
          </div>
          {(location.state !== null && !location.state?.isDetails) && 
          <>
            <section className={styles.checkTerms}>
              <CustomCheck onChangeCallBack={()=>setTermsAvailable(!termsAvailable)}/>
              <span className={styles.textCheckConditions}>
                He leído las condiciones de {location.state ? (location.state?.type).toLowerCase() : 'renta'}
              </span>
            </section>
            <section className={styles.buttonsTermsArea}>
              <button className={styles.buttonCancel} onClick={() =>  navigate('/carrito')}>CANCELAR</button>
              <button className={styles.buttonContinue} onClick={() => navigate('/pago', { state: { ...location.state } })} disabled={!termsAvailable}>CONTINUAR</button>
            </section>
          </>
          }
        </section>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditionsMobile;
